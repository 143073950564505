import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getProfilerConfigCurrentDataSource,
  getProfilerConfigDataSourceSchemaList,
  getProfilerConfigSchemaListUsage,
  refreshTreeDataSourceNode,
  resetProfilerConfigDataSourceSchemaList,
  updateProfilerConfigDataSourceProfilerConfig,
  updateProfilerConfigSchemaList,
} from "../../../actions/profiler/profiler-action";
import { columnFn, safeToLowerCase } from "../../../components/entity-list/columns";
import { NgTextTooltip } from "../../../components/text-tooltip/ng-text-tooltip";
import Tooltip from "../../../components/tooltip/ng-tooltip";
import {
  EVENT,
  PAGE,
  getDatasourceDetailProps,
  trackEvent,
} from "../../../utils/telemetry";
import { ManageTabHeader, ManageTabNgTable } from "../manage-tabs";
import ProfilerConfirmationDialog from "../profiler-confirmation-dialog";
import { profilerEnabledFnSorter } from "../../../utils/profiler-util";
import { NgTableClickableText, NgTableTheme } from "../../../components/table/ng-table";
import { ProfilerSummaryTabKey } from "../utils";
import { getConnectionHealth } from "../../../utils/datasource";
import { ConnectionHealth } from "../../../utils/enums";
import useBatchUpdate from "../use-batch-update";
import NgButton from "../../../components/button/ng-button";
import useNavigationBlocker from "../../../hooks/useNavigationBlocker";
import Switch from "../../../atom/switch";

import "./profiler-data-source-manage-schemas-tab.scss";

const currentDataSourceRefreshInterval = 5000;

function ManualScanIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M1.16602 2.33341C1.16602 1.68908 1.68835 1.16675 2.33268 1.16675H4.66602C4.98818 1.16675 5.24935 1.42792 5.24935 1.75008C5.24935 2.07225 4.98818 2.33341 4.66602 2.33341H2.33268V4.66675C2.33268 4.98891 2.07152 5.25008 1.74935 5.25008C1.42718 5.25008 1.16602 4.98891 1.16602 4.66675V2.33341Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M1.74935 8.75008C2.07152 8.75008 2.33268 9.01125 2.33268 9.33341V11.6667H4.66602C4.98818 11.6667 5.24935 11.9279 5.24935 12.2501C5.24935 12.5722 4.98818 12.8334 4.66602 12.8334H2.33268C1.68836 12.8334 1.16602 12.3111 1.16602 11.6667V9.33341C1.16602 9.01125 1.42718 8.75008 1.74935 8.75008Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M12.8327 9.33341C12.8327 9.01125 12.5715 8.75008 12.2493 8.75008C11.9272 8.75008 11.666 9.01125 11.666 9.33341V11.6667H9.33268C9.01052 11.6667 8.74935 11.9279 8.74935 12.2501C8.74935 12.5722 9.01052 12.8334 9.33268 12.8334H11.666C12.3104 12.8334 12.8327 12.3111 12.8327 11.6667V9.33341Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M8.74935 1.75008C8.74935 1.42792 9.01052 1.16675 9.33268 1.16675H11.666C12.3104 1.16675 12.8327 1.68909 12.8327 2.33341V4.66675C12.8327 4.98891 12.5715 5.25008 12.2493 5.25008C11.9272 5.25008 11.666 4.98891 11.666 4.66675V2.33341H9.33268C9.01052 2.33341 8.74935 2.07225 8.74935 1.75008Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M4.08268 6.41675C3.76052 6.41675 3.49935 6.67791 3.49935 7.00008C3.49935 7.32225 3.76052 7.58341 4.08268 7.58341H9.91602C10.2382 7.58341 10.4993 7.32225 10.4993 7.00008C10.4993 6.67791 10.2382 6.41675 9.91602 6.41675H4.08268Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M5.54102 4.66675C5.54102 4.34458 5.80218 4.08341 6.12435 4.08341H7.87435C8.19651 4.08341 8.45768 4.34458 8.45768 4.66675C8.45768 4.98891 8.19651 5.25008 7.87435 5.25008H6.12435C5.80218 5.25008 5.54102 4.98891 5.54102 4.66675Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M6.12435 8.75008C5.80218 8.75008 5.54102 9.01125 5.54102 9.33341C5.54102 9.65558 5.80218 9.91675 6.12435 9.91675H7.87435C8.19651 9.91675 8.45768 9.65558 8.45768 9.33341C8.45768 9.01125 8.19651 8.75008 7.87435 8.75008H6.12435Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
}

function CancelScanIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M10.0381 3.96268C10.2659 4.19049 10.2659 4.55984 10.0381 4.78764L7.82561 7.00016L10.0381 9.21268C10.2659 9.44049 10.2659 9.80984 10.0381 10.0376C9.81032 10.2654 9.44098 10.2654 9.21317 10.0376L7.00065 7.82512L4.78813 10.0376C4.56032 10.2654 4.19098 10.2654 3.96317 10.0376C3.73537 9.80984 3.73537 9.44049 3.96317 9.21268L6.17569 7.00016L3.96317 4.78764C3.73537 4.55984 3.73537 4.19049 3.96317 3.96268C4.19098 3.73488 4.56032 3.73488 4.78813 3.96268L7.00065 6.17521L9.21317 3.96268C9.44098 3.73488 9.81032 3.73488 10.0381 3.96268Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583984 7.00016C0.583984 3.45634 3.45682 0.583496 7.00065 0.583496C10.5445 0.583496 13.4173 3.45634 13.4173 7.00016C13.4173 10.544 10.5445 13.4168 7.00065 13.4168C3.45682 13.4168 0.583984 10.544 0.583984 7.00016ZM7.00065 1.75016C4.10116 1.75016 1.75065 4.10067 1.75065 7.00016C1.75065 9.89966 4.10116 12.2502 7.00065 12.2502C9.90015 12.2502 12.2507 9.89966 12.2507 7.00016C12.2507 4.10067 9.90015 1.75016 7.00065 1.75016Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
}

function ProfilerDataSourceManageSchemasTab(props) {
  const {
    availableNodeKeys,
    config,
    dataSource,
    enableEdit,
    loading,
    schemaList,
    schemaListUsage,
    workspaceUuid,
    getProfilerConfigCurrentDataSource,
    getProfilerConfigSchemaList,
    getProfilerConfigSchemaListUsage,
    onCurrentSelectNodeChange,
    refreshTreeDataSourceNode,
    resetProfilerConfigDataSourceSchemaList,
    updateProfilerConfigDataSource,
    updateProfilerConfigSchemaList,
  } = props;

  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentSelectSchemaKeys, setCurrentSelectSchemaKeys] = useState([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [currentConfirmationDialogConfig, setCurrentConfirmationDialogConfig] =
    useState(null);
  const {
    currentEntityList: currentSchemaList,
    toggleAction,
    batchList,
    isDirty,
    reset,
  } = useBatchUpdate({
    entityList: schemaList,
    getEntityKey: (schema) => schema.uuid,
    entityUpdate: (schema, payload) => {
      const { actionType, enabled } = payload;
      if (actionType === "status") {
        return {
          ...schema,
          profilerConfig: {
            ...schema.profilerConfig,
            enabled,
          },
        };
      } else if (actionType === "tableListChange") {
        const oldProfilerConfig = schema.profilerConfig;
        const oldTableListConfig = oldProfilerConfig.tableListChange || {};
        return {
          ...schema,
          profilerConfig: {
            ...oldProfilerConfig,
            tableListChange: {
              ...oldTableListConfig,
              enabled,
            },
          },
        };
      } else {
        console.log(`Unknown type ${actionType}`);
      }
    },
    ignoreKeys: [
      "firstSeenTs",
      "lastSeenTs",
      "lastScannedTs",
      "lastTablesScannedTs",
      "removedTs",
    ],
  });

  useNavigationBlocker(
    isDirty,
    "You have unsaved changes. Do you want to leave without saving?",
    reset
  );

  useEffect(() => {
    let timeoutId = null;
    if (config.dataSource) {
      const loadCurrentDataSourceAndSchemaList = () => {
        getProfilerConfigCurrentDataSource(
          workspaceUuid,
          config.dataSource.metadata.uuid
        ).then((currentDataSource) => {
          if (getConnectionHealth(currentDataSource) === ConnectionHealth.UNKNOWN) {
            // keep fetching the currentDataSource if the connection status is Unknown
            timeoutId = setTimeout(() => {
              loadCurrentDataSourceAndSchemaList();
            }, currentDataSourceRefreshInterval);
          } else {
            getProfilerConfigSchemaList(workspaceUuid, config.dataSource);
          }
        });
      };

      if (
        enableEdit ||
        getConnectionHealth(config.dataSource) === ConnectionHealth.UNKNOWN
      ) {
        loadCurrentDataSourceAndSchemaList();
      } else {
        getProfilerConfigSchemaList(workspaceUuid, config.dataSource);
      }
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      resetProfilerConfigDataSourceSchemaList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.dataSource?.metadata?.uuid]);

  if (!config.dataSource) {
    console.log("No data source is provided");
    return null;
  }

  if (!dataSource || dataSource.metadata.uuid !== config.dataSource.metadata.uuid) {
    console.log("Loading existing data source");
    return null;
  }

  function onSelectChange(newSelectedRowKeys) {
    setCurrentSelectSchemaKeys(newSelectedRowKeys);
  }

  const rowSelection = {
    selectedRowKeys: currentSelectSchemaKeys,
    onChange: onSelectChange,
  };

  function updateSchemas(workspaceUuid, dataSource, paramsList) {
    setIsUpdateInProgress(true);
    updateProfilerConfigSchemaList(workspaceUuid, dataSource, paramsList).finally(
      () => {
        setIsUpdateInProgress(false);
        getProfilerConfigSchemaList(workspaceUuid, config.dataSource);
      }
    );
  }

  function onProfilerConfigChange(currentSchema, actionType, enabled) {
    const currentBulkSchemaUuids =
      currentSelectSchemaKeys.length > 0
        ? currentSelectSchemaKeys
        : [currentSchema.uuid];

    toggleAction(currentBulkSchemaUuids, { actionType, enabled });
  }

  function trackChanges(paramsList) {
    const getChanges = (original, changed, getStatus) => {
      const changeMap = Object.fromEntries(
        changed.map((schema) => [schema.uuid, getStatus(schema)])
      );
      let enabled = 0,
        disabled = 0;
      for (const schema of original) {
        if (schema.uuid in changeMap && changeMap[schema.uuid] !== getStatus(schema)) {
          if (changeMap[schema.uuid]) {
            enabled += 1;
          } else {
            disabled += 1;
          }
        }
      }
      return { enabled, disabled };
    };

    const schemaStatusStats = getChanges(
      schemaList,
      paramsList,
      (schema) => schema.profilerConfig?.enabled
    );
    const schemaTableListStatusStats = getChanges(
      schemaList,
      paramsList,
      (schema) => schema.profilerConfig?.tableListChange?.enabled
    );

    if (schemaStatusStats.disabled > 0) {
      trackEvent(
        schemaStatusStats.disabled > 1
          ? EVENT.DEACTIVATE_SCHEMA_BULK
          : EVENT.DEACTIVATE_SCHEMA,
        {
          ...getDatasourceDetailProps(dataSource),
          page: PAGE.MANAGE_SCHEMAS,
        }
      );
    }

    if (schemaStatusStats.enabled > 0) {
      trackEvent(
        schemaStatusStats.enabled > 1
          ? EVENT.ACTIVATE_SCHEMA_BULK
          : EVENT.ACTIVATE_SCHEMA,
        {
          ...getDatasourceDetailProps(dataSource),
          page: PAGE.MANAGE_SCHEMAS,
        }
      );
    }

    if (schemaTableListStatusStats.disabled > 0) {
      trackEvent(
        schemaTableListStatusStats.disabled > 1
          ? EVENT.DISABLE_SCHEMA_ACTIVITY_BULK
          : EVENT.DISABLE_SCHEMA_ACTIVITY,
        {
          ...getDatasourceDetailProps(dataSource),
          page: PAGE.MANAGE_SCHEMAS,
        }
      );
    }

    if (schemaTableListStatusStats.enabled > 0) {
      trackEvent(
        schemaTableListStatusStats.enabled > 1
          ? EVENT.ENABLE_SCHEMA_ACTIVITY_BULK
          : EVENT.ENABLE_SCHEMA_ACTIVITY,
        {
          ...getDatasourceDetailProps(dataSource),
          page: PAGE.MANAGE_SCHEMAS,
        }
      );
    }
  }

  function saveChanges() {
    const schemasToDisable = batchList.filter(
      (schema) => !schema.profilerConfig?.enabled
    );

    if (schemasToDisable.length > 0) {
      getProfilerConfigSchemaListUsage(
        workspaceUuid,
        dataSource,
        schemasToDisable.map((schema) => schema.uuid)
      );
      const indicatorName = schemasToDisable.map(({ name }) => name).join(", ");
      const currentConfirmationDialogConfig = {
        defaultConfirmationMsg: `You are about to deactivate schema ${indicatorName}, which has no metrics or monitors`,
        context: batchList,
      };

      setIsConfirmationDialogOpen(true);
      setCurrentConfirmationDialogConfig(currentConfirmationDialogConfig);
    } else if (batchList.length > 0) {
      updateSchemas(workspaceUuid, dataSource, batchList);
      trackChanges(batchList);
    }
  }

  function onStatusChange(schema, newIsEnabled) {
    onProfilerConfigChange(schema, "status", newIsEnabled);
  }

  function onTableListChange(schema, newIsEnabled) {
    onProfilerConfigChange(schema, "tableListChange", newIsEnabled);
  }

  const columns = [
    columnFn({
      title: (
        <Tooltip title="Toggle to make schema active or inactive in the explorer tree">
          Active
        </Tooltip>
      ),
      key: "profilerConfig",
      width: 80,
      render: function (profilerConfig, data) {
        const { enabled = false } = profilerConfig;
        return (
          <Switch
            size="small"
            checked={enabled}
            onChange={onStatusChange.bind(null, data)}
            disabled={!enableEdit || isUpdateInProgress}
          />
        );
      },
      getCompareVal: (indexedValue, _row) => (indexedValue?.enabled ? 1 : 0),
    })({ dataIndex: "profilerConfig" }),
    columnFn({
      title: "Schema",
      key: "name",
      render: function (name, row) {
        return availableNodeKeys.has(row.uuid) ? (
          <NgTableClickableText
            onClick={() =>
              onCurrentSelectNodeChange(
                { dataSource, schemaInfo: row },
                ProfilerSummaryTabKey.MANAGE_TABLES
              )
            }
          >
            {name}
          </NgTableClickableText>
        ) : (
          <NgTextTooltip>{name}</NgTextTooltip>
        );
      },
      getCompareVal: (indexedValue, _row) => safeToLowerCase(indexedValue),
      defaultSortOrder: "ascend",
    })({ dataIndex: "name" }),
    {
      title: (
        <Tooltip title="Table activity measures tables added and removed from the schema">
          Table Activity
        </Tooltip>
      ),
      key: "profilerConfigActivity",
      dataIndex: "profilerConfig",
      width: 145,
      render: function (profilerConfig, data) {
        const enabled = profilerConfig?.tableListChange?.enabled;
        return (
          <Switch
            size="small"
            checked={enabled}
            onChange={onTableListChange.bind(null, data)}
            disabled={!profilerConfig.enabled || !enableEdit || isUpdateInProgress}
          />
        );
      },
      sorter: {
        compare: profilerEnabledFnSorter((row) =>
          row.profilerConfig?.tableListChange?.enabled ? 1 : 0
        ),
      },
    },
  ];

  const filteredSchemaList =
    searchText.length > 0
      ? currentSchemaList.filter(
          ({ name }) => name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
      : currentSchemaList;

  const forceScan = dataSource.config.profiler.forceScan;
  function handleForceScanClick(newForceScan) {
    trackEvent(EVENT.RUN_MANUAL_SCAN, {
      ...getDatasourceDetailProps(dataSource),
      page: PAGE.MANAGE_SCHEMAS,
    });
    updateProfilerConfigDataSource(workspaceUuid, dataSource.metadata.uuid, {
      ...dataSource.config.profiler,
      forceScan: newForceScan,
    }).then(() => {
      refreshTreeDataSourceNode(workspaceUuid, dataSource);
    });
  }

  const scanActionComponent = (
    <div onClick={handleForceScanClick.bind(null, !forceScan)}>
      {!forceScan ? (
        <div className="profiler-manage-schemas-scan-action-container">
          <ManualScanIcon />
          Run manual scan
        </div>
      ) : (
        <>
          <div className="profiler-manage-schemas-scan-action-container">
            <CancelScanIcon />
            Cancel scan
          </div>
          <div className="profiler-manage-schemas-content-header-sub-title-container">
            Running Scan...
          </div>
        </>
      )}
    </div>
  );

  const headerControls = (
    <>
      <NgButton
        outline
        testId="manage-schema-cancel-button"
        disabled={!isDirty || isUpdateInProgress}
        onClick={reset}
      >
        Cancel
      </NgButton>
      <NgButton
        testId="manage-schema-save-button"
        disabled={!isDirty || isUpdateInProgress}
        onClick={saveChanges}
      >
        {isUpdateInProgress ? "Saving..." : "Save"}
      </NgButton>
    </>
  );

  return (
    <div className="profiler-manage-schemas">
      <ManageTabHeader
        entityName="schema"
        lastScannedTs={dataSource?.status?.lastScannedTs}
        lastScannedStatus={dataSource?.status?.lastScannedStatus}
        searchText={searchText}
        setSearchText={setSearchText}
        leftControls={enableEdit && scanActionComponent}
        rightControls={headerControls}
      />
      <div className="profiler-manage-schemas-table-container">
        <ManageTabNgTable
          entityName="schema"
          theme={NgTableTheme.DARK}
          rowSelection={rowSelection}
          dataSource={filteredSchemaList}
          columns={columns}
          rowKey={"uuid"}
          loading={loading}
          isFiltered={currentSchemaList.length !== filteredSchemaList.length}
        />
      </div>
      {isConfirmationDialogOpen && currentConfirmationDialogConfig && (
        <ProfilerConfirmationDialog
          modalIsOpen={isConfirmationDialogOpen}
          setIsOpen={(isConfirmationDialogOpen) =>
            setIsConfirmationDialogOpen(isConfirmationDialogOpen)
          }
          okClicked={(paramsList) => {
            updateSchemas(workspaceUuid, dataSource, paramsList);
            trackChanges(paramsList);
          }}
          usage={schemaListUsage}
          context={currentConfirmationDialogConfig.context}
          defaultConfirmationMsg={
            currentConfirmationDialogConfig.defaultConfirmationMsg
          }
          enableUsage={currentConfirmationDialogConfig.enableUsage}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.profilerReducer.profilerConfigDataSourceSchemaList.loading,
  schemaList: state.profilerReducer.profilerConfigDataSourceSchemaList.data,
  schemaListUsage: state.profilerReducer.profilerConfigDataSourceSchemaListUsage,
  currentDataSource: state.profilerReducer.profilerConfigCurrentDataSource,
});

const mapDispatchToProps = (dispatch) => ({
  getProfilerConfigCurrentDataSource: (workspaceUuid, dataSourceUuid) =>
    dispatch(getProfilerConfigCurrentDataSource(workspaceUuid, dataSourceUuid)),
  getProfilerConfigSchemaList: (workspaceUuid, dataSource) =>
    dispatch(getProfilerConfigDataSourceSchemaList(workspaceUuid, dataSource)),
  getProfilerConfigSchemaListUsage: (workspaceUuid, dataSource, tableUuids) =>
    dispatch(getProfilerConfigSchemaListUsage(workspaceUuid, dataSource, tableUuids)),
  updateProfilerConfigDataSource: (
    workspaceUuid,
    dataSourceUuid,
    dataSourceProfilerConfig
  ) =>
    dispatch(
      updateProfilerConfigDataSourceProfilerConfig(
        workspaceUuid,
        dataSourceUuid,
        dataSourceProfilerConfig
      )
    ),
  updateProfilerConfigSchemaList: (workspaceUuid, dataSource, paramsList) =>
    dispatch(
      updateProfilerConfigSchemaList(workspaceUuid, dataSource, paramsList, true)
    ),
  refreshTreeDataSourceNode: (workspaceUuid, dataSourceUuid) =>
    dispatch(refreshTreeDataSourceNode(workspaceUuid, dataSourceUuid)),
  resetProfilerConfigDataSourceSchemaList: () =>
    dispatch(resetProfilerConfigDataSourceSchemaList()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilerDataSourceManageSchemasTab);
