import { CompassIcon } from "../icons/compass-icon/";
import { ChartLineUpIcon } from "../icons/chart-line-up-icon/";
import { ListNumbersIcon } from "../icons/list-numbers-icon/";
import { WarningIcon } from "../icons/warning-icon/";
import { SquaresFourIcon } from "../icons/squares-four-icon/";
import { DataBaseIcon } from "../icons/database-icon/";
import { ListChecksIcon } from "../icons/list-checks-icon/";
import { CalendarMinusIcon } from "../icons/calendar-minus-icon/";
import { PlugsIcon } from "../icons/plugs-icon/";
import { ListBulletsIcon } from "../icons/list-bullets-icon/";
import { NotePencilIcon } from "../icons/note-pencil-icon/";
import { URIPath } from "../../utils/uri-path";

export const SideBarNavigationAction = Object.freeze({
  EXPLORER: "explorer",
  METRICS: "metrics",
  MONITORS: "monitors",
  INCIDENTS: "incidents",
  DASHBOARD: "dashboard",
  DATA_SOURCE: "datasource",
  QUERY_GOVERNANCE: "queryGovernance",
  ALERT: "alert",
  MEMBER: "member",
  INTEGRATION: "integration",
  SCHEDULE: "schedule",
  DRAFTS: "drafts",
});

export const WorkspaceItemKeyToUriMapper = {
  [SideBarNavigationAction.EXPLORER]: URIPath.EXPLORER,
  [SideBarNavigationAction.METRICS]: URIPath.NG_METRIC_LIST,
  [SideBarNavigationAction.MONITORS]: URIPath.NG_MONITOR_LIST,
  [SideBarNavigationAction.INCIDENTS]: URIPath.NG_INCIDENT_LIST,
  [SideBarNavigationAction.DASHBOARD]: URIPath.DASHBOARD,
  [SideBarNavigationAction.DATA_SOURCE]: URIPath.DATA_SOURCE_LIST,
  [SideBarNavigationAction.QUERY_GOVERNANCE]: URIPath.QUERY_GOVERNANCE,
  [SideBarNavigationAction.ALERT]: URIPath.ALERT_LIST,
  [SideBarNavigationAction.INTEGRATION]: URIPath.INTEGRATION_LIST,
  [SideBarNavigationAction.SCHEDULE]: URIPath.SCHEDULE_LIST,
  [SideBarNavigationAction.DRAFTS]: URIPath.NG_DRAFT_LIST,
};

export const WorkspaceItemKeyToLabelMapper = {
  [SideBarNavigationAction.EXPLORER]: "Explorer",
  [SideBarNavigationAction.METRICS]: "Metrics",
  [SideBarNavigationAction.MONITORS]: "Monitors",
  [SideBarNavigationAction.INCIDENTS]: "Incidents",
  [SideBarNavigationAction.DASHBOARD]: "Dashboards",
  [SideBarNavigationAction.DATA_SOURCE]: "Datasources",
  [SideBarNavigationAction.QUERY_GOVERNANCE]: "Query Governance",
  [SideBarNavigationAction.ALERT]: "System Events",
  [SideBarNavigationAction.INTEGRATION]: "Integrations",
  [SideBarNavigationAction.SCHEDULE]: "Schedules",
  [SideBarNavigationAction.DRAFTS]: "Drafts",
};

export const WorkspaceItemKeyToIconMapper = {
  [SideBarNavigationAction.EXPLORER]: CompassIcon,
  [SideBarNavigationAction.METRICS]: ChartLineUpIcon,
  [SideBarNavigationAction.MONITORS]: ListNumbersIcon,
  [SideBarNavigationAction.INCIDENTS]: WarningIcon,
  [SideBarNavigationAction.DASHBOARD]: SquaresFourIcon,
  [SideBarNavigationAction.DATA_SOURCE]: DataBaseIcon,
  [SideBarNavigationAction.QUERY_GOVERNANCE]: ListChecksIcon,
  [SideBarNavigationAction.ALERT]: CalendarMinusIcon,
  [SideBarNavigationAction.INTEGRATION]: PlugsIcon,
  [SideBarNavigationAction.SCHEDULE]: ListBulletsIcon,
  [SideBarNavigationAction.DRAFTS]: NotePencilIcon,
};

export const SideBarMenuItemNodeType = Object.freeze({
  WORKSPACE: "workspace",
  ADMIN: "admin",
  CREATE_WORKSPACE: "createWorkspace",
});

export const SidebarMenuItemNodeTypeToUriMapper = {
  [SideBarMenuItemNodeType.ADMIN]: URIPath.ADMIN,
  [SideBarMenuItemNodeType.CREATE_WORKSPACE]: URIPath.CREATE_WORKSPACE,
};
