import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TimeRangeSelector from "../../components/time-range-selector/";
import getUnixTime from "date-fns/getUnixTime";
import {
  getDefaultUserSetting,
  getQueryStringFromUserSetting,
  getUserSettingFromQueryString,
} from "../../utils/user-setting";
import IncidentListEntityTable from "./incident-list-entity-table";
import { collectStats } from "../../utils/iterables";
import { IncidentStatus } from "../../utils/enums";
import { NowContext } from "../../utils/now";
import {
  getOptionByValue,
  incidentDirectionOptions,
  incidentSeverityOptions,
  incidentStatusOptions,
  incidentValidationStatusOptions,
} from "../../utils/options";
import { incidentFields } from "../../components/search/use-search/use-search-incidents-config";

import "./incident-list.scss";

function countRowStatus(row, targetStatus) {
  return row.incidentData.status === targetStatus ? 1 : 0;
}

function getRowStats(rows) {
  return collectStats(rows, {
    Total: (_row) => 1,
    Active: (row) => (row.incidentData.ongoing ? 1 : 0),
    Unviewed: (row) => countRowStatus(row, IncidentStatus.UNVIEWED),
    Viewed: (row) => countRowStatus(row, IncidentStatus.VIEWED),
    Closed: (row) => countRowStatus(row, IncidentStatus.CLOSED),
    Rejected: (row) => countRowStatus(row, IncidentStatus.REJECTED),
    Submitted: (row) => countRowStatus(row, IncidentStatus.SUBMITTED),
  });
}

class IncidentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      incidentList: props.incidentList,
      incidentListUserSetting: {},
      userSetting: null,
      workspaceUuid: "",
      isUpdateNeed: false,
    };

    this.onDisplayTimeRangeChange = this.onDisplayTimeRangeChange.bind(this);

    this.onOptionsSaved = this.onOptionsSaved.bind(this);

    this.onLocalFilterSaved = this.onLocalFilterSaved.bind(this);
  }

  componentDidMount() {
    const {
      incidentListUserSetting,
      match: {
        params: { workspaceUuid },
      },
    } = this.props;

    this.props.getDataSourceList(workspaceUuid);

    const currentWorkspaceUserSetting = IncidentList.loadUserSetting(
      getDefaultUserSetting(),
      this.props
    );
    IncidentList.updateHomeUrl(
      getQueryStringFromUserSetting(currentWorkspaceUserSetting),
      this.props
    );

    this.props.updateIncidentListUserSetting({
      ...incidentListUserSetting,
      [workspaceUuid]: currentWorkspaceUserSetting,
    });
  }

  static loadUserSetting(baseUserSetting, props) {
    return getUserSettingFromQueryString(baseUserSetting, props.location.search, {
      compatibilityLocalFilterSetting: {
        [incidentFields.INCIDENT_STATUS]: (statusList) =>
          statusList.map((item) =>
            typeof item === "number"
              ? getOptionByValue(incidentStatusOptions, item)?.label
              : item
          ),
        [incidentFields.SEVERITY]: (severityList) =>
          severityList.map((item) =>
            typeof item === "number"
              ? getOptionByValue(incidentSeverityOptions, item)?.label
              : item
          ),
        [incidentFields.DIRECTION]: (directionList) =>
          directionList.map((item) =>
            typeof item === "number"
              ? getOptionByValue(incidentDirectionOptions, item)?.label
              : item
          ),
        [incidentFields.VALIDATION_STATUS]: (validationStatusList) =>
          validationStatusList.map(
            (item) =>
              getOptionByValue(incidentValidationStatusOptions, item)?.label ?? item
          ),
      },
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {
      incidentList,
      incidentListUserSetting,
      match: {
        params: { workspaceUuid },
      },
    } = props;

    if (workspaceUuid !== state.workspaceUuid) {
      return {
        ...state,
        workspaceUuid,
      };
    } else if (incidentListUserSetting !== state.incidentListUserSetting) {
      const newUserSetting = incidentListUserSetting[workspaceUuid] || null;
      if (newUserSetting) {
        IncidentList.updateIncidents(workspaceUuid, newUserSetting, props);
      } else {
        console.log(`No user setting for workspaceUuid ${workspaceUuid}`);
      }

      return {
        ...state,
        incidentListUserSetting,
        userSetting: newUserSetting,
      };
    } else if (incidentList !== state.incidentList) {
      return {
        ...state,
        incidentList,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  updateUserSetting(partialUserSetting) {
    const { workspaceUuid, userSetting, incidentListUserSetting } = this.state;
    const newUserSetting = {
      ...userSetting,
      ...partialUserSetting,
    };
    const newQueryString = getQueryStringFromUserSetting(newUserSetting);
    IncidentList.updateHomeUrl(newQueryString, this.props);

    this.props.updateIncidentListUserSetting({
      ...incidentListUserSetting,
      [workspaceUuid]: newUserSetting,
    });
  }

  static updateHomeUrl(newQueryString, props) {
    const { location, history } = props;
    let newLocation = `${location.pathname}${newQueryString}`;
    history.replace(newLocation);
  }

  static updateIncidents(workspaceUuid, userSetting, props) {
    const {
      time: { startTime, endTime },
      customerOptions,
      fromAlertDigest,
    } = userSetting;
    const queryObject = {
      startTime: getUnixTime(startTime),
      endTime: getUnixTime(endTime),
      customerOptions,
      fromAlertDigest,
    };
    props.getIncidentList(workspaceUuid, queryObject);
  }

  onOptionsSaved(selectedOptions) {
    const customerOptions = { ...selectedOptions };
    this.updateUserSetting({
      customerOptions,
      currentPage: 0,
    });
  }

  onDisplayTimeRangeChange(newTime) {
    const oldTimeSetting = this.state.userSetting.time;
    this.updateUserSetting({
      time: {
        ...oldTimeSetting,
        ...newTime,
      },
      currentPage: 0,
    });
  }

  onSearchItemChange(newLocalFilterSetting) {
    this.onLocalFilterSaved(newLocalFilterSetting);
  }

  onLocalFilterSaved(newPartialLocalFilter) {
    const { localFilterSetting } = this.state.userSetting;
    const newLocalFilterSetting = {
      ...localFilterSetting,
      ...newPartialLocalFilter,
    };
    this.updateUserSetting({
      localFilterSetting: newLocalFilterSetting,
    });
  }

  render() {
    const { workspaceUuid, userSetting, incidentList } = this.state;
    if (!userSetting) {
      console.log(
        `Waiting for user setting for ${workspaceUuid} becoming initialized.`
      );
      return null;
    }

    const { time, localFilterSetting } = userSetting;

    const searchControls = (
      <TimeRangeSelector
        currentTime={this.context()}
        value={time}
        onChange={this.onDisplayTimeRangeChange}
      />
    );
    return (
      <div className="incident-list lightup-vertical-flex-container">
        <IncidentListEntityTable
          workspaceUserPermissions={this.props.workspaceUserPermissions}
          paginationParams={this.props.paginationParams}
          userId={this.props.userInfo.id}
          loading={this.props.loading}
          incidentList={incidentList}
          searchItem={localFilterSetting}
          searchControls={searchControls}
          getRowStats={getRowStats}
          onPaginationChange={(pagination) => {
            this.props.updatePaginationParams(pagination);
          }}
          onSearchItemChange={this.onSearchItemChange.bind(this)}
          onIncidentStatusChanged={this.props.updateHomeIncidentStatus.bind(
            this.props,
            workspaceUuid
          )}
          onIncidentValidationStatusChanged={this.props.updateHomeIncidentValidationStatus.bind(
            this.props,
            workspaceUuid
          )}
          incidentListPageConfiguration={this.props.incidentListPageConfiguration}
          getIncidentListPageConfiguration={this.props.getIncidentListPageConfiguration}
          updateIncidentListPageConfiguration={
            this.props.updateIncidentListPageConfiguration
          }
          dataSourceList={this.props.dataSourceList}
        />
      </div>
    );
  }
}
IncidentList.contextType = NowContext;

export default withRouter(IncidentList);
