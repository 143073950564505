import { getTimeZoneList } from "./time";
import {
  AppUserRole,
  DbColumType,
  IncidentDirection,
  IncidentScore,
  IncidentStatus,
  IncidentValidationStatus,
  MetricConfigType,
  TimeDurationUnit,
  WorkspaceUserRole,
  QueryScope,
  CollectionModeType,
  getCollectionModeTypeDisplayName,
  CollectionWindow,
  OpsGenieResponderType,
  OpsGeniePriorityType,
  getAppRoleDisplayName,
  getWorkspaceRoleDisplayName,
} from "./enums";
import {
  getIncidentStatusDisplayStr,
  getIncidentValidationStatusDisplayStr,
} from "./incident";

export const NO_SELECTION_OPTION_LABEL = "—";
export function getOptionByValue(options, value) {
  let result = null;
  let i = 0;
  for (i = 0; i < options.length; i++) {
    if (options[i].value === value) {
      result = options[i];
      break;
    }
  }

  return result;
}

export const TimezoneOptions = getTimeZoneList().map(function (timezone) {
  return {
    label: `(GMT ${timezone.utcOffsetStr}) ${timezone.name}`,
    value: timezone.name,
  };
});

export const directionOptions = [
  { value: "DOWN", label: "Lower" },
  { value: "UP", label: "Higher" },
  { value: "BOTH", label: "Lower or Higher" },
];

export const incidentStatusOptions = [
  IncidentStatus.UNVIEWED,
  IncidentStatus.VIEWED,
  IncidentStatus.REJECTED,
  IncidentStatus.SUBMITTED,
  IncidentStatus.CLOSED,
].map((value) => {
  return {
    label: getIncidentStatusDisplayStr(value),
    value,
  };
});

export const incidentValidationStatusOptions = [
  IncidentValidationStatus.UNRESOLVED,
  IncidentValidationStatus.RESOLVED,
  IncidentValidationStatus.NOT_RUNNING,
  IncidentValidationStatus.RUNNING,
  IncidentValidationStatus.ERROR,
].map((value) => {
  return {
    label: getIncidentValidationStatusDisplayStr(value),
    value,
  };
});

export const incidentDirectionOptions = [
  { label: "Both", value: IncidentDirection.BOTH },
  { label: "Down", value: IncidentDirection.DOWN },
  { label: "Up", value: IncidentDirection.UP },
];

export const incidentSeverityOptions = [
  { label: "Medium", value: IncidentScore.NORMAL },
  { label: "High", value: IncidentScore.HIGH },
];

export const workspaceRoleOptions = [
  WorkspaceUserRole.ADMIN,
  WorkspaceUserRole.EDITOR,
  WorkspaceUserRole.VIEWER,
  WorkspaceUserRole.OBSERVER,
].map((value) => ({ label: getWorkspaceRoleDisplayName(value), value }));

export const appRoleOptions = [
  AppUserRole.ADMIN,
  AppUserRole.EDITOR,
  AppUserRole.VIEWER,
].map((value) => ({ label: getAppRoleDisplayName(value), value }));

export const evalDelayOptions = [
  { label: "Second", value: TimeDurationUnit.SECONDS },
  { label: "Minute", value: TimeDurationUnit.MINUTES },
  { label: "Hour", value: TimeDurationUnit.HOURS },
  { label: "Day", value: TimeDurationUnit.DAYS },
  { label: "Week", value: TimeDurationUnit.WEEKS },
];

export const queryScopeOptions = [
  { label: "Incremental", value: QueryScope.TIME_RANGE },
  { label: "Full table", value: QueryScope.FULL_TABLE },
];

export const metricQueryScopeOptions = [
  { label: "Incremental", value: MetricConfigType.METRIC_CONFIG },
  { label: "Full table", value: MetricConfigType.FULL_TABLE_METRIC_CONFIG },
];

export const dataCollectionOptions = [
  CollectionModeType.TRIGGERED,
  CollectionModeType.SCHEDULED,
  CollectionModeType.CUSTOM_SCHEDULED,
].map((collectionModeType) => ({
  value: collectionModeType,
  label: getCollectionModeTypeDisplayName(collectionModeType),
}));

export function getDataCollectionOptions(opts = {}) {
  const { isBlobStorage = false, isRowByRow = false } = opts;
  if (isBlobStorage) {
    return dataCollectionOptions.filter(
      ({ value }) => value === CollectionModeType.SCHEDULED
    );
  }

  if (isRowByRow) {
    return dataCollectionOptions.filter(
      ({ value }) => value !== CollectionModeType.CUSTOM_SCHEDULED
    );
  }

  return dataCollectionOptions;
}

export const collectionWindowOptions = [
  { label: "Complete window", value: CollectionWindow.COMPLETE },
  { label: "Partial window", value: CollectionWindow.PARTIAL },
];

export const simplifiedCollectionWindowOptions = [
  { label: "Complete", value: CollectionWindow.COMPLETE },
  { label: "Partial", value: CollectionWindow.PARTIAL },
];

export function getGroupedOptions(options, optionsGroup) {
  const optionsResult = [];
  const groups = {};
  options.forEach((option) => {
    if (!optionsGroup.hasOwnProperty(option.value)) return;
    const groupName = optionsGroup[option.value];
    if (!groups.hasOwnProperty(groupName)) {
      groups[groupName] = { label: groupName, options: [] };
      optionsResult.push(groups[groupName]);
    }
    groups[groupName].options.push(option);
  });
  return optionsResult;
}

export const opsGeniePriorityOptions = [
  { value: OpsGeniePriorityType.P1, label: "P1-Critical" },
  { value: OpsGeniePriorityType.P2, label: "P2-High" },
  { value: OpsGeniePriorityType.P3, label: "P3-Moderate" },
  { value: OpsGeniePriorityType.P4, label: "P4-Low" },
  { value: OpsGeniePriorityType.P5, label: "P5-Informational" },
];

export const opsGenieResponderTypeOptions = [
  { value: OpsGenieResponderType.TEAM, label: "Team" },
  { value: OpsGenieResponderType.USER, label: "User" },
  { value: OpsGenieResponderType.ESCALATION, label: "Escalation" },
  { value: OpsGenieResponderType.SCHEDULE, label: "Schedule" },
];

export const DbColumTypeOptions = [
  { value: DbColumType.TIMESTAMP_TZ, label: "Timestamp tz" },
  { value: DbColumType.DATETIME, label: "Date time" },
  { value: DbColumType.DATE, label: "Date" },
];
