import React, { useState } from "react";
import FileUploaderZone from "../../file-uploader-zone/file-uploader-zone";
import { NgStepDescription } from "../../step/ng-step";
import Tabs, { TabPane } from "../../tabs/tabs";
import TextArea from "../../../atom/textarea";

import "./big-query-config-view.scss";

function BigQueryConfigView(props) {
  const { onChange } = props;

  const [currentTabName, setCurrentTabName] = useState("upload");

  return (
    <div className="data-source-wizard-big-query-container">
      <NgStepDescription>
        Provide your BigQuery service account key and test connection to connect to your
        datasource.
      </NgStepDescription>
      <Tabs activeKey={currentTabName} onChange={setCurrentTabName}>
        <TabPane tab="Upload" key="upload">
          <FileUploaderZone
            onChange={(currentInputFileContent) => {
              onChange &&
                currentInputFileContent &&
                onChange(currentInputFileContent.fileContent);
            }}
          />
        </TabPane>
        <TabPane tab="JSON" key="json">
          <div className="data-source-big-query-json-input">
            <div className="data-source-big-query-paste-json">
              Paste your service account key
            </div>
            <TextArea
              className="data-source-big-query-user-input-textarea"
              onChange={(evt) => {
                onChange && onChange(evt.target.value);
              }}
              rows={10}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default BigQueryConfigView;
