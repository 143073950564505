import { connect } from "react-redux";
import QueryGovernance from "./query-governance";
import {
  getDataSourceList,
  getDataSourceQueryList,
  updateDataSourceGovernance,
} from "../../../actions/datasource/data-source-action";
import { getScheduleList } from "../../../actions/schedule/schedule-action";
import {
  closeTakeover,
  openWorkspaceTakeover,
} from "../../../actions/takeover/takeover-action";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  dataSourceList: state.dataSourceReducer.dataSourceList,
  queryList: state.dataSourceReducer.currentDataSourceQueryList,
  scheduleList: state.scheduleReducer.scheduleList.data,
});

const mapDispatchToProps = (dispatch) => ({
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
  getDataSourceQueryList: (workspaceUuid, dataSourceUuid, options) =>
    dispatch(getDataSourceQueryList(workspaceUuid, dataSourceUuid, options)),
  getScheduleList: (workspaceUuid) => dispatch(getScheduleList(workspaceUuid)),
  updateDataSourceGovernance: (workspaceUuid, dataSource, newGovernance) =>
    dispatch(updateDataSourceGovernance(workspaceUuid, dataSource, newGovernance)),
  openWorkspaceTakeover: (takeoverElement, width, outsideClick) =>
    dispatch(openWorkspaceTakeover(takeoverElement, width, outsideClick)),
  closeTakeover: () => dispatch(closeTakeover()),
});

const QueryGovernanceEnhanced = connect(
  mapStateToProps,
  mapDispatchToProps
)(QueryGovernance);

export default QueryGovernanceEnhanced;
export const QueryGovernanceWithRouter = withRouter(QueryGovernanceEnhanced);
