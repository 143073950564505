export function CalendarMinusIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path
        d="M11.375 2.25H10.0625V1.8125C10.0625 1.69647 10.0164 1.58519 9.93436 1.50314C9.85231 1.42109 9.74103 1.375 9.625 1.375C9.50897 1.375 9.39769 1.42109 9.31564 1.50314C9.23359 1.58519 9.1875 1.69647 9.1875 1.8125V2.25H4.8125V1.8125C4.8125 1.69647 4.76641 1.58519 4.68436 1.50314C4.60231 1.42109 4.49103 1.375 4.375 1.375C4.25897 1.375 4.14769 1.42109 4.06564 1.50314C3.98359 1.58519 3.9375 1.69647 3.9375 1.8125V2.25H2.625C2.39294 2.25 2.17038 2.34219 2.00628 2.50628C1.84219 2.67038 1.75 2.89294 1.75 3.125V11.875C1.75 12.1071 1.84219 12.3296 2.00628 12.4937C2.17038 12.6578 2.39294 12.75 2.625 12.75H11.375C11.6071 12.75 11.8296 12.6578 11.9937 12.4937C12.1578 12.3296 12.25 12.1071 12.25 11.875V3.125C12.25 2.89294 12.1578 2.67038 11.9937 2.50628C11.8296 2.34219 11.6071 2.25 11.375 2.25ZM3.9375 3.125V3.5625C3.9375 3.67853 3.98359 3.78981 4.06564 3.87186C4.14769 3.95391 4.25897 4 4.375 4C4.49103 4 4.60231 3.95391 4.68436 3.87186C4.76641 3.78981 4.8125 3.67853 4.8125 3.5625V3.125H9.1875V3.5625C9.1875 3.67853 9.23359 3.78981 9.31564 3.87186C9.39769 3.95391 9.50897 4 9.625 4C9.74103 4 9.85231 3.95391 9.93436 3.87186C10.0164 3.78981 10.0625 3.67853 10.0625 3.5625V3.125H11.375V4.875H2.625V3.125H3.9375ZM11.375 11.875H2.625V5.75H11.375V11.875ZM8.75 8.8125C8.75 8.92853 8.70391 9.03981 8.62186 9.12186C8.53981 9.20391 8.42853 9.25 8.3125 9.25H5.6875C5.57147 9.25 5.46019 9.20391 5.37814 9.12186C5.29609 9.03981 5.25 8.92853 5.25 8.8125C5.25 8.69647 5.29609 8.58519 5.37814 8.50314C5.46019 8.42109 5.57147 8.375 5.6875 8.375H8.3125C8.42853 8.375 8.53981 8.42109 8.62186 8.50314C8.70391 8.58519 8.75 8.69647 8.75 8.8125Z"
        fill="#3F4050"
      />
    </svg>
  );
}
