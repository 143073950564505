import { connect } from "react-redux";
import {
  editKpi,
  getCurrentCustomSqlKpiTableColumnList,
  getCurrentKpiPartitionSampleData,
  getCurrentKpiQueryList,
  getCurrentKpiSampleDataSampleDataList,
  getCurrentKpiSampleDataTableSchemaList,
  getCurrentKpiSchemaList,
  getCurrentKpiSrcPartitionSampleData,
  getCurrentKpiSrcTableColumnList,
  getCurrentKpiSrcTableList,
  getCurrentKpiInColumnColumnList,
  getCurrentKpiTableColumnList,
  getCurrentKpiTableColumnValueList,
  getCurrentKpiTableList,
  getKpi,
  getKpiList,
  previewKpi,
  resetKPIStatus,
  setCurrentCustomSqlKpiTableColumnList,
  terminateKpiPreview,
  verifyKpiCustomSql,
  validateKpiFailingRecordsSql,
  addKpi,
} from "../../actions/kpi/kpi-action";
import { getTagList } from "../../actions/tag/tag-action";
import { getDataSourceList } from "../../actions/datasource/data-source-action";
import { getRuleList } from "../../actions/rule/rule-action";
import { getIntegrationList } from "../../actions/integration/integration-action";
import { deleteDraft } from "../../actions/draft/draft-action";

import EditKPIWizard from "./edit-kpi";

const mapStateToProps = (state) => ({
  waffle: state.userReducer.currentUserInfo.waffle,
  lastPreviewStatus: state.kpiReducer.lastPreviewStatus,
  lastPreviewTime: state.kpiReducer.lastPreviewTime,
  lastPreviewResult: state.kpiReducer.lastPreviewResult,
  dataSourceList: state.dataSourceReducer.dataSourceList.data,
  kpiList: state.kpiReducer.kpiList,
  currentEditKpi: state.kpiReducer.currentEditKpi,
  currentSchemaList: state.kpiReducer.currentKpiSchemaList,
  currentSrcSchemaList: state.kpiReducer.currentKpiSrcSchemaList,
  currentTableList: state.kpiReducer.currentKpiTableList,
  currentSrcTableList: state.kpiReducer.currentKpiSrcTableList,
  currentCustomSqlColumnList: state.kpiReducer.currentCustomSqlKpiTableColumnList,
  currentColumnList: state.kpiReducer.currentKpiTableColumnList,
  currentSrcColumnList: state.kpiReducer.currentKpiSrcTableColumnList,
  currentInColumnColumnList: state.kpiReducer.currentKpiInColumnColumnList,
  currentColumnValueList: state.kpiReducer.currentKpiTableColumnValueList,
  currentFullCompareSrcTableColumnValueList:
    state.kpiReducer.currentKpiFullCompareSrcTableColumnValueList,
  currentFullCompareTargetTableColumnValueList:
    state.kpiReducer.currentKpiFullCompareTargetTableColumnValueList,
  currentColumnInfo: state.kpiReducer.currentKpiColumnInfo,
  currentKpiQueryList: state.kpiReducer.currentKpiQueryList,
  currentKpiPartitionSampleData: state.kpiReducer.currentKpiPartitionSampleData,
  currentKpiSrcPartitionSampleData: state.kpiReducer.currentKpiSrcPartitionSampleData,
  currentKpiSampleDataTableSchemaList:
    state.kpiReducer.currentKpiSampleDataTableSchemaList,
  currentKpiSampleDataSampleDataList:
    state.kpiReducer.currentKpiSampleDataSampleDataList,
  currentKpiValidateFailingRecordsSqlResult:
    state.kpiReducer.currentKpiValidateFailingRecordsSqlResult,
  tagList: state.tagReducer.tagList,
  ruleList: state.ruleReducer.ruleList.data,
  workspaceList: state.adminReducer.userWorkspaceList.data,
  integrationList: state.integrationReducer.integrationList.data,
});

const mapDispatchToProps = (dispatch) => ({
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
  getKpiList: (workspaceUuid, isForceRefresh) =>
    dispatch(getKpiList(workspaceUuid, isForceRefresh)),
  getCurrentKpiSchemaList: (workspaceUuid, dataSource, isTarget) =>
    dispatch(getCurrentKpiSchemaList(workspaceUuid, dataSource, isTarget)),
  getCurrentKpiTableList: (workspaceUuid, dataSource) =>
    dispatch(getCurrentKpiTableList(workspaceUuid, dataSource)),
  getCurrentKpiSrcTableList: (workspaceUuid, dataSource) =>
    dispatch(getCurrentKpiSrcTableList(workspaceUuid, dataSource)),
  getCurrentKpiTableColumnList: (workspaceUuid, dataSourceUuid, tableUuid) =>
    dispatch(getCurrentKpiTableColumnList(workspaceUuid, dataSourceUuid, tableUuid)),
  resetCurrentCustomSqlKpiTableColumnList: () =>
    dispatch(setCurrentCustomSqlKpiTableColumnList([])),
  getCurrentCustomSqlKpiTableColumnList: (workspaceUuid, dataSource, tableUuid) =>
    dispatch(
      getCurrentCustomSqlKpiTableColumnList(workspaceUuid, dataSource, tableUuid)
    ),
  getCurrentKpiSrcTableColumnList: (workspaceUuid, dataSourceUuid, tableUuid) =>
    dispatch(getCurrentKpiSrcTableColumnList(workspaceUuid, dataSourceUuid, tableUuid)),
  getCurrentKpiInColumnColumnList: (workspaceUuid, dataSourceUuid, tableUuid) =>
    dispatch(getCurrentKpiInColumnColumnList(workspaceUuid, dataSourceUuid, tableUuid)),
  getCurrentKpiTableColumnValueList: (workspaceUuid, kpi) =>
    dispatch(getCurrentKpiTableColumnValueList(workspaceUuid, kpi)),
  getCurrentKpiQueryList: (workspaceUuid, kpi, options) =>
    dispatch(getCurrentKpiQueryList(workspaceUuid, kpi, options)),
  getKpi: (workspaceUuid, id, isClone) => dispatch(getKpi(workspaceUuid, id, isClone)),
  editKpi: (workspaceUuid, id, newKpi, isForce, silently) =>
    dispatch(editKpi(workspaceUuid, id, newKpi, isForce, silently)),
  previewKpi: (workspaceUuid, newKpi) => dispatch(previewKpi(workspaceUuid, newKpi)),
  terminateKpiPreview: (workspaceUuid, kpi) =>
    dispatch(terminateKpiPreview(workspaceUuid, kpi)),
  verifyKpiCustomSql: (workspaceUuid, kpi) =>
    dispatch(verifyKpiCustomSql(workspaceUuid, kpi)),
  validateKpiFailingRecordsSql: (workspaceUuid, kpi) =>
    dispatch(validateKpiFailingRecordsSql(workspaceUuid, kpi)),
  getCurrentKpiPartitionSampleData: (workspaceUuid, dataSourceUuid, table) =>
    dispatch(getCurrentKpiPartitionSampleData(workspaceUuid, dataSourceUuid, table)),
  getCurrentKpiSrcPartitionSampleData: (workspaceUuid, dataSourceUuid, table) =>
    dispatch(getCurrentKpiSrcPartitionSampleData(workspaceUuid, dataSourceUuid, table)),
  getCurrentKpiSampleDataTableSchemaList: (workspaceUuid, dataSourceUuid, tableUuid) =>
    dispatch(
      getCurrentKpiSampleDataTableSchemaList(workspaceUuid, dataSourceUuid, tableUuid)
    ),
  getCurrentKpiSampleDataSampleDataList: (workspaceUuid, requestObject) =>
    dispatch(getCurrentKpiSampleDataSampleDataList(workspaceUuid, requestObject)),
  resetKPIStatus: (options) => dispatch(resetKPIStatus(options)),
  getTagList: (workspaceUuid) => dispatch(getTagList(workspaceUuid)),
  getRuleList: (workspaceUuid) => dispatch(getRuleList(workspaceUuid)),
  getIntegrationList: (workspaceUuid) => dispatch(getIntegrationList(workspaceUuid)),
  addKpi: (workspaceUuid, newKpi) => dispatch(addKpi(workspaceUuid, newKpi)),
  deleteDraft: (workspaceUuid, draftMetric) =>
    dispatch(deleteDraft(workspaceUuid, draftMetric)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditKPIWizard);
