import React from "react";

import BodySmall from "../../elements/Typography/BodySmall";
import { TypographyColors } from "../../elements/Typography/enum";
import Link from "../../elements/Typography/Link";

import "./inputhelp.scss";

export default function InputHelp(props) {
  if (!props.helpText && !props.helpLink && !props.helpLinkText) {
    return null;
  }
  return (
    <div className="input-help-section" data-testid={props.testId}>
      {props.helpText && (
        <BodySmall color={TypographyColors.INFO2}>{props.helpText}</BodySmall>
      )}
      {props.helpLink && (
        <Link
          target="_blank"
          rel="noreferrer"
          href={props.helpLink}
          testId={props.testId}
        >
          {props.helpLinkText || props.helpLink}
        </Link>
      )}
    </div>
  );
}
