import React, { useEffect, useState } from "react";
import { getMetricTypeFromConfigData } from "../../components/metric/utils";
import { metricCategoryIconComponent } from "../../components/metric/fields/icons";
import { getURIInstance, URIPath } from "../../utils/uri-path";
import NgButton, { TextWithIcon } from "../../components/button/ng-button";
import { OpenNewIcon } from "./icons";
import { getKpiPromise } from "../../rest/kpi";
import { DraftType, MetricConfigType } from "../../utils/enums";
import { DIFF_STATES, sideBySide, sideObjectPatch } from "../../utils/compare";
import ConfigureMetricDiffSummary from "../../components/metric/summary-view/configure-metric-diff-summary";
import { Spinner } from "../../atom/spinner";
import Divider from "../../atom/divider";
import { classesName } from "../../utils/css";
import { NgTextTooltip } from "../../components/text-tooltip/ng-text-tooltip";

import "./draft-detail-panel.scss";

function MetricDetail(props) {
  const {
    metric,
    diffData,
    workspaceUuid,
    viewMetricText,
    kpiList,
    nameClassName = null,
    title = null,
    dataSourceList = [],
    queryScopeFieldExpectChange = null,
  } = props;
  const metricType = getMetricTypeFromConfigData(metric);
  const MetricTypeIcon = metricCategoryIconComponent(metricType);

  return (
    <div className="metric-detail-container">
      {Boolean(title) && <div className="metric-detail-title">{title}</div>}
      <div
        className={classesName(
          "config-summary-row-container",
          "metric-detail-header-container",
          nameClassName
        )}
      >
        <MetricTypeIcon width={14} height={14} />
        <NgTextTooltip>{metric.metadata.name}</NgTextTooltip>
      </div>
      <div className="metric-detail-content-container">
        <ConfigureMetricDiffSummary
          configData={metric}
          diffData={diffData}
          dataSourceList={dataSourceList}
          kpiList={kpiList}
          queryScopeFieldExpectChange={queryScopeFieldExpectChange}
        />
      </div>
      <div className="metric-detail-action-container">
        <NgButton
          block
          onClick={() => {
            const metricLink = getURIInstance(URIPath.EDIT_METRIC, {
              workspaceUuid,
              id: metric.metadata.uuid,
            });
            window.open(`/#${metricLink}`, "_blank");
          }}
        >
          <TextWithIcon icon={<OpenNewIcon width={16} height={16} />}>
            {viewMetricText}
          </TextWithIcon>
        </NgButton>
      </div>
    </div>
  );
}

function getIsQueryScopeChangin(origMetricConfig, newMetricConfig) {
  // This method determines whether the query scope is being changed between
  // full table and incremental modes. The flag will be used to simulate a change when
  // the query scope configuration is updated, but some related fields were not properly
  // removed and remain the same in the new configuration. These fields should appear as removed or added.
  if (origMetricConfig.configType === MetricConfigType.FULL_COMPARE_METRIC_CONFIG) {
    return origMetricConfig.queryScope !== newMetricConfig.queryScope;
  }
  return origMetricConfig.configType !== newMetricConfig.configType;
}

function DraftDetailPanel(props) {
  const { workspaceUuid, data, closeTakeover, dataSourceList, kpiList } = props;
  const isUpdateDraft = data.draftMetadata?.type === DraftType.UPDATE;
  const [isLoading, setIsLoading] = useState(false);
  const [diffData, setDiffData] = useState(null);
  const [metricData, setMetricData] = useState(data);
  const [parentMetricData, setParentMetricData] = useState(null);
  const isDiff = Boolean(parentMetricData) && Boolean(diffData);

  useEffect(() => {
    if (isUpdateDraft && data.draftMetadata?.targetUuid) {
      setIsLoading(true);
      Promise.all([
        getKpiPromise(workspaceUuid, data.metadata.uuid),
        getKpiPromise(workspaceUuid, data.draftMetadata.targetUuid),
      ])
        .then(([currentMetricData, currentParentMetricData]) => {
          setMetricData(currentMetricData);
          setParentMetricData(currentParentMetricData);
          setDiffData({
            isNameDifferent:
              currentParentMetricData.metadata.name !== currentMetricData.metadata.name,
            isQueryScopeChangin: getIsQueryScopeChangin(
              currentParentMetricData.config,
              currentMetricData.config
            ),
            ...sideBySide(
              ...sideObjectPatch(
                currentParentMetricData.config,
                currentMetricData.config,
                {
                  seasonality: null, // force seasonality to be null if one side has it
                }
              )
            ),
          });
        })
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="draft-detail-panel-container">
      <div className="draft-detail-panel-header-container">
        <div className="draft-detail-panel-header-title-container">Details</div>
        <div
          className="draft-detail-panel-header-icon-container"
          onClick={() => closeTakeover()}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.24408 5.24408C5.56951 4.91864 6.09715 4.91864 6.42259 5.24408L10 8.82149L13.5774 5.24408C13.9028 4.91864 14.4305 4.91864 14.7559 5.24408C15.0814 5.56951 15.0814 6.09715 14.7559 6.42259L11.1785 10L14.7559 13.5774C15.0814 13.9028 15.0814 14.4305 14.7559 14.7559C14.4305 15.0814 13.9028 15.0814 13.5774 14.7559L10 11.1785L6.42259 14.7559C6.09715 15.0814 5.56951 15.0814 5.24408 14.7559C4.91864 14.4305 4.91864 13.9028 5.24408 13.5774L8.82149 10L5.24408 6.42259C4.91864 6.09715 4.91864 5.56951 5.24408 5.24408Z"
              fill="#121224"
            />
          </svg>
        </div>
      </div>
      <div className="draft-detail-panel-content-container">
        {isLoading ? (
          <div className="metric-detail-loader">
            <Spinner size="large" />
          </div>
        ) : (
          <>
            {data.type === "metric" && (
              <>
                {isDiff && (
                  <>
                    <MetricDetail
                      metric={parentMetricData}
                      workspaceUuid={workspaceUuid}
                      diffData={diffData?.left}
                      viewMetricText="View Target Metric"
                      nameClassName={diffData?.isNameDifferent && "deleted"}
                      title="TARGET"
                      dataSourceList={dataSourceList}
                      kpiList={kpiList}
                      queryScopeFieldExpectChange={
                        diffData?.isQueryScopeChangin ? DIFF_STATES.DELETED : null
                      }
                    />
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </>
                )}
                <MetricDetail
                  metric={metricData}
                  workspaceUuid={workspaceUuid}
                  diffData={diffData?.right}
                  viewMetricText="View Draft Metric"
                  nameClassName={diffData?.isNameDifferent && "added"}
                  title={isDiff && "DRAFT"}
                  dataSourceList={dataSourceList}
                  kpiList={kpiList}
                  queryScopeFieldExpectChange={
                    diffData?.isQueryScopeChangin ? DIFF_STATES.ADDED : null
                  }
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DraftDetailPanel;
