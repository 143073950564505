import * as types from "./user-action-types";
import {
  getWorkspaceUserListPromise,
  addWorkspaceUserPromise,
  patchWorkspaceUserPromise,
  deleteWorkspaceUserPromise,
  getAppConfigPromise,
  getAppHealthPromise,
  getCurrentUserPromise,
  loginUserPromise,
  logoutUserPromise,
  acceptUserDisclaimPromise,
  getUserWorkspacePermissionPromise,
} from "../../rest/user";
import {
  getApiCredentialPromise,
  addApiCredentialPromise,
  patchApiCredentialPromise,
  deleteApiCredentialPromise,
} from "../../rest/api-credential";
import { postUserInfoLoadedEffects } from "../../app/app";
import auth0, { UNVERIFIED_EMAIL_CODE } from "../../utils/auth0";
import { isFeatureEnabled } from "../../utils/general";
import { SupportedFeature } from "../../utils/enums";
import logger from "../../utils/logger";

export function setCurrentUserInfo(data) {
  return { type: types.SET_CURRENT_USER, userInfo: data };
}

export function setUserList(data) {
  return { type: types.SET_USER_LIST, userList: data };
}

export function setUserAPIKeyList(data) {
  return { type: types.SET_USER_API_KEY_LIST, userAPIKeyList: data };
}

export function setCurrentAddedUserAPIKey(data) {
  return {
    type: types.SET_CURRENT_ADDED_USER_API_KEY,
    currentAddedUserAPIKey: data,
  };
}

export function setUserPermissionMap(data) {
  return {
    type: types.SET_USER_PERMISSION_MAP,
    workspaceUserPermissionMap: data,
  };
}

function getCurrentUserFromServer(responseData) {
  return {
    disclaimerAccepted:
      responseData.profile && responseData.profile["disclaim_accepted"],
    email: responseData.email,
    id: responseData.id,
    firstName: responseData["first_name"] || "",
    lastName: responseData["last_name"] || "",
    isSuperuser: responseData.is_superuser,
    permissions: responseData.app_permissions || [],
    username: responseData.username,
    workspaces: responseData.workspaces,
    externalId: responseData?.profile?.external_id || "",
    expirationTimestamp: responseData?.profile?.expiration_timestamp,
    appRole: responseData["app_role"],
  };
}

export function getUserConfig() {
  return (dispatch) => {
    Promise.all([getAppConfigPromise(), getAppHealthPromise()])
      .then(async function ([appConfig, appHealth]) {
        const newUserInfo = {
          auth0ClientId: appConfig["auth0ClientId"],
          auth0DomainName: appConfig["auth0DomainName"],
          roles: appConfig["roles"],
          waffle: appConfig["waffle"] || {},
          baseWorkspace: appConfig["baseWorkspace"],
          companyName: appConfig["companyName"] || "Lightup",
          version: appHealth["version"] || "unknown",
          isLightupLite: appConfig["isLightupLite"] || false,
        };

        logger.toggle(
          isFeatureEnabled(newUserInfo.waffle, SupportedFeature.ENABLE_DEBUG_LOGS)
        );

        if (!!newUserInfo.auth0ClientId) {
          await auth0.init(newUserInfo.auth0ClientId, newUserInfo.auth0DomainName);
          const isAuth0SessionValid = await auth0.refreshAccessToken();
          if (!isAuth0SessionValid) {
            console.log("Auth0 session is timed out. Need to clear the django session");
            logoutUserPromise({ disableToast: true })
              .catch((err) => {
                console.log(`Fail to logout due to ${err}`);
              })
              .finally(() => {
                dispatch(
                  setCurrentUserInfo({
                    isConfigInitialized: true,
                    ...newUserInfo,
                  })
                );
              });
          } else {
            dispatch(
              setCurrentUserInfo({
                isConfigInitialized: true,
                ...newUserInfo,
              })
            );
          }
        } else {
          dispatch(
            setCurrentUserInfo({
              isConfigInitialized: true,
              ...newUserInfo,
            })
          );
        }
      })
      .catch(function (error) {
        console.log(`Fail to get user config for ${error}`);
        dispatch(
          setCurrentUserInfo({
            isConfigInitialized: true,
            isUserEmailUnverified: error.code === UNVERIFIED_EMAIL_CODE,
          })
        );
      });
  };
}

export function getUserInfo() {
  return (dispatch, getState) => {
    return getCurrentUserPromise()
      .then(function (currentUser) {
        const userInfo = {
          ...getCurrentUserFromServer(currentUser),
          isCurrentUserInitialized: true,
          loginSucceed: true,
          logoutSucceed: false,
        };

        const localStorageWorkspaceUuid = localStorage.getItem("@workspace-uuid");
        const userWorkspaces = userInfo?.workspaces || [];
        let currentWorkspaceUuid = "";
        if (
          localStorageWorkspaceUuid &&
          userWorkspaces.find(({ uuid }) => uuid === localStorageWorkspaceUuid)
        ) {
          currentWorkspaceUuid = localStorageWorkspaceUuid;
        } else if (userWorkspaces.length > 0) {
          currentWorkspaceUuid = userWorkspaces[0].uuid;
        } else if (userInfo.baseWorkspace && userInfo.baseWorkspace.uuid) {
          currentWorkspaceUuid = userInfo.baseWorkspace.uuid;
        }
        userInfo.currentWorkspaceUuid = currentWorkspaceUuid;

        dispatch(setCurrentUserInfo(userInfo));
        return userInfo;
      })
      .catch(function (error) {
        console.log(`Fail to get user info for ${error}`);
        dispatch(
          setCurrentUserInfo({
            isCurrentUserInitialized: true,
            loginSucceed: false,
            logoutSucceed: false,
          })
        );
      });
  };
}

export function updateUserWorkspaces() {
  return (dispatch, getState) => {
    return getCurrentUserPromise()
      .then(function (currentUser) {
        dispatch(
          setCurrentUserInfo({
            workspaces: currentUser.workspaces,
          })
        );
        return currentUser;
      })
      .catch(function (err) {
        console.log(`Fail to update user workspaces due to ${err}`);
      });
  };
}

export function getUserPermissionForAllWorkspace(workspaceList) {
  return (dispatch, getState) => {
    if (workspaceList.length === 0) {
      return;
    }

    Promise.all(
      workspaceList.map(({ uuid }) => getUserWorkspacePermissionPromise(uuid))
    )
      .then(function (allUserPermissionData) {
        const updatedUserPermissionMap = {};
        for (let i = 0; i < workspaceList.length; i++) {
          updatedUserPermissionMap[workspaceList[i].uuid] = {
            loading: false,
            data: allUserPermissionData[i],
          };
        }

        const { workspaceUserPermissionMap } = getState().userReducer;
        dispatch(
          setUserPermissionMap({
            ...workspaceUserPermissionMap,
            ...updatedUserPermissionMap,
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to get user permission for all workspaces for ${err}`);
      });
  };
}

export function getUserPermissionForWorkspace(workspaceUuid) {
  return (dispatch, getState) => {
    const { workspaceUserPermissionMap } = getState().userReducer;
    if (
      workspaceUserPermissionMap[workspaceUuid] &&
      workspaceUserPermissionMap[workspaceUuid].loading
    ) {
      console.log(`Skip loading user permission for ${workspaceUuid}`);
      return;
    }

    dispatch(
      setUserPermissionMap({
        ...workspaceUserPermissionMap,
        [workspaceUuid]: {
          loading: true,
          data: {
            isSuperuser: false,
            permissions: [],
          },
        },
      })
    );

    getUserWorkspacePermissionPromise(workspaceUuid)
      .then(function (userPermissionObject) {
        const { workspaceUserPermissionMap } = getState().userReducer;
        dispatch(
          setUserPermissionMap({
            ...workspaceUserPermissionMap,
            [workspaceUuid]: {
              loading: false,
              data: userPermissionObject,
            },
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to get user permission for ${workspaceUuid} due to ${err}`);
        delete workspaceUserPermissionMap[workspaceUuid];
        dispatch(
          setUserPermissionMap({
            ...workspaceUserPermissionMap,
          })
        );
      });
  };
}

export function getUserAPIKeyList() {
  return (dispatch) => {
    getApiCredentialPromise()
      .then(function (newApiKeyList) {
        dispatch(setUserAPIKeyList(newApiKeyList));
      })
      .catch(function (error) {
        console.log(`Fail to get user API credential list for ${error}`);
      });
  };
}

export function addUserAPIKey() {
  return (dispatch) => {
    addApiCredentialPromise()
      .then(function (newApiKey) {
        dispatch(getUserAPIKeyList());
        dispatch(setCurrentAddedUserAPIKey(newApiKey));
      })
      .catch(function (error) {
        console.log(`Fail to create user API credential for ${error}`);
      });
  };
}

export function deleteUserAPIKey(uuid) {
  return (dispatch) => {
    deleteApiCredentialPromise(uuid)
      .then(function (response) {
        dispatch(getUserAPIKeyList());
      })
      .catch(function (error) {
        console.log(`Fail to delete user API credential for ${error}`);
      });
  };
}

export function updateUserAPIKeyActiveStatus(uuid, active) {
  return (dispatch) => {
    patchApiCredentialPromise(uuid, { data: { active } })
      .then(function (response) {
        dispatch(getUserAPIKeyList());
      })
      .catch(function (error) {
        console.log(`Fail to update active status of user API credential for ${error}`);
      });
  };
}

export function resetCurrentAddedUserAPIKey() {
  return (dispatch) => {
    dispatch(setCurrentAddedUserAPIKey(null));
  };
}

export function login(authInfo) {
  return (dispatch, getState) => {
    const {
      currentUserInfo: { auth0ClientId },
    } = getState().userReducer;
    loginUserPromise({ auth_info: authInfo })
      .then(function (_response) {
        dispatch(getUserInfo()).then((userInfo) => {
          if (userInfo) {
            postUserInfoLoadedEffects(userInfo);
          }
        });
      })
      .catch(function (error) {
        console.log(`Fail to login for ${error}`);
        if (!!auth0ClientId) {
          console.log("Logging out of auth0 for un-entitled user.");
          auth0.logout();
        }
        dispatch(setCurrentUserInfo({ loginSucceed: false, logoutSucceed: false }));
      });
  };
}

export function logout() {
  return (dispatch) => {
    logoutUserPromise()
      .then(function (response) {
        dispatch(setCurrentUserInfo({ loginSucceed: false, logoutSucceed: true }));
      })
      .catch(function (error) {
        console.log(`Fail to logout for ${error}`);
      });
  };
}

export function getUserList(workspaceUuid, opts = {}) {
  return (dispatch) => {
    const { isRefresh = false } = opts;
    !isRefresh &&
      dispatch(
        setUserList({
          loading: true,
          data: [],
          updatedAt: Date.now(),
        })
      );
    getWorkspaceUserListPromise(workspaceUuid)
      .then(function (userList) {
        dispatch(
          setUserList({
            loading: false,
            data: userList,
            updatedAt: Date.now(),
          })
        );
      })
      .catch(function (error) {
        dispatch(
          setUserList({
            loading: false,
            data: [],
            updatedAt: Date.now(),
          })
        );
        console.log(`Fail to get user list for ${error}`);
      });
  };
}

export function addUser(workspaceUuid, newUser) {
  return (dispatch) => {
    addWorkspaceUserPromise(workspaceUuid, newUser)
      .then(function (response) {
        getUserList(workspaceUuid)(dispatch);
      })
      .catch(function (error) {
        console.log(`Fail to create user for ${error}`);
      });
  };
}

export function editUser(workspaceUuid, user) {
  return (dispatch) => {
    const { id, role } = user;
    if (role) {
      patchWorkspaceUserPromise(workspaceUuid, id, { role })
        .then(function (response) {
          getUserList(workspaceUuid)(dispatch);
        })
        .catch(function (error) {
          console.log(`Fail to edit user for ${error}`);
        });
    } else {
      console.log("Fail to edit user: role is not set");
    }
  };
}

export function removeUser(workspaceUuid, user) {
  return (dispatch) => {
    deleteWorkspaceUserPromise(workspaceUuid, user)
      .then(function (response) {
        getUserList(workspaceUuid)(dispatch);
      })
      .catch(function (error) {
        console.log(`Fail to create user for ${error}`);
      });
  };
}

export function updateUserDisclaimAccepted(disclaimAccepted) {
  return (dispatch) => {
    acceptUserDisclaimPromise()
      .then(function (response) {
        dispatch(setCurrentUserInfo({ disclaimerAccepted: true }));
      })
      .catch(function (error) {
        console.log(`Fail to update user disclaim status for ${error}`);
      });
  };
}

export function updateCurrentWorkspaceUuid(currentWorkspaceUuid) {
  return (dispatch) => {
    localStorage.setItem("@workspace-uuid", currentWorkspaceUuid);
    dispatch(setCurrentUserInfo({ currentWorkspaceUuid }));
  };
}
