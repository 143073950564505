import {
  DeleteOutlined,
  PlusOutlined,
  SettingOutlined,
  EditOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import {
  createProfilerVirtualTable,
  deleteProfilerVirtualTables,
  getProfilerConfigDataSourceTableList,
  getProfilerConfigTableBlobList,
  getProfilerConfigTableListUsage,
  resetProfilerConfigBlobStorageForm,
  updateProfilerConfigTableList,
  getProfilerConfigDataSourceTableColumnList,
} from "../../../actions/profiler/profiler-action";
import {
  openWorkspaceTakeover,
  closeTakeover,
} from "../../../actions/takeover/takeover-action";
import NgDropdownMenu from "../../../components/ng-dropdown-menu";
import { LabeledSelect } from "../../../components/labeled-control/labeled-control";
import NgButton, { TextWithIcon } from "../../../components/button/ng-button";
import { columnFn } from "../../../components/entity-list/columns";
import { NgTableTheme, NgTableClickableText } from "../../../components/table/ng-table";
import {
  DbColumTypeOptions,
  TimezoneOptions,
  getDataCollectionOptions,
  simplifiedCollectionWindowOptions,
} from "../../../utils/options";
import {
  isPartitionConfigEnabled,
  isVirtualTableEnabled as isVirtualTableEnabledUtil,
} from "../../../utils/datasource";
import DurationInput from "../../../components/duration-input/duration-input";
import TimestampColumnSelect from "../../../components/timestamp-column-select/";
import Tooltip from "../../../components/tooltip/ng-tooltip";
import {
  CollectionWindow,
  CollectionModeType,
  DataSourceType,
  SupportedFeature,
  QueryScope,
  TableColumnTypeCategory,
  isFileSource as getIsFileSource,
  TakeoverWidth,
} from "../../../utils/enums";
import {
  isFeatureEnabled,
  isTimestampTimezoneConfigEnabled,
} from "../../../utils/general";
import useSearch, {
  searchEntityType,
} from "../../../components/search/use-search/use-search";
import { getColumnTypeCategory } from "../../../utils/column";
import { ManageTabHeader, ManageTabNgTable } from "../manage-tabs";
import ProfilerConfirmationDialog from "../profiler-confirmation-dialog";
import ProfilerSettingBlobStorageTableForm from "../takeover/schema/profiler-setting-blob-storage-table-form";
import ProfilerSchemaVirtualTableTakeoverView, {
  ProfilerSchemaVirtualTableTakeOverViewClassName,
} from "../takeover/schema/profiler-schema-virtual-table-takeover-view";
import { getTimezone } from "countries-and-timezones";
import useBatchUpdate from "../use-batch-update";
import { deepcopy } from "../../../utils/objects";
import {
  EVENT,
  PAGE,
  getSchemaDetailProps,
  trackEvent,
} from "../../../utils/telemetry";
import useNavigationBlocker from "../../../hooks/useNavigationBlocker";
import { ProfileTableIcon } from "../icons";
import { Spinner } from "../../../atom/spinner";
import Switch from "../../../atom/switch";
import { isArrayEqual } from "../../../utils/arrays";

import "./profiler-manage-tables-tab.scss";

const queryScopeOptions = [
  { value: QueryScope.TIME_RANGE, label: "Incremental" },
  { value: QueryScope.FULL_TABLE, label: "Full Table" },
];

const aggregationWindowOptions = [
  { value: "hour", label: "hourly" },
  { value: "day", label: "daily" },
  { value: "week", label: "weekly" },
];

const fullTableWindowOptions = [
  { value: "day", label: "daily" },
  { value: "week", label: "weekly" },
];

const queryScopeTooltip = (
  <div>
    Refers to the amount of the source asset's data that is queried for one metric data
    collection. Full Table query scope is designed to support dimension tables (those
    with no time-based fields), while Incremental query scope is designed to support
    tables where you want to progressively query consecutive time periods. See{" "}
    <a
      href=" https://docs.lightup.ai/docs/query-scope"
      target="_blank"
      rel="noreferrer"
    >
      https://docs.lightup.ai/docs/query-scope
    </a>
  </div>
);

const dataCollectionScheduleTooltip = (
  <div>
    Lightup metric collection can be scheduled by Lightup, triggered by your own ETL
    flow, or run on a cron-based custom schedule you add to the table configuration. See{" "}
    <a
      href="https://docs.lightup.ai/docs/data-collection-schedule"
      target="_blank"
      rel="noreferrer"
    >
      https://docs.lightup.ai/docs/data-collection-schedule
    </a>
  </div>
);

const timestampTooltip = (
  <div>
    You can set Timestamp to specify the column to use as your timestamp. See{" "}
    <a href="https://docs.lightup.ai/docs/timestamp" target="_blank" rel="noreferrer">
      https://docs.lightup.ai/docs/timestamp
    </a>
  </div>
);

const pollingIntervalTooltip = (
  <div>
    Specify an Aggregation Interval to set the timeframe over which each metric value is
    aggregated (Incremental query scope) or Polling Interval (full table query scope) to
    establish how often metrics run if Data Collection Schedule is set to Scheduled. See{" "}
    <a
      href="https://docs.lightup.ai/docs/polling-interval"
      target="_blank"
      rel="noreferrer"
    >
      https://docs.lightup.ai/docs/polling-interval
    </a>{" "}
    and{" "}
    <a
      href="https://docs.lightup.ai/docs/aggregation-interval"
      target="_blank"
      rel="noreferrer"
    >
      https://docs.lightup.ai/docs/aggregation-interval
    </a>
  </div>
);

const pollingTimezoneTooltip = (
  <div>
    Set Aggregation Timezone to specify the time zone to use for metric aggregation
    (Incremental) or Set Polling Timezone to match the time zone associated with the
    Polling Interval. See{" "}
    <a
      href="https://docs.lightup.ai/docs/aggregation-time-zone"
      target="_blank"
      rel="noreferrer"
    >
      https://docs.lightup.ai/docs/aggregation-time-zone
    </a>{" "}
    and{" "}
    <a
      href="https://docs.lightup.ai/docs/polling-timezone"
      target="_blank"
      rel="noreferrer"
    >
      https://docs.lightup.ai/docs/polling-timezone
    </a>
  </div>
);

const pollingDelayTooltip = (
  <div>
    Evaluation Delay (Incremental query scope) is a blackout period during which data is
    considered not stable or not ready for running data quality checks. Polling Delay
    (full table query scope) to introduce a delay to the start of data collection. The
    delay cannot be set to a period that is longer than half the value of Polling
    interval. See{" "}
    <a
      href="https://docs.lightup.ai/docs/evaluation-delay"
      target="_blank"
      rel="noreferrer"
    >
      https://docs.lightup.ai/docs/evaluation-delay
    </a>{" "}
    and{" "}
    <a
      href="https://docs.lightup.ai/docs/polling-delay"
      target="_blank"
      rel="noreferrer"
    >
      https://docs.lightup.ai/docs/polling-delay
    </a>
  </div>
);

const partitionTooltip = (
  <div>
    Partitions optimize the way you organize and query your data. Using partitions can
    speed up queries against the table as well as data manipulation. See{" "}
    <a href="https://docs.lightup.ai/docs/partitions" target="_blank" rel="noreferrer">
      https://docs.lightup.ai/docs/partitions
    </a>
  </div>
);

const dataCollectionWindowTooltip = (
  <div>
    You can set the Data Collection Window to specify whether metrics with incremental
    query scope should use data from the last completed aggregation interval or data
    from the current, partially-complete aggregation interval. See{" "}
    <a
      href="https://docs.lightup.ai/docs/data-collection-window"
      target="_blank"
      rel="noreferrer"
    >
      https://docs.lightup.ai/docs/data-collection-window
    </a>
  </div>
);

function pluralize(count, singular, plural) {
  return count === 1 ? singular : plural;
}

function formatListWithAnd(items) {
  if (items.length === 0) {
    return "";
  }
  if (items.length === 1) {
    return items[0];
  }
  if (items.length === 2) {
    return `${items[0]} and ${items[1]}`;
  }
  return `${items.slice(0, -1).join(", ")}, and ${items[items.length - 1]}`;
}

function ProfilerSchemaManageTables(props) {
  const {
    config,
    enableEdit,
    enableCreateTable,
    loading,
    tableBlobList,
    tableColumnList,
    tableList: defaultTableList,
    tableListUsage,
    workspaceUuid,
    createProfilerVirtualTable,
    deleteProfilerVirtualTables,
    getProfilerConfigTableBlobList,
    getProfilerConfigTableList,
    getProfilerConfigTableListUsage,
    onOpenTableConfig,
    resetProfilerConfigBlobStorageForm,
    updateProfilerConfigTableList,
    searchItem,
    onSearchItemChange,
    getProfilerConfigColumnList,
    openWorkspaceTakeover,
    closeTakeover,
    waffle,
    workspaceUserPermissions,
  } = props;

  const [currentSelectTableKeys, setCurrentSelectTableKeys] = useState([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isVirtualTableFormOpen, setIsVirtualTableFormOpen] = useState(false);
  const [currentConfirmationDialogConfig, setCurrentConfirmationDialogConfig] =
    useState(null);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [currentPageTableList, setCurrentPageTableList] = useState([]);
  const virtualTableTakeoverRef = useRef(null);

  const {
    currentEntityList: currentTableList,
    toggleAction,
    batchList,
    isDirty,
    reset,
  } = useBatchUpdate({
    entityList: defaultTableList,
    getEntityKey: (table) => table.uuid,
    entityUpdate: (table, payload) => {
      const { partialUpdate = {} } = payload;
      const oldProfilerConfig = table.profilerConfig;
      return {
        ...table,
        profilerConfig: {
          ...oldProfilerConfig,
          ...partialUpdate,
        },
      };
    },
    ignoreKeys: [
      "firstSeenTs",
      "lastScannedTs",
      "lastSeenTs",
      "removedTs",
      "schemaUpdatedTs",
    ],
  });

  useNavigationBlocker(isDirty, "You have unsaved changes - discard?", reset);

  const { dataSource, schema } = config;

  useEffect(() => {
    dataSource &&
      currentPageTableList.length > 0 &&
      getProfilerConfigColumnList(workspaceUuid, dataSource, currentPageTableList);
  }, [
    workspaceUuid,
    dataSource,
    defaultTableList,
    getProfilerConfigColumnList,
    currentPageTableList,
  ]);

  useEffect(() => {
    return () => {
      resetProfilerConfigBlobStorageForm();
    };
  }, [resetProfilerConfigBlobStorageForm]);

  const pollingIntervalOptions = useMemo(() => {
    return isFeatureEnabled(waffle, SupportedFeature.FULL_TABLE_METRIC_HOURLY)
      ? aggregationWindowOptions
      : fullTableWindowOptions;
  }, [waffle]);

  const defaultTableListMap = useMemo(() => {
    return defaultTableList.reduce((acc, table) => {
      acc[table.uuid] = table;
      return acc;
    }, {});
  }, [defaultTableList]);

  // We need to keep the selectedRows content in sync with the table list;
  useEffect(() => {
    if (currentSelectTableKeys.length > 0) {
      setCurrentSelectTableKeys(
        currentSelectTableKeys.filter((uuid) => !!defaultTableListMap[uuid])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTableListMap]);

  const { options: searchOptions, filter: filterRows } = useSearch({
    entityType: searchEntityType.TABLE,
    tables: currentTableList,
  });

  if (!dataSource) {
    console.log("No data source is provided");
    return null;
  }

  if (!schema) {
    console.log("No schema is provided");
    return null;
  }

  const dataSourceType = dataSource?.config?.connection?.type;
  const isDataBrick = dataSourceType === DataSourceType.DATABRICKS;
  const isFileSource = getIsFileSource(dataSourceType);
  const isDataGovernanceEnabled =
    dataSource?.config.governance.indexedTimestampColumnsOnly;

  const isPartitionEnabled = isPartitionConfigEnabled(dataSourceType);
  const isVirtualTableEnabled = isVirtualTableEnabledUtil(dataSourceType);
  function getRequestParams(tableList) {
    return tableList.map((table) => {
      return {
        uuid: table.uuid,
        profilerConfig: table.profilerConfig,
      };
    });
  }

  function updateTables(workspaceUuid, dataSource, paramsList) {
    setIsUpdateInProgress(true);
    return updateProfilerConfigTableList(workspaceUuid, dataSource, paramsList).finally(
      () => {
        setIsUpdateInProgress(false);
      }
    );
  }

  function getNormalizedTableUuids(currentTableUuid, isVirtualTableOnly = false) {
    if (currentSelectTableKeys.length === 0) {
      return [currentTableUuid];
    }

    const tableUuidToQueryScopeMapper = {};
    const tableUuidToVirtualTableMapper = {};
    currentTableList.forEach((currentTable) => {
      tableUuidToQueryScopeMapper[currentTable.uuid] =
        currentTable.profilerConfig.queryScope;
      tableUuidToVirtualTableMapper[currentTableUuid] = currentTable.isUserDefined;
    });
    const selectedTableQueryScope = tableUuidToQueryScopeMapper[currentTableUuid];

    const tableUuids = [];
    for (let currentSelectedTableUuid of currentSelectTableKeys) {
      if (
        tableUuidToQueryScopeMapper[currentSelectedTableUuid] ===
          selectedTableQueryScope &&
        (!isVirtualTableOnly || tableUuidToVirtualTableMapper[currentSelectedTableUuid])
      ) {
        tableUuids.push(currentSelectedTableUuid);
      }
    }

    return tableUuids;
  }

  function onProfilerConfigChange(
    currentTable,
    partialUpdate,
    isVirtualTableOnly = false
  ) {
    const currentBulkTableUuids = getNormalizedTableUuids(
      currentTable.uuid,
      isVirtualTableOnly
    );
    toggleAction(currentBulkTableUuids, { partialUpdate });
  }

  function saveChanges() {
    const tablesToDisable = batchList.filter((table) => !table.profilerConfig?.enabled);
    const tablesToEnable = batchList.filter((table) => table.profilerConfig?.enabled);
    trackEvent(
      batchList.length > 1
        ? EVENT.SAVE_TABLE_CONFIGURATION_BULK
        : EVENT.SAVE_TABLE_CONFIGURATION,
      {
        ...getSchemaDetailProps(schema, dataSource),
        page: PAGE.MANAGE_TABLES,
      }
    );

    if (tablesToDisable.length > 0) {
      getProfilerConfigTableListUsage(
        workspaceUuid,
        dataSource,
        tablesToDisable.map((table) => table.uuid)
      );
      const indicatorName = formatListWithAnd(
        tablesToDisable.map(({ tableName }) => tableName)
      );
      const currentConfirmationDialogConfig = {
        defaultConfirmationMsg: pluralize(
          tablesToDisable.length,
          `You are about to deactivate table ${indicatorName}, which has no metrics or monitors`,
          `You are about to deactivate tables ${indicatorName}, none of which have metrics or monitors`
        ),
        context: batchList,
        onOkClicked: (paramsList) => {
          updateTables(workspaceUuid, dataSource, paramsList);
        },
      };
      setIsConfirmationDialogOpen(true);
      setCurrentConfirmationDialogConfig(currentConfirmationDialogConfig);
    } else if (tablesToEnable.length > 0) {
      const paramsList = getRequestParams(tablesToEnable);
      updateTables(workspaceUuid, dataSource, paramsList);
    }
  }

  function onSelectChange(newSelectedRowKeys) {
    setCurrentSelectTableKeys(newSelectedRowKeys);
  }

  function openVirtualTableForm() {
    if (isFileSource) {
      resetProfilerConfigBlobStorageForm();
      setIsVirtualTableFormOpen(true);
    } else {
      console.log("Open virtual table");
      openWorkspaceTakeover(
        <ProfilerSchemaVirtualTableTakeoverView
          closeTakeover={closeTakeover}
          dataSource={dataSource}
          schema={schema}
          onSave={onCreateVirtualTable}
          workspaceUuid={workspaceUuid}
          workspaceUserPermissions={workspaceUserPermissions}
          ref={virtualTableTakeoverRef}
        />,
        TakeoverWidth.FULLSCREEN,
        () => virtualTableTakeoverRef.current?.onCancelClicked(),
        ProfilerSchemaVirtualTableTakeOverViewClassName
      );
    }
  }

  function onCreateVirtualTable(virtualTable) {
    return createProfilerVirtualTable(workspaceUuid, dataSource, {
      ...virtualTable,
      schemaUuid: schema.uuid,
    })
      .then((data) => {
        getProfilerConfigTableList(workspaceUuid, config.dataSource, config.schema);
        setIsVirtualTableFormOpen(false);
        return Promise.resolve(data);
      })
      .catch((err) => Promise.reject(err));
  }

  function onTableDelete(currentTable) {
    let normalizedTableList;
    if (currentSelectTableKeys.length > 0) {
      normalizedTableList = currentTableList.filter(
        ({ uuid }) => currentSelectTableKeys.indexOf(uuid) !== -1
      );
    } else {
      normalizedTableList = [currentTable];
    }

    if (!isFileSource) {
      normalizedTableList = normalizedTableList.filter(
        ({ isUserDefined }) => isUserDefined
      );
    }

    if (normalizedTableList.length === 0) {
      return;
    }

    getProfilerConfigTableListUsage(
      workspaceUuid,
      dataSource,
      normalizedTableList.map((table) => table.uuid)
    );

    const indicatorName = normalizedTableList
      .map(({ tableName }) => tableName)
      .join(", ");
    const currentConfirmationDialogConfig = {
      defaultConfirmationMsg: `You are about to delete table ${indicatorName}, which has no metrics or monitors`,
      context: batchList,
      onOkClicked: () => {
        deleteProfilerVirtualTables(workspaceUuid, dataSource, normalizedTableList);
      },
    };
    setIsConfirmationDialogOpen(true);
    setCurrentConfirmationDialogConfig(currentConfirmationDialogConfig);
  }

  function onSaveVirtualTable(currentTable) {
    const paramsList = getRequestParams([currentTable]);
    return updateTables(workspaceUuid, dataSource, paramsList);
  }

  function onTableEdit(currentTable) {
    openWorkspaceTakeover(
      <ProfilerSchemaVirtualTableTakeoverView
        closeTakeover={closeTakeover}
        dataSource={dataSource}
        schema={schema}
        defaultValue={currentTable}
        onSave={onSaveVirtualTable}
        workspaceUuid={workspaceUuid}
        isEdit={true}
        workspaceUserPermissions={workspaceUserPermissions}
        ref={virtualTableTakeoverRef}
      />,
      TakeoverWidth.FULLSCREEN,
      () => virtualTableTakeoverRef.current?.onCancelClicked(),
      ProfilerSchemaVirtualTableTakeOverViewClassName
    );
  }

  function onUpdateBlobList(virtualTable) {
    getProfilerConfigTableBlobList(workspaceUuid, dataSource, {
      blobPathFormat: virtualTable.blobPathFormat,
      schemaUuid: schema.uuid,
    });
  }

  function configureTables(targetTableList, beingEnabled = false) {
    let normalizedTableList = targetTableList;
    if (beingEnabled) {
      // already enabled table can not configure query scope, so in case we are enabling
      // we need to disable them first to be able to configure it
      normalizedTableList = targetTableList.map((table) => ({
        ...table,
        profilerConfig: {
          ...table.profilerConfig,
          enabled: false,
        },
      }));
    }
    onOpenTableConfig({
      dataSource,
      tableList: normalizedTableList,
    });
  }

  function onConfigureClick(currentTable) {
    const currentBulkTableUuids = new Set(
      currentSelectTableKeys.length > 0 ? currentSelectTableKeys : [currentTable.uuid]
    );
    const targetTableList = currentTableList.filter(({ uuid }) =>
      currentBulkTableUuids.has(uuid)
    );
    configureTables(targetTableList);
  }

  function onCustomScheduleClick(currentTable) {
    const currentBulkTableUuids = new Set(
      currentSelectTableKeys.length > 0 ? currentSelectTableKeys : [currentTable.uuid]
    );
    const targetTableList = deepcopy(
      currentTableList.filter(({ uuid }) => currentBulkTableUuids.has(uuid))
    );

    for (let currentTable of targetTableList) {
      if (
        currentTable.profilerConfig.collectionMode.type !==
        CollectionModeType.CUSTOM_SCHEDULED
      ) {
        currentTable.profilerConfig.collectionMode = {
          type: CollectionModeType.CUSTOM_SCHEDULED,
          timezone: "UTC",
          crontabExpression: "0 */12 * * *",
        };
      }
    }

    onOpenTableConfig({
      dataSource,
      tableList: targetTableList,
      isModifiedTableList: true,
    });
  }

  function onVirtualTimestampClick(currentTable) {
    const currentBulkTableUuids = new Set(
      currentSelectTableKeys.length > 0 ? currentSelectTableKeys : [currentTable.uuid]
    );
    const targetTableList = deepcopy(
      currentTableList.filter(({ uuid }) => currentBulkTableUuids.has(uuid))
    );

    onOpenTableConfig({
      dataSource,
      tableList: targetTableList,
      isVirtualTimestampVisible: true,
    });
  }

  function onPartitionsSettingClick(currentTable) {
    const currentBulkTableUuids = new Set(
      currentSelectTableKeys.length > 0 ? currentSelectTableKeys : [currentTable.uuid]
    );
    const isPartitionSectionOpenForTableList = {};
    const targetTableList = deepcopy(
      currentTableList.filter(({ uuid }) => currentBulkTableUuids.has(uuid))
    );

    for (let currentTable of targetTableList) {
      if (!currentTable.profilerConfig.partitions) {
        currentTable.profilerConfig.partitions = [];
      }
      if (currentTable.profilerConfig.partitions.length === 0) {
        currentTable.profilerConfig.partitions.push({ columnName: "", format: "" });
      }
      isPartitionSectionOpenForTableList[currentTable.uuid] = true;
    }

    onOpenTableConfig({
      dataSource,
      tableList: targetTableList,
      isPartitionSectionOpenForTableList,
    });
  }

  const rowSelection = {
    selectedRowKeys: currentSelectTableKeys,
    onChange: onSelectChange,
  };

  const columns = [
    columnFn({
      title: (
        <Tooltip title="When you activate a table you can configure its settings, which are inherited by metrics that are based on the table. You can override these settings on a per-metric basis as needed.">
          Status
        </Tooltip>
      ),
      key: "status",
      width: 80,
      maxWidth: 120,
      fixed: "left",
      render: function (enabled, data) {
        return (
          <Switch
            size="small"
            checked={enabled}
            onChange={(checked) => onProfilerConfigChange(data, { enabled: checked })}
            disabled={!enableEdit || isUpdateInProgress}
          />
        );
      },
    })({ dataIndex: ["profilerConfig", "enabled"] }),
    columnFn({
      title: "Tables",
      key: "tableName",
      width: 200,
      minWidth: 100,
      fixed: "left",
      render: function (tableName, data, _index) {
        const { profilerConfig } = data;
        const { enabled = false } = profilerConfig;
        return (
          <div className="profiler-schema-manage-tables-table-name-cell-container">
            <ProfileTableIcon width={14} height={14} />
            <NgTableClickableText
              clickable={enableEdit && enabled}
              onClick={enableEdit && enabled ? () => onConfigureClick(data) : null}
            >
              {tableName}
            </NgTableClickableText>
          </div>
        );
      },
    })({ dataIndex: "tableName" }),
    columnFn({
      title: <Tooltip title={queryScopeTooltip}>Query Scope</Tooltip>,
      key: "queryScope",
      width: 140,
      render: function (queryScope, row, _index) {
        const originalTable = defaultTableListMap[row.uuid];
        return (
          <LabeledSelect
            disabled={
              !enableEdit ||
              isUpdateInProgress ||
              originalTable?.profilerConfig?.enabled || // don't allow change query scope if profiler is anready enabled
              !row.profilerConfig.enabled
            }
            label=""
            showSearch
            staticLabel
            options={
              isFileSource
                ? queryScopeOptions.filter(
                    ({ value }) => value === QueryScope.TIME_RANGE
                  )
                : queryScopeOptions
            }
            value={queryScope}
            onChange={(newQueryScope) => {
              if (newQueryScope === queryScope) {
                return;
              }

              const { collectionMode, collectionWindow } = row.profilerConfig;
              let configUpdate = { queryScope: newQueryScope };
              if (
                newQueryScope === QueryScope.FULL_TABLE &&
                collectionMode?.type === CollectionModeType.CUSTOM_SCHEDULED
              ) {
                configUpdate = {
                  ...configUpdate,
                  collectionMode: {
                    type: CollectionModeType.SCHEDULED,
                  },
                };
              }

              if (newQueryScope === QueryScope.FULL_TABLE) {
                configUpdate = {
                  ...configUpdate,
                  timestampColumn: null,
                  timestampColumnFunctions: null,
                };
                configUpdate.collectionWindow = null;
              } else if (collectionWindow === null) {
                configUpdate.collectionWindow = CollectionWindow.COMPLETE;
              }

              onProfilerConfigChange(row, configUpdate);
            }}
            dropdownMatchSelectWidth={false}
          />
        );
      },
    })({ dataIndex: ["profilerConfig", "queryScope"] }),
    columnFn({
      title: (
        <Tooltip title={dataCollectionScheduleTooltip}>
          Data Collection Schedule
        </Tooltip>
      ),
      key: "collectionMode",
      width: 160,
      minWidth: 120,
      render: function (collectionMode, row) {
        return (
          <LabeledSelect
            disabled={!enableEdit || isUpdateInProgress || !row.profilerConfig.enabled}
            label=""
            showSearch
            staticLabel
            options={getDataCollectionOptions({
              isBlobStorage: isFileSource,
            })}
            value={collectionMode?.type}
            onSelect={(newCollectionModeType) => {
              if (
                collectionMode?.type === newCollectionModeType &&
                newCollectionModeType !== CollectionModeType.CUSTOM_SCHEDULED
              ) {
                return;
              }

              let newCollectionMode;
              if (newCollectionModeType === CollectionModeType.CUSTOM_SCHEDULED) {
                onCustomScheduleClick(row);
                return;
              }

              newCollectionMode = {
                type: newCollectionModeType,
              };

              onProfilerConfigChange(row, { collectionMode: newCollectionMode });
            }}
            dropdownMatchSelectWidth={false}
          />
        );
      },
      getCompareVal: (collectionMode) => collectionMode?.type || "",
    })({ dataIndex: ["profilerConfig", "collectionMode"] }),
    columnFn({
      title: <Tooltip title={timestampTooltip}>Timestamp</Tooltip>,
      key: "timestamp",
      width: 250,
      minWidth: 150,
      render: function (profilerConfig, row, _index) {
        const {
          partitions,
          timestampColumn,
          timestampColumnType,
          timestampColumnFunctions,
        } = profilerConfig;
        const isVirtualTable = row.isUserDefined;
        const columnList = tableColumnList.data[row.uuid] || [];
        const timestampColumnList = [];
        for (let currentColumn of columnList) {
          if (isDataGovernanceEnabled && !currentColumn.isIndexed) {
            continue;
          }

          if (
            getColumnTypeCategory(currentColumn) === TableColumnTypeCategory.TIMESTAMP
          ) {
            timestampColumnList.push(currentColumn);
          } else if (
            isDataBrick &&
            partitions &&
            partitions.length === 1 &&
            currentColumn.columnName === partitions[0].columnName
          ) {
            timestampColumnList.push(currentColumn);
          } else if (
            currentColumn.columnName === timestampColumn &&
            timestampColumnFunctions
          ) {
            timestampColumnList.push(currentColumn);
          }
        }

        return (
          <div className="profiler-schema-manage-tables-timestamp-cell-container">
            <TimestampColumnSelect
              label={""}
              disabled={
                !enableEdit ||
                isUpdateInProgress ||
                profilerConfig.queryScope === QueryScope.FULL_TABLE ||
                !profilerConfig.enabled
              }
              staticLabel
              style={{ width: "100%" }}
              value={{ timestampColumn, timestampColumnFunctions }}
              timestampColumnList={timestampColumnList}
              enableAdd={!isFileSource}
              enableClear={isFileSource}
              onChange={(newTimestampInfo) => {
                onProfilerConfigChange(row, newTimestampInfo);
              }}
              size={"large"}
              onAddVirtualTimestamp={() => {
                onVirtualTimestampClick(row);
              }}
              onEditVirtualTimestamp={() => {
                onVirtualTimestampClick(row);
              }}
              autoSelectFirstOption
            />
            <LabeledSelect
              options={DbColumTypeOptions}
              value={isVirtualTable ? timestampColumnType : "Auto"}
              disabled={
                !enableEdit ||
                isUpdateInProgress ||
                profilerConfig.queryScope === QueryScope.FULL_TABLE ||
                !profilerConfig.enabled ||
                !isVirtualTable
              }
              label=""
              onChange={(newTimestampColumnType) => {
                if (newTimestampColumnType === timestampColumnType) {
                  return;
                }

                onProfilerConfigChange(
                  row,
                  {
                    timestampColumnType: newTimestampColumnType,
                  },
                  true
                );
              }}
              dropdownMatchSelectWidth={false}
            />
          </div>
        );
      },
      getCompareVal: (profilerConfig) => profilerConfig?.timestampColumn || "",
    })({ dataIndex: "profilerConfig" }),
    columnFn({
      title: "Timestamp Timezone",
      key: "timestamp-timezone",
      width: 180,
      minWidth: 110,
      render: function (profilerConfig, row, _index) {
        const { partitions, timestampColumn, dataTimezone } = profilerConfig;
        const columnList = tableColumnList.data[row.uuid] || [];

        const isPartitionColumnUsedAsTimestampColumn =
          partitions.length === 1 && partitions[0].columnName === timestampColumn;

        const timestampColumnInfo = columnList.find(
          ({ columnName }) => timestampColumn === columnName
        );

        const allowDataTimezoneConfig =
          isTimestampTimezoneConfigEnabled(timestampColumnInfo?.columnType) &&
          !isPartitionColumnUsedAsTimestampColumn;

        return (
          <LabeledSelect
            label=""
            showSearch
            staticLabel
            options={
              isFileSource
                ? TimezoneOptions.filter(({ value }) => value === "UTC")
                : TimezoneOptions
            }
            value={allowDataTimezoneConfig ? dataTimezone : "Derived"}
            disabled={!enableEdit || isUpdateInProgress || !allowDataTimezoneConfig}
            onChange={(newDataTimezone) => {
              onProfilerConfigChange(row, { dataTimezone: newDataTimezone });
            }}
            dropdownMatchSelectWidth={280}
          />
        );
      },
      getCompareVal: (profilerConfig) => profilerConfig?.dataTimezone || "",
    })({ dataIndex: "profilerConfig" }),
    columnFn({
      title: <Tooltip title={pollingIntervalTooltip}>Agg./Polling interval</Tooltip>,
      key: "interval",
      width: 130,
      minWidth: 130,
      render: function (profilerConfig, row) {
        const {
          queryScope,
          collectionMode,
          window: aggregationWindow,
          pollingWindow,
        } = profilerConfig;
        const isTimeRangeScope = queryScope === QueryScope.TIME_RANGE;
        const normalizedWindow = isTimeRangeScope ? aggregationWindow : pollingWindow;
        return (
          <LabeledSelect
            label=""
            showSearch
            staticLabel
            disabled={
              !enableEdit ||
              isUpdateInProgress ||
              (!isTimeRangeScope &&
                collectionMode?.type !== CollectionModeType.SCHEDULED) ||
              !profilerConfig.enabled
            }
            options={
              isTimeRangeScope ? aggregationWindowOptions : pollingIntervalOptions
            }
            value={normalizedWindow}
            onChange={(newWindow) => {
              if (newWindow === normalizedWindow) {
                return;
              }

              onProfilerConfigChange(
                row,
                isTimeRangeScope ? { window: newWindow } : { pollingWindow: newWindow }
              );
            }}
            dropdownMatchSelectWidth={false}
          />
        );
      },
      getCompareVal: (profilerConfig) => {
        const normalizedWindow =
          profilerConfig.queryScope === QueryScope.TIME_RANGE
            ? profilerConfig.window
            : profilerConfig.pollingWindow;
        return normalizedWindow;
      },
    })({ dataIndex: "profilerConfig" }),
    columnFn({
      title: <Tooltip title={pollingTimezoneTooltip}>Agg./Polling timezone</Tooltip>,
      key: "timezone",
      width: 180,
      minWidth: 130,
      render: function (profilerConfig, row) {
        const { queryScope, collectionMode, timezone, pollingTimezone } =
          profilerConfig;
        const isTimeRangeScope = queryScope === QueryScope.TIME_RANGE;
        const normalizeTimezone = isTimeRangeScope ? timezone : pollingTimezone;
        return (
          <LabeledSelect
            label=""
            showSearch
            staticLabel
            disabled={
              !enableEdit ||
              isUpdateInProgress ||
              (!isTimeRangeScope &&
                collectionMode?.type !== CollectionModeType.SCHEDULED) ||
              !profilerConfig.enabled
            }
            options={
              isFileSource && isTimeRangeScope
                ? TimezoneOptions.filter(({ value }) => value === "UTC")
                : TimezoneOptions
            }
            value={normalizeTimezone}
            onChange={(newTimezone) => {
              if (newTimezone === normalizeTimezone) {
                return;
              }

              onProfilerConfigChange(
                row,
                isTimeRangeScope
                  ? { timezone: newTimezone }
                  : { pollingTimezone: newTimezone }
              );
            }}
            dropdownMatchSelectWidth={280}
          />
        );
      },
      getCompareVal: (profilerConfig) => {
        const normalizedTimezoneStr =
          profilerConfig.queryScope === QueryScope.TIME_RANGE
            ? getTimezone(profilerConfig.timezone)?.utcOffsetStr
            : getTimezone(profilerConfig.pollingTimezone)?.utcOffsetStr;
        return normalizedTimezoneStr;
      },
    })({ dataIndex: "profilerConfig" }),
    columnFn({
      title: <Tooltip title={pollingDelayTooltip}>Agg./Polling delay</Tooltip>,
      key: "delay",
      width: 180,
      minWidth: 130,
      render: function (profilerConfig, row) {
        const { queryScope, collectionMode, syncDelay, pollingDelay } = profilerConfig;
        const isTimeRangeScope = queryScope === QueryScope.TIME_RANGE;
        const normalizeDelay = isTimeRangeScope ? syncDelay : pollingDelay;
        return (
          <DurationInput
            staticLabel
            label={""}
            disabled={
              !enableEdit ||
              isUpdateInProgress ||
              (!isTimeRangeScope &&
                collectionMode?.type !== CollectionModeType.SCHEDULED) ||
              !profilerConfig.enabled
            }
            value={normalizeDelay}
            onChange={(newPollingDelay) => {
              if (newPollingDelay === pollingDelay) {
                return;
              }

              onProfilerConfigChange(
                row,
                isTimeRangeScope
                  ? { syncDelay: newPollingDelay }
                  : { pollingDelay: newPollingDelay }
              );
            }}
            className="profiler-schema-manage-tables-duration-input"
          />
        );
      },
      getCompareVal: (profilerConfig) =>
        profilerConfig.queryScope === QueryScope.TIME_RANGE
          ? profilerConfig.syncDelay
          : profilerConfig.pollingDelay,
    })({ dataIndex: "profilerConfig" }),
  ];

  if (isPartitionEnabled) {
    columns.push(
      columnFn({
        title: <Tooltip title={partitionTooltip}>Partitions (Recommended)</Tooltip>,
        key: "partition",
        width: 140,
        minWidth: 140,
        maxWidth: 200,
        render: function (profilerConfig, row) {
          return (
            <NgButton
              outline
              disabled={
                !enableEdit ||
                isUpdateInProgress ||
                profilerConfig.queryScope !== QueryScope.TIME_RANGE ||
                !profilerConfig.enabled
              }
              onClick={() => onPartitionsSettingClick(row)}
              className="profiler-schema-manage-tables-partition-button"
            >
              <SettingOutlined />
            </NgButton>
          );
        },
        sorter: null,
      })({ dataIndex: "profilerConfig" })
    );
  }

  columns.push(
    columnFn({
      title: (
        <Tooltip title={dataCollectionWindowTooltip}>Data collection window</Tooltip>
      ),
      key: "dataCollectionWindow",
      width: 160,
      minWidth: 120,
      render: function (collectionWindow, row) {
        return (
          <LabeledSelect
            label=""
            disabled={
              !enableEdit ||
              isUpdateInProgress ||
              row.profilerConfig.queryScope === QueryScope.FULL_TABLE ||
              !row.profilerConfig.collectionWindow ||
              !row.profilerConfig.enabled
            }
            showSearch
            staticLabel
            options={simplifiedCollectionWindowOptions}
            value={collectionWindow}
            onChange={(newCollectionWindow) => {
              if (collectionWindow === newCollectionWindow) {
                return;
              }

              onProfilerConfigChange(row, { collectionWindow: newCollectionWindow });
            }}
            dropdownMatchSelectWidth={false}
          />
        );
      },
    })({ dataIndex: ["profilerConfig", "collectionWindow"] })
  );

  if ((isFileSource && enableEdit) || isVirtualTableEnabled) {
    columns.push(
      columnFn({
        title: "",
        key: "profilerConfigTableAction",
        width: 70,
        render: function (_, data) {
          const menuItems = [];
          if ((data.isUserDefined || isFileSource) && enableEdit) {
            menuItems.push({
              label: "Delete",
              icon: <DeleteOutlined />,
              onClick: () => {
                onTableDelete(data);
              },
              danger: true,
            });
          }

          if (data.isUserDefined) {
            menuItems.push({
              label: enableEdit ? "Edit" : "View",
              icon: <EditOutlined />,
              onClick: () => {
                onTableEdit(data);
              },
            });
          }

          if (menuItems.length === 0) {
            return null;
          }
          return <NgDropdownMenu menuItems={menuItems} position="bottomRight" />;
        },
        sorter: null,
        fixed: "right",
      })({ dataIndex: "uuid" })
    );
  }

  const filteredTableList = filterRows(currentTableList, searchItem);

  const isScanning = !schema.lastTablesScannedTs;

  function renderProfilerSettingVirtualTableForm() {
    if (loading || isScanning) {
      return <Spinner size="large" className="profiler-setting-schema-spinner" />;
    }
    if (!isVirtualTableFormOpen && (loading || defaultTableList.length !== 0)) {
      return null;
    }
    return (
      <ProfilerSettingBlobStorageTableForm
        tableBlobList={tableBlobList}
        onCancel={() => setIsVirtualTableFormOpen(false)}
        onCreate={onCreateVirtualTable}
        onUpdateBlobList={onUpdateBlobList}
      />
    );
  }

  const virtualTableButton =
    (defaultTableList.length !== 0 && isFileSource) || isVirtualTableEnabled ? (
      <NgButton
        text
        onClick={openVirtualTableForm}
        disabled={isVirtualTableFormOpen}
        className="manage-table-create-virtual-table-button"
      >
        <TextWithIcon icon={<PlusOutlined />} iconPosition="left">
          Create Virtual Table
        </TextWithIcon>
      </NgButton>
    ) : null;

  const headerControls = (
    <>
      <NgButton
        outline
        testId="manage-table-cancel-button"
        disabled={!isDirty || isUpdateInProgress}
        onClick={reset}
      >
        Cancel
      </NgButton>
      <NgButton
        testId="manage-table-save-button"
        disabled={!isDirty || isUpdateInProgress}
        onClick={saveChanges}
      >
        {isUpdateInProgress ? "Saving..." : "Save"}
      </NgButton>
    </>
  );

  return (
    <div className="profiler-manage-tables-tab">
      <ManageTabHeader
        entityName="table"
        lastScannedTs={schema.lastScannedTs}
        leftControls={enableCreateTable && virtualTableButton}
        rightControls={headerControls}
        searchOptions={searchOptions}
        searchItem={searchItem}
        onSearchItemChange={onSearchItemChange}
        showSearchControls={!isVirtualTableFormOpen}
      />
      {isFileSource && renderProfilerSettingVirtualTableForm()}
      <div className="profiler-manage-tables-tab-table-container">
        {(!isFileSource || defaultTableList.length !== 0) &&
          !isVirtualTableFormOpen && (
            <ManageTabNgTable
              entityName="table"
              theme={NgTableTheme.DARK}
              loadingTitle={isScanning ? "Scanning..." : undefined}
              rowSelection={rowSelection}
              dataSource={filteredTableList}
              columns={columns}
              rowKey={"uuid"}
              loading={loading || isScanning}
              summary={(pageData) => {
                const pageDataUuids = pageData.map(({ uuid }) => uuid);
                if (!isArrayEqual(currentPageTableList, pageDataUuids)) {
                  setTimeout(() => {
                    setCurrentPageTableList(pageDataUuids);
                  });
                }
                return null;
              }}
              resizableHeader={{
                enabled: true,
                defaultMinWidth: 80,
                defaultMaxWidth: 500,
                ignoreLastColumn: true,
              }}
              currentPageRowKeys={currentPageTableList}
              isFiltered={defaultTableList.length !== filteredTableList.length}
            />
          )}
      </div>
      {isConfirmationDialogOpen && currentConfirmationDialogConfig && (
        <ProfilerConfirmationDialog
          modalIsOpen={isConfirmationDialogOpen}
          setIsOpen={(isConfirmationDialogOpen) =>
            setIsConfirmationDialogOpen(isConfirmationDialogOpen)
          }
          okClicked={(paramsList) => {
            currentConfirmationDialogConfig.onOkClicked(paramsList);
          }}
          usage={tableListUsage}
          title={currentConfirmationDialogConfig.title}
          context={currentConfirmationDialogConfig.context}
          okText={currentConfirmationDialogConfig.okText}
          enableUsage={currentConfirmationDialogConfig.enableUsage}
          defaultConfirmationMsg={
            currentConfirmationDialogConfig.defaultConfirmationMsg
          }
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  waffle: state.userReducer.currentUserInfo.waffle,
  tableColumnList: state.profilerReducer.profilerConfigDataSourceTableColumnList,
  loading: state.profilerReducer.profilerConfigDataSourceTableList.loading,
  tableList: state.profilerReducer.profilerConfigDataSourceTableList.data,
  tableListUsage: state.profilerReducer.profilerConfigDataSourceTableListUsage,
  tableBlobList: state.profilerReducer.profilerConfigTableBlobList,
});

const mapDispatchToProps = (dispatch) => ({
  getProfilerConfigTableList: (workspaceUuid, dataSource, schema) =>
    dispatch(getProfilerConfigDataSourceTableList(workspaceUuid, dataSource, schema)),
  getProfilerConfigTableListUsage: (workspaceUuid, dataSource, tableUuids) =>
    dispatch(getProfilerConfigTableListUsage(workspaceUuid, dataSource, tableUuids)),
  createProfilerVirtualTable: (workspaceUuid, dataSource, payload) =>
    dispatch(createProfilerVirtualTable(workspaceUuid, dataSource, payload)),
  deleteProfilerVirtualTables: (workspaceUuid, dataSource, tables) =>
    dispatch(deleteProfilerVirtualTables(workspaceUuid, dataSource, tables)),
  getProfilerConfigTableBlobList: (workspaceUuid, dataSource, payload) =>
    dispatch(getProfilerConfigTableBlobList(workspaceUuid, dataSource, payload)),
  resetProfilerConfigBlobStorageForm: () =>
    dispatch(resetProfilerConfigBlobStorageForm()),
  updateProfilerConfigTableList: (
    workspaceUuid,
    dataSource,
    paramsList,
    opts = { isRefreshTree: true }
  ) =>
    dispatch(
      updateProfilerConfigTableList(workspaceUuid, dataSource, paramsList, opts)
    ),
  getProfilerConfigColumnList: (workspaceUuid, dataSource, tableUuid) =>
    dispatch(
      getProfilerConfigDataSourceTableColumnList(workspaceUuid, dataSource, tableUuid)
    ),
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick, className) =>
    dispatch(
      openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick, className)
    ),
  closeTakeover: () => dispatch(closeTakeover()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilerSchemaManageTables);
