import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import WorkspaceForm from "../../components/workspace/workspace-form";
import {
  WorkspaceFormMode,
  WorkspaceUserRole,
  SupportedFeature,
} from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/general";
import { Prompt } from "react-router-dom";
import { getURIInstance, URIPath } from "../../utils/uri-path";
import { goBackOrToDefault } from "../../utils/router";

const confirmExitPrompt = "You have unsaved changes - discard?";

function AddWorkspace(props) {
  const {
    addWorkspace,
    getAppUserList,
    appUserList,
    userInfo,
    workspaceList,
    goBackOnCancel = true,
    history,
    closeTakeover,
  } = props;
  const [isFormChanged, setIsFormChanged] = useState(false);

  const currentUser = {
    email: userInfo.email,
    role: WorkspaceUserRole.ADMIN,
    username: userInfo.username,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
  };

  const initialValues = {
    name: "",
    description: "",
    iconId: "I1",
    users: [currentUser],
  };

  const allowInviteToWorkspace = isFeatureEnabled(
    userInfo.waffle,
    SupportedFeature.ALLOW_INVITE_TO_WORKSPACE
  );

  const handleNavigation = (nextLocation) => {
    // Trigger Prompt only if the pathname is changing
    return nextLocation.pathname !== history.location.pathname;
  };

  useEffect(() => {
    if (!allowInviteToWorkspace) {
      getAppUserList();
    }
  }, [allowInviteToWorkspace, getAppUserList]);

  function onFormChange(_, isChangedFromInitial) {
    setIsFormChanged(isChangedFromInitial);
  }

  function onCancelClick() {
    if (!isFormChanged || window.confirm(confirmExitPrompt)) {
      // If the form was changed, we've already confirmed the exit. Set
      // isFormChanged to false so that the prompt doesn't show up again.
      setIsFormChanged(false);
      if (goBackOnCancel) {
        goToLastLocation();
      } else {
        closeTakeover();
      }
    }
  }

  function goToLastLocation() {
    goBackOrToDefault(
      history,
      workspaceList.length > 0
        ? getURIInstance(URIPath.EXPLORER, {
            workspaceUuid: workspaceList[0].uuid,
          })
        : URIPath.NO_WORKSPACE
    );
  }

  function onCreateClick(form, currentWorkspaceUserOperations) {
    form
      .validateFields()
      .then((workspace) => {
        return addWorkspace(workspace, currentWorkspaceUserOperations);
      })
      .then((createdWorkspace) => {
        setIsFormChanged(false);
        console.log("Start to redirect to explorer page after workspace is created.");
        const nextUrl = getURIInstance(URIPath.EXPLORER, {
          workspaceUuid: createdWorkspace.uuid,
        });
        closeTakeover();
        history.push(nextUrl);
      })
      .catch((e) => {
        console.log("Failed to submit workspace work", e);
      });
  }

  return (
    <>
      <Prompt
        message={(nextLocation) =>
          handleNavigation(nextLocation) ? confirmExitPrompt : true
        }
        when={isFormChanged}
      />
      <WorkspaceForm
        mode={WorkspaceFormMode.CREATE}
        initialValues={initialValues}
        currentUser={currentUser}
        appUserList={appUserList}
        allowInviteToWorkspace={allowInviteToWorkspace}
        onCancelClick={onCancelClick}
        onSubmitClick={onCreateClick}
        canEditFields={true}
        canEditExistingUserRoles={true}
        canAddUsers={true}
        canRemoveUsers={true}
        canDeleteWorkspace={false}
        onChange={onFormChange}
        submitEnabled={isFormChanged}
      />
    </>
  );
}

export default withRouter(AddWorkspace);
