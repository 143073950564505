import * as Sentry from "@sentry/react";
import { matchPath } from "react-router-dom";
import history from "../app/history";

export function configureSentry(dsn, env, release) {
  Sentry.init({
    dsn: dsn,
    environment: env,
    release: release,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        matchPath,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}
