import * as types from "./incident-action-types";
import { get } from "lodash";
import { getCaseListPromise, addCasePromise, updateCasePromise } from "../../rest/case";
import { getDataSourcePromise } from "../../rest/datasource";
import {
  getIncidentPromise,
  getIncidentListPromise,
  endIncidentPromise,
  updateIncidentStatusPromise,
  updateIncidentValidationStatusPromise,
  getIncidentCommentListPromise,
  addIncidentCommentPromise,
  updateIncidentCommentPromise,
  deleteIncidentCommentPromise,
  getIncidentSampleDataPromise,
  terminateIncidentSampleDataPromise,
  getIncidentCorrelatedMetricsPromise,
  getIncidentConfigPromise,
  updateIncidentConfigPromise,
  getIncidentUserDefinedRelatedMetricsPromise,
} from "../../rest/incident";
import {
  getKpiCachedSliceValueListPromise,
  getKpiListPromise,
  getKpiPromise,
} from "../../rest/kpi";
import {
  getRulePromise,
  getRuleListPromise,
  getRulePreviewPromise,
  getRuleSliceValueListPromise,
} from "../../rest/rule";
import {
  getPageConfigurationPromise,
  updatePageConfigurationPromise,
} from "../../rest/workspace";
import { getFilterDataPromise, getSignalDataPromise } from "../../rest/metrics";
import { getKPIGranularity } from "../../utils/defaults";
import {
  SymptomTypeConst,
  SeasonalityTypeConst,
  IncidentCreatorType,
  IncidentStatus,
  IncidentType,
  getIncidentScore,
  RequestStatus,
  IncidentValidationStatus,
  ListPageName,
  getIncidentValidationStatus,
} from "../../utils/enums";
import { isActivityMetric } from "../../utils/metric";
import {
  HOUR_IN_SECONDS,
  WEEK_IN_SECONDS,
  getStringFromTimeStamp,
} from "../../utils/time";
import getUnixTime from "date-fns/getUnixTime";
import { getURIInstance, URIPath } from "../../utils/uri-path";
import history from "../../app/history";

function setIncidentList(data) {
  return { type: types.SET_INCIDENT_LIST, incidentList: data };
}

function setIncidentListPageConfiguration(incidentListPageConfiguration) {
  return {
    type: types.SET_INCIDENT_LIST_PAGE_CONFIGURATION,
    incidentListPageConfiguration,
  };
}

function setIncidentPaginationParams(data) {
  return {
    type: types.SET_INCIDENT_PAGINATION_PARAMS,
    incidentPaginationParams: data,
  };
}

function setCurrentIncident(data) {
  return { type: types.SET_CURRENT_INCIDENT, currentIncident: data };
}

function setCurrentGroupIncidentList(data) {
  return {
    type: types.SET_CURRENT_GROUP_INCIDENT_LIST,
    currentGroupIncidentList: data,
  };
}

function setCurrentGroupIncidentListMetrics(data) {
  return {
    type: types.SET_CURRENT_GROUP_INCIDENT_LIST_METRICS,
    currentGroupIncidentListMetrics: data,
  };
}

function setCurrentIncidentMetricsData(keyName, data) {
  return {
    type: types.SET_CURRENT_INCIDENT_METRICS_DATA,
    keyName,
    currentIncidentMetricsData: data,
  };
}

function setCurrentIncidentCommentList(data) {
  return {
    type: types.SET_CURRENT_INCIDENT_COMMENT_LIST,
    currentIncidentCommentList: data,
  };
}

function setCurrentIncidentCorrelatedMetrics(data) {
  return {
    type: types.SET_CURRENT_INCIDENT_CORRELATED_METRICS,
    currentIncidentCorrelatedMetrics: data,
  };
}

function setCurrentIncidentUserDefinedRelatedMetrics(data) {
  return {
    type: types.SET_CURRENT_INCIDENT_USER_DEFINED_RELATED_METRICS,
    currentIncidentUserDefinedRelatedMetrics: data,
  };
}

function setCurrentIncidentConfig(data) {
  return {
    type: types.SET_CURRENT_INCIDENT_CONFIG,
    currentIncidentConfig: data,
  };
}

function setCurrentIncidentInterestMetricSliceValueList(data) {
  return {
    type: types.SET_CURRENT_INCIDENT_INTEREST_METRIC_SLICE_VALUE_LIST,
    sliceValueList: data,
  };
}

function setIncidentListUserSetting(data) {
  return {
    type: types.SET_INCIDENT_LIST_USER_SETTING,
    incidentListUserSetting: data,
  };
}

function setIncidentKpiList(data) {
  return { type: types.SET_INCIDENT_KPI_LIST, kpiList: data };
}

function setIncidentRuleList(data) {
  return { type: types.SET_INCIDENT_RULE_LIST, ruleList: data };
}

function setIncidentSliceValueList(data) {
  return { type: types.SET_INCIDENT_SLICE_VALUE_LIST, sliceValueList: data };
}

function setIncidentFileTicketResult(data) {
  return {
    type: types.SET_INCIDENT_FILE_TICKET_RESULT,
    incidentFileTicketResult: data,
  };
}

function setIncidentCaseList(data) {
  return { type: types.SET_INCIDENT_CASE_LIST, incidentCaseList: data };
}

function setIncidentListForSummary(data) {
  return {
    type: types.SET_INCIDENT_LIST_FOR_SUMMARY,
    incidentListForSummary: data,
  };
}

function setIncidentSampleData(data) {
  return {
    type: types.SET_INCIDENT_SAMPLE_DATA,
    incidentSampleData: data,
  };
}

function getIncidentBasicInfoFromServer(currentIncident) {
  const incidentId = currentIncident.id;
  const date = getStringFromTimeStamp(Math.floor(currentIncident.start_ts), {
    onlyDate: true,
  });
  const filterUuid = currentIncident.filter_uuid;
  const startTime = Math.floor(currentIncident.start_ts);
  const endTime = Math.ceil(currentIncident.close_ts);
  const status = currentIncident.status;
  const slice = currentIncident.slice ? currentIncident.slice : {};
  const direction = currentIncident.direction;
  const reason = currentIncident.reason;
  const incidentType = currentIncident.incident_type;
  const metadata = currentIncident.metadata;
  const validation = currentIncident.validation;
  const creationTime = Math.floor(currentIncident.creation_ts);
  const failingRecordsCount = get(
    currentIncident,
    "failing_records_count.count",
    "N/A"
  );

  return {
    uuid: currentIncident.uuid,
    incidentId,
    incidentType,
    filterUuid,
    date,
    metadata,
    startTime,
    endTime,
    creationTime,
    status,
    slice,
    ongoing: currentIncident.ongoing,
    direction,
    reason,
    validation,
    score: getIncidentScore(currentIncident.impact),
    failingRecordsCount,
  };
}

function getIncidentUserSettingPromise(workspaceUuid, { previewMode, uuid }) {
  if (previewMode) {
    return Promise.resolve({});
  }

  return getIncidentConfigPromise(workspaceUuid, uuid, { withRef: false });
}

export function getCreatorInfoPromise(workspaceUuid, basicInfo, { creatorUuid }) {
  return new Promise(function (resolve, reject) {
    if (basicInfo.incidentType === IncidentType.ANOMALY) {
      getRulePromise(workspaceUuid, creatorUuid)
        .then(function (filterInfo) {
          const symptomType = filterInfo.config.symptom.type;
          let windowSize = filterInfo.config.symptom.featureConfig.windowSize || 0;
          const streamUuid =
            filterInfo.config.metrics.length > 0 ? filterInfo.config.metrics[0] : "";

          if (!streamUuid) {
            console.log("Stream is not found.");
            reject("stream uuid is empty");
            return;
          }

          getKpiPromise(workspaceUuid, streamUuid)
            .then(function (kpiInfo) {
              let queryStartTime = 0;
              let queryEndTime = 0;

              if (symptomType === SymptomTypeConst.SLOW_BURN_TREND_CHANGE) {
                windowSize = windowSize ? windowSize : WEEK_IN_SECONDS;
                queryStartTime = basicInfo.startTime - 10 * windowSize;
                queryEndTime = basicInfo.startTime + 10 * windowSize;
              } else if (symptomType === SymptomTypeConst.SHARP_CHANGE) {
                windowSize = windowSize ? windowSize : WEEK_IN_SECONDS;
                queryStartTime = basicInfo.startTime - windowSize;
                queryEndTime = basicInfo.startTime + windowSize;
              } else {
                const defaultWindowSize = Math.max(
                  basicInfo.endTime - basicInfo.startTime,
                  2 * HOUR_IN_SECONDS,
                  getKPIGranularity(kpiInfo)
                );
                windowSize = windowSize ? windowSize : defaultWindowSize;
                queryStartTime = basicInfo.startTime - 3 * windowSize;
                queryEndTime = basicInfo.endTime + windowSize;
              }

              const dataSourceUuid = kpiInfo.config.sources[0];
              getDataSourcePromise(workspaceUuid, dataSourceUuid)
                .then(function (dataSourceInfo) {
                  resolve({
                    creatorInfo: {
                      type: IncidentCreatorType.FILTER,
                      creatorUuid,
                      dataSourceInfo,
                      filterInfo,
                      kpiInfo,
                    },
                    queryPeriod: {
                      queryStartTime,
                      queryEndTime,
                    },
                  });
                })
                .catch(function (err) {
                  console.log(`Fail to fetch datasource for ${dataSourceUuid}`);
                  reject(err);
                });
            })
            .catch(function (err) {
              console.log(`Fail to fetch stream for ${streamUuid} due to ${err}`);
              reject(err);
            });
        })
        .catch(function (err) {
          reject(err);
        });
    } else {
      console.log("Unsupported event type incident");
    }
  });
}

export function getIncident(
  workspaceUuid,
  { uuid, filterUuid, previewUuid },
  requestVersion
) {
  return (dispatch, getState) => {
    // Disable toast on 404 and do the re-direction in catch
    const disableToast = (error) => error?.response?.status === 404;

    getIncidentPromise(workspaceUuid, uuid, disableToast)
      .then(function (response) {
        let basicInfo = {
          seasonalityList: [],
          season: SeasonalityTypeConst.NONE,
        };

        basicInfo = getIncidentBasicInfoFromServer(response);
        if (!basicInfo.filterUuid) {
          console.log(`Invalid filterUuid for incident ${uuid}`);
          return;
        }

        let previewMode = false;
        if (previewUuid && filterUuid) {
          previewMode = true;
          basicInfo.filterUuid = filterUuid;
          console.log(
            `Replace filter uuid for ${filterUuid} with ${previewUuid} in get Incident list`
          );
        }

        const getAllBasicInfoPromise = [
          getCreatorInfoPromise(workspaceUuid, basicInfo, {
            creatorUuid: basicInfo.filterUuid,
          }),
        ];

        if (previewMode) {
          getAllBasicInfoPromise.push(
            getRulePreviewPromise(workspaceUuid, filterUuid, previewUuid)
          );
        }

        Promise.all(getAllBasicInfoPromise)
          .then(function ([creatorInfoResponse, previewInfo]) {
            const { queryPeriod, creatorInfo } = creatorInfoResponse;
            let { queryStartTime, queryEndTime } = queryPeriod;
            const { kpiInfo, filterInfo } = creatorInfo;

            if (previewMode && previewInfo) {
              queryStartTime = Math.max(queryStartTime, previewInfo["startTimestamp"]);
              queryEndTime = Math.min(queryEndTime, previewInfo["endTimestamp"]);
            }

            const currentTimestamp = getUnixTime(new Date());
            queryEndTime = Math.max(
              Math.min(queryEndTime, currentTimestamp),
              basicInfo.endTime
            );

            Promise.all([
              getRuleListPromise(workspaceUuid),
              getKpiListPromise(workspaceUuid),
              getIncidentUserSettingPromise(workspaceUuid, {
                previewMode,
                uuid,
              }),
            ])
              .then((responseArr) => {
                // Process filter result
                const [ruleList, kpiList, currentIncidentUserSetting] = responseArr;
                basicInfo = {
                  ...basicInfo,
                  displayStartTime: queryStartTime,
                  displayEndTime: queryEndTime,
                };

                dispatch(setIncidentKpiList(kpiList));
                dispatch(setIncidentRuleList(ruleList));

                dispatch(
                  setCurrentIncident({
                    basicInfo,
                    kpiInfo,
                    filterInfo,
                    creatorInfo,
                    userSetting: currentIncidentUserSetting,
                  })
                );
              })
              .catch(function (err) {
                console.log(`Fail to fetch filter info or cube info with ${err}`);
              });
          })
          .catch(function (err) {
            console.log(`Fail to fetch filter information for ${err}`);
          });
      })
      .catch(function (error) {
        console.log(`Fail to get all incidents for ${error}`);

        if (error?.response?.status === 404) {
          const redirectionURL = getURIInstance(URIPath.NG_INCIDENT_LIST, {
            workspaceUuid,
          });

          // Redirect user to incident listing page
          history.push(redirectionURL);
        }
      });
  };
}

export function getCurrentGroupIncidentList(
  workspaceUuid,
  { filterUuid, previewUuid, uuid, queryStartTime, queryEndTime }
) {
  return (dispatch, getState) => {
    let previewMode = false;
    if (previewUuid && filterUuid) {
      previewMode = true;
    }

    getIncidentListPromise(workspaceUuid, {
      startTime: queryStartTime,
      endTime: queryEndTime,
      ...(previewMode ? { previewUuids: [previewUuid] } : {}),
      filterUuids: filterUuid ? [filterUuid] : [],
      customerOptions: {
        rejected: true,
      },
    })
      .then(function (allIncidentResponse) {
        const currentGroupIncidentList = [];
        for (let { incidents, ...otherProperties } of allIncidentResponse) {
          const currentFilteredIncidents = incidents.filter(
            ({ uuid: currentIncidentUuid, status }) => {
              return currentIncidentUuid === uuid || status !== IncidentStatus.REJECTED;
            }
          );

          if (currentFilteredIncidents.length > 0) {
            currentGroupIncidentList.push({
              ...otherProperties,
              incidents: currentFilteredIncidents,
            });
          }
        }
        dispatch(setCurrentGroupIncidentList(currentGroupIncidentList));
      })
      .catch(function (error) {
        console.log(`Fail to update current group incident list for ${error}`);
      });
  };
}

export function getIncidentData(workspaceUuid, uuid, queryObject) {
  return (dispatch, getState) => {
    const keyName = "currentIncidentMainMetricsData";

    const { metric } = queryObject;
    const getDataPromise = isActivityMetric(metric)
      ? getSignalDataPromise
      : getFilterDataPromise;
    getDataPromise(workspaceUuid, queryObject)
      .then(function (newMetricsData) {
        dispatch(setCurrentIncidentMetricsData(keyName, newMetricsData));
      })
      .catch(function (err) {
        console.trace(`Fail to get incident data for ${uuid} with ${err}`);
      });
  };
}

export function getIncidentList(workspaceUuid, queryObject, isForceRefresh = false) {
  return (dispatch, getState) => {
    const { incidentList } = getState().incidentReducer;
    const oldQueryObject = incidentList.queryObject || {};
    const oldWorkspaceUuid = incidentList.workspaceUuid;
    const oldRejected =
      oldQueryObject.customerOptions && oldQueryObject.customerOptions.rejected;
    const newRejected =
      queryObject.customerOptions && queryObject.customerOptions.rejected;
    if (
      workspaceUuid === oldWorkspaceUuid &&
      oldQueryObject.startTime === queryObject.startTime &&
      oldQueryObject.endTime === queryObject.endTime &&
      oldRejected === newRejected
    ) {
      console.log("Query object is the same for incident dashboard. Returning.");
      return;
    }
    dispatch(setIncidentList({ loading: true, queryObject, workspaceUuid, data: [] }));
    dispatch(setIncidentPaginationParams({ page: 1, pageSize: 10 }));
    getIncidentListPromise(workspaceUuid, queryObject)
      .then(function (incidentList) {
        dispatch(
          setIncidentList({
            loading: false,
            queryObject,
            workspaceUuid,
            data: incidentList,
          })
        );
      })
      .catch(function (err) {
        dispatch(
          setIncidentList({
            loading: false,
            queryObject: {},
            workspaceUuid,
            data: [],
          })
        );
        console.log(`Fail to get incident list ${err}`);
      });
  };
}

export function getIncidentListPageConfiguration(workspaceUuid) {
  return (dispatch, getState) => {
    getPageConfigurationPromise(workspaceUuid, ListPageName.INCIDENTS)
      .then(function (newConfiguration) {
        dispatch(setIncidentListPageConfiguration(newConfiguration));
      })
      .catch(function (error) {
        // handle error
        console.log(`Fail to load incident list page configuration for ${error}`);
        dispatch(setIncidentListPageConfiguration({ columns: [] }));
      });
  };
}

export function updateIncidentListPageConfiguration(
  workspaceUuid,
  newPageConfiguration
) {
  return (dispatch, getState) => {
    updatePageConfigurationPromise(workspaceUuid, newPageConfiguration)
      .then(function (updatedPageConfiguration) {
        dispatch(setIncidentListPageConfiguration(updatedPageConfiguration));
      })
      .catch(function (error) {
        console.log(`Failed to update incident list page configuration for ${error}`);
      });
  };
}

export function updateIncidentPaginationParams(paginationParams) {
  return (dispatch, getState) => {
    dispatch(setIncidentPaginationParams(paginationParams));
  };
}

export function getIncidentListForSummary(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    dispatch(setIncidentListForSummary({ loading: true, data: [] }));
    getIncidentListPromise(workspaceUuid, queryObject)
      .then(function (incidentList) {
        dispatch(setIncidentListForSummary({ loading: false, data: incidentList }));
      })
      .catch(function (err) {
        dispatch(setIncidentListForSummary({ loading: false, data: [] }));
        console.log(`Fail to get incident list for summary ${err}`);
      });
  };
}

export function updateIncidentStatus(workspaceUuid, uuid, status) {
  return (dispatch, getState) => {
    return updateIncidentStatusPromise(workspaceUuid, uuid, status)
      .then(function (newIncidentData) {
        const { currentIncident } = getState().incidentReducer;
        const { basicInfo } = currentIncident;
        const updatedBasicInfo = getIncidentBasicInfoFromServer(newIncidentData);
        const newBasicInfo = { ...basicInfo, status: updatedBasicInfo.status };
        const newCurrentIncident = {
          ...currentIncident,
          basicInfo: newBasicInfo,
        };
        dispatch(setCurrentIncident(newCurrentIncident));
        // Update the incident list cache
        updateIncidentStatusForIncidentListCache([uuid], { status })(
          dispatch,
          getState
        );
      })
      .catch(function (error) {
        console.log(`Fail to update incident ${uuid} status to ${status}`);
      });
  };
}

export function endIncident(workspaceUuid, uuid) {
  return (dispatch, getState) => {
    return endIncidentPromise(workspaceUuid, uuid)
      .then(function (newIncidentData) {
        const { currentIncident } = getState().incidentReducer;
        const { basicInfo } = currentIncident;
        const updatedBasicInfo = getIncidentBasicInfoFromServer(newIncidentData);
        const ongoing = updatedBasicInfo.ongoing;
        const newBasicInfo = { ...basicInfo, ongoing };
        const newCurrentIncident = {
          ...currentIncident,
          basicInfo: newBasicInfo,
        };
        dispatch(setCurrentIncident(newCurrentIncident));
        // Update the incident list cache
        updateIncidentStatusForIncidentListCache([uuid], { ongoing })(
          dispatch,
          getState
        );
      })
      .catch(function (error) {
        console.log(`Fail to end incident ${uuid}`);
      });
  };
}

export function updateIncidentValidationStatus(workspaceUuid, uuid, validation) {
  return (dispatch, getState) => {
    return updateIncidentValidationStatusPromise(workspaceUuid, uuid, validation)
      .then(function (newIncidentData) {
        const { currentIncident } = getState().incidentReducer;
        const { basicInfo } = currentIncident;
        const updatedBasicInfo = getIncidentBasicInfoFromServer(newIncidentData);
        const newBasicInfo = {
          ...basicInfo,
          validation: updatedBasicInfo.validation,
        };
        const newCurrentIncident = {
          ...currentIncident,
          basicInfo: newBasicInfo,
        };
        dispatch(setCurrentIncident(newCurrentIncident));
        // Update the incident list cache
        updateIncidentStatusForIncidentListCache([uuid], {
          validation: updatedBasicInfo.validation,
        })(dispatch, getState);
      })
      .catch(function (error) {
        console.log(
          `Fail to update incident ${uuid} validation status to ${JSON.stringify(
            validation
          )}`
        );
      });
  };
}

function updateIncidentStatusForIncidentListCache(incidentUuids, newPatchObject) {
  return (dispatch, getState) => {
    const currentIncidentList = getState().incidentReducer.incidentList;
    const currentGroupIncidentListData = currentIncidentList.data;
    let newGroupIncidentListData = [];
    let isFound = false;

    for (let currentGroupIncidentList of currentGroupIncidentListData) {
      for (let i = 0; i < currentGroupIncidentList.incidents.length; i++) {
        if (incidentUuids.includes(currentGroupIncidentList.incidents[i].uuid)) {
          currentGroupIncidentList.incidents[i] = {
            ...currentGroupIncidentList.incidents[i],
            ...newPatchObject,
          };
          isFound = true;
        }
      }

      if (isFound) {
        newGroupIncidentListData.push({
          ...currentGroupIncidentList,
          incidents: [...currentGroupIncidentList.incidents],
        });
      } else {
        newGroupIncidentListData.push(currentGroupIncidentList);
      }
    }

    isFound &&
      dispatch(
        setIncidentList({
          loading: false,
          queryObject: currentIncidentList.queryObject,
          data: newGroupIncidentListData,
        })
      );
  };
}

export function updateHomeIncidentStatus(workspaceUuid, incidentList, newStatus) {
  return (dispatch, getState) => {
    const incidentUuids = incidentList.map(({ uuid }) => uuid);

    const allRequests = incidentUuids.map((uuid) =>
      updateIncidentStatusPromise(workspaceUuid, uuid, newStatus)
    );

    Promise.all(allRequests)
      .then(function () {
        updateIncidentStatusForIncidentListCache(incidentUuids, {
          status: newStatus,
        })(dispatch, getState);
      })
      .catch(function (err) {
        console.log("Fail to update home incident status.");
      });
  };
}

export function updateHomeIncidentValidationStatus(
  workspaceUuid,
  incidentList,
  newValidation
) {
  return (dispatch, getState) => {
    const incidentUuids = incidentList.map(({ uuid }) => uuid);
    const allRequests = incidentUuids.map((uuid) =>
      updateIncidentValidationStatusPromise(workspaceUuid, uuid, newValidation)
    );

    Promise.all(allRequests)
      .then(function (allResponses) {
        for (let currentIncident of allResponses) {
          updateIncidentStatusForIncidentListCache([currentIncident.uuid], {
            validation: currentIncident.validation,
            validationStatus: getIncidentValidationStatus(currentIncident.validation),
          })(dispatch, getState);
        }
      })
      .catch(function (err) {
        console.log("Fail to update home incident validation status.");
      });
  };
}

export function getCurrentIncidentCommentList(workspaceUuid, incidentUuid) {
  return (dispatch, getState) => {
    const currentUserId = getState().userReducer.currentUserInfo.id;
    getIncidentCommentListPromise(workspaceUuid, incidentUuid, currentUserId)
      .then(function (incidentCommentList) {
        dispatch(setCurrentIncidentCommentList(incidentCommentList));
      })
      .catch(function (err) {
        console.log(`Fail to fetch incident comment list for ${incidentUuid}`);
      });
  };
}

export function getCurrentIncidentRelatedMetrics(workspaceUuid, incidentId, opts = {}) {
  return (dispatch, _getState) => {
    dispatch(setCurrentIncidentCorrelatedMetrics({ loading: true, data: {} }));
    dispatch(setCurrentIncidentUserDefinedRelatedMetrics({ loading: true, data: {} }));
    const correlatedMetricsPromise = getIncidentCorrelatedMetricsPromise(
      workspaceUuid,
      incidentId,
      opts
    )
      .then(function (incidentCorrelatedMetrics) {
        dispatch(
          setCurrentIncidentCorrelatedMetrics({
            loading: false,
            data: incidentCorrelatedMetrics,
          })
        );
      })
      .catch(function (err) {
        dispatch(setCurrentIncidentCorrelatedMetrics({ loading: false, data: {} }));
        console.log(`Fail to fetch incident correlated metrics for ${incidentId}`);
      });
    const userDefinedRelatedMetricsPromise =
      getIncidentUserDefinedRelatedMetricsPromise(workspaceUuid, incidentId)
        .then(function (incidentUserDefinedRelatedMetrics) {
          dispatch(
            setCurrentIncidentUserDefinedRelatedMetrics({
              loading: false,
              data: incidentUserDefinedRelatedMetrics,
            })
          );
        })
        .catch(function (err) {
          dispatch(
            setCurrentIncidentUserDefinedRelatedMetrics({ loading: false, data: {} })
          );
          console.log(
            `Fail to fetch incident user defined related metrics for ${incidentId}`
          );
        });
    return Promise.all([correlatedMetricsPromise, userDefinedRelatedMetricsPromise]);
  };
}

export function getIncidentConfig(workspaceUuid, incidentId, opts) {
  return (dispatch, _getState) => {
    const { quiet = false, startTime, endTime } = opts;
    if (!quiet) {
      dispatch(setCurrentIncidentConfig({ loading: true, data: {} }));
    }
    return getIncidentConfigPromise(workspaceUuid, incidentId, { startTime, endTime })
      .then(function (incidentConfig) {
        dispatch(
          setCurrentIncidentConfig({
            loading: false,
            data: incidentConfig,
          })
        );
      })
      .catch(function (err) {
        dispatch(setCurrentIncidentConfig({ loading: false, data: {} }));
        console.log(`Fail to fetch incident config for ${incidentId}`);
      });
  };
}

export function updateIncidentConfig(workspaceUuid, incidentId, config) {
  return (_dispatch, _getState) => {
    return updateIncidentConfigPromise(workspaceUuid, incidentId, config).catch(
      function (err) {
        console.log(`Fail to update incident config for ${incidentId}`);
      }
    );
  };
}

export function addIncidentComment(workspaceUuid, incidentUuid, incidentComment) {
  return (dispatch, getState) => {
    const currentUserId = getState().userReducer.currentUserInfo.id;
    return addIncidentCommentPromise(
      workspaceUuid,
      incidentUuid,
      incidentComment,
      currentUserId
    )
      .then(function (response) {
        getCurrentIncidentCommentList(workspaceUuid, incidentUuid)(dispatch, getState);
      })
      .catch(function (err) {
        console.log(
          `Fail to create incident comment list with ${JSON.stringify(incidentComment)}`
        );
      });
  };
}

export function deleteIncidentComment(workspaceUuid, incidentUuid, incidentComment) {
  return (dispatch, getState) => {
    deleteIncidentCommentPromise(workspaceUuid, incidentUuid, incidentComment.id)
      .then(function (response) {
        getCurrentIncidentCommentList(workspaceUuid, incidentUuid)(dispatch, getState);
      })
      .catch(function (err) {
        console.log(
          `Fail to delete incident comment list with ${JSON.stringify(incidentComment)}`
        );
      });
  };
}

export function editIncidentComment(workspaceUuid, incidentUuid, incidentComment) {
  return (dispatch, getState) => {
    const currentUserId = getState().userReducer.currentUserInfo.id;
    updateIncidentCommentPromise(
      workspaceUuid,
      incidentUuid,
      incidentComment.id,
      incidentComment.comment,
      currentUserId
    )
      .then(function (response) {
        getCurrentIncidentCommentList(workspaceUuid, incidentUuid)(dispatch, getState);
      })
      .catch(function (err) {
        console.log(
          `Fail to edit incident comment list with ${JSON.stringify(incidentComment)}`
        );
      });
  };
}

export function getGroupIncidentListMetrics(workspaceUuid, options) {
  return (dispatch, getState) => {
    const { startTime, endTime, filterInfoList } = options;
    const allRequests = [];
    for (let currentFilterInfoItem of filterInfoList) {
      const {
        metricUuid = null,
        filterUuid = null,
        sliceKeyValuePairs,
        autoMetricType,
        dataSourceUuid,
        tableUuid,
        columnUuid,
        metric,
      } = currentFilterInfoItem;

      if (!metricUuid && !filterUuid) {
        console.log(`Invalid query object for ${JSON.stringify()}`);
        continue;
      }

      const queryObject = {
        startTime,
        endTime,
        metricUuid,
        filterUuid,
        sliceKeyValuePairs,
        autoMetricType,
        dataSourceUuid,
        tableUuid,
        columnUuid,
        metric,
      };

      if (filterUuid) {
        allRequests.push(getFilterDataPromise(workspaceUuid, queryObject));
      } else {
        allRequests.push(getSignalDataPromise(workspaceUuid, queryObject));
      }
    }

    if (allRequests.length === 0) {
      dispatch(setCurrentGroupIncidentListMetrics([]));
      return;
    }

    Promise.all(allRequests)
      .then(function (allMetricsData) {
        dispatch(setCurrentGroupIncidentListMetrics(allMetricsData));
      })
      .catch(function (err) {
        console.log(`Fail to get all the metrics for incident ${err}`);
      });
  };
}

export function updateIncidentListUserSetting(data) {
  return (dispatch) => {
    dispatch(setIncidentListUserSetting(data));
  };
}

export function getIncidentSliceValueList(workspaceUuid, ruleId, timePeriod) {
  return (dispatch, getState) => {
    dispatch(setIncidentSliceValueList([]));
    getRuleSliceValueListPromise(workspaceUuid, ruleId, timePeriod)
      .then(function (sliceValueList) {
        dispatch(setIncidentSliceValueList(sliceValueList));
      })
      .catch(function (error) {
        console.log(`Fail to get incident slice value list for ${error}`);
      })
      .finally(function () {});
  };
}

export function addIncidentTicket(workspaceUuid, newCase) {
  return (dispatch, getState) => {
    const { incidentUuid } = newCase;
    dispatch(
      setIncidentFileTicketResult({
        status: RequestStatus.DOING,
        value: "",
      })
    );
    return addCasePromise(workspaceUuid, newCase)
      .then(function (data) {
        dispatch(
          setIncidentFileTicketResult({
            status: RequestStatus.SUCCESS,
            data: data.value,
          })
        );
        dispatch(getIncidentCaseList(workspaceUuid, incidentUuid));
      })
      .catch(function (err) {
        dispatch(
          setIncidentFileTicketResult({
            status: RequestStatus.FAILURE,
            data: [],
          })
        );
        console.log(`Fail to create incident ticket with ${JSON.stringify(newCase)}`);
      });
  };
}

export function getIncidentCaseList(workspaceUuid, incidentUuid) {
  return (dispatch, getState) => {
    dispatch(setIncidentCaseList([]));
    getCaseListPromise(workspaceUuid, { incidentUuid })
      .then(function (caseList) {
        dispatch(setIncidentCaseList(caseList));
      })
      .catch(function (err) {
        console.log(`Fail to get incident case list with ${err}`);
      });
  };
}

export function updateIncidentCase(workspaceUuid, newCase) {
  return (dispatch, getState) => {
    updateCasePromise(workspaceUuid, newCase.metadata.uuid, newCase)
      .then(function (response) {
        dispatch(getIncidentCaseList(workspaceUuid, newCase.config.incidents[0]));
      })
      .catch(function (err) {
        console.log(`Fail to update incident case with ${err}`);
      });
  };
}

export function startPollingIncidentValidationStatus(workspaceUuid, pollManager, uuid) {
  return (dispatch, getState) => {
    pollManager
      .start(getIncidentPromise.bind(null, workspaceUuid, uuid), (currentIncident) => {
        return (
          currentIncident.validation &&
          [
            IncidentValidationStatus.RUNNING,
            IncidentValidationStatus.CANCELING,
          ].includes(currentIncident.validation.status)
        );
      })
      .then(function (newIncidentData) {
        const { currentIncident } = getState().incidentReducer;
        const { basicInfo } = currentIncident;
        const updatedBasicInfo = getIncidentBasicInfoFromServer(newIncidentData);
        const newBasicInfo = {
          ...basicInfo,
          validation: updatedBasicInfo.validation,
        };
        const newCurrentIncident = {
          ...currentIncident,
          basicInfo: newBasicInfo,
        };
        dispatch(setCurrentIncident(newCurrentIncident));
        // Update the incident list cache
        updateIncidentStatusForIncidentListCache([uuid], {
          validation: updatedBasicInfo.validation,
        })(dispatch, getState);
      })
      .catch(function (err) {
        console.log(`Fail to poll the incident validation status for ${err}`);
      });
  };
}

export function resetCurrentIncident(options = {}) {
  return (dispatch) => {
    const { ticketResultOnly = false } = options;
    if (!ticketResultOnly) {
      const keyNameList = ["currentIncidentMainMetricsData"];
      for (let keyName of keyNameList) {
        dispatch(setCurrentIncidentMetricsData(keyName, { metricsData: null }));
      }
      dispatch(setCurrentIncident({}));
      dispatch(setCurrentIncidentCommentList([]));
      dispatch(setCurrentGroupIncidentList([]));
      dispatch(setCurrentGroupIncidentListMetrics([]));
      dispatch(setIncidentKpiList([]));
      dispatch(setIncidentRuleList([]));
      dispatch(setIncidentSliceValueList([]));
      dispatch(setIncidentCaseList([]));
    }
    dispatch(
      setIncidentFileTicketResult({
        status: RequestStatus.UNKNOWN,
        data: "",
      })
    );
  };
}

export function getIncidentSampleData(
  workspaceUuid,
  incidentUuid,
  previewUuid,
  opts = {}
) {
  return (dispatch, getState) => {
    const abortController = new AbortController();
    const currentIncidentSampleData =
      getState().incidentReducer.currentIncidentSampleData.data;
    const { query, countInfo } = currentIncidentSampleData;
    const { keepQuery = false, ...payloadOpts } = opts;
    const refreshCount = payloadOpts.refreshCount;
    let resetData;
    if (refreshCount) {
      resetData = currentIncidentSampleData; // For refresh count, we only want to update countInfo when it returns;
    } else if (keepQuery) {
      resetData = { query, countInfo }; // keep sql query if required
    } else {
      resetData = {};
    }

    dispatch(
      setIncidentSampleData({
        loading: !refreshCount,
        countLoading: !!refreshCount,
        previewUuid,
        abortController,
        data: resetData,
      })
    );
    return getIncidentSampleDataPromise(
      workspaceUuid,
      incidentUuid,
      previewUuid,
      payloadOpts,
      {
        signal: abortController.signal,
      }
    )
      .then((response) => {
        // we will keep the query if it includes '{limit}' which can be replaced with a custom limit
        let updateData;
        if (refreshCount) {
          updateData = {
            ...currentIncidentSampleData,
            countInfo: response.data.countInfo,
          };
        } else if (keepQuery && query?.includes("{limit}")) {
          updateData = { ...response.data, query, countInfo };
        } else {
          updateData = response.data;
        }
        dispatch(
          setIncidentSampleData({
            loading: false,
            countLoading: false,
            previewUuid: null,
            abortController: null,
            data: updateData,
          })
        );
      })
      .catch((err) => {
        console.log(`Failed to retrieve sample data for incident ${incidentUuid}`, err);
        dispatch(
          setIncidentSampleData({
            loading: false,
            countLoading: false,
            previewUuid: null,
            abortController: null,
            data: keepQuery ? { query } : {}, // keep sql query if required
          })
        );
      });
  };
}

export function cancelIncidentSampleDataRequest(opts = {}) {
  const { keepQuery = false } = opts;
  return (dispatch, getState) => {
    const {
      abortController,
      data: { query },
    } = getState().incidentReducer.currentIncidentSampleData;
    if (abortController) {
      abortController.abort();
      setIncidentSampleData({
        loading: false,
        countLoading: false,
        previewUuid: null,
        abortController: null,
        data: keepQuery ? { query } : {}, // keep sql query if required
      });
    }
  };
}

export function terminateIncidentSampleDataQuery(
  workspaceUuid,
  incidentUuid,
  previewUuid
) {
  return (_dispatch, getState) => {
    const incidentSampleData = getState().incidentReducer.currentIncidentSampleData;
    if (incidentSampleData.loading && incidentSampleData.previewUuid === previewUuid) {
      return terminateIncidentSampleDataPromise(
        workspaceUuid,
        incidentUuid,
        previewUuid
      );
    }
  };
}

export function resetCurrentIncidentInterestMetricSliceValueList() {
  return (dispatch) => {
    dispatch(
      setCurrentIncidentInterestMetricSliceValueList({ loading: false, data: [] })
    );
  };
}

export function getCurrentIncidentInterestMetricSliceValueList(
  workspaceUuid,
  metricUuid
) {
  return (dispatch, getState) => {
    dispatch(
      setCurrentIncidentInterestMetricSliceValueList({ loading: true, data: [] })
    );
    return getKpiCachedSliceValueListPromise(workspaceUuid, { metricUuid })
      .then((sliceValues) => {
        dispatch(
          setCurrentIncidentInterestMetricSliceValueList({
            loading: false,
            data: sliceValues,
          })
        );
      })
      .catch((err) => {
        console.log(`Failed to retrieve slice values for metric ${metricUuid}`, err);
        dispatch(
          setCurrentIncidentInterestMetricSliceValueList({
            loading: false,
            data: [],
          })
        );
      });
  };
}
