import React, { useState } from "react";
import { Form, Space } from "antd";
import { LabeledInput, LabeledMultiSelect } from "../labeled-control/labeled-control";
import NgToggleCheckbox from "../../components/toggle-checkbox/ng-toggle-checkbox";
import PasscodeConfirmationModal from "../../components/passcode-confirmation-modal/passcode-confirmation-modal";
import UserRoleList from "../../components/user-role-list/user-role-list";
import { ApprovalIcon } from "../../components/icons/approval-icon";
import { WorkspaceUserRole } from "../../utils/enums";
import { getAlertingChannelDisplayType } from "../../utils/alerting";
import { SubmitType } from "../../views/integrations/integration-metadata";
import Radio, { RadioGroup } from "../../atom/radio";

import "./workspace-fields.scss";

const nameRules = [
  {
    required: true,
    message: "You must provide a workspace name",
  },
];

const NameInput = (props) => {
  const { editable } = props;
  return (
    <div className="workspace-form-name-input">
      <Form.Item name="name" rules={nameRules}>
        <LabeledInput label="Workspace Name" disabled={!editable} autoComplete="off" />
      </Form.Item>
    </div>
  );
};

const DescriptionInput = (props) => {
  const { editable } = props;
  return (
    <div className="workspace-form-description-input">
      <Form.Item name="description">
        <LabeledInput label="Description" disabled={!editable} autoComplete="off" />
      </Form.Item>
    </div>
  );
};

export const AdminList = (props) => {
  const { admins, currentUser } = props;
  return (
    <div className="workspace-form-admin-list">
      <h3>Workspace Admins</h3>
      <UserRoleList userList={admins} currentUser={currentUser} />
    </div>
  );
};

const ApprovalMode = Object.freeze({
  NONE: "none",
  METRIC: "metric",
  MONITOR: "monitor",
});

function getApprovalModeFromConfig(config) {
  const { metricApproval, monitorApproval } = config;
  if (!metricApproval && !monitorApproval) {
    return ApprovalMode.NONE;
  }

  if (monitorApproval) {
    return ApprovalMode.MONITOR;
  }

  if (metricApproval) {
    return ApprovalMode.METRIC;
  }
}

function getConfigFromApprovalMode(approvalMode) {
  if (approvalMode === ApprovalMode.NONE) {
    return { metricApproval: false, monitorApproval: false };
  }

  if (approvalMode === ApprovalMode.MONITOR) {
    return { metricApproval: false, monitorApproval: true };
  }

  if (approvalMode === ApprovalMode.METRIC) {
    return { metricApproval: true, monitorApproval: false };
  }
}

const ApprovalConfigItem = (props) => {
  const { editable, value = {}, onChange, integrationList = [] } = props;
  const [isOpen, setIsOpen] = useState(false);
  const approvalMode = getApprovalModeFromConfig(value);
  const isApprovalEnabled = approvalMode !== ApprovalMode.NONE;

  const channelOptions = integrationList
    .filter(({ mode }) => mode !== SubmitType.MANUAL)
    .map(({ id, title, type }) => {
      return {
        label: `${getAlertingChannelDisplayType(type)}: ${title}`,
        value: id,
      };
    });

  return (
    <div className="workspace-form-approval-config-item-container">
      <div className="workspace-form-approval-config-item-header-container">
        <div className="workspace-form-approval-config-item-title-container">
          <ApprovalIcon /> Approval<span>(optional)</span>
        </div>
        <NgToggleCheckbox
          size="mini"
          value={isApprovalEnabled}
          onChange={() => {
            const newIsApprovalEnabled = !isApprovalEnabled;
            if (newIsApprovalEnabled) {
              onChange(getConfigFromApprovalMode(ApprovalMode.METRIC));
            } else {
              setIsOpen(true);
            }
          }}
          disabled={!editable}
        />
      </div>
      <div className="workspace-form-approval-config-item-description-container">
        Editor roles in this workspace will need approval to create and edit metrics and
        monitors
      </div>
      <div className="workspace-form-approval-config-item-radio-group-container">
        <RadioGroup
          onChange={(e) => {
            onChange(getConfigFromApprovalMode(e.target.value));
          }}
          value={approvalMode}
          disabled={!editable}
        >
          <Space direction="vertical">
            <Radio
              value={ApprovalMode.METRIC}
              disabled={approvalMode === ApprovalMode.NONE}
            >
              Require Metric Approval
            </Radio>
            <Radio value={ApprovalMode.MONITOR} disabled>
              Require Metric & Monitor Approval
            </Radio>
          </Space>
        </RadioGroup>
      </div>
      <div className="workspace-form-approval-config-item-notification-channels-container">
        <LabeledMultiSelect
          label="Approval notification channel"
          value={value.alertChannels?.map(({ channelId }) => channelId) ?? []}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
          }
          options={channelOptions}
          onChange={(newChannels) => {
            onChange({
              ...value,
              alertChannels:
                newChannels?.length > 0
                  ? newChannels?.map((channelId) => ({
                      channelId,
                    }))
                  : null,
            });
          }}
          mode="multiple"
          disabled={approvalMode === ApprovalMode.NONE || !editable}
          staticLabel
        />
      </div>
      <PasscodeConfirmationModal
        visible={isOpen}
        title="This action requires attention"
        onCancel={() => setIsOpen(false)}
        onConfirm={() => {
          onChange(getConfigFromApprovalMode(ApprovalMode.NONE));
        }}
      >
        Changing the workspace approval setting while there are existing draft metrics
        will delete all the existing drafts.
      </PasscodeConfirmationModal>
    </div>
  );
};

export const WorkspaceHeaderFields = (props) => {
  const { editable = true } = props;

  return (
    <div className="workspace-form-header">
      <div className="workspace-form-header-title-container">Workspace details</div>
      <NameInput editable={editable} />
      <DescriptionInput editable={editable} />
    </div>
  );
};

export const WorkspaceSettingFields = (props) => {
  const { currentUser, editable = true, integrationList = [] } = props;

  return (
    <div className="workspace-form">
      {currentUser.role === WorkspaceUserRole.ADMIN && (
        <Form.Item name={["config", "enableApprovals"]}>
          <ApprovalConfigItem editable={editable} integrationList={integrationList} />
        </Form.Item>
      )}
    </div>
  );
};
