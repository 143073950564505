import {
  getStringCompareFunction,
  stringListCompareFunction,
} from "../../../utils/search";
import { capitalizeFirstLetter } from "../../../utils/general";
import { DraftStateNameMap, DraftTypeNameMap } from "../../../utils/enums";
import { metricFields } from "./use-search-metrics-config";
import {
  displayColumnName,
  displaySchemaName,
  displayTableName,
} from "../../entity-list/columns";

export const GENERAL_SEARCH_FIELD = "searchName";

const stringCompareFunction = getStringCompareFunction(false);
const strictStringCompareFunction = getStringCompareFunction(true);

export const draftFields = {
  DATA_SOURCE_NAME: "dataSourceName",
  SCHEMA_NAME: "schemaName",
  TABLE_NAME: metricFields.TABLE_NAME,
  COLUMN_NAME: metricFields.COLUMN_NAME,
  DRAFT_NAME: "draftName",
  DRAFT_TYPE: "draftType",
  DRAFT_STATUS: "draftStatus",
  DRAFT_REQUEST_ACTION: "requestAction",
  DRAFT_TARGET_OBJECT: "targetObject",
  CREATED_BY: metricFields.CREATED_BY,
  LAST_MODIFY_BY: metricFields.LAST_MODIFY_BY,
  DRAFT_ID: "draftId",
};

export const draftFieldOptionValueGetterFn = {
  [draftFields.DRAFT_ID]: (draft) => draft.metadata.idSerial,
  [draftFields.DRAFT_NAME]: (draft) => draft.draftMetadata.name,
  [draftFields.DRAFT_TYPE]: (draft) => capitalizeFirstLetter(draft.type),
  [draftFields.DRAFT_STATUS]: (draft) =>
    DraftStateNameMap[draft.draftMetadata.state] || "",
  [draftFields.DRAFT_REQUEST_ACTION]: (draft) =>
    DraftTypeNameMap[draft.draftMetadata.type] || "",
  [draftFields.DRAFT_TARGET_OBJECT]: (draft) => draft.metadata.name,
  [draftFields.CREATED_BY]: (draft) => draft?.metadata.ownedBy?.username,
  [draftFields.LAST_MODIFY_BY]: (draft) => draft?.metadata.updatedBy?.username,
  [draftFields.SCHEMA_NAME]: displaySchemaName,
  [draftFields.TABLE_NAME]: displayTableName,
  [draftFields.COLUMN_NAME]: displayColumnName,
};

export const draftFilterFn = {
  [GENERAL_SEARCH_FIELD]: (row, searchName) => {
    return (
      searchName.length &&
      !stringListCompareFunction(
        [
          row.draftMetadata.name,
          row.type,
          row.draftMetadata.state,
          row.draftMetadata.type,
          row.metadata.name,
          row.metadata.ownedBy?.username,
          row.metadata.updatedBy?.username,
        ],
        searchName,
        stringCompareFunction
      )
    );
  },
  [draftFields.DRAFT_ID]: (row, draftId) => {
    return !draftId.includes(row.metadata.idSerial.toString());
  },
  [draftFields.DRAFT_NAME]: (row, draftName) => {
    return !draftName.includes(row.draftMetadata.name);
  },
  [draftFields.DRAFT_TYPE]: (row, draftType) => {
    return !draftType.includes(capitalizeFirstLetter(row.type));
  },
  [draftFields.DRAFT_STATUS]: (row, draftStatus) => {
    return !draftStatus.includes(DraftStateNameMap[row.draftMetadata.state] || "");
  },
  [draftFields.DRAFT_REQUEST_ACTION]: (row, requestAction) => {
    return !requestAction.includes(DraftTypeNameMap[row.draftMetadata.type] || "");
  },
  [draftFields.DRAFT_TARGET_OBJECT]: (row, targetObject) => {
    return !targetObject.includes(row.metadata.name);
  },
  [draftFields.CREATED_BY]: (row, createdBy) => {
    return !stringCompareFunction(row.metadata.ownedBy?.username, createdBy);
  },
  [draftFields.LAST_MODIFY_BY]: (row, lastModifyBy) => {
    return !stringCompareFunction(row.metadata.updatedBy?.username, lastModifyBy);
  },
  [draftFields.DATA_SOURCE_NAME]: (row, dataSourceName) => {
    return !stringListCompareFunction(
      row.dataSourceNames,
      dataSourceName,
      strictStringCompareFunction
    );
  },
  [draftFields.SCHEMA_NAME]: (row, schemaName) => {
    return !strictStringCompareFunction(row.displaySchemaName, schemaName);
  },
  [draftFields.TABLE_NAME]: (row, tableName) => {
    return !strictStringCompareFunction(row.displayTableName, tableName);
  },
  [draftFields.COLUMN_NAME]: (row, columnName) => {
    return !row.config?.valueColumns?.some((column) =>
      strictStringCompareFunction(column.columnName, columnName)
    );
  },
};
