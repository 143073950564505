import { Tabs } from "antd";
import React from "react";
import { TextWithIcon } from "../../components/button/ng-button";
import { MetadataIcon } from "../../components/metric/fields/icons";
import { isFileSource } from "../../utils/enums";
import { AppPermissions } from "../../utils/permissions";
import { hasPermission } from "../../utils/uri-path";
import { HistoryOutlined } from "@ant-design/icons";
import {
  ConfigureAutoMetricsIcon,
  DataProfilingIcon,
  ManageColumnsIcon,
  ManageTablesIcon,
  SummaryIcon,
  TableHealthIcon,
} from "./menu-icons";
import { ProfilerTreeNodeType, getSelectedNodeTypeAndId } from "./tree-data";
import { ProfilerSummaryTabKey, canViewDataProfile } from "./utils";
import {
  isSomeMetadataMetricEnabled,
  tableSupportsDataProfiling,
} from "../../utils/table";
import { TableConfigIcon } from "../../components/icons/table-config-icon";
import { dataSourceSupportsDataProfiling } from "../../utils/datasource";
import "./profiler-summary-tab-view.scss";
import Icon, { IconName } from "../../elements/Icon";

export const nodeTypeSupportedTabs = {
  [ProfilerTreeNodeType.DATASOURCE]: [
    ProfilerSummaryTabKey.SUMMARY,
    ProfilerSummaryTabKey.MANAGE_SCHEMAS,
    ProfilerSummaryTabKey.GOVERNANCE,
    ProfilerSummaryTabKey.QUERY_HISTORY,
  ],
  [ProfilerTreeNodeType.SCHEMA]: [
    ProfilerSummaryTabKey.SUMMARY,
    ProfilerSummaryTabKey.CONFIGURE_DATA_PROFILES,
    ProfilerSummaryTabKey.CONFIGURE_AUTO_METRICS,
    ProfilerSummaryTabKey.MANAGE_TABLES,
  ],
  [ProfilerTreeNodeType.TABLE]: [
    ProfilerSummaryTabKey.HEALTH,
    ProfilerSummaryTabKey.DATA_PROFILE,
    ProfilerSummaryTabKey.AUTO_METRICS,
    ProfilerSummaryTabKey.CONFIGURATION,
    ProfilerSummaryTabKey.MANAGE_COLUMNS,
    ProfilerSummaryTabKey.ACTIVITY,
  ],
  [ProfilerTreeNodeType.COLUMN]: [
    ProfilerSummaryTabKey.DATA_PROFILE,
    ProfilerSummaryTabKey.AUTO_METRICS,
  ],
};

export function defaultTabKey(nodeType, options = {}) {
  const {
    dataSource = null,
    tableInfo = null,
    workspaceUserPermissions = null,
    columnInfo = {},
  } = options;
  let supportedTabs = [];

  if (nodeType === ProfilerTreeNodeType.SCHEMA && dataSource) {
    supportedTabs = nodeTypeSupportedTabs[ProfilerTreeNodeType.SCHEMA].filter(
      (currentKey) => schemaNodeSupportsTabKey({ dataSource }, currentKey)
    );
  } else if (
    nodeType === ProfilerTreeNodeType.TABLE &&
    dataSource &&
    tableInfo &&
    workspaceUserPermissions
  ) {
    supportedTabs = nodeTypeSupportedTabs[ProfilerTreeNodeType.TABLE].filter(
      (currentKey) =>
        tableNodeSupportsTabKey(
          { dataSource, tableInfo, workspaceUserPermissions },
          currentKey
        )
    );
  } else if (
    nodeType === ProfilerTreeNodeType.COLUMN &&
    dataSource &&
    workspaceUserPermissions
  ) {
    supportedTabs = nodeTypeSupportedTabs[ProfilerTreeNodeType.COLUMN].filter(
      (currentKey) =>
        columnNodeSupportsTabKey(
          { dataSource, tableInfo, workspaceUserPermissions },
          currentKey
        )
    );

    const isTableConfigured = tableInfo?.profilerConfig?.enabled;
    const isAutometricEnabled =
      columnInfo?.profilerConfig?.categoricalDistribution?.enabled ||
      columnInfo?.profilerConfig?.categoryListChange?.enabled ||
      columnInfo?.profilerConfig?.missingValue?.enabled ||
      columnInfo?.profilerConfig?.numericalDistribution?.enabled;
    const supportsAutoMetricTab = supportedTabs.includes(
      ProfilerSummaryTabKey.AUTO_METRICS
    );

    if (supportsAutoMetricTab && (isTableConfigured || isAutometricEnabled)) {
      return ProfilerSummaryTabKey.AUTO_METRICS;
    }
  } else {
    supportedTabs = nodeTypeSupportedTabs[nodeType];
  }

  return supportedTabs?.[0];
}

export function dataSourceNodeSupportsTabKey(key) {
  return nodeTypeSupportedTabs[ProfilerTreeNodeType.DATASOURCE].includes(key);
}

export function schemaNodeSupportsTabKey({ dataSource }, key) {
  if (!nodeTypeSupportedTabs[ProfilerTreeNodeType.SCHEMA].includes(key)) {
    return false;
  } else if (key === ProfilerSummaryTabKey.CONFIGURE_DATA_PROFILES) {
    return dataSourceSupportsDataProfiling(dataSource);
  } else {
    return true;
  }
}

export function tableNodeSupportsTabKey(
  { dataSource, tableInfo, workspaceUserPermissions },
  key
) {
  const canModifyTableSettings = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_TABLEPROFILERCONFIGDETAILVIEW,
  ]);
  const canViewDataChart = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
    AppPermissions.BACKEND_APPS_STREAM_RESULT_VIEWS_VIEW_METRICDATAPOINTSVIEW,
  ]);
  const isTableMetricDefined =
    isSomeMetadataMetricEnabled(tableInfo) ||
    (tableInfo.profilerConfig?.enabled &&
      (tableInfo.profilerConfig?.dataDelay?.enabled ||
        tableInfo.profilerConfig?.volume?.enabled));
  const isDataProfileEnabled = tableInfo?.profilerConfig?.dataProfiler?.enabled;

  if (!nodeTypeSupportedTabs[ProfilerTreeNodeType.TABLE].includes(key)) {
    return false;
  } else if (key === ProfilerSummaryTabKey.HEALTH) {
    return hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_VIEW_TABLEHEALTHVIEW,
    ]);
  } else if (key === ProfilerSummaryTabKey.DATA_PROFILE) {
    return (
      dataSourceSupportsDataProfiling(dataSource) &&
      tableSupportsDataProfiling(tableInfo) &&
      ((canViewDataProfile(workspaceUserPermissions) && isDataProfileEnabled) ||
        canModifyTableSettings)
    );
  } else if (key === ProfilerSummaryTabKey.AUTO_METRICS) {
    return canViewDataChart && (isTableMetricDefined || canModifyTableSettings);
  } else if (key === ProfilerSummaryTabKey.ACTIVITY) {
    return hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_VIEW_TABLECHANGELOG,
    ]);
  } else {
    return true;
  }
}

export function columnNodeSupportsTabKey(
  { workspaceUserPermissions, dataSource, tableInfo },
  key
) {
  const canViewSummary = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_COLUMNPROFILESUMMARYVIEW,
  ]);
  const canViewDataProfile = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_COLUMNDATAPROFILEDETAILVIEW,
  ]);
  const canViewEventChart = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_RESULT_VIEWS_VIEW_PROFILERCOLUMNEVENTLISTVIEW,
  ]);
  const canViewDataChart = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMLIST,
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_VIEW_FILTERLIST,
    AppPermissions.BACKEND_APPS_STREAM_RESULT_VIEWS_VIEW_METRICDATAPOINTSVIEW,
  ]);

  if (!nodeTypeSupportedTabs[ProfilerTreeNodeType.COLUMN].includes(key)) {
    return false;
  } else if (key === ProfilerSummaryTabKey.AUTO_METRICS) {
    return canViewEventChart || canViewDataChart || canViewSummary;
  } else if (key === ProfilerSummaryTabKey.DATA_PROFILE) {
    return (
      !isFileSource(dataSource?.config?.connection?.type) &&
      tableSupportsDataProfiling(tableInfo) &&
      canViewDataProfile
    );
  } else {
    return true;
  }
}

export function isTabKeySupported({ node, waffle, workspaceUserPermissions }, key) {
  switch (getSelectedNodeTypeAndId(node)[0]) {
    case ProfilerTreeNodeType.DATASOURCE:
      return dataSourceNodeSupportsTabKey(key);
    case ProfilerTreeNodeType.SCHEMA:
      return schemaNodeSupportsTabKey({ waffle, dataSource: node.dataSource }, key);
    case ProfilerTreeNodeType.TABLE:
      return tableNodeSupportsTabKey(
        {
          dataSource: node.dataSource,
          tableInfo: node.tableInfo,
          waffle,
          workspaceUserPermissions,
        },
        key
      );
    case ProfilerTreeNodeType.COLUMN:
      return columnNodeSupportsTabKey(
        {
          dataSource: node.dataSource,
          workspaceUserPermissions,
        },
        key
      );
    default:
      return true;
  }
}

const tabInfoMapper = {
  [ProfilerSummaryTabKey.SUMMARY]: {
    label: "Summary",
    icon: <SummaryIcon />,
  },
  [ProfilerSummaryTabKey.HEALTH]: {
    label: "Table Health",
    icon: <TableHealthIcon />,
  },
  [ProfilerSummaryTabKey.DATA_PROFILE]: {
    label: "Data Profile",
    icon: <DataProfilingIcon size={14} height={14} />,
  },
  [ProfilerSummaryTabKey.AUTO_METRICS]: {
    label: "Auto Metrics",
    icon: <MetadataIcon />,
  },
  [ProfilerSummaryTabKey.CONFIGURATION]: {
    label: "Configuration",
    icon: <TableConfigIcon />,
  },
  [ProfilerSummaryTabKey.MANAGE_COLUMNS]: {
    label: "Manage Columns",
    icon: <ManageColumnsIcon />,
  },
  [ProfilerSummaryTabKey.MANAGE_TABLES]: {
    label: "Table Configuration",
    icon: <ManageTablesIcon />,
  },
  [ProfilerSummaryTabKey.MANAGE_SCHEMAS]: {
    label: "Manage Schemas",
    icon: <ManageTablesIcon />,
  },
  [ProfilerSummaryTabKey.CONFIGURE_AUTO_METRICS]: {
    label: "Manage Auto Metrics",
    icon: <ConfigureAutoMetricsIcon />,
  },
  [ProfilerSummaryTabKey.CONFIGURE_DATA_PROFILES]: {
    label: "Manage Data Profiles",
    icon: <DataProfilingIcon height={14} />,
  },
  [ProfilerSummaryTabKey.ACTIVITY]: {
    label: "Activity",
    icon: <Icon name={IconName.ArrowClick} />,
  },
  [ProfilerSummaryTabKey.GOVERNANCE]: {
    label: "Governance",
    icon: <Icon name={IconName.FileMagnifyingGlass} size={18} />,
  },
  [ProfilerSummaryTabKey.QUERY_HISTORY]: {
    label: "Query History",
    icon: <HistoryOutlined />,
  },
};

export default function ProfilerSummaryTabView(props) {
  const { tabConfigs = [], value, onChange } = props;
  if (tabConfigs.length === 0) {
    return null;
  }

  return (
    <Tabs
      className="profiler-summary-tab-tabs lightup"
      activeKey={value}
      onChange={onChange}
      size={"large"}
    >
      {tabConfigs.map(({ key, content }) => {
        const { label, icon } = tabInfoMapper[key];

        return (
          <Tabs.TabPane
            tab={
              <TextWithIcon iconPosition="left" icon={icon}>
                <span className="profiler-summary-tab-label">{label}</span>
              </TextWithIcon>
            }
            key={`${key}`}
          >
            <div className="profiler-summary-tab-content-container">{content}</div>
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
}

export function getLocalStorageKey(type) {
  return `@profiler-checklist-${type}`;
}
