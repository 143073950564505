import * as types from "./admin-action-types";
import {
  addAppUserPromise,
  addWorkspaceUserPromise,
  batchWorkspaceUserOperationPromise,
  deleteAppUserPromise,
  deleteWorkspaceUserPromise,
  getAppUserListPromise,
  patchAppUserPromise,
  addSystemIntegrationPromise,
  deleteSystemIntegrationPromise,
  getSystemIntegrationListPromise,
  updateSystemIntegrationPromise,
  previewSystemIntegrationPromise,
} from "../../rest/user";
import {
  addWorkspacePromise,
  deleteWorkspacePromise,
  getWorkspaceListPromise,
  patchWorkspacePromise,
} from "../../rest/workspace";
import {
  getUserList,
  updateUserWorkspaces,
  updateCurrentWorkspaceUuid,
} from "../user/user-action";
import { toast } from "../../components/toast/toast";

function setAppUserList(appUserList) {
  return { type: types.SET_APP_USER_LIST, appUserList };
}

function setAppWorkspaceList(appWorkspaceList) {
  return { type: types.SET_APP_WORKSPACE_LIST, appWorkspaceList };
}

function setUserWorkspaceList(userWorkspaceList) {
  return { type: types.SET_USER_WORKSPACE_LIST, userWorkspaceList };
}

function setSystemIntegrationList(systemIntegrationList) {
  return { type: types.SET_SYSTEM_INTEGRATION_LIST, systemIntegrationList };
}

export function getAppUserList(isReset = true) {
  return (dispatch, getState) => {
    isReset && dispatch(setAppUserList({ loading: true, data: [] }));
    return getAppUserListPromise()
      .then(function (data) {
        dispatch(setAppUserList({ loading: false, data }));
      })
      .catch(function (err) {
        dispatch(setAppUserList({ loading: false, data: [] }));
        console.log(`Fail to get app user list for ${err}`);
      });
  };
}

export function getAppWorkspaceList(isReset = true) {
  return (dispatch, getState) => {
    isReset && dispatch(setAppWorkspaceList({ loading: true, data: [] }));
    return getWorkspaceListPromise()
      .then(function (data) {
        dispatch(setAppWorkspaceList({ loading: false, data }));
      })
      .catch(function (err) {
        dispatch(setAppWorkspaceList({ loading: false, data: [] }));
        console.log(`Fail to get app workspace list for ${err}`);
      });
  };
}

export function getUserWorkspaceList(isReset = true) {
  return (dispatch, getState) => {
    isReset && dispatch(setUserWorkspaceList({ loading: true, data: [] }));
    return getWorkspaceListPromise({ memberOnly: true })
      .then(function (data) {
        dispatch(setUserWorkspaceList({ loading: false, data }));
      })
      .catch(function (err) {
        dispatch(setUserWorkspaceList({ loading: false, data: [] }));
        console.log(`Fail to get user workspace list for ${err}`);
      });
  };
}

export function addWorkspace(workspace, newUserOperations = {}) {
  return (dispatch, getState) => {
    return addWorkspacePromise(workspace)
      .then(function (createdWorkspace) {
        return batchWorkspaceUserOperationPromise(
          createdWorkspace.uuid,
          newUserOperations
        )
          .then(function () {
            return new Promise((resolve, rejected) => {
              Promise.all([
                getAppWorkspaceList(false)(dispatch, getState),
                getAppUserList(false)(dispatch, getState),
                getUserWorkspaceList(false)(dispatch, getState),
                updateUserWorkspaces()(dispatch, getState),
              ])
                .then(() => {
                  updateCurrentWorkspaceUuid(createdWorkspace.uuid)(dispatch, getState);
                  resolve(createdWorkspace);
                })
                .catch((error) => rejected(error));
            });
          })
          .catch(function (err) {
            console.log(`Fail to add member to workspace ${err}`);
          });
      })
      .catch(function (error) {
        console.log(`Failed to add workspace ${error}`);
      });
  };
}

export function updateWorkspace(
  uuid,
  partialWorkspace,
  updateUserOperations = {},
  isAppLevel = true
) {
  return (dispatch, getState) => {
    const isWorkspaceUpdated = Object.keys(partialWorkspace).length > 0;
    const updateWorkspacePromiseObject = isWorkspaceUpdated
      ? patchWorkspacePromise(uuid, partialWorkspace, isAppLevel)
      : Promise.resolve();

    updateWorkspacePromiseObject
      .then(function () {
        batchWorkspaceUserOperationPromise(uuid, updateUserOperations)
          .then(function () {
            isWorkspaceUpdated && getAppWorkspaceList(false)(dispatch, getState);
            getUserList(uuid, { isRefresh: true })(dispatch);
            getAppUserList(false)(dispatch, getState);
            isWorkspaceUpdated && getUserWorkspaceList(false)(dispatch, getState);
            isWorkspaceUpdated && updateUserWorkspaces()(dispatch, getState);
          })
          .catch(function (err) {
            console.log(`Fail to batch update user member to workspace ${err}`);
          });
      })
      .catch(function (error) {
        console.log(`Failed to batch update user member to workspace ${error}`);
      });
  };
}

export function deleteWorkspace(uuid, isAppLevel = true) {
  return (dispatch, getState) => {
    deleteWorkspacePromise(uuid, isAppLevel)
      .then(function () {
        getAppWorkspaceList(false)(dispatch, getState);
        getAppUserList(false)(dispatch, getState);
        getUserWorkspaceList(false)(dispatch, getState);
        updateUserWorkspaces()(dispatch, getState);
      })
      .catch(function (error) {
        console.log(`Failed to batch update user member to workspace ${error}`);
      });
  };
}

export function addAppUser(newUser) {
  return (dispatch, getState) => {
    addAppUserPromise(newUser)
      .then(function (response) {
        getAppUserList(false)(dispatch, getState);
      })
      .catch(function (error) {
        console.log(`Fail to add app user for ${error}`);
      });
  };
}

export function deleteAppUser(username) {
  return (dispatch, getState) => {
    deleteAppUserPromise(username)
      .then(function () {
        getAppUserList(false)(dispatch, getState);
      })
      .catch(function (err) {
        console.log(`Fail to delete ${username} due to ${err}`);
      });
  };
}

export function updateAppUser(username, role) {
  return (dispatch, getState) => {
    patchAppUserPromise(username, { role })
      .then(function () {
        getAppUserList(false)(dispatch, getState);
      })
      .catch(function (err) {
        console.log(`Fail to update ${username} role due to ${err}`);
      });
  };
}

export function addAppUserToWorkspace(workspaceUuid, user) {
  return (dispatch, getState) => {
    addWorkspaceUserPromise(workspaceUuid, user)
      .then(function () {
        getAppUserList(false)(dispatch, getState);
        getUserWorkspaceList(false)(dispatch, getState);
        updateUserWorkspaces()(dispatch, getState);
      })
      .catch(function (err) {
        console.log(`Fail to add ${user.email} to ${workspaceUuid} due to ${err}`);
      });
  };
}

export function removeAppUserFromWorkspace(workspaceUuid, user) {
  return (dispatch, getState) => {
    deleteWorkspaceUserPromise(workspaceUuid, user)
      .then(function () {
        getAppUserList(false)(dispatch, getState);
        getUserWorkspaceList(false)(dispatch, getState);
        updateUserWorkspaces()(dispatch, getState);
      })
      .catch(function (err) {
        console.log(`Fail to remove ${user.email} from ${workspaceUuid} due to ${err}`);
      });
  };
}

export function getSystemIntegrationList(opts = {}) {
  return (dispatch, getState) => {
    const { quiet = false } = opts;
    !quiet && dispatch(setSystemIntegrationList({ data: [], loading: true }));
    getSystemIntegrationListPromise()
      .then(function (data) {
        dispatch(setSystemIntegrationList({ data: data.data, loading: false }));
      })
      .catch(function (err) {
        console.log(`Fail to get system integration list due to ${err}`);
        dispatch(setSystemIntegrationList({ data: [], loading: false }));
      });
  };
}

export function addSystemIntegration(data) {
  return (dispatch, getState) => {
    addSystemIntegrationPromise(data)
      .then(function () {
        getSystemIntegrationList({ quiet: true })(dispatch, getState);
      })
      .catch(function (err) {
        console.log(`Failed to add integration item due to ${err}`);
      });
  };
}

export function updateSystemIntegration(data) {
  return (dispatch, getState) => {
    updateSystemIntegrationPromise(data.metadata.uuid, data)
      .then(function () {
        getSystemIntegrationList({ quiet: true })(dispatch, getState);
      })
      .catch(function (err) {
        console.log(`Failed to update integration item due to ${err}`);
      });
  };
}

export function deleteSystemIntegration(data) {
  return (dispatch, getState) => {
    deleteSystemIntegrationPromise(data.metadata.uuid)
      .then(function () {
        getSystemIntegrationList({ quiet: true })(dispatch, getState);
      })
      .catch(function (err) {
        console.log(`Failed to delete integration item due to ${err}`);
      });
  };
}

export function previewSystemIntegration(data) {
  return (dispatch, getState) => {
    previewSystemIntegrationPromise(data)
      .then(function (data) {
        toast("Successfully test connection");
      })
      .catch(function (err) {
        console.log(`Failed to preview system integration due to ${err}`);
      });
  };
}
