import React, { useState } from "react";
import { Modal, Form, Select } from "antd";
import Button from "../../components/button/ng-button";
import DashboardChartFilterList from "./dashboard-chart-filter-list";
import Divider from "../../atom/divider";
import TextInput from "../../atom/TextInput";
import "./dashboard-chart-config-dialog.scss";

const aggregationColumnOptions = [
  { label: "# Incidents", value: "incident" },
  { label: "# Slices", value: "sliceSet" },
];

function DashboardChartConfigDialog(props) {
  const {
    dialogTitle,
    workspaceUuid,
    initialValues,
    onSubmit,
    TriggerComponent,
    ChartViewComponent,
    incidentList,
    uuid,
    getData,
    userSetting,
    dataSourceOptions,
    kpiOptions,
    ruleOptions,
    tagList,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [valuesForm, setValuesForm] = useState({ ...initialValues });
  const [form] = Form.useForm();

  const onSubmitForm = (values) => {
    onSubmit && onSubmit(values);
    setIsModalVisible(false);
  };

  const closePopup = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    form.setFieldsValue(initialValues);
    setValuesForm(initialValues);
    setIsModalVisible(true);
  };

  return (
    <>
      <TriggerComponent onClick={showModal} />
      {isModalVisible && (
        <Modal
          className="dashboard-chart-config-dialog"
          footer={null}
          title={dialogTitle}
          visible={isModalVisible}
          onOk={form.submit}
          onCancel={closePopup}
          width={1000}
        >
          <div className="dashboard-chart-config-dialog-body-container lightup-horizon-flex-container">
            <div className="dashboard-chart-preview-container">
              <ChartViewComponent
                workspaceUuid={workspaceUuid}
                config={valuesForm}
                incidentList={incidentList}
                userSetting={userSetting}
                uuid={uuid}
                getData={getData}
                isDialogMode={true}
              />
            </div>
            <div className="dashboard-chart-config-divider-container">
              <Divider type="vertical" style={{ height: "100%" }} />
            </div>
            <div className="dashboard-chart-config-container">
              <Form
                className="dashboard-chart-form-config-container"
                form={form}
                layout="vertical"
                onFinish={onSubmitForm}
                initialValues={initialValues}
                onValuesChange={(changedValues, allValues) => {
                  if (allValues.filters) {
                    // Need to remove all undefined element due to removing operation.
                    allValues.filters = allValues.filters.filter(
                      (currentFilter) => currentFilter && currentFilter.type
                    );
                  }
                  setValuesForm(allValues);
                }}
              >
                <Form.Item
                  label="Chart title"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <TextInput autoComplete="off" />
                </Form.Item>
                <div className="dashboard-chart-section-title-container">Data</div>
                <Form.Item label="Groups" name="groupColumns">
                  <Select
                    placeholder="Select group(s)"
                    mode={"multiple"}
                    allowClear={true}
                  >
                    <Select.Option value="column">Column</Select.Option>
                    <Select.Option value="datasource">Datasource</Select.Option>
                    <Select.OptGroup label="Date">
                      <Select.Option value="week">Week</Select.Option>
                      <Select.Option value="day">Day</Select.Option>
                      <Select.Option value="hour">Hour</Select.Option>
                    </Select.OptGroup>
                    <Select.Option value="direction">Direction</Select.Option>
                    <Select.Option value="metric">Metric</Select.Option>
                    <Select.Option value="monitor">Monitor</Select.Option>
                    <Select.Option value="severity">Severity</Select.Option>
                    <Select.Option value="slice">Slice</Select.Option>
                    <Select.Option value="status">Status</Select.Option>
                    <Select.Option value="table">Table</Select.Option>
                    <Select.Option value="tags">Tag</Select.Option>
                    <Select.Option value="validationStatus">
                      Validation Status
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Values" name="aggregationColumns">
                  <Select
                    placeholder="Select value(s)"
                    options={aggregationColumnOptions}
                    mode={"multiple"}
                    allowClear={true}
                  />
                </Form.Item>
                <div className="dashboard-chart-section-title-container">Filters</div>
                <DashboardChartFilterList
                  form={form}
                  dataSourceOptions={dataSourceOptions}
                  kpiOptions={kpiOptions}
                  ruleOptions={ruleOptions}
                  tagList={tagList}
                />
              </Form>
              <div className="dashboard-chart-config-rest-container" />
              <div className="dashboard-chart-footer-container">
                <Button
                  primary
                  key="submit"
                  onClick={form.submit}
                  className="save-button"
                >
                  Save
                </Button>
                <Button outline key="back" onClick={closePopup}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default DashboardChartConfigDialog;
