import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Prompt } from "react-router-dom";
import WorkspaceForm from "../../components/workspace/workspace-form";
import {
  WorkspaceUserRole,
  WorkspaceFormMode,
  SupportedFeature,
} from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/general";
import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import WorkspaceDeleteModal from "../../components/workspace/workspace-delete-modal";
import { goBackOrToDefault } from "../../utils/router";
import { deepcopy } from "../../utils/objects";

const confirmExitPrompt = "You have unsaved changes - discard?";

function EditWorkspace(props) {
  const {
    getUserList,
    getAppUserList,
    userList,
    userListLoading,
    userListUpdatedAt,
    appUserList,
    userInfo,
    workspaceList,
    match: { params },
    workspaceUserPermissions,
    workspaceUuid,
    updateWorkspace,
    deleteWorkspace,
    updateCurrentWorkspaceUuid,
    integrationList,
    getIntegrationList,
    history,
  } = props;

  const canEditFields = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_WORKSPACES_VIEWS_EDIT_WORKSPACEDETAIL,
  ]);
  const canEditExistingUserRoles = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_USER_VIEWS_EDIT_WORKSPACEUSERDETAIL,
  ]);
  const canAddUsers = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_USER_VIEWS_EDIT_WORKSPACEUSERLIST,
  ]);
  const canRemoveUsers = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_USER_VIEWS_EDIT_WORKSPACEUSERDETAIL,
  ]);
  const canDeleteWorkspace = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_WORKSPACES_VIEWS_EDIT_WORKSPACEDETAIL,
  ]);

  const currentWorkspaceUuid = workspaceUuid ?? params.workspaceUuid;
  const currentWorkspace = workspaceList.find((ws) => ws.uuid === currentWorkspaceUuid);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const allowInviteToWorkspace = isFeatureEnabled(
    userInfo.waffle,
    SupportedFeature.ALLOW_INVITE_TO_WORKSPACE
  );

  useEffect(() => {
    if (currentWorkspaceUuid) {
      getUserList(currentWorkspaceUuid);
      getIntegrationList(currentWorkspaceUuid);
      !allowInviteToWorkspace && getAppUserList();
    }
  }, [
    currentWorkspaceUuid,
    getUserList,
    getAppUserList,
    allowInviteToWorkspace,
    getIntegrationList,
  ]);

  if (!currentWorkspaceUuid) {
    console.log("Failed to find current workspace to edit.");
    return null;
  }

  if (!currentWorkspace) {
    console.log(`Fail to find workspace uuid ${currentWorkspaceUuid}`);
    return null;
  }

  const initialValues = {
    name: currentWorkspace.name,
    description: currentWorkspace.description,
    config: deepcopy(currentWorkspace.config),
    iconId: currentWorkspace.iconId,
    users: deepcopy(userList),
  };

  function onFormChange(_, isChangedFromInitial) {
    setIsFormChanged(isChangedFromInitial);
  }

  function afterCancelOrSave() {
    goBackOrToDefault(
      history,
      getURIInstance(URIPath.EXPLORER, { workspaceUuid: currentWorkspaceUuid })
    );
  }

  function onCancelClick() {
    if (!isFormChanged || window.confirm(confirmExitPrompt)) {
      // If the form was changed, we've already confirmed the exit. Set
      // isFormChanged to false so that the prompt doesn't show up again.
      setIsFormChanged(false);
      afterCancelOrSave();
    }
  }

  function onSaveClick(form, currentWorkspaceUserOperations) {
    form
      .validateFields()
      .then((workspace) => {
        // We want to make sure to pass the diff here, since the user may not have
        // permission to edit the workspace fields. updateWorkspace will not issue a
        // request to update workspace fields if the diff is empty.
        const workspaceDiff = ["name", "description", "iconId", "config"].reduce(
          (diff, fieldName) => {
            if (initialValues[fieldName] !== workspace[fieldName]) {
              diff[fieldName] = workspace[fieldName];
            }
            return diff;
          },
          {}
        );
        updateWorkspace(
          currentWorkspaceUuid,
          workspaceDiff,
          currentWorkspaceUserOperations
        );
        setIsFormChanged(false);
      })
      .catch((e) => {
        console.log("Failed to submit workspace work", e);
      });
  }

  function onDeleteConfirmed() {
    deleteWorkspace(currentWorkspaceUuid);
    setIsDeleteModalVisible(false);
    const otherWorkspaces = workspaceList.filter(
      (ws) => ws.uuid !== currentWorkspaceUuid
    );
    updateCurrentWorkspaceUuid(otherWorkspaces?.[0].uuid || "");
    const nextUrl =
      otherWorkspaces.length > 0
        ? getURIInstance(URIPath.EXPLORER, {
            workspaceUuid: otherWorkspaces[0].uuid,
          })
        : URIPath.NO_WORKSPACE;
    history.push(nextUrl);
  }

  function onDeleteCancelled() {
    setIsDeleteModalVisible(false);
  }

  function onDeleteClick() {
    setIsDeleteModalVisible(true);
  }

  const currentUser = {
    email: userInfo.email,
    role: WorkspaceUserRole.ADMIN,
    username: userInfo.username,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
  };

  const formKey = `${currentWorkspaceUuid}_${userListUpdatedAt}`;

  return (
    <>
      <Prompt message={confirmExitPrompt} when={isFormChanged} />
      <WorkspaceForm
        key={formKey}
        mode={WorkspaceFormMode.EDIT}
        initialValues={initialValues}
        userListLoading={userListLoading}
        currentUser={currentUser}
        appUserList={appUserList}
        allowInviteToWorkspace={allowInviteToWorkspace}
        onSubmitClick={onSaveClick}
        onDeleteClick={onDeleteClick}
        onCancelClick={onCancelClick}
        onChange={onFormChange}
        canEditFields={canEditFields}
        canEditExistingUserRoles={canEditExistingUserRoles}
        canAddUsers={canAddUsers}
        canRemoveUsers={canRemoveUsers}
        canDeleteWorkspace={canDeleteWorkspace}
        submitEnabled={isFormChanged}
        integrationList={integrationList}
      />
      <WorkspaceDeleteModal
        visible={isDeleteModalVisible}
        onDeleteConfirmed={onDeleteConfirmed}
        onDeleteCancelled={onDeleteCancelled}
        workspaceName={currentWorkspace.name}
      />
    </>
  );
}

export default withRouter(EditWorkspace);
