import React from "react";
import PropTypes from "prop-types";
import Typography from "./Typography";
import { FontWeights, TypographyTypes } from "./enum";

const BodyMedium = ({ children, ...typographyProps }) => (
  <Typography elementType="p" size={TypographyTypes.BODY_MEDIUM} {...typographyProps}>
    {children}
  </Typography>
);

BodyMedium.propTypes = {
  weight: PropTypes.oneOf([FontWeights.REGULAR, FontWeights.MEDIUM]),
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

export default BodyMedium;
