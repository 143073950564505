import React from "react";
import { withRouter } from "react-router-dom";
import Button from "../../components/button/ng-button";
import { getCategoryDescription, getColorFromEventLevel } from "./utils";
import sqlFormatter from "sql-formatter";
import { DetailIcon } from "./icons";
import {
  MetricSummarySection,
  MonitorSummarySection,
} from "../../views/incident/tabs/summary/index";
import { capitalizeFirstLetter } from "../../utils/general";
import { getStringFromTimeStamp } from "../../utils/time";
import NotificationLevelCell from "./notification-level-cell";

import NotificationDatasourceActionsMenu from "./notification-datasource-actions-menu";
import { DataSourceActions } from "../datasources/datasource-actions-menu";
import NgTooltip from "../../components/tooltip/ng-tooltip";
import NgButton from "../../components/button/ng-button";
import NotificationNameCell from "./notification-name-cell";
import NotificationDetailInfoCell from "./notification-detail-info-cell";
import { NgTableClickableText } from "../../components/table/ng-table";
import { EventObjectType } from "../../utils/enums";
import { URIPath, getURIInstance } from "../../utils/uri-path";
import TextArea from "../../atom/textarea";
import "./notification-view.scss";

function NotificationDetailInfoRow(props) {
  const {
    history,
    workspaceUserPermissions,
    metric,
    monitor,
    integration,
    workspaceUuid,
    eventRecord,
  } = props;
  const { eventTs, category, level, objectType, sourceName, sourceUuid } = eventRecord;

  const actionsMenuCommonProps = {
    workspaceUuid,
    workspaceUserPermissions,
    history,
  };
  return (
    <div className="notification-view-detailed-info-row-container">
      <NotificationDetailInfoCell label={"Date & time"}>
        {getStringFromTimeStamp(eventTs)}
      </NotificationDetailInfoCell>
      <NotificationDetailInfoCell label={"Level"}>
        <NotificationLevelCell value={level} />
      </NotificationDetailInfoCell>
      <NotificationDetailInfoCell label={"Category"} isBold={true}>
        <NgTooltip placement={"bottomLeft"} title={getCategoryDescription(category)}>
          {category}
        </NgTooltip>
      </NotificationDetailInfoCell>
      <NotificationDetailInfoCell label={"Object type"}>
        {capitalizeFirstLetter(objectType)}
      </NotificationDetailInfoCell>
      <NotificationDetailInfoCell label={"Name"} isBold={true}>
        <NotificationNameCell
          actionsMenuCommonProps={actionsMenuCommonProps}
          eventRecord={eventRecord}
          metric={metric}
          monitor={monitor}
          integration={integration}
        />
      </NotificationDetailInfoCell>
      {objectType !== EventObjectType.INTEGRATION && (
        <NotificationDetailInfoCell label={"Datasource"} isBold={true}>
          <NotificationDatasourceActionsMenu
            {...actionsMenuCommonProps}
            dataSourceUuid={sourceUuid}
            trigger={
              <NgButton link className="notification-view-detailed-info-cell-link">
                {sourceName}
              </NgButton>
            }
            actions={[
              DataSourceActions.VIEW_EDIT,
              DataSourceActions.VIEW_DATASOURCES_LIST,
            ]}
          />
        </NotificationDetailInfoCell>
      )}
    </div>
  );
}

function NotificationView(props) {
  const {
    value,
    workspaceUuid,
    workspaceUserPermissions,
    dataSourceList,
    history,
    enableDetailInfoRow = false,
    onClose,
  } = props;
  const { loading, data } = value;
  if (loading || !data) {
    return null;
  }

  const { eventRecord, monitor, metric, integration } = data;
  const color = getColorFromEventLevel(eventRecord.level);
  const contentArray = [
    {
      title: `${eventRecord.category} alert details`,
      content: (
        <>
          {enableDetailInfoRow && (
            <NotificationDetailInfoRow
              workspaceUuid={workspaceUuid}
              workspaceUserPermissions={workspaceUserPermissions}
              eventRecord={eventRecord}
              dataSourceList={dataSourceList}
              history={history}
              metric={metric}
              monitor={monitor}
              integration={integration}
            />
          )}
          <div
            style={{ background: color, border: `1px solid ${color}` }}
            className="notification-view-message-section-container"
          >
            {eventRecord.message.message}
          </div>
        </>
      ),
    },
  ];

  const sql = eventRecord?.message?.sql;
  sql &&
    contentArray.push({
      title: "sql",
      content: (
        <div>
          <TextArea
            className="incident-sample-data-sql-query"
            style={{ minHeight: 150 }}
            value={sqlFormatter.format(sql, { language: "sql", indent: "\t" })}
            autoSize={false}
          />
        </div>
      ),
    });

  metric &&
    contentArray.push({
      title: "metric info",
      content: (
        <div className="notification-view-message-metric-container">
          <div className="notification-view-message-metric-name-container">
            {metric.metadata.name}
          </div>
          <MetricSummarySection
            title={""}
            metric={metric}
            workspaceUserPermissions={workspaceUserPermissions}
            history={history}
          />
        </div>
      ),
    });

  monitor &&
    contentArray.push({
      title: "Monitor info",
      content: (
        <div className="notification-view-message-metric-container">
          <div className="notification-view-message-metric-name-container">
            {monitor.metadata.name}
          </div>
          <MonitorSummarySection
            title={""}
            monitor={monitor}
            workspaceUserPermissions={workspaceUserPermissions}
            history={history}
          />
        </div>
      ),
    });

  integration &&
    contentArray.push({
      title: "Integration info",
      content: (
        <div className="notification-view-message-metric-container">
          <NgTableClickableText
            onClick={() => {
              const integrationListUrl = `${getURIInstance(URIPath.INTEGRATION_LIST, {
                workspaceUuid,
              })}`;
              history.push(integrationListUrl);
            }}
          >
            {integration.title}
          </NgTableClickableText>
        </div>
      ),
    });

  eventRecord?.sourceUuid &&
    workspaceUuid &&
    contentArray.push({
      title: "Datasource info",
      content: (
        <div className="notification-view-message-metric-container">
          <NotificationDatasourceActionsMenu
            history={history}
            workspaceUuid={workspaceUuid}
            workspaceUserPermissions={workspaceUserPermissions}
            dataSourceUuid={eventRecord.sourceUuid}
            trigger={
              <NgTableClickableText>{eventRecord.sourceName}</NgTableClickableText>
            }
            actions={[
              DataSourceActions.VIEW_EDIT,
              DataSourceActions.VIEW_DATASOURCES_LIST,
            ]}
          />
        </div>
      ),
    });

  return (
    <div className="notification-view-container">
      <div className="notification-view-header-container">
        <div className="notification-view-header-title-container">
          <DetailIcon width={24} height={24} />
          {`${capitalizeFirstLetter(eventRecord.level)} Details`}
        </div>
        <Button outline size="large" onClick={() => onClose()}>
          Close
        </Button>
      </div>
      <div className="notification-view-content-container">
        {contentArray.map(({ title, content }) => {
          return (
            <div className="notification-view-section-container" key={title}>
              <div className="notification-view-section-title-container">{title}</div>
              <div className="notification-view-section-content-container">
                {content}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withRouter(NotificationView);
