export const FontWeights = {
  REGULAR: "regular",
  MEDIUM: "medium",
  BOLD: "bold",
};

export const TypographyTypes = {
  CAPTION: "caption",
  BODY_SMALL: "body-small",
  BODY_MEDIUM: "body-medium",
  BODY_LARGE: "body-large",
  HEADLINE_SMALL: "headline-small",
  HEADLINE_MEDIUM: "headline-medium",
  HEADLINE_LARGE: "headline-large",
  TITLE_SMALL: "title-small",
  TITLE_MEDIUM: "title-medium",
  TITLE_LARGE: "title-large",
};

export const TypographyColors = {
  DEFAULT: "default",
  INFO1: "info1",
  INFO2: "info2",
  DISABLED: "placeholder-disabled",
  WHITE: "white",
  ERROR: "error",
  LINK: "link",
};
