import React from "react";
import PropTypes from "prop-types";
import Typography from "./Typography";
import { FontWeights, TypographyTypes } from "./enum";

const HeadlineLarge = ({ children, ...typographyProps }) => (
  <Typography
    elementType="h1"
    size={TypographyTypes.HEADLINE_LARGE}
    weight={FontWeights.BOLD}
    {...typographyProps}
  >
    {children}
  </Typography>
);

HeadlineLarge.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

export default HeadlineLarge;
