import { getStoreDispatcher, getStore } from "../app/store";
import { setNavigationState } from "../actions/navigation/navigation-action";
import { useEffect } from "react";
import history from "../app/history";

let currentCleanCallback = null;
let unblockHistory = null;

export const blockNavigation = (message, cleanCallback = null) => {
  const dispatch = getStoreDispatcher();
  dispatch(setNavigationState({ blocked: true, message }));
  if (!unblockHistory) {
    unblockHistory = history.block(() => canNavigate());
  }
  currentCleanCallback = cleanCallback;
};

export const unblockNavigation = () => {
  const dispatch = getStoreDispatcher();
  dispatch(setNavigationState({ blocked: false, message: null }));
  if (unblockHistory) {
    unblockHistory();
    unblockHistory = null;
  }
  currentCleanCallback = null;
};

export const canNavigate = (opts = {}) => {
  const navigationState = getStore().getState().navigationReducer.navigationState;
  if (!navigationState.blocked) {
    return true;
  }
  if (!opts.noConfirm && window.confirm(navigationState.message)) {
    currentCleanCallback?.();
    unblockNavigation();
    return true;
  }
  return false;
};

const useNavigationBlocker = (when, message, cleanCallback = null) => {
  useEffect(() => {
    if (when) {
      blockNavigation(message, cleanCallback);
    } else {
      unblockNavigation();
    }
  }, [when, message, cleanCallback]);
};

export default useNavigationBlocker;

/* This util is safe to use across application even outside Explorer and in Class Components  */
export const blockHistory = (history, msg, condition, cleanCB) => {
  const unblock = history.block(() => {
    const showConfirmDialog = typeof condition === "function" ? condition() : condition;
    if (showConfirmDialog) {
      const acceptChanged = window.confirm(msg);
      if (acceptChanged) {
        cleanCB?.();
        unblock();
      }
      return acceptChanged;
    }
    return true;
  });
  return unblock;
};
