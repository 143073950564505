import React from "react";
import HeadlineLarge from "../elements/Typography/HeadlineLarge";
import BodyMedium from "../elements/Typography/BodyMedium";
import { Link } from "react-router-dom";
export default function ErrorFallback() {
  return (
    <div className="error-boundary">
      <HeadlineLarge>Something went wrong.</HeadlineLarge>
      <BodyMedium>
        Click <Link to="/">here</Link> to return to homepage
      </BodyMedium>
    </div>
  );
}
