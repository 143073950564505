export function CompassIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path
        d="M7 1.8125C5.87512 1.8125 4.7755 2.14607 3.8402 2.77102C2.90489 3.39597 2.17591 4.28423 1.74544 5.32349C1.31496 6.36274 1.20233 7.50631 1.42179 8.60958C1.64124 9.71284 2.18292 10.7263 2.97833 11.5217C3.77374 12.3171 4.78716 12.8588 5.89043 13.0782C6.99369 13.2977 8.13726 13.185 9.17651 12.7546C10.2158 12.3241 11.104 11.5951 11.729 10.6598C12.3539 9.7245 12.6875 8.62488 12.6875 7.5C12.6859 5.99207 12.0862 4.54636 11.0199 3.48009C9.95365 2.41382 8.50793 1.81409 7 1.8125ZM7 12.3125C6.04818 12.3125 5.11773 12.0303 4.32632 11.5014C3.53491 10.9726 2.91808 10.221 2.55383 9.34166C2.18959 8.46229 2.09428 7.49466 2.27997 6.56113C2.46566 5.62759 2.92401 4.77009 3.59705 4.09705C4.27009 3.42401 5.1276 2.96566 6.06113 2.77997C6.99466 2.59428 7.9623 2.68958 8.84167 3.05383C9.72104 3.41808 10.4726 4.03491 11.0014 4.82632C11.5303 5.61773 11.8125 6.54818 11.8125 7.5C11.8111 8.77591 11.3036 9.99915 10.4014 10.9014C9.49915 11.8036 8.27591 12.3111 7 12.3125ZM9.42922 4.48344L5.92922 6.23344C5.8446 6.27594 5.77595 6.3446 5.73344 6.42922L3.98344 9.92922C3.95005 9.99594 3.93428 10.0701 3.93763 10.1446C3.94098 10.2192 3.96334 10.2916 4.00257 10.3551C4.04181 10.4185 4.09663 10.4709 4.16182 10.5072C4.227 10.5435 4.30039 10.5626 4.375 10.5625C4.44292 10.5624 4.50991 10.5467 4.57078 10.5166L8.07078 8.76656C8.1554 8.72406 8.22406 8.6554 8.26657 8.57078L10.0166 5.07078C10.0579 4.9886 10.0722 4.8955 10.0575 4.80471C10.0429 4.71391 10 4.63004 9.93499 4.56501C9.86996 4.49998 9.78609 4.4571 9.69529 4.44245C9.6045 4.42781 9.5114 4.44215 9.42922 4.48344ZM7.54688 8.04687L5.35336 9.14664L6.45313 6.95312L8.64883 5.85555L7.54688 8.04687Z"
        fill="#3F4050"
      />
    </svg>
  );
}
