import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { SideBarWidth, TakeoverWidth } from "../../utils/enums";

import "./index.scss";

function TakeoverViewContentWrap({ workspaceTakeoverElement, outsideClick }) {
  const location = useLocation();
  const [oldLocation] = useState(location);

  React.useEffect(() => {
    if (
      oldLocation.pathname !== location.pathname ||
      oldLocation.search !== location.search
    ) {
      outsideClick?.();
    }
  }, [location, oldLocation, outsideClick]);

  return workspaceTakeoverElement;
}

const getWidthStyle = (size, sideBarWidth) => {
  const stylesMap = {
    [TakeoverWidth.WIDE]: { width: `calc(70% - ${sideBarWidth}px * 0.7)` }, // 70% of available space
    [TakeoverWidth.NARROW]: { width: "400px" },
    [TakeoverWidth.NORMAL]: { width: "500px" },
    [TakeoverWidth.MEDIUM]: { width: "600px" },
    [TakeoverWidth.EXTENDED]: {
      width: "900px",
      maxWidth: `calc(100% - 100px - ${sideBarWidth}px)`, // available space less 100px
    },
  };
  const defaultStyle = { width: `calc(100% - ${sideBarWidth}px)` };

  // TakeoverWidth.FULLSCREEN uses the default style
  return stylesMap[size] ?? defaultStyle;
};

function TakeoverView(props) {
  const {
    workspaceTakeoverOpen,
    workspaceTakeoverKey,
    workspaceTakeoverElement,
    size,
    className = "",
    outsideClick = null,
    isSideBarExpanded,
  } = props;

  if (!workspaceTakeoverOpen) {
    return null;
  }

  const sideBarWidth = isSideBarExpanded
    ? SideBarWidth.MAX_WIDTH
    : SideBarWidth.MIN_WIDTH;

  const widthStyle = getWidthStyle(size, sideBarWidth);

  return (
    <>
      <div
        className="lightup-app-takeover-mask-container"
        onClick={() => outsideClick?.()}
      />
      <div
        className={`lightup-app-takeover-container ${className}`}
        style={widthStyle}
        key={workspaceTakeoverKey}
      >
        <TakeoverViewContentWrap
          workspaceTakeoverElement={workspaceTakeoverElement}
          outsideClick={outsideClick}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  workspaceTakeoverOpen: state.takeoverReducer.workspaceTakeoverOpen,
  workspaceTakeoverKey: state.takeoverReducer.workspaceTakeoverKey,
  workspaceTakeoverElement: state.takeoverReducer.workspaceTakeoverElement,
  size: state.takeoverReducer.size,
  outsideClick: state.takeoverReducer.outsideClick,
  className: state.takeoverReducer.className,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TakeoverView);
