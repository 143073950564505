import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import PrivateRoute from "../components/private-route/private-route";
import Disclaimer from "../views/disclaimer";
import LoginView from "../views/login";
import Forbidden from "../views/forbidden/forbidden";
import HeaderApp from "../views/header-app";
import PageNotFoundView from "../views/notfound/page-not-found-view";
import { SupportedFeature } from "../utils/enums";
import { isFeatureEnabled } from "../utils/general";
import { joinURIPaths, workspaceURIRelativePath, URIPath } from "../utils/uri-path";
import Expired from "../components/trial/expired";
import { toast as reactToast } from "react-toastify";

const legacyURIPathArray = Object.values(workspaceURIRelativePath);

const Routes = ({ userInfo, sidebarCollapsed }) => {
  const currentLocation = useLocation();

  useEffect(() => {
    const pathnameDismissExceptions = [
      "/editRule/",
      "/addDataSource",
      "/editDataSource/",
      "/settings/datasource",
    ];

    const queryParamsDismissExceptions = ["tabName=training"];

    const hasPathnameException = pathnameDismissExceptions.some((exception) => {
      return currentLocation.pathname.includes(exception);
    });

    const hasQueryParamsException = queryParamsDismissExceptions.some((exception) => {
      return currentLocation.search.includes(exception);
    });

    const shouldDismissToast = !hasQueryParamsException && !hasPathnameException;

    if (shouldDismissToast) {
      reactToast.dismiss();
    }
  }, [currentLocation.pathname, currentLocation.search]);

  const headerURIs = [
    URIPath.SETTINGS,
    URIPath.MONITOR_LIST,
    URIPath.INCIDENT_LIST,
    URIPath.ALERT_LIST,
    URIPath.ALERT_ITEM,
    URIPath.DASHBOARD,
    URIPath.EXPLORER,
    URIPath.ADD_DATA_SOURCE,
    URIPath.ADD_METRIC,
    URIPath.ADD_MONITOR,
    URIPath.INCIDENT,
    URIPath.EDIT_DATA_SOURCE,
    URIPath.EDIT_METRIC,
    URIPath.EDIT_MONITOR,
    URIPath.EDIT_WORKSPACE,
    URIPath.CREATE_WORKSPACE,
    URIPath.ADMIN,
    URIPath.NG_METRIC_LIST,
    URIPath.NG_DRAFT_LIST,
    URIPath.NG_MONITOR_LIST,
    URIPath.NG_INCIDENT_LIST,
    URIPath.TRIAL_EXPIRED,
    URIPath.NO_WORKSPACE,
  ];

  if (isFeatureEnabled(userInfo.waffle, SupportedFeature.DATAEXPORT_DASHBOARD)) {
    headerURIs.push(URIPath.DOWNLOADS);
  }

  return (
    <Switch>
      <Route path={URIPath.LOGIN} component={LoginView} />
      <PrivateRoute path={URIPath.DISCLAIMER} component={Disclaimer} />
      <PrivateRoute exact path={URIPath.FORBIDDEN} component={Forbidden} />
      <PrivateRoute path={URIPath.TRIAL_EXPIRED} component={Expired} />
      <Route exact path={URIPath.WS_ROOT}>
        <Redirect to={joinURIPaths(currentLocation.pathname, "/profiler")} />
      </Route>
      <PrivateRoute
        disableWorkspacePermissionCheck={true}
        path={headerURIs}
        component={HeaderApp}
        sidebarCollapsed={sidebarCollapsed}
      />
      <PrivateRoute
        path={[URIPath.ROOT, ...legacyURIPathArray]}
        disableWorkspacePermissionCheck={true}
        component={Redirect}
      />
      <Route path="*" component={PageNotFoundView} />
    </Switch>
  );
};

export default Routes;
