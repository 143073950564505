import { connect } from "react-redux";
import {
  getDraftList,
  deleteDraft,
  updateDraft,
} from "../../actions/draft/draft-action";
import {
  openWorkspaceTakeover,
  closeTakeover,
} from "../../actions/takeover/takeover-action";
import DraftList from "./draft-list";
import { getDataSourceList } from "../../actions/datasource/data-source-action";
import { getKpiList, updateKpiTags } from "../../actions/kpi/kpi-action";
import { getTagList } from "../../actions/tag/tag-action";

const mapStateToProps = (state) => ({
  draftList: state.draftReducer.draftList,
  dataSourceList: state.dataSourceReducer.dataSourceList.data,
  kpiList: state.kpiReducer.kpiList,
  tagList: state.tagReducer.tagList,
});

const mapDispatchToProps = (dispatch) => ({
  getDraftList: (workspaceUuid) => dispatch(getDraftList(workspaceUuid)),
  updateDraft: (workspaceUuid, newDraft) =>
    dispatch(updateDraft(workspaceUuid, newDraft)),
  deleteDraft: (workspaceUuid, draft) => dispatch(deleteDraft(workspaceUuid, draft)),
  openWorkspaceTakeover: (takeoverElement, fullScreen, outsideClick) =>
    dispatch(openWorkspaceTakeover(takeoverElement, fullScreen, outsideClick)),
  closeTakeover: () => dispatch(closeTakeover()),
  getDataSourceList: (workspaceUuid) => dispatch(getDataSourceList(workspaceUuid)),
  getKpiList: (workspaceUuid) => dispatch(getKpiList(workspaceUuid)),
  getTagList: (workspaceUuid) => dispatch(getTagList(workspaceUuid)),
  updateKpiTags: (workspaceUuid, metric, newTags) =>
    dispatch(updateKpiTags(workspaceUuid, metric, newTags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DraftList);
