import React, { useState } from "react";
import Tooltip from "../../../components/tooltip/ng-tooltip";
import { isFileSource as getIsFileSource } from "../../../utils/enums";
import { PAGE, getSchemaDetailProps } from "../../../utils/telemetry";
import { profilerConfigChangeEventTracking } from "../takeover/schema/schema-tracking";
import { connect } from "react-redux";
import { updateProfilerConfigTableList } from "../../../actions/profiler/profiler-action";
import { ManageTabHeader, ManageTabNgTable, tableNameColumn } from "../manage-tabs";
import { columnFn } from "../../../components/entity-list/columns";
import useBatchUpdate from "../use-batch-update";
import NgButton from "../../../components/button/ng-button";
import useNavigationBlocker from "../../../hooks/useNavigationBlocker";
import { NgTableTheme } from "../../../components/table/ng-table";
import { tableSupportsDataProfiling } from "../../../utils/table";
import "./profiler-schema-configure-data-profiles-tab.scss";
import Switch from "../../../atom/switch";
import "./profiler-manage-tables-tab.scss";

function ProfilerSchemaConfigureDataProfiles(props) {
  const {
    availableNodeKeys,
    config,
    enableEdit,
    loading,
    searchText,
    tableList,
    workspaceUuid,
    onCurrentSelectNodeChange,
    onOpenTableConfig,
    setSearchText,
    updateProfilerConfigTableList,
  } = props;

  const [currentSelectTableKeys, setCurrentSelectTableKeys] = useState([]);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const {
    currentEntityList: currentTableList,
    toggleAction,
    batchList,
    isDirty,
    reset,
  } = useBatchUpdate({
    entityList: tableList,
    getEntityKey: (table) => table.uuid,
    entityUpdate: (table, payload) => {
      const { enabled } = payload;
      const oldProfilerConfig = table.profilerConfig;
      const oldDataProfilerConfig = oldProfilerConfig.dataProfiler
        ? oldProfilerConfig.dataProfiler
        : {};
      return {
        ...table,
        profilerConfig: {
          ...oldProfilerConfig,
          dataProfiler: {
            ...oldDataProfilerConfig,
            enabled,
          },
        },
      };
    },
    ignoreKeys: [
      "firstSeenTs",
      "lastScannedTs",
      "lastSeenTs",
      "removedTs",
      "schemaUpdatedTs",
    ],
  });

  useNavigationBlocker(isDirty, "You have unsaved changes - discard?", reset);

  if (!config.dataSource) {
    console.log("No data source is provided");
    return null;
  }

  if (!config.schema) {
    console.log("No schema is provided");
    return null;
  }

  const { dataSource, schema } = config;

  const dataSourceType = dataSource.config.connection.type;
  const isFileSource = getIsFileSource(dataSourceType);

  function updateTables(workspaceUuid, dataSource, paramsList, isOptimisticUpdate) {
    setIsUpdateInProgress(true);
    updateProfilerConfigTableList(workspaceUuid, dataSource, paramsList, {
      isOptimisticUpdate,
      isRefreshTree: true,
    }).finally(() => {
      setIsUpdateInProgress(false);
    });
  }

  function onProfilerConfigChange(currentTable, actionType, enabled) {
    const currentBulkTableUuids =
      currentSelectTableKeys.length > 0 ? currentSelectTableKeys : [currentTable.uuid];

    profilerConfigChangeEventTracking(enabled, actionType, currentBulkTableUuids, {
      ...getSchemaDetailProps(schema, dataSource),
      page: PAGE.MANAGE_TABLES,
    });

    toggleAction(currentBulkTableUuids, { actionType, enabled });
  }

  function saveChanges() {
    if (batchList.length > 0) {
      updateTables(workspaceUuid, dataSource, batchList);
    }
  }

  function onDataProfilerChange(currentTable, newStatus) {
    onProfilerConfigChange(currentTable, "dataProfiler", newStatus);
  }

  function onSelectChange(newSelectedRowKeys) {
    setCurrentSelectTableKeys(newSelectedRowKeys);
  }

  function onConfigureClick(currentTable) {
    const currentBulkTableUuids = new Set(
      currentSelectTableKeys.length > 0 ? currentSelectTableKeys : [currentTable.uuid]
    );
    const targetTableList = tableList.filter(({ uuid }) =>
      currentBulkTableUuids.has(uuid)
    );
    onOpenTableConfig({
      dataSource,
      tableList: targetTableList,
    });
  }

  const rowSelection = {
    selectedRowKeys: currentSelectTableKeys,
    onChange: onSelectChange,
  };

  const columns = [
    columnFn({
      title: (
        <Tooltip title="Toggle on to enable data profile and make table active in Explorer tree">
          Enable Data Profile
        </Tooltip>
      ),
      key: "dataProfiler",
      width: 170,
      render: (dataProfiler, data, _index) => {
        const enabled = Boolean(dataProfiler?.enabled);
        return (
          <Switch
            size="small"
            checked={enabled}
            onChange={onDataProfilerChange.bind(null, data)}
            disabled={
              !enableEdit || isUpdateInProgress || !tableSupportsDataProfiling(data)
            }
          />
        );
      },
      getCompareVal: (_indexedValue, row) =>
        row.profilerConfig?.dataProfiler?.enabled ? 1 : 0,
    })({ dataIndex: ["profilerConfig", "dataProfiler"] }),
    tableNameColumn({
      dataIndex: "tableName",
      renderProps: {
        availableNodeKeys,
        canConfigure: enableEdit,
        dataSource,
        schema,
        onCurrentSelectNodeChange,
        onConfigureClick,
      },
    }),
  ];

  const filteredTableList =
    searchText.length > 0
      ? currentTableList.filter(
          ({ tableName }) =>
            tableName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
      : currentTableList;

  const isScanning = !schema.lastTablesScannedTs;

  const headerControls = (
    <>
      <NgButton
        outline
        testId="manage-table-data-profile-cancel-button"
        disabled={!isDirty || isUpdateInProgress}
        onClick={reset}
      >
        Cancel
      </NgButton>
      <NgButton
        testId="manage-table-data-profile-schema-save-button"
        disabled={!isDirty || isUpdateInProgress}
        onClick={saveChanges}
      >
        {isUpdateInProgress ? "Saving..." : "Save"}
      </NgButton>
    </>
  );

  // console.log({tableList, currentTableList, batchList})

  return (
    <div className="profiler-manage-tables-tab">
      <ManageTabHeader
        entityName="table"
        searchText={searchText}
        setSearchText={setSearchText}
        lastScannedTs={schema.lastScannedTs}
        rightControls={headerControls}
      />
      <div className="profiler-manage-tables-tab-table-container">
        {(!isFileSource || tableList.length !== 0) && (
          <ManageTabNgTable
            entityName="table"
            theme={NgTableTheme.DARK}
            loading={loading || isScanning}
            loadingTitle={isScanning ? "Scanning..." : undefined}
            rowSelection={rowSelection}
            dataSource={filteredTableList}
            columns={columns}
            rowKey={"uuid"}
            isFiltered={tableList.length !== filteredTableList.length}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.profilerReducer.profilerConfigDataSourceTableList.loading,
  tableList: state.profilerReducer.profilerConfigDataSourceTableList.data,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfilerConfigTableList: (workspaceUuid, dataSource, paramsList, opts) =>
    dispatch(
      updateProfilerConfigTableList(workspaceUuid, dataSource, paramsList, opts)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilerSchemaConfigureDataProfiles);
