import React, { useEffect, useState } from "react";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { LabeledSelect } from "../labeled-control/labeled-control";
import ButtonText from "../button/button-text";
import Divider from "../../atom/divider";
import "./index.scss";

function TimestampColumnSelect(props) {
  const {
    label = "",
    value,
    disabled,
    timestampColumnList,
    staticLabel,
    size = "middle",
    onChange,
    onAddVirtualTimestamp,
    onEditVirtualTimestamp,
    style = {},
    showSearch = true,
    enableAdd = true,
    enableClear = false,
    autoSelectFirstOption = false,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { timestampColumn, timestampColumnFunctions } = value;

  const onChangeTimestampColumn = (newTimestampColumn) => {
    newTimestampColumn = newTimestampColumn || null;
    if (newTimestampColumn !== timestampColumn) {
      onChange({
        timestampColumn: newTimestampColumn,
      });
    }
  };

  useEffect(() => {
    if (
      autoSelectFirstOption &&
      !timestampColumn &&
      !disabled &&
      timestampColumnList.length > 0
    ) {
      onChangeTimestampColumn(timestampColumnList[0].columnName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectFirstOption, timestampColumn, disabled, timestampColumnList]);

  return (
    <LabeledSelect
      allowClear={enableClear}
      staticLabel={staticLabel}
      showSearch={showSearch}
      filterOption={(input, option) => {
        return (option?.value || "").toLowerCase().includes(input.toLowerCase());
      }}
      label={label}
      disabled={disabled}
      dropdownClassName="ligthup-timestamp-column-select-dropdown-container"
      style={style}
      dropdownMatchSelectWidth={280}
      optionLabelProp="label"
      open={isMenuOpen}
      value={timestampColumn}
      onChange={(newTimestampColumn) => {
        setIsMenuOpen(false);
        onChangeTimestampColumn(newTimestampColumn);
      }}
      onDropdownVisibleChange={(open) => setIsMenuOpen(open)}
      size={size}
      dropdownRender={(menu) => {
        return (
          <>
            {(timestampColumnList.length > 0 || timestampColumnFunctions) && (
              <>
                {menu}
                {enableAdd && (
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                )}
              </>
            )}
            {enableAdd && (
              <ButtonText
                className="timestamp-column-select-create-virtual-timestamp-button"
                disabled={Boolean(timestampColumnFunctions)}
                onClick={() => {
                  onAddVirtualTimestamp();
                  setIsMenuOpen(false);
                }}
              >
                Create virtual timestamp
                <PlusOutlined />
              </ButtonText>
            )}
          </>
        );
      }}
    >
      {timestampColumnList.map((item, index) => (
        <Select.Option
          key={item.columnName}
          className="timestamp-column-select-option-container"
        >
          {item.columnName}
          {item.isIndexed && (
            <span style={{ marginLeft: "3px", color: "#BFBFBF" }}>
              ({item?.indexType || "index"})
            </span>
          )}
          {item.columnName === timestampColumn && timestampColumnFunctions && (
            <span
              className="timestamp-column-select-option-edit-action-container"
              style={{ marginLeft: "20px" }}
              onClick={(e) => {
                onEditVirtualTimestamp();
              }}
            >
              <EditOutlined />
            </span>
          )}
        </Select.Option>
      ))}
    </LabeledSelect>
  );
}

export default TimestampColumnSelect;
