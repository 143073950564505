import React from "react";
import Button from "../button/ng-button";
import { Dropdown, Menu } from "antd";
import { SettingOutlined } from "@ant-design/icons";

import "./app-setting-menu.scss";

const AppSettingMenuKey = Object.freeze({
  ADMIN: "admin",
  WORKSPACE_SETTING: "workspaceSetting",
});

function AppSettingMenu(props) {
  const { onGoToAdmin, onGoToWorkspaceSetting, canViewAdmin, canViewWorkspaceSetting } =
    props;
  function onAppSettingMenuClick(e) {
    const { key } = e;
    if (key === AppSettingMenuKey.ADMIN) {
      onGoToAdmin();
    } else if (key === AppSettingMenuKey.WORKSPACE_SETTING) {
      onGoToWorkspaceSetting();
    } else {
      console.log(`Unexpected key ${key}`);
    }
  }

  const settingMenu = (
    <Menu className="lightup-popup-menu" onClick={onAppSettingMenuClick}>
      <Menu.Item key={AppSettingMenuKey.ADMIN} disabled={!canViewAdmin}>
        Admin
      </Menu.Item>
      <Menu.Item
        key={AppSettingMenuKey.WORKSPACE_SETTING}
        disabled={!canViewWorkspaceSetting}
      >
        Workspace settings
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={settingMenu}
      trigger={["click"]}
      placement={"topLeft"}
      overlayClassName="app-setting-menu-container"
    >
      <Button outline style={{ width: 32 }}>
        <SettingOutlined />
      </Button>
    </Dropdown>
  );
}

export default AppSettingMenu;
