export function NotePencilIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path
        d="M12.5595 3.69045L10.8095 1.94045C10.7689 1.89977 10.7206 1.8675 10.6675 1.84548C10.6144 1.82347 10.5575 1.81213 10.5 1.81213C10.4425 1.81213 10.3856 1.82347 10.3325 1.84548C10.2794 1.8675 10.2311 1.89977 10.1905 1.94045L4.94047 7.19045C4.89985 7.23111 4.86765 7.27937 4.84569 7.33248C4.82373 7.38559 4.81245 7.44251 4.8125 7.49998V9.24998C4.8125 9.36601 4.85859 9.47729 4.94064 9.55934C5.02269 9.64138 5.13397 9.68748 5.25 9.68748H7C7.05747 9.68752 7.11439 9.67625 7.1675 9.65429C7.22061 9.63233 7.26887 9.60012 7.30953 9.55951L12.5595 4.30951C12.6002 4.26888 12.6325 4.22063 12.6545 4.16751C12.6765 4.1144 12.6878 4.05747 12.6878 3.99998C12.6878 3.94248 12.6765 3.88555 12.6545 3.83244C12.6325 3.77933 12.6002 3.73108 12.5595 3.69045ZM6.81898 8.81248H5.6875V7.68099L9.1875 4.18099L10.319 5.31248L6.81898 8.81248ZM10.9375 4.69396L9.80602 3.56248L10.5 2.86849L11.6315 3.99998L10.9375 4.69396ZM12.25 7.49998V11.875C12.25 12.107 12.1578 12.3296 11.9937 12.4937C11.8296 12.6578 11.6071 12.75 11.375 12.75H2.625C2.39294 12.75 2.17038 12.6578 2.00628 12.4937C1.84219 12.3296 1.75 12.107 1.75 11.875V3.12498C1.75 2.89291 1.84219 2.67035 2.00628 2.50626C2.17038 2.34217 2.39294 2.24998 2.625 2.24998H7C7.11603 2.24998 7.22731 2.29607 7.30936 2.37812C7.39141 2.46017 7.4375 2.57145 7.4375 2.68748C7.4375 2.80351 7.39141 2.91479 7.30936 2.99684C7.22731 3.07888 7.11603 3.12498 7 3.12498H2.625V11.875H11.375V7.49998C11.375 7.38395 11.4211 7.27267 11.5031 7.19062C11.5852 7.10857 11.6965 7.06248 11.8125 7.06248C11.9285 7.06248 12.0398 7.10857 12.1219 7.19062C12.2039 7.27267 12.25 7.38395 12.25 7.49998Z"
        fill="#3F4050"
      />
    </svg>
  );
}
