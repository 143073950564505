import React, { useState } from "react";
import { Resizable } from "react-resizable";

import "react-resizable/css/styles.css";
import "./with-resizable-header.scss";

const singleColumnStrategy = (columnWidths, index, size) => ({
  ...columnWidths,
  [index]: size.width,
});

const betweenColumnsStrategy = (columnWidths, index, size) => {
  const sharedWidth = columnWidths[index] + columnWidths[index + 1];
  return {
    ...columnWidths,
    [index]: size.width,
    [index + 1]: Math.max(sharedWidth - size.width, 10),
  };
};

export const ResizeStrategies = Object.freeze({
  SINGLE_COLUMN: "singleColumnStrategy",
  BETWEEN_COLUMNS: "betweenColumnsStrategy",
});

const ResizeStrategiesFn = Object.freeze({
  [ResizeStrategies.SINGLE_COLUMN]: singleColumnStrategy,
  [ResizeStrategies.BETWEEN_COLUMNS]: betweenColumnsStrategy,
});

const resizableHeaderDefaults = {
  enabled: true,
  ignoreLastColumn: false,
  resizeStrategy: ResizeStrategies.SINGLE_COLUMN,
  defaultWidth: 256,
  defaultMinWidth: 10,
  defaultMaxWidth: Infinity,
};

const ResizableHeader = (props) => {
  const { onResize, width, minWidth, maxWidth, ...restProps } = props;

  if (!width || !onResize) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span className="table-resizable-handle" onClick={(e) => e.stopPropagation()} />
      }
      className="table-resizable-header"
      onResize={onResize}
      minConstraints={[minWidth, 0]}
      maxConstraints={[maxWidth, 0]}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export const withResizableHeader = (WrappedTable) => {
  return ({ columns, resizableHeader, ...props }) => {
    const [columnWidths, setColumnWidths] = useState(() =>
      columns.reduce((acc, col, index) => {
        acc[index] = col.width;
        return acc;
      }, {})
    );

    const resizableHeaderConfig = {
      ...resizableHeaderDefaults,
      ...resizableHeader,
    };

    const handleColumnResize =
      (index) =>
      (_, { size }) => {
        setColumnWidths((prevColumnWidths) =>
          ResizeStrategiesFn[resizableHeaderConfig.resizeStrategy](
            prevColumnWidths,
            index,
            size
          )
        );
      };

    const processedColumns = columns.map((col, index) => {
      const width =
        columnWidths[index] ?? col.width ?? resizableHeaderConfig.defaultWidth;
      const minWidth = col.minWidth ?? resizableHeaderConfig.defaultMinWidth;
      const maxWidth = col.maxWidth ?? resizableHeaderConfig.defaultMaxWidth;

      if (
        (resizableHeaderConfig.ignoreLastColumn ||
          resizableHeaderConfig.resizeStrategy === ResizeStrategies.BETWEEN_COLUMNS) &&
        index === columns.length - 1
      ) {
        return col;
      }

      return {
        ...col,
        width,
        onHeaderCell: (column) => ({
          width: column.width,
          minWidth,
          maxWidth,
          onResize: handleColumnResize(index),
        }),
      };
    });

    const components = {
      header: {
        cell: ResizableHeader,
      },
    };

    return (
      <WrappedTable {...props} columns={processedColumns} components={components} />
    );
  };
};
