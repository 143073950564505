import React from "react";
import { classesName } from "../../utils/css";
import { Radio as AntRadio } from "antd";
import "./radio.scss";

export default function Radio(props) {
  const { className = "", label, testId, children, ...restProps } = props;
  return (
    <div
      className={classesName("lightup-radio", !label && "nolabel", className)}
      data-testid={testId}
    >
      <AntRadio {...restProps}>{children}</AntRadio>
    </div>
  );
}

export function RadioGroup(props) {
  const { className = "", testId, ...restProps } = props;
  return (
    <AntRadio.Group
      data-testid={testId}
      className={classesName("lightup-radio", className)}
      {...restProps}
    />
  );
}
