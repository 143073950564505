export function SquaresFourIcon(props) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path
        d="M5.6875 2.6875H3.0625C2.83044 2.6875 2.60788 2.77969 2.44378 2.94378C2.27969 3.10788 2.1875 3.33044 2.1875 3.5625V6.1875C2.1875 6.41956 2.27969 6.64212 2.44378 6.80622C2.60788 6.97031 2.83044 7.0625 3.0625 7.0625H5.6875C5.91956 7.0625 6.14212 6.97031 6.30622 6.80622C6.47031 6.64212 6.5625 6.41956 6.5625 6.1875V3.5625C6.5625 3.33044 6.47031 3.10788 6.30622 2.94378C6.14212 2.77969 5.91956 2.6875 5.6875 2.6875ZM5.6875 6.1875H3.0625V3.5625H5.6875V6.1875ZM10.9375 2.6875H8.3125C8.08044 2.6875 7.85788 2.77969 7.69378 2.94378C7.52969 3.10788 7.4375 3.33044 7.4375 3.5625V6.1875C7.4375 6.41956 7.52969 6.64212 7.69378 6.80622C7.85788 6.97031 8.08044 7.0625 8.3125 7.0625H10.9375C11.1696 7.0625 11.3921 6.97031 11.5562 6.80622C11.7203 6.64212 11.8125 6.41956 11.8125 6.1875V3.5625C11.8125 3.33044 11.7203 3.10788 11.5562 2.94378C11.3921 2.77969 11.1696 2.6875 10.9375 2.6875ZM10.9375 6.1875H8.3125V3.5625H10.9375V6.1875ZM5.6875 7.9375H3.0625C2.83044 7.9375 2.60788 8.02969 2.44378 8.19378C2.27969 8.35788 2.1875 8.58044 2.1875 8.8125V11.4375C2.1875 11.6696 2.27969 11.8921 2.44378 12.0562C2.60788 12.2203 2.83044 12.3125 3.0625 12.3125H5.6875C5.91956 12.3125 6.14212 12.2203 6.30622 12.0562C6.47031 11.8921 6.5625 11.6696 6.5625 11.4375V8.8125C6.5625 8.58044 6.47031 8.35788 6.30622 8.19378C6.14212 8.02969 5.91956 7.9375 5.6875 7.9375ZM5.6875 11.4375H3.0625V8.8125H5.6875V11.4375ZM10.9375 7.9375H8.3125C8.08044 7.9375 7.85788 8.02969 7.69378 8.19378C7.52969 8.35788 7.4375 8.58044 7.4375 8.8125V11.4375C7.4375 11.6696 7.52969 11.8921 7.69378 12.0562C7.85788 12.2203 8.08044 12.3125 8.3125 12.3125H10.9375C11.1696 12.3125 11.3921 12.2203 11.5562 12.0562C11.7203 11.8921 11.8125 11.6696 11.8125 11.4375V8.8125C11.8125 8.58044 11.7203 8.35788 11.5562 8.19378C11.3921 8.02969 11.1696 7.9375 10.9375 7.9375ZM10.9375 11.4375H8.3125V8.8125H10.9375V11.4375Z"
        fill="#3F4050"
      />
    </svg>
  );
}
