import React from "react";
import { Select } from "antd";
import {
  DashboardFilterType,
  DashboardFilterValue,
  TimeDurationUnit,
} from "../../utils/enums";
import TextInput from "../../atom/TextInput";

function TimeDuration(props) {
  const { value = { value: "", unit: "" }, onChange } = props;

  const { value: numberValue, unit } = value;

  const onNumberChange = (e) => {
    const newNumber = parseInt(e.target.value || "0", 10);
    if (Number.isNaN(newNumber)) {
      return;
    }

    onChange && onChange({ value: newNumber, unit });
  };

  const onUnitChange = (newUnitValue) => {
    if (newUnitValue === unit) {
      return;
    }

    onChange && onChange({ value: numberValue, unit: newUnitValue });
  };

  return (
    <span>
      <TextInput
        type="text"
        value={numberValue}
        onChange={onNumberChange}
        wrapperStyle={{
          display: "inline-flex",
        }}
        style={{
          width: 58,
        }}
      />
      <Select
        value={unit}
        onChange={onUnitChange}
        style={{
          marginLeft: 10,
          width: 120,
        }}
      >
        <Select.Option value={TimeDurationUnit.MINUTES}>Minutes</Select.Option>
        <Select.Option value={TimeDurationUnit.HOURS}>Hours</Select.Option>
        <Select.Option value={TimeDurationUnit.DAYS}>Days</Select.Option>
        <Select.Option value={TimeDurationUnit.WEEKS}>Weeks</Select.Option>
        <Select.Option value={TimeDurationUnit.MONTHS}>Months</Select.Option>
        <Select.Option value={TimeDurationUnit.YEARS}>Years</Select.Option>
      </Select>
    </span>
  );
}

function DashboardChartTimeRange(props) {
  const {
    value = {
      type: DashboardFilterType.TIME_DURATION,
      value: {
        type: DashboardFilterValue.TIME_DURATION,
        duration: {
          value: "",
          unit: "",
        },
      },
    },
    onChange,
  } = props;

  function onTimeTypeChange(newType) {
    if (newType === value.type) {
      return;
    }
    // Implement this when supporting date range.
  }

  function onDurationChange(newDuration) {
    onChange &&
      onChange({
        type: DashboardFilterType.TIME_DURATION,
        value: {
          type: DashboardFilterValue.TIME_DURATION,
          duration: newDuration,
        },
      });
  }

  return (
    <div>
      <Select
        value={value.type}
        onChange={onTimeTypeChange}
        style={{
          marginBottom: 8,
        }}
      >
        <Select.Option value={DashboardFilterType.TIME_DURATION}>
          Within the last
        </Select.Option>
        <Select.Option value={DashboardFilterType.TIME_RANGE} disabled>
          Between
        </Select.Option>
      </Select>
      <TimeDuration value={value.value.duration} onChange={onDurationChange} />
    </div>
  );
}

export default DashboardChartTimeRange;
