import React, { Component } from "react";
import { withRouter, matchPath } from "react-router";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Divider from "../../atom/divider";
import { CaretDownIcon } from "../../components/icons/caret-down-icon/";
import { CaretRightIcon } from "../../components/icons/caret-right-icon/";
import SidebarMenuItemNode from "./sidebar-menu-item-node";
import {
  URIPath,
  URIPathPermissions,
  hasPermission,
  getURIInstance,
} from "../../utils/uri-path";
import {
  WorkspaceItemKeyToUriMapper,
  WorkspaceItemKeyToLabelMapper,
  SideBarNavigationAction,
} from "./util";
import { SideBarWidth } from "../../utils/enums";

import "./sidebar.scss";

const UriToWorkspaceItemKeyMapper = {};
for (const [key, value] of Object.entries(WorkspaceItemKeyToUriMapper)) {
  UriToWorkspaceItemKeyMapper[value] = key;
}

UriToWorkspaceItemKeyMapper[URIPath.ADD_DATA_SOURCE] =
  SideBarNavigationAction.DATA_SOURCE;
UriToWorkspaceItemKeyMapper[URIPath.EDIT_DATA_SOURCE] =
  SideBarNavigationAction.DATA_SOURCE;

UriToWorkspaceItemKeyMapper[URIPath.ADD_METRIC] = SideBarNavigationAction.METRICS;
UriToWorkspaceItemKeyMapper[URIPath.EDIT_METRIC] = SideBarNavigationAction.METRICS;

UriToWorkspaceItemKeyMapper[URIPath.ADD_MONITOR] = SideBarNavigationAction.MONITORS;
UriToWorkspaceItemKeyMapper[URIPath.EDIT_MONITOR] = SideBarNavigationAction.MONITORS;

UriToWorkspaceItemKeyMapper[URIPath.INCIDENT] = SideBarNavigationAction.INCIDENTS;

const mainMenuList = [
  SideBarNavigationAction.EXPLORER,
  SideBarNavigationAction.METRICS,
  SideBarNavigationAction.MONITORS,
  SideBarNavigationAction.INCIDENTS,
  SideBarNavigationAction.DASHBOARD,
  SideBarNavigationAction.DATA_SOURCE,
].map((type) => ({ type, label: WorkspaceItemKeyToLabelMapper[type] }));

const moreNavigationActionList = [
  SideBarNavigationAction.ALERT,
  SideBarNavigationAction.INTEGRATION,
  SideBarNavigationAction.SCHEDULE,
];

const moreMenuList = moreNavigationActionList.map((type) => ({
  type,
  label: WorkspaceItemKeyToLabelMapper[type],
}));

const ToggleIconWidth = 26;

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: "",
      isMoreExpanded: false,
      currentSelectedKey: "",
      workspaceList: [],
    };

    this.onSideBarMenuItemClick = this.onSideBarMenuItemClick.bind(this);
    this.onSideBarExpandClick = this.onSideBarExpandClick.bind(this);
  }

  componentDidMount() {
    console.log("SideBar is initialized");
    // @Todo (Sergey, Ming): Move to app level permission check
    this.props.getUserAPIKeyList();
    this.props.getWorkspaceList();
    // Open more items by default when default URI belongs to any more items.
    if (moreNavigationActionList.includes(this.state.currentSelectedKey)) {
      this.setState({ isMoreExpanded: true });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      location: { pathname },
      workspaceList,
    } = props;
    let isChanged = false;
    if (workspaceList !== state.workspaceList) {
      state = { ...state, workspaceList };
      props.getUserPermissionForAllWorkspace(workspaceList);
      isChanged = true;
    }

    if (pathname !== state.pathname) {
      state = { ...state, pathname };
      isChanged = true;
    }

    if (isChanged) {
      let currentSelectedKey = "";
      for (let currentUri in UriToWorkspaceItemKeyMapper) {
        if (
          UriToWorkspaceItemKeyMapper.hasOwnProperty(currentUri) &&
          matchPath(pathname, { path: currentUri, exact: false, strict: false })
        ) {
          currentSelectedKey = UriToWorkspaceItemKeyMapper[currentUri];
          break;
        }
      }

      return {
        ...state,
        currentSelectedKey,
      };
    }

    return null;
  }

  onSideBarMenuItemClick(key) {
    this.props.closeTakeover();
    const { currentSelectedKey } = this.state;
    if (currentSelectedKey === key) {
      return;
    }

    this.setState({ currentSelectedKey: key });
    const nextUrlTemplate = WorkspaceItemKeyToUriMapper[key];
    const { currentWorkspaceUuid } = this.props.userInfo;
    const nextUrl = getURIInstance(nextUrlTemplate, {
      workspaceUuid: currentWorkspaceUuid,
    });
    this.props.history.push(nextUrl);
  }

  onSideBarExpandClick() {
    const { isSideBarExpanded, setIsSideBarExpanded } = this.props;
    setIsSideBarExpanded(!isSideBarExpanded);
  }

  render() {
    const { currentSelectedKey, isMoreExpanded } = this.state;
    const {
      userInfo,
      workspaceUserPermissionMap,
      workspaceList,
      isSideBarExpanded = true,
    } = this.props;

    const { currentWorkspaceUuid } = userInfo;
    const workspaceUserPermissions = workspaceUserPermissionMap[currentWorkspaceUuid]
      ?.data
      ? workspaceUserPermissionMap[currentWorkspaceUuid].data
      : { isSuperuser: false, permissions: [] };
    const currentWorkspace = workspaceList.find(
      ({ uuid }) => currentWorkspaceUuid === uuid
    );
    const normalizedMainMenuList = mainMenuList.filter(({ type }) =>
      hasPermission(
        workspaceUserPermissions,
        URIPathPermissions[WorkspaceItemKeyToUriMapper[type]]
      )
    );
    const approveEnabled = currentWorkspace?.config?.enableApprovals?.metricApproval;
    let normalizedMoreItemList = [...moreMenuList];
    if (approveEnabled) {
      normalizedMoreItemList.push({
        type: SideBarNavigationAction.DRAFTS,
        label: "Drafts",
      });
    }

    normalizedMoreItemList = normalizedMoreItemList.filter(({ type }) =>
      hasPermission(
        workspaceUserPermissions,
        URIPathPermissions[WorkspaceItemKeyToUriMapper[type]]
      )
    );

    const sideBarWidth = isSideBarExpanded
      ? SideBarWidth.MAX_WIDTH
      : SideBarWidth.MIN_WIDTH;

    return (
      <div className={"sidebar-container"} style={{ width: sideBarWidth }}>
        {normalizedMainMenuList.map(({ label, type }) => (
          <SidebarMenuItemNode
            label={label}
            key={type}
            type={type}
            currentSelectedKey={currentSelectedKey}
            onClick={this.onSideBarMenuItemClick}
            isExpanded={isSideBarExpanded}
          />
        ))}
        <Divider style={{ margin: 0 }} />
        <SidebarMenuItemNode
          label="More"
          type="More"
          key="More"
          currentSelectedKey={currentSelectedKey}
          onClick={() => this.setState({ isMoreExpanded: !isMoreExpanded })}
          isExpanded={isSideBarExpanded}
          iconComponent={isMoreExpanded ? CaretDownIcon : CaretRightIcon}
        />
        {isMoreExpanded &&
          normalizedMoreItemList.map(({ label, type }) => (
            <SidebarMenuItemNode
              label={label}
              type={type}
              key={type}
              currentSelectedKey={currentSelectedKey}
              onClick={this.onSideBarMenuItemClick}
              isExpanded={isSideBarExpanded}
            />
          ))}
        <div
          onClick={this.onSideBarExpandClick}
          className="side-expand-container"
          style={{ left: sideBarWidth - ToggleIconWidth }}
        >
          {isSideBarExpanded ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </div>
      </div>
    );
  }
}

export default withRouter(SideBar);
