import React, { useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { IncidentValidationStatus, NotificationTypeConst } from "../../utils/enums";
import { getStringFromTimeStamp } from "../../utils/time";
import Button from "../../components/button/ng-button";
import { IncidentValidationStatusIcon } from "../../components/icons/summary/incident";
import { ViewIcon } from "../profiler/icons";
import Popover from "../../components/popover";
import ButtonText from "../../components/button/button-text";
import { ErrorIcon } from "../../components/icons/status";
import { toast } from "../../components/toast/toast";

import "./incident-validation-status-view.scss";

function ValidationResolvedIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.81488 2.81487C4.14123 1.48854 5.97548 0.666992 8.00033 0.666992C10.0252 0.666992 11.8594 1.48854 13.1858 2.81487C14.5121 4.14123 15.3337 5.97548 15.3337 8.00033C15.3337 10.0252 14.5121 11.8594 13.1858 13.1858C11.8594 14.5121 10.0252 15.3337 8.00033 15.3337C5.97548 15.3337 4.14123 14.5121 2.81488 13.1858C1.48854 11.8594 0.666992 10.0252 0.666992 8.00033C0.666992 5.97548 1.48854 4.14123 2.81488 2.81487ZM11.8051 6.47173C12.0654 6.21138 12.0654 5.78927 11.8051 5.52892C11.5447 5.26857 11.1226 5.26857 10.8623 5.52892L7.33366 9.05752L5.80506 7.52892C5.54471 7.26857 5.1226 7.26857 4.86225 7.52892C4.6019 7.78927 4.6019 8.21138 4.86225 8.47173L6.86225 10.4717C7.1226 10.7321 7.54471 10.7321 7.80506 10.4717L11.8051 6.47173Z"
        fill="#5CCAB4"
      />
    </svg>
  );
}

function ValidationUnresolvedIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M10.3333 0.666992C10.528 0.666992 10.7128 0.752034 10.8395 0.899798L13.8395 4.3998C13.9431 4.52063 14 4.67452 14 4.83366V14.667C14 15.0352 13.7015 15.3337 13.3333 15.3337H2.66667C2.29848 15.3337 2 15.0352 2 14.667V1.33366C2 0.965469 2.29848 0.666992 2.66667 0.666992H10.3333ZM10.0267 2.00033H3.33333V14.0003H12.6667V5.08033L10.0267 2.00033ZM8 4.33366C9.03467 4.33366 10.009 4.72904 10.7462 5.42533C11.5407 6.17575 12 7.21881 12 8.33366C12 10.5428 10.2091 12.3337 8 12.3337C5.79088 12.3337 4 10.5428 4 8.33366C4 6.12454 5.79088 4.33366 8 4.33366ZM6.64529 6.03625C6.30558 6.237 6.01427 6.51095 5.79305 6.83639L5.70259 6.97895L9.35465 10.6311C9.6944 10.4303 9.98575 10.1564 10.207 9.83089L10.2974 9.68831L6.64529 6.03625Z"
        fill="#B80739"
      />
    </svg>
  );
}

function IncidentValidationStatusView(props) {
  const { value, onValidation, onCancelValidation } = props;

  let { disabled = false, reason = "" } = props;

  const [popoverVisible, setPopoverVisible] = useState(false);

  let buttonText;
  let buttonIcon;
  let popover = null;
  let onButtonClick;

  if (!value) {
    buttonText = "Run Validation";
    buttonIcon = <IncidentValidationStatusIcon />;
    onButtonClick = onValidation;
  } else {
    const { status, lastRunTs = 0, runStatusMessage = "" } = value;

    const lastRunDisplayTimeStr =
      typeof lastRunTs === "number" ? getStringFromTimeStamp(lastRunTs) : "";
    if (
      status === IncidentValidationStatus.RUNNING ||
      status === IncidentValidationStatus.CANCELING
    ) {
      let buttonStatusText;
      let displayStatusText;
      let isDisabled;
      buttonIcon = <IncidentValidationStatusIcon />;
      if (status === IncidentValidationStatus.RUNNING) {
        buttonStatusText = "Running";
        displayStatusText = "Validation";
        isDisabled = false;
      } else {
        buttonStatusText = "Canceling";
        displayStatusText = "Cancel validation";
        isDisabled = true;
      }

      buttonText = `${buttonStatusText} Validation...`;
      popover = (
        <div className="validation-status-button-popover">
          <ButtonText
            disabled={isDisabled}
            onClick={() => {
              onCancelValidation && onCancelValidation();
              setPopoverVisible(false);
            }}
            className="validation-status-button-popover-action-button"
          >
            <CloseCircleOutlined />
            Cancel Validation
          </ButtonText>
          {status === IncidentValidationStatus.RUNNING && (
            <div className="validation-status-button-popover-timing">
              {displayStatusText} started on {lastRunDisplayTimeStr}
            </div>
          )}
        </div>
      );
    } else if (
      status === IncidentValidationStatus.RESOLVED ||
      status === IncidentValidationStatus.UNRESOLVED ||
      status === IncidentValidationStatus.ERROR
    ) {
      let statusMsg;
      if (status === IncidentValidationStatus.RESOLVED) {
        statusMsg = "resolved";
        buttonIcon = <ValidationResolvedIcon />;
      } else if (status === IncidentValidationStatus.UNRESOLVED) {
        statusMsg = "unresolved";
        buttonIcon = <ValidationUnresolvedIcon />;
      } else if (status === IncidentValidationStatus.ERROR) {
        statusMsg = "error";
        buttonIcon = <ErrorIcon />;
      }

      buttonText = `Validation ${statusMsg}`;

      popover = (
        <div className="validation-status-button-popover">
          <ButtonText
            onClick={() => {
              onValidation && onValidation();
              setPopoverVisible(false);
            }}
            className="validation-status-button-popover-action-button"
          >
            <IncidentValidationStatusIcon />
            Retry Validation
          </ButtonText>
          {status === IncidentValidationStatus.ERROR && (
            <ButtonText
              onClick={() => {
                toast(runStatusMessage, NotificationTypeConst.FAILURE);
              }}
              className="validation-status-button-popover-action-button"
            >
              <ViewIcon />
              View Error
            </ButtonText>
          )}
          <div className="validation-status-button-popover-timing">
            Validation {statusMsg} on {lastRunDisplayTimeStr}
          </div>
        </div>
      );
    }
  }

  const button = (
    <Button
      outline
      onClick={onButtonClick}
      disabled={disabled}
      title={reason}
      testId="incident-validation-button"
      className="validation-status-button"
    >
      <div className="validation-status-button-content">
        {buttonText}
        {buttonIcon}
      </div>
    </Button>
  );

  if (popover) {
    return (
      <Popover
        trigger="click"
        content={popover}
        placement="bottomLeft"
        visible={popoverVisible}
        onVisibleChange={setPopoverVisible}
      >
        {button}
      </Popover>
    );
  } else {
    return button;
  }
}

export default IncidentValidationStatusView;
