import React, { Component } from "react";
import { DeleteOutlined, HistoryOutlined, PlusOutlined } from "@ant-design/icons";
import Button from "../../../components/button/ng-button";
import TimeOption from "../../../components/time-option/time-option";
import { DataSourceType, QueryGovernanceSchedulingMode } from "../../../utils/enums";
import { getSettingFromParams, hasPermission } from "../../../utils/uri-path";
import {
  getDisplayTimeFromSecond,
  getSecondFromDisplayTime,
  MINUTE_IN_SECONDS,
  WEEK_IN_SECONDS,
} from "../../../utils/time";
import { isEqual } from "lodash";
import { AppPermissions } from "../../../utils/permissions";
import NgStepContainer from "../../../components/step/ng-step";
import {
  LabeledSelect,
  LabeledInputNumber,
} from "../../../components/labeled-control/labeled-control";
import NgToggleCheckbox from "../../../components/toggle-checkbox/ng-toggle-checkbox";
import ButtonIcon from "../../../components/button/button-icon";
import DurationInput from "../../../components/duration-input/duration-input";
import { getUnixTime, isAfter, isBefore, startOfDay, subDays } from "date-fns";
import moment from "moment";

import {
  EVENT,
  PAGE,
  getDatasourceDetailProps,
  trackEvent,
} from "../../../utils/telemetry";
import { deepcopy } from "../../../utils/objects";

import QueryGovernanceDataSource from "./query-governance-data-source";
import { Tabs } from "antd";
import SettingConfigIcon from "../../../components/icons/setting-config";
import { Spinner } from "../../../atom/spinner";
import { classesName } from "../../../utils/css";
import { blockHistory } from "../../../hooks/useNavigationBlocker";
import QueryHistory from "./query-table";
import "./query-governance.scss";

const timeSettingOptions = [
  { value: "s", label: "second" },
  { value: "m", label: "minute" },
  { value: "h", label: "hour" },
  { value: "d", label: "day" },
  { value: "w", label: "week" },
  { value: "mo", label: "month" },
];

// Datasource types for which query governance is _fully_ supported. Query scheduling is supported for all types.
const fullySupportedDataSourceTypeList = [
  DataSourceType.REDSHIFT,
  DataSourceType.SNOWFLAKE,
  DataSourceType.DATABRICKS,
  DataSourceType.POSTGRES,
  DataSourceType.MYSQL,
];

const enforcedTimestampSourceTypeToStringMapper = {
  [DataSourceType.REDSHIFT]: "sort-keyed",
  [DataSourceType.MICROSOFTSQL]: "indexed",
  [DataSourceType.ORACLE]: "indexed",
  [DataSourceType.POSTGRES]: "indexed",
  [DataSourceType.GREENPLUM]: "indexed",
  [DataSourceType.TERADATA]: "indexed",
  [DataSourceType.MYSQL]: "indexed",
};

const defaultQueryTimeoutLimit = 15 * MINUTE_IN_SECONDS;
const defaultQueryDataRangeLimit = 2 * WEEK_IN_SECONDS;

const schedulingModeOptions = [
  {
    label: "Allow",
    value: QueryGovernanceSchedulingMode.ALLOW,
  },
  {
    label: "Deny",
    value: QueryGovernanceSchedulingMode.DENY,
  },
];

function scheduleSlotsFromUuids(scheduleUuids) {
  return scheduleUuids.map((scheduleUuid, index) => ({
    scheduleUuid,
    id: index,
  }));
}

function schedulingFromDatasource(dataSourceObject) {
  let schedulingMode = QueryGovernanceSchedulingMode.ALLOW;
  let scheduleSlots = [];
  if (dataSourceObject.config.governance.allowScheduleUuids.length > 0) {
    schedulingMode = QueryGovernanceSchedulingMode.ALLOW;
    scheduleSlots = scheduleSlotsFromUuids(
      dataSourceObject.config.governance.allowScheduleUuids
    );
  } else if (dataSourceObject.config.governance.denyScheduleUuids.length > 0) {
    schedulingMode = QueryGovernanceSchedulingMode.DENY;
    scheduleSlots = scheduleSlotsFromUuids(
      dataSourceObject.config.governance.denyScheduleUuids
    );
  }
  return {
    schedulingMode,
    scheduleSlots,
    schedulingEnabled: scheduleSlots.length > 0,
  };
}

export const MAX_LOOKBACK_DAYS = 7;

export function timeRangeDefaultRange(now = new Date()) {
  return {
    startTime: subDays(now, 1),
    endTime: now,
  };
}

export function timeRangeDisabledDate(lookbackDays, now = new Date()) {
  const earliestSelectableDate = startOfDay(subDays(now, lookbackDays));
  return (momentDate) =>
    isBefore(momentDate.toDate(), earliestSelectableDate) ||
    isAfter(momentDate.toDate(), now);
}

export function timeRangeSelectorPresets(now = new Date()) {
  const nowMoment = moment(now);
  return {
    "1d": [moment(subDays(now, 1)), nowMoment.clone()],
    "1w": [moment(subDays(now, 7)), nowMoment.clone()],
  };
}

function ScheduleSlot(props) {
  const {
    slotId,
    selectedUuid,
    scheduleOptions = [],
    onDeleteClick = (_slotId) => {},
    onSelectedScheduleChange = (_slotId, _newUuid) => {},
  } = props;

  const selectedOption = scheduleOptions.find((opt) => opt.value === selectedUuid);

  return (
    <div className="query-governance-scheduling-slot">
      <div className="query-governance-scheduling-schedule-select">
        <LabeledSelect
          label="Schedule"
          staticLabel
          options={scheduleOptions}
          value={selectedOption}
          placeholder="Select a schedule"
          onChange={(scheduleUuid) => onSelectedScheduleChange(slotId, scheduleUuid)}
        />
      </div>
      <ButtonIcon icon={<DeleteOutlined />} onClick={() => onDeleteClick(slotId)} />
    </div>
  );
}

function SchedulingStep(props) {
  const {
    schedulingEnabled = false,
    allowPreviewsWhenPaused = false,
    scheduleList = [],
    schedulingMode = QueryGovernanceSchedulingMode.ALLOW,
    scheduleSlots = [],
    onSchedulingModeChange = (_modeOpt) => {},
    onScheduleSlotAdd = () => {},
    onScheduleSlotDelete = (_slotId) => {},
    onScheduleSlotChange = (_slotId, _scheduleUuid) => {},
    onAllowPreviewsWhenPaused = (_enabled) => {},
  } = props;

  const selectedSchedules = new Set(scheduleSlots.map((slot) => slot.uuid));

  const scheduleOptions = scheduleList
    .filter((schedule) => !selectedSchedules.has(schedule.id))
    .map((schedule) => ({
      label: schedule.title,
      value: schedule.id,
    }));

  const schedulingModeValue = schedulingModeOptions.find(
    (opt) => opt.value === schedulingMode
  );
  if (!schedulingEnabled) {
    return null;
  }
  return (
    <div>
      <div className="query-governance-scheduling">
        <NgToggleCheckbox
          labelPosition="left"
          label="Always allow interactive queries"
          value={allowPreviewsWhenPaused}
          onChange={onAllowPreviewsWhenPaused}
        />
        <div className="query-governance-scheduling-controls">
          <LabeledSelect
            style={{ width: 180 }}
            label="Schedule Mode"
            options={schedulingModeOptions}
            value={schedulingModeValue}
            onChange={onSchedulingModeChange}
          />
          <Button primary size="large" onClick={onScheduleSlotAdd}>
            Add
            <PlusOutlined />
          </Button>
        </div>
        {scheduleSlots.map((slot) => (
          <ScheduleSlot
            key={slot.id}
            slotId={slot.id}
            selectedUuid={slot.scheduleUuid}
            scheduleOptions={scheduleOptions}
            onSelectedScheduleChange={onScheduleSlotChange}
            onDeleteClick={onScheduleSlotDelete}
          />
        ))}
      </div>
    </div>
  );
}

class QueryGovernance extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      workspaceUuid: "",
      currentDataSourceObject: null,
      dataSourceList: {
        loading: false,
        data: [],
      },
      queryTimeRange: timeRangeDefaultRange(now),
      schedulingEnabled: false,
      schedulingMode: QueryGovernanceSchedulingMode.ALLOW,
      scheduleSlots: [],
    };

    this.getQueryList = this.getQueryList.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onDataSourceChange = this.onDataSourceChange.bind(this);
    this.onMaxQueryDurationInSecondsChange =
      this.onMaxQueryDurationInSecondsChange.bind(this);
    this.onMaxQueryPeriodInSecondsChange =
      this.onMaxQueryPeriodInSecondsChange.bind(this);
    this.onIndexedTimestampColumnsChange =
      this.onIndexedTimestampColumnsChange.bind(this);
    this.onAllowPreviewsWhenPausedChange =
      this.onAllowPreviewsWhenPausedChange.bind(this);
    this.onAllowRecordStorageChange = this.onAllowRecordStorageChange.bind(this);
    this.onAllowRawDataSamplesChange = this.onAllowRawDataSamplesChange.bind(this);
    this.onFailingRecordsCountCacheChange =
      this.onFailingRecordsCountCacheChange.bind(this);
    this.onSaveCompleteFullCompareDiffSamplesChange =
      this.onSaveCompleteFullCompareDiffSamplesChange.bind(this);
    this.getAllowDenySchedulesConfig = this.getAllowDenySchedulesConfig.bind(this);
    this.onMaxBackfillDurationChange = this.onMaxBackfillDurationChange.bind(this);
    this.onMaxCategoricalDistinctCountChange =
      this.onMaxCategoricalDistinctCountChange.bind(this);

    this.onSave = this.onSave.bind(this);
    this.unblockNav = null;
  }

  getQueryList(dataSourceUuid) {
    this.props.getDataSourceQueryList(this.state.workspaceUuid, dataSourceUuid, {
      startTs: getUnixTime(this.state.queryTimeRange.startTime),
      endTs: getUnixTime(this.state.queryTimeRange.endTime),
    });
  }

  isFormDirty() {
    if (this.state.currentDataSourceObject === null) {
      return false;
    }
    const {
      schedulingEnabled,
      schedulingMode,
      scheduleSlots,
      currentDataSourceObject,
      originalDatasourceState,
    } = this.state;

    const hascurrentDataSourceObjectChanged = !isEqual(
      originalDatasourceState.currentDataSourceObject,
      currentDataSourceObject
    );

    const hasSchedulingSettingChanged =
      originalDatasourceState.schedulingEnabled !== schedulingEnabled;
    const hasSchedulingModeChanged =
      originalDatasourceState.schedulingMode !== schedulingMode;
    const hasSchedulingSlotsChanged = !isEqual(
      originalDatasourceState.scheduleSlots,
      scheduleSlots
    );

    if (
      hascurrentDataSourceObjectChanged ||
      hasSchedulingSettingChanged ||
      hasSchedulingModeChanged ||
      hasSchedulingSlotsChanged
    ) {
      this.unblockNav = blockHistory(
        this.props.history,
        "You have unsaved changes - discard?",
        true,
        this.resetForm
      );
      return true;
    } else {
      this.unblockNav?.();
      this.unblockNav = null;
      return false;
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      dataSourceList,
      match: {
        params: { workspaceUuid },
      },
    } = props;
    if (workspaceUuid && workspaceUuid !== state.workspaceUuid) {
      console.log("Loading data list for data governance");
      props.getDataSourceList(workspaceUuid);
      props.getScheduleList(workspaceUuid);

      return {
        ...state,
        workspaceUuid,
      };
    } else if (dataSourceList !== state.dataSourceList) {
      const defaultDataSourceUuid = getSettingFromParams(
        "dataSourceUuid",
        props.location.search,
        ""
      );
      let currentDataSourceObject = null;
      let newSchedulingMode = state.schedulingMode;
      let newScheduleSlots = [];
      let newSchedulingEnabled = false;

      if (dataSourceList.data.length > 0) {
        if (state.currentDataSourceObject || defaultDataSourceUuid) {
          const targetDataSourceUuid =
            defaultDataSourceUuid || state.currentDataSourceObject?.metadata.uuid;
          for (let dataSource of dataSourceList.data) {
            if (targetDataSourceUuid === dataSource.metadata.uuid) {
              currentDataSourceObject = deepcopy(dataSource);
              break;
            }
          }
        }

        if (!currentDataSourceObject) {
          currentDataSourceObject = deepcopy(dataSourceList.data[0]);
        }

        if (currentDataSourceObject) {
          const {
            schedulingMode: dataSourceSchedulingMode,
            scheduleSlots: dataSourceScheduleSlots,
            schedulingEnabled: dataSourceSchedulingEnabled,
          } = schedulingFromDatasource(currentDataSourceObject);
          newSchedulingMode = dataSourceSchedulingMode;
          newScheduleSlots = dataSourceScheduleSlots;
          newSchedulingEnabled = dataSourceSchedulingEnabled;
        }
      }

      currentDataSourceObject &&
        props.getDataSourceQueryList(
          workspaceUuid,
          currentDataSourceObject.metadata.uuid,
          {
            startTs: getUnixTime(state.queryTimeRange.startTime),
            endTs: getUnixTime(state.queryTimeRange.endTime),
          }
        );
      return {
        ...state,
        schedulingEnabled: newSchedulingEnabled,
        schedulingMode: newSchedulingMode,
        scheduleSlots: newScheduleSlots,
        currentDataSourceObject,
        dataSourceList,
        originalDatasourceState: deepcopy({
          schedulingEnabled: newSchedulingEnabled,
          schedulingMode: newSchedulingMode,
          scheduleSlots: newScheduleSlots,
          currentDataSourceObject,
        }),
      };
    }

    return null;
  }
  onMaxQueryDurationInSecondsChange(newMaxQueryDurationStr) {
    const newMaxQueryDurationInSeconds =
      newMaxQueryDurationStr === undefined
        ? undefined
        : getSecondFromDisplayTime(newMaxQueryDurationStr);
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.maxQueryDurationInSeconds !== newMaxQueryDurationInSeconds) {
      currentDataSourceObject.config.governance = {
        ...governance,
        maxQueryDurationInSeconds: newMaxQueryDurationInSeconds,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onMaxQueryPeriodInSecondsChange(newMaxQueryPeriodStr) {
    const newMaxQueryPeriodInSeconds =
      newMaxQueryPeriodStr === undefined
        ? undefined
        : getSecondFromDisplayTime(newMaxQueryPeriodStr);
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.maxQueryPeriodInSeconds !== newMaxQueryPeriodInSeconds) {
      currentDataSourceObject.config.governance = {
        ...governance,
        maxQueryPeriodInSeconds: newMaxQueryPeriodInSeconds,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onIndexedTimestampColumnsChange(newIndexedTimestampColumnsOnly) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.indexedTimestampColumnsOnly !== newIndexedTimestampColumnsOnly) {
      currentDataSourceObject.config.governance = {
        ...governance,
        indexedTimestampColumnsOnly: newIndexedTimestampColumnsOnly,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onAllowRecordStorageChange(newAllowRecordStorage) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.allowRecordStorage !== newAllowRecordStorage) {
      currentDataSourceObject.config.governance = {
        ...governance,
        allowRecordStorage: newAllowRecordStorage,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onAllowRawDataSamplesChange(newAllowRawDataSamples) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.allowRawDataSamples !== newAllowRawDataSamples) {
      currentDataSourceObject.config.governance = {
        ...governance,
        allowRawDataSamples: newAllowRawDataSamples,
      };
      this.setState({ currentDataSourceObject });
    }
  }

  onFailingRecordsCountCacheChange(newAllowFailingRecordsCountCache) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.cacheFailingRecordsCount !== newAllowFailingRecordsCountCache) {
      currentDataSourceObject.config.governance = {
        ...governance,
        cacheFailingRecordsCount: newAllowFailingRecordsCountCache,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onSaveCompleteFullCompareDiffSamplesChange(newSaveCompleteFullCompareDiffSamples) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (
      governance.saveCompleteFullCompareDiffSamples !==
      newSaveCompleteFullCompareDiffSamples
    ) {
      currentDataSourceObject.config.governance = {
        ...governance,
        saveCompleteFullCompareDiffSamples: newSaveCompleteFullCompareDiffSamples,
      };
      this.setState({ currentDataSourceObject });
    }
  }

  onAllowPreviewsWhenPausedChange(newAllowPreviewsWhenPaused) {
    const { currentDataSourceObject } = this.state;
    const { governance = {} } = currentDataSourceObject.config;
    if (governance.allowPreviewsWhenPaused !== newAllowPreviewsWhenPaused) {
      currentDataSourceObject.config.governance = {
        ...governance,
        allowPreviewsWhenPaused: newAllowPreviewsWhenPaused,
      };

      this.setState({ currentDataSourceObject });
    }
  }

  onMaxBackfillDurationChange(newMaxBackfillDuration) {
    const { currentDataSourceObject } = this.state;
    this.setState({
      currentDataSourceObject: {
        ...currentDataSourceObject,
        config: {
          ...currentDataSourceObject.config,
          governance: {
            ...currentDataSourceObject.config.governance,
            maxBackfillDurationInSeconds: newMaxBackfillDuration,
          },
        },
      },
    });
  }

  onMaxCategoricalDistinctCountChange(newMaxCategoricalDistinctCount) {
    const { currentDataSourceObject } = this.state;
    this.setState({
      currentDataSourceObject: {
        ...currentDataSourceObject,
        config: {
          ...currentDataSourceObject.config,
          governance: {
            ...currentDataSourceObject.config.governance,
            maxCategoricalDistinctCount: newMaxCategoricalDistinctCount,
          },
        },
      },
    });
  }

  onSave() {
    const { currentDataSourceObject, workspaceUuid } = this.state;
    if (!currentDataSourceObject) {
      return;
    }

    Object.assign(
      currentDataSourceObject.config.governance,
      this.getAllowDenySchedulesConfig()
    );

    const oldDataSourceObject = this.state.dataSourceList.data.filter(
      (dataSourceObject) =>
        dataSourceObject.metadata.uuid === currentDataSourceObject.metadata.uuid
    )[0];

    this.props.updateDataSourceGovernance(
      workspaceUuid,
      oldDataSourceObject,
      currentDataSourceObject.config.governance || {}
    );

    trackEvent(EVENT.SAVE_QUERY_GOVERNANCE, {
      ...getDatasourceDetailProps(currentDataSourceObject),
      page: PAGE.QUERY_GOVERNANCE,
    });
  }

  onDataSourceChange(dataSourceOption) {
    const { currentDataSourceObject } = this.state;
    if (
      !currentDataSourceObject ||
      dataSourceOption !== currentDataSourceObject.metadata.uuid
    ) {
      const newDataSourceObject = this.state.dataSourceList.data.filter(
        (dataSourceObject) => dataSourceObject.metadata.uuid === dataSourceOption
      )[0];
      const { schedulingMode, scheduleSlots, schedulingEnabled } =
        schedulingFromDatasource(newDataSourceObject);
      const updatedState = {
        currentDataSourceObject: deepcopy(newDataSourceObject),
        schedulingEnabled,
        schedulingMode,
        scheduleSlots,
      };
      this.setState({
        ...updatedState,
        originalDatasourceState: deepcopy(updatedState),
      });
      this.getQueryList(newDataSourceObject.metadata.uuid);
    }
  }

  getAllowDenySchedulesConfig() {
    const schedules = {
      allowScheduleUuids: [],
      denyScheduleUuids: [],
    };
    if (!this.state.schedulingEnabled) {
      return schedules;
    }
    const selectedScheduleUuids = this.state.scheduleSlots
      .filter((slot) => slot.scheduleUuid)
      .map((slot) => slot.scheduleUuid);
    if (this.state.schedulingMode === QueryGovernanceSchedulingMode.ALLOW) {
      schedules.allowScheduleUuids = selectedScheduleUuids;
    } else if (this.state.schedulingMode === QueryGovernanceSchedulingMode.DENY) {
      schedules.denyScheduleUuids = selectedScheduleUuids;
    }
    return schedules;
  }
  getDatasourceListProps() {
    const {
      currentDataSourceObject,
      dataSourceList: { data, loading },
    } = this.state;
    const { datasourceUuid } = this.props;
    let currentDataSourceUuid;
    if (datasourceUuid) {
      currentDataSourceUuid = datasourceUuid;
    } else {
      currentDataSourceUuid = currentDataSourceObject
        ? currentDataSourceObject.metadata.uuid
        : "";
    }
    const dataSourceOptions = data?.map(function (dataSource) {
      return {
        value: dataSource.metadata.uuid,
        label: dataSource.metadata.name,
      };
    });
    return {
      isLoading: loading,
      selectedDataSourceId: currentDataSourceUuid,
      dataSourceOptions,
      onDataSourceChange: this.onDataSourceChange,
    };
  }

  resetForm() {
    const { originalDatasourceState } = this.state;

    this.setState((prevState) => ({
      ...prevState,
      currentDataSourceObject: deepcopy(
        originalDatasourceState.currentDataSourceObject
      ),
      scheduleSlots: deepcopy(originalDatasourceState.scheduleSlots),
      schedulingEnabled: originalDatasourceState.schedulingEnabled,
      schedulingMode: originalDatasourceState.schedulingMode,
    }));
  }
  render() {
    const {
      workspaceUserPermissions,
      scheduleList,
      dataSourceList: { loading },
    } = this.props;
    const { schedulingEnabled, schedulingMode, scheduleSlots } = this.state;
    const enableSaveButton = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCEDETAIL,
    ]);

    const steps = [];
    if (!this.state.dataSourceList.loading) {
      const { currentDataSourceObject } = this.state;

      const currentDataSourceGovernance = currentDataSourceObject
        ? currentDataSourceObject.config.governance
        : {};

      const dataSourceType = currentDataSourceObject?.config.connection.type || "";
      const schedulingSupported =
        currentDataSourceObject && currentDataSourceGovernance;
      const governanceFullySupported =
        currentDataSourceObject &&
        currentDataSourceGovernance &&
        fullySupportedDataSourceTypeList.includes(
          currentDataSourceObject.config.connection.type
        );

      if (schedulingSupported) {
        steps.push({
          title: "Scheduling",
          component: (
            <SchedulingStep
              schedulingEnabled={schedulingEnabled}
              allowPreviewsWhenPaused={
                currentDataSourceGovernance.allowPreviewsWhenPaused
              }
              scheduleList={scheduleList}
              schedulingMode={schedulingMode}
              scheduleSlots={scheduleSlots}
              onSchedulingModeChange={(newMode) => {
                this.setState({ schedulingMode: newMode });
              }}
              onScheduleSlotChange={(slotId, newScheduleUuid) => {
                const newScheduleSlots = scheduleSlots.map((slot) => {
                  if (slot.id === slotId) {
                    slot.scheduleUuid = newScheduleUuid;
                  }
                  return slot;
                });
                this.setState({
                  scheduleSlots: newScheduleSlots,
                });
              }}
              onScheduleSlotAdd={() => {
                const highestId = scheduleSlots.reduce((maxId, slot) => {
                  return Math.max(maxId, slot.id);
                }, 0);
                const newScheduleSlots = [{ id: highestId + 1 }, ...scheduleSlots];
                this.setState({
                  scheduleSlots: newScheduleSlots,
                });
              }}
              onScheduleSlotDelete={(deletedSlotId) => {
                const newScheduleSlots = scheduleSlots.filter((slot) => {
                  return slot.id !== deletedSlotId;
                });
                this.setState({
                  scheduleSlots: newScheduleSlots,
                });
              }}
              onAllowPreviewsWhenPaused={this.onAllowPreviewsWhenPausedChange}
            />
          ),
          skipIndex: true,
          description: "Enable scheduling to control when queries are issued",
          className: "query-governance-wizard-scheduling-container query-config-step",
          enabled: schedulingEnabled,
          onEnabledChange: (enabled) => this.setState({ schedulingEnabled: enabled }),
        });
      }

      const {
        maxQueryDurationInSeconds,
        maxQueryPeriodInSeconds,
        indexedTimestampColumnsOnly,
        allowRecordStorage,
        allowRawDataSamples,
        saveCompleteFullCompareDiffSamples,
        cacheFailingRecordsCount,
      } = currentDataSourceGovernance;

      if (governanceFullySupported) {
        const queryTimeoutEnabled = maxQueryDurationInSeconds !== undefined;
        steps.push({
          title: "Query timeout",
          component: queryTimeoutEnabled && (
            <>
              Terminate after
              <TimeOption
                value={
                  !queryTimeoutEnabled
                    ? undefined
                    : getDisplayTimeFromSecond(maxQueryDurationInSeconds, true, [
                        "s",
                        "m",
                        "h",
                        "d",
                        "w",
                        "mo",
                      ])
                }
                onChange={this.onMaxQueryDurationInSecondsChange}
                options={timeSettingOptions}
              />
            </>
          ),
          skipIndex: true,
          description:
            "Terminates the query if the runtime exceeds the specified value.",
          className: "query-governance-wizard-query-timeout-container",
          enabled: queryTimeoutEnabled,
          onEnabledChange: (enabled) => {
            const newMaxQueryDurationInSeconds = enabled
              ? getDisplayTimeFromSecond(defaultQueryTimeoutLimit, true, [
                  "s",
                  "m",
                  "h",
                  "d",
                  "w",
                  "mo",
                ])
              : undefined;
            this.onMaxQueryDurationInSecondsChange(newMaxQueryDurationInSeconds);
          },
        });

        const dataRangeLimitEnabled = maxQueryPeriodInSeconds !== undefined;
        steps.push({
          title: "Query data range limit",
          component: dataRangeLimitEnabled && (
            <>
              Limit range to
              <TimeOption
                value={
                  !dataRangeLimitEnabled
                    ? undefined
                    : getDisplayTimeFromSecond(maxQueryPeriodInSeconds, true, [
                        "s",
                        "m",
                        "h",
                        "d",
                        "w",
                        "mo",
                      ])
                }
                onChange={this.onMaxQueryPeriodInSecondsChange}
                options={timeSettingOptions}
              />
            </>
          ),
          skipIndex: true,
          description:
            "Terminates the query if the data date range specified too large.",
          className: "query-governance-wizard-query-period-container",
          enabled: dataRangeLimitEnabled,
          onEnabledChange: (enabled) => {
            const newMaxQueryPeriodInSeconds = enabled
              ? getDisplayTimeFromSecond(defaultQueryDataRangeLimit, true, [
                  "s",
                  "m",
                  "h",
                  "d",
                  "w",
                  "mo",
                ])
              : undefined;
            this.onMaxQueryPeriodInSecondsChange(newMaxQueryPeriodInSeconds);
          },
        });
      }

      const enforcedTimestampDescriptionString =
        enforcedTimestampSourceTypeToStringMapper[dataSourceType];
      if (enforcedTimestampDescriptionString) {
        steps.push({
          title: `Enforce ${enforcedTimestampDescriptionString} timestamps`,
          skipIndex: true,
          description: `Prevent configuration of metrics that don't use ${enforcedTimestampDescriptionString} timestamps.`,
          className: "query-governance-wizard-indexed-timestamp-column-container",
          enabled: !!indexedTimestampColumnsOnly,
          onEnabledChange: (enabled) => this.onIndexedTimestampColumnsChange(enabled),
        });
      }

      steps.push({
        title: "Enable data storage",
        skipIndex: true,
        description:
          "Enable storage of sample data where source and target do not match",
        enabled: allowRecordStorage,
        onEnabledChange: (enabled) => this.onAllowRecordStorageChange(enabled),
      });

      steps.push({
        title: "Store full difference",
        skipIndex: true,
        description:
          "Enable storage of all data points where source and target do not match",
        enabled: saveCompleteFullCompareDiffSamples,
        onEnabledChange: (enabled) =>
          this.onSaveCompleteFullCompareDiffSamplesChange(enabled),
        enabledProps: {
          disabled: !allowRecordStorage,
        },
      });

      steps.push({
        title: "Enable data samples",
        skipIndex: true,
        description: "Allow display of raw data samples in the application",
        enabled: allowRawDataSamples,
        onEnabledChange: (enabled) => this.onAllowRawDataSamplesChange(enabled),
      });

      steps.push({
        title: "Enable failing records count cache",
        skipIndex: true,
        description:
          "Allow failing records count to be fetched and cached automatically",
        enabled: cacheFailingRecordsCount,
        onEnabledChange: (enabled) => this.onFailingRecordsCountCacheChange(enabled),
      });
    }

    steps.push({
      title: "Maximum backfill duration",
      description:
        "Limits the backfill duration for metrics. All metrics will be updated to conform to this policy.",
      component: (
        <DurationInput
          // Ensure time unit gets reset when datasource changes.
          key={this.state.currentDataSourceObject?.metadata.uuid}
          label="Backfill duration"
          value={
            this.state.currentDataSourceObject?.config.governance
              .maxBackfillDurationInSeconds
          }
          onChange={this.onMaxBackfillDurationChange}
        />
      ),
    });

    steps.push({
      title: "Maximum distinct values",
      description:
        "Categorical distribution metric will be collected only if the number of distinct values is less than this maximum.",
      component: (
        <LabeledInputNumber
          label="Maximum distinct values"
          value={
            this.state.currentDataSourceObject?.config.governance
              .maxCategoricalDistinctCount
          }
          onChange={this.onMaxCategoricalDistinctCountChange}
        />
      ),
    });
    const disableSave = this.isFormDirty()
      ? !enableSaveButton || !this.state.currentDataSourceObject
      : true;
    const showDatasourceDropdown = !this.props.datasourceUuid;
    return (
      <div
        className={classesName(
          "query-governance-container lightup-vertical-flex-container",
          this.props.datasourceUuid && "no-pad-top"
        )}
      >
        <div className="query-governance-header">
          <div className="query-governance-datasource">
            {showDatasourceDropdown && (
              <QueryGovernanceDataSource {...this.getDatasourceListProps()} />
            )}
          </div>
          <div className="query-governance-save-container">
            <Button
              block
              outline
              size="large"
              disabled={disableSave}
              onClick={this.resetForm}
            >
              Cancel
            </Button>
            <Button
              block
              primary
              size="large"
              disabled={disableSave}
              onClick={this.onSave}
            >
              Save
            </Button>
          </div>
        </div>
        <div>
          <Tabs defaultActiveKey={"configure"} className="lightup">
            <Tabs.TabPane
              key="configure"
              label="Configure"
              tab={
                <span>
                  <SettingConfigIcon className="anticon" />
                  Configure
                </span>
              }
            >
              <Spinner spinning={loading}>
                <div className="query-governance-config-container lightup-auto-flex-item-container lightup-vertical-flex-container">
                  <NgStepContainer
                    steps={steps}
                    className="query-governance-step-config-container lightup-rest-flex-item-container"
                  />
                </div>
              </Spinner>
            </Tabs.TabPane>
            {!this.props.hideHistory && (
              <Tabs.TabPane
                tab={
                  <span>
                    <HistoryOutlined className="history-icon" />
                    History
                  </span>
                }
              >
                <div className="query-governance-content-container lightup-rest-flex-item-container">
                  <QueryHistory
                    currentDataSourceObject={this.state.currentDataSourceObject}
                    timeRangeDefaultRange={timeRangeDefaultRange}
                    timeRangeDisabledDate={timeRangeDisabledDate}
                    maxLookbackDays={MAX_LOOKBACK_DAYS}
                    timeRangeSelectorPresets={timeRangeSelectorPresets}
                    workspaceUuid={this.state.workspaceUuid}
                  />
                </div>
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default QueryGovernance;
