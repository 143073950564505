export function ChartLineUpIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path
        d="M12.6875 11.875C12.6875 11.991 12.6414 12.1023 12.5594 12.1844C12.4773 12.2664 12.366 12.3125 12.25 12.3125H1.75C1.63397 12.3125 1.52269 12.2664 1.44064 12.1844C1.35859 12.1023 1.3125 11.991 1.3125 11.875V3.125C1.3125 3.00897 1.35859 2.89769 1.44064 2.81564C1.52269 2.73359 1.63397 2.6875 1.75 2.6875C1.86603 2.6875 1.97731 2.73359 2.05936 2.81564C2.14141 2.89769 2.1875 3.00897 2.1875 3.125V9.06898L4.94047 6.31547C4.9811 6.27479 5.02935 6.24252 5.08246 6.22051C5.13558 6.19849 5.19251 6.18716 5.25 6.18716C5.30749 6.18716 5.36442 6.19849 5.41754 6.22051C5.47065 6.24252 5.5189 6.27479 5.55953 6.31547L7 7.75648L9.88148 4.875H8.75C8.63397 4.875 8.52269 4.82891 8.44064 4.74686C8.35859 4.66481 8.3125 4.55353 8.3125 4.4375C8.3125 4.32147 8.35859 4.21019 8.44064 4.12814C8.52269 4.04609 8.63397 4 8.75 4H10.9375C11.0535 4 11.1648 4.04609 11.2469 4.12814C11.3289 4.21019 11.375 4.32147 11.375 4.4375V6.625C11.375 6.74103 11.3289 6.85231 11.2469 6.93436C11.1648 7.01641 11.0535 7.0625 10.9375 7.0625C10.8215 7.0625 10.7102 7.01641 10.6281 6.93436C10.5461 6.85231 10.5 6.74103 10.5 6.625V5.49352L7.30953 8.68453C7.2689 8.72521 7.22065 8.75748 7.16754 8.7795C7.11442 8.80151 7.05749 8.81284 7 8.81284C6.94251 8.81284 6.88558 8.80151 6.83246 8.7795C6.77935 8.75748 6.7311 8.72521 6.69047 8.68453L5.25 7.24352L2.1875 10.306V11.4375H12.25C12.366 11.4375 12.4773 11.4836 12.5594 11.5656C12.6414 11.6477 12.6875 11.759 12.6875 11.875Z"
        fill="#3F4050"
      />
    </svg>
  );
}
