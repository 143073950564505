import React from "react";
import Divider from "../../atom/divider";
import { Dropdown, Menu } from "antd";
import { Avatar } from "../avatar/";
import { getAppRoleDisplayName, getWorkspaceRoleDisplayName } from "../../utils/enums";

const UserSettingAction = Object.freeze({
  LOGOUT: "logout",
  HELP: "help",
  API_CREDENTIAL: "apiCredential",
  SUPPORT: "support",
  USERNAME: "username",
  APP_ROLE: "appRole",
  WORKSPACE_ROLE: "workspaceRole",
  VERSION: "version",
});

function UserSettingMenu(props) {
  const {
    userInfo,
    workspaceRole,
    onLogout,
    onOpenHelpPage,
    onOpenAPIKeyDialog,
    onOpenSupportDialog,
  } = props;
  const { username, appRole } = userInfo;

  async function onUserSettingMenuClick(e) {
    const { key } = e;
    if (key === UserSettingAction.LOGOUT) {
      await onLogout();
    } else if (key === UserSettingAction.HELP) {
      onOpenHelpPage();
    } else if (key === UserSettingAction.API_CREDENTIAL) {
      onOpenAPIKeyDialog();
    } else if (key === UserSettingAction.SUPPORT) {
      onOpenSupportDialog();
    }
  }

  const settingMenu = (
    <Menu className="lightup-popup-menu" onClick={onUserSettingMenuClick}>
      <Menu.Item key={UserSettingAction.USERNAME} style={{ pointerEvents: "none" }}>
        {username}
      </Menu.Item>
      <Menu.Item
        key={UserSettingAction.WORKSPACE_ROLE}
        style={{ pointerEvents: "none" }}
        disabled
      >
        {`Workspace ${getWorkspaceRoleDisplayName(workspaceRole) || "Unknown"}`}
      </Menu.Item>
      <Menu.Item
        key={UserSettingAction.APP_ROLE}
        style={{ pointerEvents: "none" }}
        disabled
      >
        {`App ${getAppRoleDisplayName(appRole)}`}
      </Menu.Item>
      <Divider style={{ margin: "2px 0px" }} />
      <Menu.Item key={UserSettingAction.API_CREDENTIAL}>API Credentials</Menu.Item>
      <Menu.Item key={UserSettingAction.HELP}>Help</Menu.Item>
      <Menu.Item key={UserSettingAction.SUPPORT}>Support</Menu.Item>
      <Menu.Item key={UserSettingAction.VERSION} style={{ pointerEvents: "none" }}>
        {userInfo.version}
      </Menu.Item>
      <Menu.Item key={UserSettingAction.LOGOUT}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={settingMenu}
      trigger={["click"]}
      placement={"topLeft"}
      overlayClassName="user-setting-dropdown-overlay-container"
    >
      <div style={{ cursor: "pointer" }}>
        <Avatar username={username} size={32} bgColor="#F4AF47" textColor="#FFFFFF" />
      </div>
    </Dropdown>
  );
}

export default UserSettingMenu;
