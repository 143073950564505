import React from "react";
import { withRouter, matchPath } from "react-router";
import {
  WorkspaceItemKeyToUriMapper,
  WorkspaceItemKeyToLabelMapper,
  WorkspaceItemKeyToIconMapper,
} from "../sidebar/util";

import "./workspace-header.scss";

const UriToWorkspaceItemKeyMapper = {};
for (const [key, value] of Object.entries(WorkspaceItemKeyToUriMapper)) {
  UriToWorkspaceItemKeyMapper[value] = key;
}

function WorkspaceHeader(props) {
  const {
    location: { pathname },
  } = props;

  let currentSelectedKey = "";
  for (let currentUri in UriToWorkspaceItemKeyMapper) {
    if (
      UriToWorkspaceItemKeyMapper.hasOwnProperty(currentUri) &&
      matchPath(pathname, { path: currentUri, exact: false, strict: false })
    ) {
      currentSelectedKey = UriToWorkspaceItemKeyMapper[currentUri];
      break;
    }
  }

  if (!currentSelectedKey) {
    return null;
  }

  const IconComponent = WorkspaceItemKeyToIconMapper[currentSelectedKey];
  const displayLabel = WorkspaceItemKeyToLabelMapper[currentSelectedKey];
  return (
    <div className="workspace-header-container">
      <IconComponent />
      {displayLabel}
    </div>
  );
}

export default withRouter(WorkspaceHeader);
