import React from "react";
import PropTypes from "prop-types";
import Typography from "./Typography";
import { FontWeights, TypographyColors, TypographyTypes } from "./enum";

const Link = ({ children, href = "#", target, rel, testId, ...typographyProps }) => {
  return (
    <Typography
      elementType="a" // Render as an anchor tag
      href={href}
      target={target}
      rel={rel}
      size={TypographyTypes.BODY_SMALL}
      weight={FontWeights.REGULAR}
      color={TypographyColors.LINK}
      testId={testId ? `energy-link-${testId}` : undefined}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  testId: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};

export default Link;
