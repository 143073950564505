import { connect } from "react-redux";

import NoWorkspace from "./no-workspace";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NoWorkspace);
