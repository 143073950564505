import * as types from "./kpi-action-types";
import { RequestStatus, MetricConfigType, ListPageName } from "../../utils/enums";
import {
  getDataSourcePartitionSampleDataPromise,
  getDataSourceSchemaListPromise,
  getDataSourceTableColumnListPromise,
  getDataSourceTableListLegacyPromise,
  triggerDataSourceMetricPromise,
} from "../../rest/datasource";
import {
  addKpiPromise,
  deleteKpiPromise,
  getKpiListPromise,
  getKpiTableSchemaSamplesPreviewPromise,
  getKpiPromise,
  getKpiQueryListPromise,
  getKpiSchemaPromise,
  getKpiSliceValueListPromise,
  updateKpiPromise,
  validateMetricFailingRecordsSqlPromise,
  terminateKpiPreviewPromise,
  resumeKpiPromise,
} from "../../rest/kpi";
import {
  getPageConfigurationPromise,
  updatePageConfigurationPromise,
} from "../../rest/workspace";
import { getMetricPreviewDataPromise } from "../../rest/metrics";
import { getIncidentListPromise } from "../../rest/incident";
import { getSliceValuePayload } from "../../utils/general";
import { getTagList } from "../tag/tag-action";
import { indexBy } from "../../utils/iterables";
import { deepcopy } from "../../utils/objects";

function setKpiList(kpiList) {
  return { type: types.SET_KPI_LIST, kpiList };
}

function setKpiListPageConfiguration(kpiListPageConfiguration) {
  return { type: types.SET_KPI_LIST_PAGE_CONFIGURATION, kpiListPageConfiguration };
}

function setKpiIncidentList(data) {
  return { type: types.SET_KPI_INCIDENT_LIST, kpiIncidentList: data };
}

function setKPILastPreviewTime(lastPreviewTime) {
  return { type: types.SET_KPI_LAST_PREVIEW_TIME, lastPreviewTime };
}

function setKPILastPreviewStatus(lastPreviewStatus) {
  return { type: types.SET_KPI_LAST_PREVIEW_STATUS, lastPreviewStatus };
}

function setKPILastPreviewResult(lastPreviewResult) {
  return { type: types.SET_KPI_LAST_PREVIEW_RESULT, lastPreviewResult };
}

function setCurrentCloneKpi(data) {
  return { type: types.SET_CURRENT_CLONE_KPI, currentCloneKpi: data };
}

function setCurrentEditKpi(data) {
  return { type: types.SET_CURRENT_EDIT_KPI, currentEditKpi: data };
}

function setCurrentKpiSchemaList(data) {
  return {
    type: types.SET_CURRENT_KPI_SCHEMA_LIST,
    currentKpiSchemaList: data,
  };
}

function setCurrentKpiSrcSchemaList(data) {
  return {
    type: types.SET_CURRENT_KPI_SRC_SCHEMA_LIST,
    currentKpiSrcSchemaList: data,
  };
}

function setCurrentKpiTableList(data) {
  return { type: types.SET_CURRENT_KPI_TABLE_LIST, currentKpiTableList: data };
}

function setCurrentKpiSrcTableList(data) {
  return {
    type: types.SET_CURRENT_KPI_SRC_TABLE_LIST,
    currentKpiSrcTableList: data,
  };
}

function setCurrentKpiTableColumnList(data) {
  return {
    type: types.SET_CURRENT_KPI_TABLE_COLUMN_LIST,
    currentKpiTableColumnList: data,
  };
}

export function setCurrentCustomSqlKpiTableColumnList(data) {
  return {
    type: types.SET_CURRENT_CUSTOM_SQL_KPI_TABLE_COLUMN_LIST,
    currentCustomSqlKpiTableColumnList: data,
  };
}

function setCurrentKpiSrcTableColumnList(data) {
  return {
    type: types.SET_CURRENT_KPI_SRC_TABLE_COLUMN_LIST,
    currentKpiSrcTableColumnList: data,
  };
}

function setCurrentKpiInColumnColumnList(data) {
  return {
    type: types.SET_CURRENT_KPI_IN_COLUMN_COLUMN_LIST,
    currentKpiInColumnColumnList: data,
  };
}

function setCurrentKpiTableColumnValueList(data) {
  return {
    type: types.SET_CURRENT_KPI_TABLE_COLUMN_VALUE_LIST,
    currentKpiTableColumnValueList: data,
  };
}

function setCurrentKpiFullCompareSrcTableColumnValueList(data) {
  return {
    type: types.SET_CURRENT_KPI_FULL_COMPARE_SOURCE_TABLE_COLUMN_VALUE_LIST,
    currentKpiFullCompareSrcTableColumnValueList: data,
  };
}

function setCurrentKpiFullCompareTargetTableColumnValueList(data) {
  return {
    type: types.SET_CURRENT_KPI_FULL_COMPARE_TARGET_TABLE_COLUMN_VALUE_LIST,
    currentKpiFullCompareTargetTableColumnValueList: data,
  };
}

function setCurrentKpiQueryList(data) {
  return { type: types.SET_CURRENT_KPI_QUERY_LIST, currentKpiQueryList: data };
}

function setCurrentKpiPartitionSampleData(data) {
  return {
    type: types.SET_CURRENT_KPI_PARTITION_SAMPLE_DATA,
    currentKpiPartitionSampleData: data,
  };
}

function setCurrentKpiSrcPartitionSampleData(data) {
  return {
    type: types.SET_CURRENT_KPI_SRC_PARTITION_SAMPLE_DATA,
    currentKpiSrcPartitionSampleData: data,
  };
}

function setCurrentKpiSampleDataTableSchemaList(data) {
  return {
    type: types.SET_CURRENT_KPI_SAMPLE_DATA_TABLE_SCHEMA_LIST,
    currentKpiSampleDataTableSchemaList: data,
  };
}

function setCurrentKpiSampleDataSampleDataList(data) {
  return {
    type: types.SET_CURRENT_KPI_SAMPLE_DATA_SAMPLE_DATA_LIST,
    currentKpiSampleDataSampleDataList: data,
  };
}

function setCurrentKpiValidateFailingRecordsSql(data) {
  return {
    type: types.SET_CURRENT_KPI_VALIDATE_FAILING_RECORDS_SQL,
    currentKpiValidateFailingRecordsSqlResult: data,
  };
}

export function getKpiList(workspaceUuid, options = {}) {
  const { isForceRefresh = false, quiet = false } = options;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { kpiList } = getState().kpiReducer;
      if (
        kpiList.data.length === 0 ||
        kpiList.data.workspaceUuid !== workspaceUuid ||
        isForceRefresh
      ) {
        if (!quiet) {
          dispatch(
            setKpiList({ loading: true, workspaceUuid, data: [], updatedAt: null })
          );
        }
        getKpiListPromise(workspaceUuid)
          .then(function (newKpiList) {
            dispatch(
              setKpiList({
                loading: false,
                workspaceUuid,
                data: newKpiList,
                updatedAt: Date.now(),
              })
            );
            resolve();
          })
          .catch(function (error) {
            reject();
            // handle error
            console.log(`Fail to load kpi list for ${error}`);
            dispatch(
              setKpiList({ loading: false, workspaceUuid, data: [], updatedAt: null })
            );
          });
      } else {
        resolve();
      }
    });
  };
}

export function getKpiListPageConfiguration(workspaceUuid) {
  return (dispatch, getState) => {
    getPageConfigurationPromise(workspaceUuid, ListPageName.METRICS)
      .then(function (newConfiguration) {
        dispatch(setKpiListPageConfiguration(newConfiguration));
      })
      .catch(function (error) {
        // handle error
        console.log(`Fail to load kpi list page configuration for ${error}`);
        dispatch(setKpiListPageConfiguration({ columns: [] }));
      });
  };
}

export function updateKpiListPageConfiguration(workspaceUuid, newPageConfiguration) {
  return (dispatch, getState) => {
    updatePageConfigurationPromise(workspaceUuid, newPageConfiguration)
      .then(function (updatedPageConfiguration) {
        dispatch(setKpiListPageConfiguration(updatedPageConfiguration));
      })
      .catch(function (error) {
        console.log(`Failed to update kpi list page configuration for ${error}`);
      });
  };
}

export function getKpiIncidentList(workspaceUuid, queryObject) {
  return (dispatch, getState) => {
    getIncidentListPromise(workspaceUuid, queryObject, false)
      .then(function (incidentList) {
        dispatch(setKpiIncidentList(incidentList));
      })
      .catch(function (err) {
        console.log(`Fail to get kpi incident list ${err}`);
      });
  };
}

export function addKpi(workspaceUuid, newKpi) {
  return (dispatch, getState) => {
    return addKpiPromise(workspaceUuid, newKpi)
      .then(function (response) {
        dispatch(getKpiList(workspaceUuid, { isForceRefresh: true }));
        return response;
      })
      .catch(function (error) {
        console.log(`Fail to create kpi for ${error}`);
      });
  };
}

function getFullCompareMetricPreviewDataPromise() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: null,
        queryStats: null,
      });
    }, 1000);
  });
}

export function previewKpi(workspaceUuid, kpiPreviewObject) {
  return (dispatch, getState) => {
    const currentTime = new Date().toLocaleTimeString();
    dispatch(setKPILastPreviewTime("Loading"));
    dispatch(
      setKPILastPreviewResult({
        data: null,
        queryStats: null,
      })
    );

    dispatch(setKPILastPreviewStatus(RequestStatus.DOING));
    const { abortController, ...otherKpiPreviewObject } = kpiPreviewObject;
    const requestOptions = {
      disableToast: () => {
        const { lastPreviewStatus } = getState().kpiReducer;
        return [RequestStatus.CANCEL_SUCCESS, RequestStatus.CANCELING].includes(
          lastPreviewStatus
        );
      },
      signal: abortController.signal,
    };

    let getPreviewDataPromise;
    if (
      kpiPreviewObject.metric.config.configType ===
      MetricConfigType.FULL_COMPARE_METRIC_CONFIG
    ) {
      getPreviewDataPromise = getFullCompareMetricPreviewDataPromise;
    } else {
      getPreviewDataPromise = getMetricPreviewDataPromise;
    }

    getPreviewDataPromise(workspaceUuid, otherKpiPreviewObject, requestOptions)
      .then(function (previewData) {
        const { lastPreviewStatus } = getState().kpiReducer;
        if ([RequestStatus.CANCEL_SUCCESS].includes(lastPreviewStatus)) {
          console.log("Skip preview result for it is canceled by the user");
          return;
        }

        dispatch(setKPILastPreviewStatus(RequestStatus.SUCCESS));
        dispatch(setKPILastPreviewResult(previewData));
      })
      .catch(function (error) {
        const { lastPreviewStatus } = getState().kpiReducer;
        if (
          lastPreviewStatus === RequestStatus.CANCEL_SUCCESS ||
          error.message === "canceled"
        ) {
          console.log("KPI preview fails due to cancel operation");
          return;
        }

        console.log(`Fail to get table list for ${error}`);
        dispatch(setKPILastPreviewStatus(RequestStatus.FAILURE));
      })
      .finally(function () {
        dispatch(setKPILastPreviewTime(currentTime));
      });
  };
}

export function terminateKpiPreview(workspaceUuid, kpiPreviewObject) {
  return (dispatch, getState) => {
    dispatch(setKPILastPreviewStatus(RequestStatus.CANCELING));
    terminateKpiPreviewPromise(workspaceUuid, kpiPreviewObject)
      .then(function () {
        kpiPreviewObject.abortController.abort();

        dispatch(setKPILastPreviewTime(""));
        dispatch(setKPILastPreviewStatus(RequestStatus.CANCEL_SUCCESS));
        dispatch(
          setKPILastPreviewResult({
            data: null,
            queryStats: null,
          })
        );
      })
      .catch(function (err) {
        console.log(`Fail to cancel kpi preview due to ${err}`);
        const { lastPreviewStatus } = getState().kpiReducer;
        if (
          [RequestStatus.SUCCESS, RequestStatus.FAILURE].includes(lastPreviewStatus)
        ) {
          console.log("Skip terminate preview result for it is done");
          return;
        }

        dispatch(setKPILastPreviewStatus(RequestStatus.DOING));
      });
  };
}

export function verifyKpiCustomSql(workspaceUuid, kpi) {
  return (dispatch, getState) => {
    dispatch(setCurrentKpiTableColumnList({ loading: true, data: [], isLoaded: true }));
    getKpiSchemaPromise(workspaceUuid, kpi)
      .then(function (schemas) {
        dispatch(
          setCurrentKpiTableColumnList({
            loading: false,
            data: schemas,
            isLoaded: true,
          })
        );
      })
      .catch(function (error) {
        dispatch(
          setCurrentKpiTableColumnList({ loading: false, data: [], isLoaded: true })
        );
        console.log(`Fail to get schema list for ${error}`);
      });
  };
}

export function validateKpiFailingRecordsSql(workspaceUuid, kpi) {
  return (dispatch, getState) => {
    dispatch(
      setCurrentKpiValidateFailingRecordsSql({ loading: true, isSuccess: false })
    );
    validateMetricFailingRecordsSqlPromise(workspaceUuid, kpi)
      .then(function (schemas) {
        dispatch(
          setCurrentKpiValidateFailingRecordsSql({ loading: false, isSuccess: true })
        );
      })
      .catch(function (error) {
        dispatch(
          setCurrentKpiValidateFailingRecordsSql({ loading: false, isSuccess: false })
        );
        console.log(`Fail to validate failing records sql for ${error}`);
      });
  };
}

export function getCurrentCustomSqlKpiTableColumnList(
  workspaceUuid,
  dataSourceUuid,
  tableUuid
) {
  return (dispatch, getState) => {
    getDataSourceTableColumnListPromise(workspaceUuid, dataSourceUuid, tableUuid)
      .then(function (columnList) {
        dispatch(setCurrentCustomSqlKpiTableColumnList(columnList));
      })
      .catch(function (error) {
        console.log(`Fail to get custom sql table column list for ${error}`);
        dispatch(setCurrentCustomSqlKpiTableColumnList([]));
      });
  };
}

export function deleteKpi(workspaceUuid, kpi) {
  return (dispatch, getState) => {
    return deleteKpiPromise(workspaceUuid, kpi.metadata.uuid, true)
      .then(function (response) {
        dispatch(getKpiList(workspaceUuid, { isForceRefresh: true }));
      })
      .catch(function (error) {
        console.log(`Fail to delete kpi for ${error}`);
      });
  };
}

export function deleteKpis(workspaceUuid, kpiList) {
  return (dispatch, _) => {
    const deletePromises = kpiList.map((kpi) =>
      deleteKpiPromise(workspaceUuid, kpi.metadata.uuid, true)
    );
    return Promise.allSettled(deletePromises);
  };
}

export function getKpi(workspaceUuid, uuid, isClone = false) {
  return (dispatch, getState) => {
    getKpiPromise(workspaceUuid, uuid)
      .then(function (kpiUI) {
        if (!isClone) {
          dispatch(setCurrentEditKpi(kpiUI));
        } else {
          dispatch(setCurrentCloneKpi(kpiUI));
        }
      })
      .catch(function (error) {
        console.log(`Fail to get kpi for ${error}`);
      });
  };
}

export function editKpi(workspaceUuid, uuid, kpi, isForce = true, silently = false) {
  return (dispatch, getState) => {
    return updateKpiPromise(workspaceUuid, uuid, kpi, isForce)
      .then(function (response) {
        if (!silently) {
          dispatch(getKpiList(workspaceUuid, { isForceRefresh: true }));
        }
        return response;
      })
      .catch(function (error) {
        console.log(`Fail to edit kpi for ${error}`);
      });
  };
}

export function updateKpiTags(workspaceUuid, kpi, newTags) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (JSON.stringify(kpi.metadata.tags) === JSON.stringify(newTags)) {
        resolve();
        return;
      }

      kpi.metadata.tags = newTags;
      updateKpiPromise(workspaceUuid, kpi.metadata.uuid, kpi)
        .then(function (response) {
          Promise.all([
            dispatch(getKpiList(workspaceUuid, { isForceRefresh: true, quiet: true })),
            dispatch(getTagList(workspaceUuid)),
          ])
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        })
        .catch(function (error) {
          reject();
          console.log(`Fail to update tags for kpi due to ${error}`);
        });
    });
  };
}

function toggleKpiLiveStatusPromise(workspaceUuid, kpi, newIsLive) {
  return newIsLive
    ? resumeKpiPromise(workspaceUuid, kpi.metadata.uuid)
    : updateKpiPromise(workspaceUuid, kpi.metadata.uuid, {
        ...kpi,
        config: { ...kpi.config, isLive: newIsLive },
      });
}

function mergeKpiLists(metrics, updatedMetrics) {
  const updatedMetricLookup = indexBy(updatedMetrics, (metric) => metric.metadata.uuid);
  const mergedList = metrics.map(
    (metric) => updatedMetricLookup[metric.metadata.uuid] ?? metric
  );
  return mergedList;
}

export function toggleKpiLiveStatus(workspaceUuid, kpi, newIsLive) {
  return (dispatch, getState) => {
    return toggleKpiLiveStatusPromise(workspaceUuid, kpi, newIsLive)
      .then(function (updatedMetric) {
        dispatch(
          setKpiList({
            data: mergeKpiLists(getState().kpiReducer.kpiList.data, [updatedMetric]),
          })
        );
      })
      .catch(function (error) {
        console.log(`Fail to update isLive for kpi due to ${error}`);
      });
  };
}

export function triggerKpis(workspaceUuid, metrics) {
  return (_dispatch, _getState) => {
    const groupedMetricsByDataSourceUuid = {};
    metrics.forEach((metric) => {
      const dataSourceUuid = metric.config.sources[0];
      if (!groupedMetricsByDataSourceUuid[dataSourceUuid]) {
        groupedMetricsByDataSourceUuid[dataSourceUuid] = [];
      }

      groupedMetricsByDataSourceUuid[dataSourceUuid].push(metric.metadata.uuid);
    });

    Promise.all(
      Object.keys(groupedMetricsByDataSourceUuid).map((dataSourceUuid) =>
        triggerDataSourceMetricPromise(workspaceUuid, dataSourceUuid, {
          metricUuids: groupedMetricsByDataSourceUuid[dataSourceUuid],
        })
      )
    )
      .then(function () {
        console.log(`trigger metrics ${JSON.stringify(metrics)} is done`);
      })
      .catch(function (err) {
        console.log(`trigger metrics ${JSON.stringify(metrics)} failed due to ${err}`);
      });
  };
}

export function toggleKpisLiveStatus(workspaceUuid, kpis, newIsLive) {
  return (dispatch, getState) => {
    const togglePromises = kpis.map((kpi) => {
      kpi.config.isLive = newIsLive;
      return toggleKpiLiveStatusPromise(workspaceUuid, kpi, newIsLive);
    });
    return Promise.allSettled(togglePromises).then((toggleOutcomes) => {
      dispatch(
        setKpiList({
          data: mergeKpiLists(
            getState().kpiReducer.kpiList.data,
            toggleOutcomes
              .filter((outcome) => outcome.value)
              .map((outcome) => outcome.value)
          ),
        })
      );
    });
  };
}

export function getCurrentKpiTableList(workspaceUuid, dataSourceUuid) {
  return (dispatch, getState) => {
    getDataSourceTableListLegacyPromise(workspaceUuid, dataSourceUuid)
      .then(function (tableList) {
        dispatch(setCurrentKpiTableList(tableList));
      })
      .catch(function (error) {
        console.log(`Fail to get table list for ${error}`);
      });
  };
}

export function getCurrentKpiSrcTableList(workspaceUuid, dataSourceUuid) {
  return (dispatch, getState) => {
    getDataSourceTableListLegacyPromise(workspaceUuid, dataSourceUuid)
      .then(function (tableList) {
        dispatch(setCurrentKpiSrcTableList(tableList));
      })
      .catch(function (error) {
        console.log(`Fail to get table list for ${error}`);
      });
  };
}

export function getCurrentKpiSchemaList(
  workspaceUuid,
  dataSourceUuid,
  isTarget = false
) {
  return (dispatch) => {
    getDataSourceSchemaListPromise(workspaceUuid, dataSourceUuid)
      .then(function (schemaList) {
        const setFunction = isTarget
          ? setCurrentKpiSrcSchemaList
          : setCurrentKpiSchemaList;
        dispatch(
          setFunction(
            schemaList.data.filter(
              (currentSchema) => !!currentSchema?.profilerConfig?.enabled
            )
          )
        );
      })
      .catch(function (error) {
        console.log(`Fail to get schema list for ${error}`);
      });
  };
}

export function getCurrentKpiTableColumnList(workspaceUuid, dataSourceUuid, tableUuid) {
  return (dispatch, getState) => {
    dispatch(setCurrentKpiTableColumnList({ loading: true, data: [] }));
    getDataSourceTableColumnListPromise(workspaceUuid, dataSourceUuid, tableUuid)
      .then(function (columnList) {
        dispatch(setCurrentKpiTableColumnList({ loading: false, data: columnList }));
      })
      .catch(function (error) {
        dispatch(setCurrentKpiTableColumnList({ loading: false, data: [] }));
        console.log(`Fail to get table column list for ${error}`);
      });
  };
}

export function getCurrentKpiSrcTableColumnList(
  workspaceUuid,
  dataSourceUuid,
  tableUuid
) {
  return (dispatch, getState) => {
    getDataSourceTableColumnListPromise(workspaceUuid, dataSourceUuid, tableUuid)
      .then(function (columnList) {
        dispatch(setCurrentKpiSrcTableColumnList(columnList));
      })
      .catch(function (error) {
        console.log(`Fail to get source table column list for ${error}`);
      });
  };
}

export function getCurrentKpiInColumnColumnList(
  workspaceUuid,
  dataSourceUuid,
  tableUuid
) {
  return (dispatch, getState) => {
    getDataSourceTableColumnListPromise(workspaceUuid, dataSourceUuid, tableUuid)
      .then(function (columnList) {
        dispatch(setCurrentKpiInColumnColumnList(columnList));
      })
      .catch(function (error) {
        console.log(`Fail to get in column column list for ${error}`);
      });
  };
}

export function getCurrentKpiTableColumnValueList(workspaceUuid, kpi) {
  return (dispatch, getState) => {
    if (
      ![
        MetricConfigType.METRIC_CONFIG,
        MetricConfigType.FULL_TABLE_METRIC_CONFIG,
      ].includes(kpi.config.configType)
    ) {
      console.log("Invalid kpi type for get slice value list");
      return;
    }

    const payload = getSliceValuePayload("metric", kpi);
    getKpiSliceValueListPromise(workspaceUuid, payload)
      .then(function (sliceValueList) {
        dispatch(setCurrentKpiTableColumnValueList(sliceValueList));
      })
      .catch(function (error) {
        console.log(`Fail to get table column value list for ${error}`);
      });
  };
}

export function getCurrentKpiQueryList(workspaceUuid, currentKpi, { startTs, endTs }) {
  return (dispatch, getState) => {
    dispatch(setCurrentKpiQueryList({ loading: true, data: [] }));
    getKpiQueryListPromise(workspaceUuid, currentKpi.metadata.uuid, {
      startTs,
      endTs,
    })
      .then(function (queryListResponse) {
        const metricObject = deepcopy(currentKpi);
        queryListResponse.forEach((currentQueryItem) => {
          currentQueryItem.metricObject = metricObject;
        });
        dispatch(setCurrentKpiQueryList({ loading: false, data: queryListResponse }));
      })
      .catch(function (err) {
        dispatch(setCurrentKpiQueryList({ loading: false, data: [] }));
        console.log(`Fail to query metric history list for ${err}`);
      });
  };
}

export function getCurrentKpiPartitionSampleData(workspaceUuid, dataSourceUuid, table) {
  return (dispatch) => {
    dispatch(setCurrentKpiPartitionSampleData({ loading: true, data: null }));
    getDataSourcePartitionSampleDataPromise(workspaceUuid, dataSourceUuid, table)
      .then((partitionSampleData) => {
        dispatch(
          setCurrentKpiPartitionSampleData({
            loading: false,
            data: partitionSampleData,
          })
        );
      })
      .catch((err) => {
        dispatch(setCurrentKpiPartitionSampleData({ loading: false, data: null }));
        console.log(
          `Fail to get kpi partition sample data for ${dataSourceUuid} due to ${err}`
        );
      });
  };
}

export function getCurrentKpiSrcPartitionSampleData(
  workspaceUuid,
  dataSourceUuid,
  table
) {
  return (dispatch) => {
    dispatch(setCurrentKpiSrcPartitionSampleData({ loading: true, data: null }));
    getDataSourcePartitionSampleDataPromise(workspaceUuid, dataSourceUuid, table)
      .then((partitionSampleData) => {
        dispatch(
          setCurrentKpiSrcPartitionSampleData({
            loading: false,
            data: partitionSampleData,
          })
        );
      })
      .catch((err) => {
        dispatch(setCurrentKpiSrcPartitionSampleData({ loading: false, data: null }));
        console.log(
          `Fail to get kpi source partition sample data for ${dataSourceUuid} due to ${err}`
        );
      });
  };
}

export function getCurrentKpiSampleDataTableSchemaList(
  workspaceUuid,
  dataSourceUuid,
  tableUuid
) {
  return (dispatch) => {
    dispatch(setCurrentKpiSampleDataTableSchemaList({ loading: true, data: [] }));
    getDataSourceTableColumnListPromise(workspaceUuid, dataSourceUuid, tableUuid)
      .then((data) => {
        dispatch(setCurrentKpiSampleDataTableSchemaList({ loading: false, data }));
      })
      .catch((err) => {
        dispatch(setCurrentKpiSampleDataTableSchemaList({ loading: false, data: [] }));
        console.log(
          `Fail to get kpi sample data table schema list for ${dataSourceUuid} due to ${err}`
        );
      });
  };
}

export function getCurrentKpiSampleDataSampleDataList(workspaceUuid, requestObject) {
  return (dispatch) => {
    dispatch(
      setCurrentKpiSampleDataSampleDataList({ loading: true, data: [], error: null })
    );
    getKpiTableSchemaSamplesPreviewPromise(workspaceUuid, requestObject)
      .then((data) => {
        dispatch(
          setCurrentKpiSampleDataSampleDataList({ loading: false, data, error: null })
        );
      })
      .catch((err) => {
        dispatch(
          setCurrentKpiSampleDataSampleDataList({
            loading: false,
            data: [],
            error: err?.response?.data?.error || "unknown error",
          })
        );
        console.log(
          `Fail to get kpi sample data table schema list for ${JSON.stringify(
            requestObject
          )} due to ${err}`
        );
      });
  };
}

export function resetKPIStatus(options = {}) {
  return (dispatch, getState) => {
    const {
      resetAll = true,
      resetCurrentEditKpi = false,
      resetCurrentCloneKpi = false,
      resetSchemaList = false,
      resetSrcSchemaList = false,
      resetTableList = false,
      resetSrcTableList = false,
      resetColumnList = false,
      resetCustomSqlColumnList = false,
      resetSrcColumnList = false,
      resetColumnValueList = false,
      resetFullCompareSrcColumnValueList = false,
      resetFullCompareTargetColumnValueList = false,
      resetPartitionSampleData = false,
      resetSrcPartitionSampleData = false,
      resetSampleDataTableSchemaList = false,
      resetSampleDataSampleDataList = false,
      resetQueryList = false,
      resetValidateFailingQuery = false,
      resetPreviewInfo = false,
    } = options;

    // Current edit kpi
    (resetAll || resetCurrentEditKpi) && dispatch(setCurrentEditKpi({}));

    // Current clone kpi
    (resetAll || resetCurrentCloneKpi) && dispatch(setCurrentCloneKpi(null));

    // schema list
    (resetAll || resetSchemaList) && dispatch(setCurrentKpiSchemaList([]));
    (resetAll || resetSrcSchemaList) && dispatch(setCurrentKpiSrcSchemaList([]));

    // Table list
    (resetAll || resetTableList) && dispatch(setCurrentKpiTableList([]));
    (resetAll || resetSrcTableList) && dispatch(setCurrentKpiSrcTableList([]));

    // Column list
    (resetAll || resetColumnList) &&
      dispatch(
        setCurrentKpiTableColumnList({ loading: false, data: [], isLoaded: false })
      );
    (resetAll || resetSrcColumnList) && dispatch(setCurrentKpiSrcTableColumnList([]));
    (resetAll || resetCustomSqlColumnList) &&
      dispatch(setCurrentCustomSqlKpiTableColumnList([]));

    (resetAll || resetValidateFailingQuery) &&
      dispatch(
        setCurrentKpiValidateFailingRecordsSql({ loading: false, isSuccess: false })
      );

    // Partition sample data
    (resetAll || resetPartitionSampleData) &&
      dispatch(setCurrentKpiPartitionSampleData({ loading: true, data: null }));
    (resetAll || resetSrcPartitionSampleData) &&
      dispatch(setCurrentKpiSrcPartitionSampleData({ loading: true, data: null }));

    // Sample data page
    (resetAll || resetSampleDataTableSchemaList) &&
      dispatch(setCurrentKpiSampleDataTableSchemaList({ loading: true, data: [] }));
    (resetAll || resetSampleDataSampleDataList) &&
      dispatch(setCurrentKpiSampleDataSampleDataList({ loading: true, data: [] }));

    // Query list
    (resetAll || resetQueryList) &&
      dispatch(setCurrentKpiQueryList({ loading: true, data: [] }));

    // Table column value list
    (resetAll || resetColumnValueList) &&
      dispatch(setCurrentKpiTableColumnValueList([]));
    (resetAll || resetFullCompareSrcColumnValueList) &&
      dispatch(setCurrentKpiFullCompareSrcTableColumnValueList([]));
    (resetAll || resetFullCompareTargetColumnValueList) &&
      dispatch(setCurrentKpiFullCompareTargetTableColumnValueList([]));

    // Preview
    if (resetAll || resetPreviewInfo) {
      dispatch(setKPILastPreviewTime(""));
      dispatch(setKPILastPreviewStatus(RequestStatus.UNKNOWN));
      dispatch(setKPILastPreviewResult(null));
    }
  };
}
