export function CaretRightIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M9.93453 7.30953L5.55953 11.6845C5.51888 11.7252 5.47063 11.7574 5.41752 11.7794C5.36441 11.8014 5.30748 11.8127 5.25 11.8127C5.19251 11.8127 5.13559 11.8014 5.08248 11.7794C5.02937 11.7574 4.98112 11.7252 4.94047 11.6845C4.89982 11.6439 4.86758 11.5956 4.84558 11.5425C4.82358 11.4894 4.81226 11.4325 4.81226 11.375C4.81226 11.3175 4.82358 11.2606 4.84558 11.2075C4.86758 11.1544 4.89982 11.1061 4.94047 11.0655L9.00648 7L4.94047 2.93453C4.85837 2.85244 4.81226 2.7411 4.81226 2.625C4.81226 2.5089 4.85837 2.39756 4.94047 2.31547C5.02256 2.23338 5.1339 2.18726 5.25 2.18726C5.3661 2.18726 5.47744 2.23338 5.55953 2.31547L9.93453 6.69047C9.97521 6.7311 10.0075 6.77935 10.0295 6.83246C10.0515 6.88557 10.0628 6.9425 10.0628 7C10.0628 7.05749 10.0515 7.11442 10.0295 7.16754C10.0075 7.22065 9.97521 7.2689 9.93453 7.30953Z"
        fill="#6C6F7D"
      />
    </svg>
  );
}
