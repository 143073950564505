import React, { useEffect } from "react";
import Header from "../../components/header/";
import PrivateRoute from "../../components/private-route/private-route";
import SidebarApp from "../sidebar-app";
import NoWorkspace from "../no-workspace";
import { URIPath } from "../../utils/uri-path";
import { connect } from "react-redux";
import { updateCurrentWorkspaceUuid } from "../../actions/user/user-action";

import "./index.scss";

function HeaderApp(props) {
  const { userInfo, match, updateCurrentWorkspaceUuid } = props;

  useEffect(() => {
    if (match.params.workspaceUuid !== userInfo.currentWorkspaceUuid) {
      // synchronize current workspace with workspace selector
      updateCurrentWorkspaceUuid(match.params.workspaceUuid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.workspaceUuid, userInfo.currentWorkspaceUuid]);

  const sideBarURIs = [
    URIPath.SETTINGS,
    URIPath.MONITOR_LIST,
    URIPath.INCIDENT_LIST,
    URIPath.ALERT_LIST,
    URIPath.ALERT_ITEM,
    URIPath.DASHBOARD,
    URIPath.EXPLORER,
    URIPath.ADD_DATA_SOURCE,
    URIPath.ADD_METRIC,
    URIPath.ADD_MONITOR,
    URIPath.INCIDENT,
    URIPath.EDIT_DATA_SOURCE,
    URIPath.EDIT_METRIC,
    URIPath.EDIT_MONITOR,
    URIPath.EDIT_WORKSPACE,
    URIPath.CREATE_WORKSPACE,
    URIPath.ADMIN,
    URIPath.NG_METRIC_LIST,
    URIPath.NG_DRAFT_LIST,
    URIPath.NG_MONITOR_LIST,
    URIPath.NG_INCIDENT_LIST,
    URIPath.TRIAL_EXPIRED,
  ];

  return (
    <div className="lightup-app-container">
      <div className="lightup-app-header-container">
        <Header
          workspaceUserPermissions={props.workspaceUserPermissions}
          userPermissions={props.userPermissions}
        />
      </div>
      <div className="lightup-app-body-container">
        <PrivateRoute
          exact
          disableWorkspacePermissionCheck={true}
          path={URIPath.NO_WORKSPACE}
          component={NoWorkspace}
        />
        <PrivateRoute
          disableWorkspacePermissionCheck={true}
          path={sideBarURIs}
          component={SidebarApp}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentWorkspaceUuid: (currentWorkspaceUuid) =>
    dispatch(updateCurrentWorkspaceUuid(currentWorkspaceUuid)),
});

export default connect(null, mapDispatchToProps)(HeaderApp);
