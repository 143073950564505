import React, { Component } from "react";
import { withRouter } from "react-router";
import AdminHeader from "./admin-header";
import UsersTab from "./tabs/users/";
import WorkspacesTab from "./tabs/workspaces/";
import SystemIntegrationsTab from "./tabs/system-integrations/";
import { AppPermissions } from "../../utils/permissions";
import { getSettingFromParams, hasPermission } from "../../utils/uri-path";
import { AdminTabItemKey } from "./utils";

import "./admin.scss";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTabKey: getSettingFromParams(
        "tabName",
        props.location.search,
        AdminTabItemKey.USERS
      ),
    };

    this.onTabClick = this.onTabClick.bind(this);
  }

  componentDidMount() {
    const { userPermissions } = this.props;
    hasPermission(userPermissions, [
      AppPermissions.BACKEND_APPS_USER_VIEWS_VIEW_APPUSERLIST,
    ]) && this.props.getAppUserList();
    hasPermission(userPermissions, [
      AppPermissions.BACKEND_APPS_WORKSPACES_VIEWS_VIEW_WORKSPACELIST,
    ]) && this.props.getAppWorkspaceList();

    this.props.getSystemIntegrationList();
  }

  onTabClick(newTabKey) {
    const { currentTabKey } = this.state;
    if (currentTabKey === newTabKey) {
      return;
    }

    this.setState({ currentTabKey: newTabKey }, () => {
      const newUrl = `${this.props.location.pathname}?tabName=${newTabKey}`;
      this.props.history.push(newUrl);
    });
  }

  render() {
    const { appUserList, appWorkspaceList, userInfo, deleteWorkspace } = this.props;
    const { currentTabKey } = this.state;

    return (
      <div className="admin-container">
        <AdminHeader currentTabKey={currentTabKey} onTabClick={this.onTabClick} />
        <div className="admin-body-container">
          <div className="admin-body-inner-container">
            {currentTabKey === AdminTabItemKey.USERS && (
              <UsersTab
                userInfo={userInfo}
                appUserList={appUserList}
                appWorkspaceList={appWorkspaceList}
                addAppUser={this.props.addAppUser}
                updateAppUser={this.props.updateAppUser}
                deleteAppUser={this.props.deleteAppUser}
                addAppUserToWorkspace={this.props.addAppUserToWorkspace}
                removeAppUserFromWorkspace={this.props.removeAppUserFromWorkspace}
              />
            )}
            {currentTabKey === AdminTabItemKey.WORKSPACES && (
              <WorkspacesTab
                appUserList={appUserList}
                appWorkspaceList={appWorkspaceList}
                addAppUserToWorkspace={this.props.addAppUserToWorkspace}
                openWorkspaceTakeover={this.props.openWorkspaceTakeover}
                deleteWorkspace={deleteWorkspace}
              />
            )}
            {currentTabKey === AdminTabItemKey.CATALOGS && (
              <SystemIntegrationsTab
                systemIntegrationList={this.props.systemIntegrationList}
                addSystemIntegration={this.props.addSystemIntegration}
                deleteSystemIntegration={this.props.deleteSystemIntegration}
                updateSystemIntegration={this.props.updateSystemIntegration}
                previewSystemIntegration={this.props.previewSystemIntegration}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Admin);
