import React, { useEffect, useMemo, useState } from "react";
import EntityList from "../../components/entity-list/entity-list";
import NgDropdownMenu from "../../components/ng-dropdown-menu";
import { TextWithIcon } from "../../components/button/ng-button";
import { NgTableClickableText } from "../../components/table/ng-table";
import { getMetricTypeFromConfigData } from "../../components/metric/utils";
import { metricCategoryIconComponent } from "../../components/metric/fields/icons";
import EntityListPage from "../../components/entity-list/entity-list-page";
import {
  createdByColumn,
  modifiedByColumn,
  columnFn,
  modifiedAtColumn,
  dataSourceColumn,
  schemaColumn,
  tableColumn,
  displaySchemaName,
  displayTableName,
  displayColumnName,
  tagsColumn,
} from "../../components/entity-list/columns";
import {
  DraftState,
  DraftStateNameMap,
  DraftType,
  DraftTypeNameMap,
  ListPageColumnKey,
  TakeoverWidth,
} from "../../utils/enums";
import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import { capitalizeFirstLetter } from "../../utils/general";
import { fnSorter } from "../../utils/sort";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  UpdateTypeIcon,
  NewTypeIcon,
  DeleteTypeIcon,
  ApprovedIcon,
  RejectedIcon,
  DraftIcon,
  ApprovePendingIcon,
} from "./icons";
import { AppPermissions } from "../../utils/permissions";
import useSearch, {
  searchEntityType,
} from "../../components/search/use-search/use-search";
import DraftDetailPanel from "./draft-detail-panel";
import DraftMetricApproveModal from "../../components/metric/modals/draft-metric-approve-modal";
import { NgTextTooltip } from "../../components/text-tooltip/ng-text-tooltip";
import { metricDataSourceUuids } from "../../utils/metric";
import { indexBy } from "../../utils/iterables";
import { getUpdatedTags } from "../../components/tag-group/ng-tag-group";

import "./draft-list.scss";

const RequestTypeToIconMap = Object.freeze({
  [DraftType.NEW]: NewTypeIcon,
  [DraftType.UPDATE]: UpdateTypeIcon,
  [DraftType.DELETE]: DeleteTypeIcon,
});

const DraftStateToIconMap = Object.freeze({
  [DraftState.DRAFT]: DraftIcon,
  [DraftState.APPROVAL_PENDING]: ApprovePendingIcon,
  [DraftState.REJECTED]: RejectedIcon,
  [DraftState.APPROVED]: ApprovedIcon,
});

function getApproveText(draftMetric) {
  if (draftMetric.draftMetadata?.type === DraftType.DELETE) {
    return "Approve and delete";
  }
  return "Approve and publish";
}

function getTableRows(dataSourceList, draftListData) {
  const dataSourcesByUuid = indexBy(
    dataSourceList,
    (datasource) => datasource.metadata.uuid
  );

  return draftListData.data.map((draftMetric) => {
    return {
      ...draftMetric,
      dataSourceNames: metricDataSourceUuids(draftMetric)
        .map((sourceUuid) => dataSourcesByUuid[sourceUuid]?.metadata.name)
        .sort(),
      displaySchemaName: displaySchemaName(draftMetric),
      displayTableName: displayTableName(draftMetric),
      displayColumnName: displayColumnName(draftMetric),
    };
  });
}

function DraftList(props) {
  const {
    draftList,
    match: {
      params: { workspaceUuid },
    },
    history,
    workspaceUserPermissions,
    deleteDraft,
    getDraftList,
    updateDraft,
    openWorkspaceTakeover,
    closeTakeover,
    dataSourceList,
    getDataSourceList,
    kpiList,
    getKpiList,
    tagList,
    getTagList,
    updateKpiTags,
  } = props;

  const [approveDraftModalState, setApproveDraftModalState] = useState(null);
  const [isTagEditing, setIsTagEditing] = useState(false);

  const canModifyMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
  ]);

  useEffect(() => {
    getDraftList(workspaceUuid);
    getKpiList(workspaceUuid);
    getDataSourceList(workspaceUuid);
    getTagList(workspaceUuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceUuid, getDraftList, getDataSourceList]);

  function onOpenSideBar(data) {
    const takeoverSize =
      data.draftMetadata?.type === DraftType.UPDATE
        ? TakeoverWidth.MEDIUM
        : TakeoverWidth.NARROW;
    openWorkspaceTakeover(
      <DraftDetailPanel
        data={data}
        workspaceUuid={workspaceUuid}
        closeTakeover={closeTakeover}
        dataSourceList={dataSourceList}
        kpiList={kpiList}
      />,
      takeoverSize,
      () => closeTakeover()
    );
  }

  function saveCurrentDraft(draftMetric) {
    setApproveDraftModalState({ ...approveDraftModalState, isLoading: true });
    updateDraft(workspaceUuid, draftMetric).finally(() =>
      setApproveDraftModalState(null)
    );
  }

  const { options: searchOptions, filter: getFilteredRows } = useSearch({
    entityType: searchEntityType.DRAFT,
    drafts: draftList.data,
    dataSources: dataSourceList,
  });

  // It is hack to test if this is a workspace admin.
  const isWorkspaceAdmin = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCELIST,
  ]);

  const tableRows = useMemo(() => {
    return getTableRows(dataSourceList, draftList);
  }, [dataSourceList, draftList]);

  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: ["metadata", "idSerial"],
      sorter: { compare: fnSorter((row) => row.metadata.idSerial) },
      width: 60,
      fixed: "left",
    },
    {
      title: "Draft name",
      key: "draftName",
      dataIndex: ["draftMetadata", "name"],
      sorter: { compare: fnSorter((row) => row.draftMetadata.name) },
      render: function (name, data) {
        return (
          <NgTableClickableText onClick={() => onOpenSideBar(data)} highlighted>
            {name}
          </NgTableClickableText>
        );
      },
      width: 160,
      fixed: "left",
    },
    {
      title: "Type",
      key: "draftType",
      dataIndex: ["type"],
      sorter: { compare: fnSorter((row) => row.type) },
      render: function (draftType) {
        return <NgTextTooltip>{capitalizeFirstLetter(draftType)}</NgTextTooltip>;
      },
      width: 90,
    },
    {
      title: "Target object",
      key: "draftTargetObject",
      dataIndex: ["metadata", "name"],
      sorter: { compare: fnSorter((row) => row.metadata.name) },
      render: function (name, row) {
        const metricType = getMetricTypeFromConfigData(row);
        const MetricTypeIcon = metricCategoryIconComponent(metricType);
        const metricUrl = getURIInstance(URIPath.EDIT_METRIC, {
          workspaceUuid,
          id: row.metadata.uuid,
        });
        return (
          <TextWithIcon
            iconPosition="left"
            icon={<MetricTypeIcon width="14" height="14" style={{ minWidth: 14 }} />}
          >
            <a
              href={`/#${metricUrl}`}
              target="_blank"
              rel="noreferrer"
              style={{ minWidth: 0 }}
            >
              <NgTextTooltip>{name}</NgTextTooltip>
            </a>
          </TextWithIcon>
        );
      },
      width: 160,
    },
    {
      title: "Status",
      key: "draftStats",
      dataIndex: ["draftMetadata", "state"],
      sorter: { compare: fnSorter((row) => row.draftMetadata.state) },
      render: function (draftStatus) {
        const Icon = DraftStateToIconMap[draftStatus];
        const name = DraftStateNameMap[draftStatus];
        return (
          <TextWithIcon
            iconPosition="left"
            icon={<Icon width="14" height="14" style={{ minWidth: 14 }} />}
          >
            <NgTextTooltip>{name}</NgTextTooltip>
          </TextWithIcon>
        );
      },
      width: 150,
    },
    {
      title: "Request action",
      key: "draftAction",
      dataIndex: ["draftMetadata", "type"],
      sorter: { compare: fnSorter((row) => row.draftMetadata.type) },
      render: function (draftAction) {
        const Icon = RequestTypeToIconMap[draftAction];
        const name = DraftTypeNameMap[draftAction];
        return (
          <TextWithIcon
            iconPosition="left"
            icon={<Icon width="14" height="14" style={{ minWidth: 14 }} />}
          >
            <NgTextTooltip>{name}</NgTextTooltip>
          </TextWithIcon>
        );
      },
      width: 120,
    },
    {
      title: "Note",
      dataIndex: ["draftMetadata", "approverNotes"],
      sorter: {
        compare: fnSorter(
          (row) => row.draftMetadata?.approverNotes?.notes?.[0]?.note ?? ""
        ),
      },
      render: function (approverNotes) {
        const note = approverNotes?.notes?.[0]?.note ?? "";
        return <NgTextTooltip>{note}</NgTextTooltip>;
      },
      width: 220,
    },
    modifiedByColumn({
      dataIndex: ["metadata", "updatedBy", "username"],
    }),
    createdByColumn({
      dataIndex: ["metadata", "ownedBy", "username"],
    }),
    modifiedAtColumn({
      dataIndex: ["status", "configUpdatedTs"],
      defaultSortOrder: "descend",
    }),
    dataSourceColumn({
      dataIndex: ["dataSourceNames"],
    }),
    schemaColumn({
      dataIndex: ["displaySchemaName"],
    }),
    tableColumn({
      dataIndex: ["displayTableName"],
    }),
    {
      title: "Column",
      key: ListPageColumnKey.COLUMN_NAME,
      dataIndex: ["displayColumnName"],
      sorter: {
        compare: fnSorter((row) => row.displayColumnName.toLowerCase()),
      },
      render: (columnName) => {
        return <NgTextTooltip>{columnName}</NgTextTooltip>;
      },
      width: 160,
    },
    tagsColumn({
      dataIndex: ["metadata", "tags"],
      renderProps: {
        editEnabled: canModifyMetric,
        disabled: isTagEditing,
        tagList,
        onChange: (_newTags, changeObject, row) => {
          setIsTagEditing(true);
          updateKpiTags(
            workspaceUuid,
            row,
            getUpdatedTags(row.metadata.tags, changeObject)
          ).finally(() => {
            setIsTagEditing(false);
          });
        },
      },
    }),
    columnFn({
      title: "",
      key: "profilerConfigDelete",
      width: 70,
      render: function (_, data) {
        const menuItems = [
          isWorkspaceAdmin && {
            label: getApproveText(data),
            icon: <ApprovedIcon width={14} height={14} />,
            onClick: () => {
              setApproveDraftModalState({
                draftMetric: data,
                isApproving: true,
              });
            },
          },
          isWorkspaceAdmin && {
            label: "Reject",
            icon: <RejectedIcon width={14} height={14} />,
            onClick: () => {
              setApproveDraftModalState({
                draftMetric: data,
                isApproving: false,
              });
            },
          },
          {
            label: "Edit draft",
            icon: <EditOutlined />,
            onClick: () => {
              history.push(
                getURIInstance(URIPath.EDIT_METRIC, {
                  workspaceUuid,
                  id: data.metadata.uuid,
                })
              );
            },
          },
          {
            label: "Delete draft",
            icon: <DeleteOutlined />,
            onClick: () => {
              deleteDraft(workspaceUuid, data);
            },
            danger: true,
          },
        ].filter((currentMenu) => !!currentMenu);

        return <NgDropdownMenu menuItems={menuItems} position="bottomRight" />;
      },
      sorter: null,
      fixed: "right",
    })({ dataIndex: "uuid" }),
  ];

  return (
    <div className="draft-list">
      <EntityListPage>
        <EntityList
          addText=""
          showAdd={false}
          searchOptions={searchOptions}
          columns={columns}
          configurableColumns={[]}
          rows={tableRows}
          getRowKey={(row) => row.metadata.uuid}
          loading={draftList.loading}
          getFilteredRows={getFilteredRows}
          onAdd={() => {}}
          onSelectedRowsChange={null}
          columnKeyList={[]}
          onColumnKeyListChange={() => {}}
          columnKeyOptions={[]}
        />
      </EntityListPage>
      {Boolean(approveDraftModalState) && (
        <DraftMetricApproveModal
          visible={approveDraftModalState !== null}
          configData={approveDraftModalState.draftMetric}
          onConfirm={saveCurrentDraft}
          onCancel={() => setApproveDraftModalState(null)}
          isInProgress={approveDraftModalState.isLoading}
          isApproving={approveDraftModalState.isApproving}
        />
      )}
    </div>
  );
}

export default DraftList;
