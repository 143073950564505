import { connect } from "react-redux";
import {
  getUserAPIKeyList,
  addUserAPIKey,
  deleteUserAPIKey,
  updateUserAPIKeyActiveStatus,
  resetCurrentAddedUserAPIKey,
  updateCurrentWorkspaceUuid,
  logout,
} from "../../actions/user/user-action";

import Header from "./header";

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  workspaceList: state.userReducer.currentUserInfo?.workspaces || [],
  workspaceUserPermissionMap: state.userReducer.workspaceUserPermissionMap,
  userAPIKeyList: state.userReducer.userAPIKeyList,
  currentAddedUserAPIKey: state.userReducer.currentAddedUserAPIKey,
});

const mapDispatchToProps = (dispatch) => ({
  getUserAPIKeyList: () => dispatch(getUserAPIKeyList()),
  addUserAPIKey: () => dispatch(addUserAPIKey()),
  deleteUserAPIKey: (id) => dispatch(deleteUserAPIKey(id)),
  updateUserAPIKeyActiveStatus: (id, active) =>
    dispatch(updateUserAPIKeyActiveStatus(id, active)),
  resetCurrentAddedUserAPIKey: () => dispatch(resetCurrentAddedUserAPIKey()),
  updateCurrentWorkspaceUuid: (currentWorkspaceUuid) =>
    dispatch(updateCurrentWorkspaceUuid(currentWorkspaceUuid)),
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
