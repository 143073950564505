import axios from "axios";
import { toast } from "../components/toast/toast";
import { NotificationTypeConst } from "./enums";
import logger from "./logger";

const axiosInstance = axios.create({
  baseURL: "/api/v0/",
  responseType: "json",
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
});

axiosInstance.interceptors.request.use(function (request) {
  logger.request(request);
  return request;
});

axiosInstance.interceptors.response.use(
  function (response) {
    logger.response(response);
    return response;
  },
  function (error) {
    logger.error(error);

    const isCancellationError = error.message === "canceled" || axios.isCancel(error);

    // Disable the toast if the request cancelled intentionally
    let isAutoToastDisabled = isCancellationError;

    // Consumer can disable toast using error config property
    if (!isAutoToastDisabled && error?.config?.hasOwnProperty("disableToast")) {
      if (typeof error.config.disableToast === "boolean") {
        isAutoToastDisabled = error.config.disableToast;
      } else if (typeof error.config.disableToast === "function") {
        isAutoToastDisabled = error.config.disableToast(error);
      }
    }

    if (error && !isAutoToastDisabled) {
      const errorMesssageToDisplay = getGenericErrorMessage(error);

      toast(errorMesssageToDisplay, NotificationTypeConst.FAILURE);
    }

    return Promise.reject(error);
  }
);

function getGenericErrorMessage(error) {
  // Setting default error message
  let errorString = `Setting up request failed due to ${error.message}`;

  // Overriding error message based on response/request if available
  if (error.response) {
    if (error.response.data) {
      errorString = error.response.data?.error ?? JSON.stringify(error.response.data);
    } else if (error.response.status) {
      errorString = `Unknown error with ${error.response.status}`;
    } else {
      errorString = "Unknown response error";
    }
  } else if (error.request) {
    console.log(error.request);
    // Request is sent but no response is received.
    errorString = "No response is received";
  }

  return errorString;
}

export default axiosInstance;

export function createCancelTokenSource() {
  return axios.CancelToken.source();
}
