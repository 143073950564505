import React from "react";
import { Route, Redirect, matchPath } from "react-router-dom";
import { connect } from "react-redux";
import { getUserPermissionForWorkspace as getUserPermissionForWorkspaceAction } from "../../actions/user/user-action";
import {
  workspaceURIRelativePath,
  hasPermission,
  wsURIPrefix,
  URIPath,
  getURIInstance,
} from "../../utils/uri-path";
import PropTypes from "prop-types";
import { isUserTrialExpired } from "../../utils/trial";
import { isFeatureEnabled } from "../../utils/general";
import { SupportedFeature } from "../../utils/enums";

const legacyURIPathArray = Object.values(workspaceURIRelativePath);

const PrivateRoute = (props) => {
  const {
    component: Component,
    disclaimerPath,
    forbidden,
    redirect,
    requiredWorkspacePermissions = [],
    userInfo,
    requiredPermissions = [],
    workspaceUserPermissionMap,
    getUserPermissionForWorkspace,
    disableWorkspacePermissionCheck = false,
    ...rest
  } = props;
  const { disclaimerAccepted, workspaces = [] } = userInfo;
  const isAuthenticated = userInfo.loginSucceed;

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: redirect,
                state: { from: routeProps.location },
              }}
            />
          );
        }

        if (
          workspaces.length === 0 &&
          routeProps.location.pathname !== URIPath.NO_WORKSPACE &&
          routeProps.location.pathname !== URIPath.ADMIN &&
          routeProps.location.pathname !== URIPath.DISCLAIMER &&
          routeProps.location.pathname !== URIPath.TRIAL_EXPIRED &&
          routeProps.location.pathname !== URIPath.CREATE_WORKSPACE
        ) {
          return <Redirect to={URIPath.NO_WORKSPACE} />;
        }

        if (
          routeProps.location.pathname === URIPath.ROOT ||
          (workspaces.length > 0 &&
            userInfo.currentWorkspaceUuid &&
            routeProps.location.pathname === URIPath.NO_WORKSPACE)
        ) {
          return (
            <Redirect
              to={getURIInstance(URIPath.EXPLORER, {
                workspaceUuid: userInfo.currentWorkspaceUuid,
              })}
            />
          );
        }

        if (
          legacyURIPathArray.some((currentLegacyURI) => {
            return matchPath(routeProps.location.pathname, {
              path: currentLegacyURI,
              exact: false,
              strict: false,
            });
          })
        ) {
          return (
            <Redirect
              push={false}
              to={{
                pathname: `/ws/${userInfo.baseWorkspace.uuid}${routeProps.location.pathname}`,
                hash: routeProps.location.hash,
                search: routeProps.location.search,
                state: routeProps.location.state,
              }}
            />
          );
        }

        const isWorkspaceMatch = matchPath(routeProps.location.pathname, {
          path: wsURIPrefix,
          exact: false,
          strict: false,
        });

        const workspaceUuid =
          isWorkspaceMatch &&
          isWorkspaceMatch.params &&
          isWorkspaceMatch.params.workspaceUuid;
        if (
          !disableWorkspacePermissionCheck &&
          workspaceUuid &&
          (!workspaceUserPermissionMap[workspaceUuid] ||
            workspaceUserPermissionMap[workspaceUuid].loading)
        ) {
          console.log(`Loading user permission for ${workspaceUuid}`);
          return null;
        }

        if (
          isFeatureEnabled(userInfo.waffle, SupportedFeature.ENABLE_TRIAL_MODE) &&
          isUserTrialExpired(userInfo) &&
          routeProps.location.pathname !== URIPath.TRIAL_EXPIRED
        ) {
          return (
            <Redirect
              to={{
                pathname: URIPath.TRIAL_EXPIRED,
              }}
            />
          );
        }

        if (
          (!disableWorkspacePermissionCheck &&
            workspaceUuid &&
            !hasPermission(
              workspaceUserPermissionMap[workspaceUuid].data,
              requiredWorkspacePermissions
            )) ||
          !hasPermission(userInfo, requiredPermissions)
        ) {
          return (
            <Redirect
              to={{
                pathname: forbidden,
              }}
            />
          );
        }

        if (!disclaimerAccepted && routeProps.location.pathname !== disclaimerPath) {
          return (
            <Redirect
              to={{
                pathname: disclaimerPath,
                state: { from: routeProps.location },
              }}
            />
          );
        }

        return (
          <Component
            {...rest}
            {...routeProps}
            userInfo={userInfo}
            userPermissions={{
              isSuperuser: userInfo.isSuperuser,
              permissions: userInfo.permissions,
            }}
            workspaceUserPermissions={
              !disableWorkspacePermissionCheck && workspaceUuid
                ? workspaceUserPermissionMap[workspaceUuid].data
                : null
            }
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  redirect: PropTypes.string,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
  requiredWorkspacePermissions: PropTypes.arrayOf(PropTypes.string),
};

PrivateRoute.defaultProps = {
  disclaimerPath: "/disclaimer",
  forbidden: "/forbidden",
  redirect: "/login",
  requiredPermissions: [],
  requiredWorkspacePermissions: [],
};

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.currentUserInfo,
  workspaceUserPermissionMap: state.userReducer.workspaceUserPermissionMap,
});

const mapDispatchToProps = (dispatch) => ({
  getUserPermissionForWorkspace: (workspaceUuid) =>
    dispatch(getUserPermissionForWorkspaceAction(workspaceUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
