import React, { useState } from "react";
import { LabeledInputNumber, LabeledSelect } from "../labeled-control/labeled-control";
import { largestWholeTimeUnitFactor, TimeUnitInSeconds } from "../../utils/time";
import { evalDelayOptions } from "../../utils/options";
import { classesName } from "../../utils/css";

import "./duration-input.scss";

const evalDelayTimeUnits = evalDelayOptions.map(({ value }) => value);

function DurationInput(props) {
  const {
    staticLabel = false,
    disabled = false,
    value = 0, // in seconds
    onChange,
    label,
    style = {},
    className = null,
  } = props;

  const [timeUnit, setTimeUnit] = useState(
    largestWholeTimeUnitFactor(value, evalDelayTimeUnits)
  );

  const quantity = value / TimeUnitInSeconds[timeUnit];
  // We should not set default time unit which is confusing to user if there is no option.
  const selectedOpt = evalDelayOptions.find((opt) => opt.value === timeUnit) ?? "";

  function onUnitChange(newUnit) {
    setTimeUnit(newUnit);
    onChange(quantity * TimeUnitInSeconds[newUnit]);
  }

  function onQuantityChange(newQuantity) {
    onChange(newQuantity * TimeUnitInSeconds[timeUnit]);
  }

  return (
    <div className={classesName("duration-input", className)} style={style}>
      <LabeledInputNumber
        disabled={disabled}
        staticLabel={staticLabel}
        label={label}
        value={quantity}
        onChange={onQuantityChange}
        min={0}
      />
      <LabeledSelect
        disabled={disabled}
        options={evalDelayOptions}
        value={selectedOpt}
        onChange={onUnitChange}
      />
    </div>
  );
}

export default DurationInput;
