import React, { useEffect, useState } from "react";
import { ProfilerAfterTreeNavigationActionType } from "../../../actions/profiler/profiler-action";
import { TextWithIcon } from "../../../components/button/ng-button";
import TreePath from "../../../components/explorer/tree-path";
import { isSchemaScanning } from "../../../utils/datasource";
import { TreePathLevel } from "../../../utils/enums";
import { useInterval } from "../../../utils/hooks";
import { AppPermissions } from "../../../utils/permissions";
import {
  EVENT,
  PAGE,
  getDatasourceDetailProps,
  trackEvent,
} from "../../../utils/telemetry";
import { hasPermission } from "../../../utils/uri-path";
import { SchemaIcon, TableConfigIcon } from "../icons";
import ProfilerEnableView from "../profiler-enable-view";
import ProfilerMonitorAllDialog from "../profiler-monitor-all-dialog";
import { ProfilerScanStatus } from "../profiler-scan-status";
import ProfilerSummaryTabView, {
  dataSourceNodeSupportsTabKey,
  defaultTabKey,
} from "../profiler-summary-tab-view";
import ProfilerChecklistView from "../profiler-checklist-view";
import ProfilerTableEnableView from "../profiler-table-enable-view";
import { ProfilerStatsCardView } from "../profiler-stats-card-view";
import {
  ProfilerSummaryTabKey,
  SCHEMA_REFRESH_POLLING_INTERVAL,
  canViewChecklist,
  buildNewRuleAndProfileConfigUpdateForDataSource,
} from "../utils";
import ProfilerDataSourceManageSchemasTab from "./profiler-data-source-manage-schemas-tab";
import { ProfilerTreeNodeType } from "../tree-data";
import { getStringFromTimeStamp } from "../../../utils/time";

import "./profiler-data-source-overview.scss";
import QueryHistory from "../../settings/query-governance/query-table";
import {
  MAX_LOOKBACK_DAYS,
  timeRangeDefaultRange,
  timeRangeDisabledDate,
  timeRangeSelectorPresets,
} from "../../settings/query-governance/query-governance";
import { QueryGovernanceWithRouter } from "../../settings/query-governance";

export function ProfilerDataSourceOverviewHeader(props) {
  const {
    type,
    name,
    lastScannedStatus = null,
    lastScannedFailedReason = null,
    currentSelectNode,
    onCurrentSelectNodeChange,
    isTreeExpand,
    onTreeExpandChange,
  } = props;

  return (
    <div>
      <TreePath
        datasource={{ type: type, name: name }}
        selectedLevel={TreePathLevel.DATASOURCE}
        statusComponent={
          <ProfilerScanStatus
            lastScannedStatus={lastScannedStatus}
            lastScannedFailedReason={lastScannedFailedReason}
          />
        }
        currentSelectNode={currentSelectNode}
        onCurrentSelectNodeChange={onCurrentSelectNodeChange}
        isTreeExpand={isTreeExpand}
        onTreeExpandChange={onTreeExpandChange}
      />
    </div>
  );
}

function ProfilerDataSourceOverview(props) {
  const {
    alertingChannelList,
    availableNodeKeys,
    currentDataSourceSummary,
    currentSelectNode,
    dataSource,
    dataSourceAllTableList,
    getProfilerCurrentDataSourceSummary,
    getProfilerCurrentMonitorObject,
    getProfilerConfigTableList,
    monitorAllForDataSource,
    onCurrentSelectNodeChange,
    profilerAfterTreeNavigationAction,
    profilerCurrentMonitorObject,
    tabKey,
    onTabChange,
    refreshTreeDataSourceNode,
    resetProfilerCurrentDataSourceSummary,
    setProfilerAfterTreeNavigationAction,
    updateTableList,
    workspaceUuid,
    workspaceUserPermissions = { isSuperuser: true, permissions: [] },
    isTreeExpand,
    onTreeExpandChange,
  } = props;

  const canAddMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMLIST,
  ]);

  const canModifyMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
  ]);

  const canManageMetrics = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEDETAIL,
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCESCHEMA,
  ]);

  const showDataSourceSummary = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_TABLEPROFILELISTVIEW,
  ]);

  const canModifySettings = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_PROFILER_CONFIG_VIEWS_EDIT_TABLEPROFILERCONFIGDETAILVIEW,
  ]);

  const [monitorDialogContext, setMonitorDialogContext] = useState(false);

  useEffect(() => {
    if (
      hasPermission(workspaceUserPermissions, [
        AppPermissions.BACKEND_APPS_SOURCE_VIEWS_VIEW_SOURCEPROFILESUMMARYVIEW,
      ])
    ) {
      getProfilerCurrentDataSourceSummary(dataSource);
    }

    getProfilerConfigTableList(dataSource);

    return () => {
      resetProfilerCurrentDataSourceSummary();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource?.metadata?.uuid]);

  useEffect(() => {
    if (profilerAfterTreeNavigationAction) {
      performAfterTreeNavigationAction(profilerAfterTreeNavigationAction);
      setProfilerAfterTreeNavigationAction(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilerAfterTreeNavigationAction]);

  useInterval(() => {
    if (isSchemaScanning(dataSource)) {
      refreshTreeDataSourceNode(dataSource);
    }
  }, SCHEMA_REFRESH_POLLING_INTERVAL);

  function performAfterTreeNavigationAction(action) {
    switch (action.type) {
      case ProfilerAfterTreeNavigationActionType.DATASOURCE_MONITOR_ALL_METRICS:
        onMonitorAll();
        break;
      default:
        console.warn("Don't know how to handle action", action);
    }
  }

  function onEnableMonitorAllClicked(groupedAutoMetricsMapper, alertChannelConfig) {
    trackEvent(EVENT.MONITOR_DEEP_METRICS, {
      ...getDatasourceDetailProps(dataSource),
      page: PAGE.EXPLORER_DATASOURCE,
    });

    if (profilerCurrentMonitorObject.loading) {
      return;
    }

    const { rules, profilerConfigUpdates } =
      buildNewRuleAndProfileConfigUpdateForDataSource(
        alertChannelConfig,
        dataSource,
        groupedAutoMetricsMapper,
        true
      );

    monitorAllForDataSource({ rules, profilerConfigUpdates }).then(() => {
      getProfilerCurrentDataSourceSummary(dataSource);
    });
  }

  function onMonitorAll() {
    getProfilerCurrentMonitorObject({
      sourceUuid: dataSource.metadata.uuid,
    });
    setMonitorDialogContext(true);
  }

  if (dataSource === null) {
    return null;
  }

  const {
    metadata: { name },
    config: {
      connection: { type },
    },
    status,
    schemas = [],
  } = dataSource;

  const createdDate = status.createdTs ? getStringFromTimeStamp(status.createdTs) : "-";
  const lastScannedDate = status.lastScannedTs
    ? getStringFromTimeStamp(status.lastScannedTs)
    : "-";
  let totalSchemas = "-";
  let activeSchemas = "-";
  let metricCount = "-";
  let monitorCount = "-";
  const monitorSummary = {
    metricCount: 0,
    monitorCount: 0,
  };

  if (
    showDataSourceSummary &&
    currentDataSourceSummary &&
    currentDataSourceSummary.data &&
    !currentDataSourceSummary.loading
  ) {
    totalSchemas = currentDataSourceSummary.data.totalSchemas;
    activeSchemas = currentDataSourceSummary.data.activeSchemas;
    metricCount = currentDataSourceSummary.data.metricCount;
    monitorCount = currentDataSourceSummary.data.monitorCount;
    monitorSummary.metricCount = metricCount;
    monitorSummary.monitorCount = monitorCount;
  }

  let allTables = [];
  if (!dataSourceAllTableList.loading && dataSourceAllTableList.data.length > 0) {
    const activeSchemaNames = schemas.map((currentSchema) => currentSchema.name);
    allTables = dataSourceAllTableList.data.filter((currentTable) =>
      activeSchemaNames.includes(currentTable.schemaName)
    );
  }

  const configTabs = [
    {
      key: ProfilerSummaryTabKey.SUMMARY,
      content: (
        <div className="profiler-data-source-overview-summary-tab-content">
          <div className="profiler-data-source-overview-summary-tab-content-left">
            <ProfilerStatsCardView
              title="Datasource info"
              statsInfoList={[
                { label: "Schemas", value: totalSchemas },
                { label: "Active schemas", value: activeSchemas },
                { label: "Metrics", value: metricCount },
                { label: "Monitors", value: monitorCount },
              ]}
              extraInfoList={[
                { label: "Added to workspace", value: createdDate },
                { label: "Last scanned by Lightup", value: lastScannedDate },
              ]}
            />
            {isSchemaScanning(dataSource) && (
              <div className="profiler-data-source-overview-schema-in-scanning-banner-container">
                <SchemaIcon
                  width={24}
                  height={24}
                  fillColor={"#FFFFFF"}
                  fillColor1={"#FFFFFF"}
                />
                <span>Schema scanning...</span>
              </div>
            )}
            {schemas.length > 0 ? (
              <div className="profiler-data-source-overview-enable-view-list-container">
                <ProfilerTableEnableView
                  dataSource={dataSource}
                  canModifySettings={canModifySettings}
                  loading={
                    currentDataSourceSummary?.loading || dataSourceAllTableList?.loading
                  }
                  tables={allTables}
                  updateTableList={(tableList) => {
                    return updateTableList(tableList).then(() =>
                      getProfilerConfigTableList(dataSource, { isRefresh: true })
                    );
                  }}
                />
              </div>
            ) : (
              <ProfilerEnableView
                fullHeight
                gridOnly={true}
                icon={<SchemaIcon />}
                description={"Activate a schema to get started"}
                buttonContent={
                  <TextWithIcon icon={<TableConfigIcon />}>Manage schemas</TextWithIcon>
                }
                buttonEnabled={canManageMetrics}
                onClick={() => onTabChange(ProfilerSummaryTabKey.MANAGE_SCHEMAS)}
              />
            )}
          </div>
          <div className="profiler-data-source-overview-summary-tab-content-right">
            {canViewChecklist(workspaceUserPermissions) && (
              <ProfilerChecklistView
                config={{
                  workspaceUuid,
                  dataSource,
                }}
                monitorSummary={monitorSummary}
                onMonitorAll={onMonitorAll}
                className="right-container-empty"
              />
            )}
          </div>
        </div>
      ),
    },
    {
      key: ProfilerSummaryTabKey.MANAGE_SCHEMAS,
      content: (
        <ProfilerDataSourceManageSchemasTab
          availableNodeKeys={availableNodeKeys}
          config={{ dataSource }}
          dataSource={dataSource}
          enableEdit={canAddMetric && canModifyMetric}
          workspaceUuid={workspaceUuid}
          onCurrentSelectNodeChange={onCurrentSelectNodeChange}
        />
      ),
    },
    {
      key: ProfilerSummaryTabKey.GOVERNANCE,
      content: (
        <QueryGovernanceWithRouter
          datasourceUuid={dataSource?.metadata?.uuid}
          title="Configure and customize settings for this datasource"
          hideHistory
        />
      ),
    },
    {
      key: ProfilerSummaryTabKey.QUERY_HISTORY,
      content: (
        <QueryHistory
          currentDataSourceObject={dataSource}
          timeRangeDefaultRange={timeRangeDefaultRange}
          timeRangeDisabledDate={timeRangeDisabledDate}
          maxLookbackDays={MAX_LOOKBACK_DAYS}
          timeRangeSelectorPresets={timeRangeSelectorPresets}
          workspaceUuid={workspaceUuid}
        />
      ),
    },
  ].filter(({ key }) => dataSourceNodeSupportsTabKey(key));

  return (
    <div className="profiler-data-source-overview-container">
      <ProfilerDataSourceOverviewHeader
        type={type}
        name={name}
        lastScannedStatus={status?.lastScannedStatus}
        lastScannedFailedReason={status?.lastScannedFailedReason}
        currentSelectNode={currentSelectNode}
        onCurrentSelectNodeChange={onCurrentSelectNodeChange}
        isTreeExpand={isTreeExpand}
        onTreeExpandChange={onTreeExpandChange}
      />
      <div className="profiler-data-source-overview-body-container">
        <ProfilerSummaryTabView
          tabConfigs={configTabs}
          value={tabKey || defaultTabKey(ProfilerTreeNodeType.DATASOURCE)}
          onChange={onTabChange}
        />
      </div>
      {monitorDialogContext && (
        <ProfilerMonitorAllDialog
          modalIsOpen={monitorDialogContext}
          metadataMetricsOnly={monitorDialogContext}
          setModalIsOpen={(isOpen) => !isOpen && setMonitorDialogContext(false)}
          alertingChannelList={alertingChannelList}
          type="Datasource"
          typeName={{ dataSource: name }}
          data={profilerCurrentMonitorObject}
          okClicked={onEnableMonitorAllClicked}
        />
      )}
    </div>
  );
}

export default ProfilerDataSourceOverview;
