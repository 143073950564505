import { metadataMetricTypes, tableAutoMetricTypes } from "./metric";
import { MetricCategory, QueryScope, VirtualTableType } from "./enums";
import { dataSourceSupportsMetricType } from "./datasource";
import { deepcopy } from "./objects";
import { get } from "lodash";
// Note: Some functions below work with MetricCategory.EVENT. This is kind of a pseudo-metric-type.
// In the context of these utilities, it represents table schema change metrics, but of course for
// these there is no underlying metric object.

export function toggleSchemaChange(table, enabled) {
  const updatedTable = { ...table };
  updatedTable.profilerConfig.metadataMetrics.schemaChange.enabled = enabled;
  return updatedTable;
}

export function isSchemaChangeEnabled(table) {
  return Boolean(table.profilerConfig.metadataMetrics?.schemaChange?.enabled);
}

export function isTableMetricTypeEnabled(table, metricType) {
  switch (metricType) {
    case MetricCategory.DATA_DELAY:
      return table.profilerConfig?.dataDelay?.enabled;
    case MetricCategory.DATA_VOLUME:
      return table.profilerConfig?.volume?.enabled;
    case MetricCategory.BYTE_COUNT:
      return Boolean(table.profilerConfig?.metadataMetrics?.byteCount);
    case MetricCategory.ROW_COUNT:
      return Boolean(table.profilerConfig?.metadataMetrics?.rowCount);
    case MetricCategory.UPDATE_DELAY:
      return Boolean(table.profilerConfig?.metadataMetrics?.updateDelay);
    case MetricCategory.COLUMN_ACTIVITY:
      return isSchemaChangeEnabled(table);
    case MetricCategory.EVENT:
      return isSchemaChangeEnabled(table);
    default:
      console.warn(`Queried status of invalid table metric type: ${metricType}`);
      return false;
  }
}

export function toggleTableMetricType(table, metricType, enabled) {
  let updatedTable = deepcopy(table);
  switch (metricType) {
    case MetricCategory.DATA_DELAY:
      updatedTable.profilerConfig.dataDelay.enabled = enabled;
      break;
    case MetricCategory.DATA_VOLUME:
      updatedTable.profilerConfig.volume.enabled = enabled;
      break;
    case MetricCategory.BYTE_COUNT:
      updatedTable.profilerConfig.metadataMetrics.byteCount = enabled;
      break;
    case MetricCategory.ROW_COUNT:
      updatedTable.profilerConfig.metadataMetrics.rowCount = enabled;
      break;
    case MetricCategory.UPDATE_DELAY:
      updatedTable.profilerConfig.metadataMetrics.updateDelay = enabled;
      break;
    case MetricCategory.COLUMN_ACTIVITY:
      updatedTable = toggleSchemaChange(updatedTable, enabled);
      break;
    case MetricCategory.EVENT:
      updatedTable = toggleSchemaChange(updatedTable, enabled);
      break;
    default:
      console.warn(`Attempted to toggle invalid table metric type: ${metricType}`);
  }
  return updatedTable;
}

export function toggleAllMetadataMetrics(table, dataSource, enabled) {
  let newMetadataMetricsTable = [...metadataMetricTypes].reduce(
    (acc, metricType) =>
      dataSourceSupportsMetricType(dataSource, metricType) &&
      tableSupportsMetricType(table, metricType)
        ? toggleTableMetricType(acc, metricType, enabled)
        : acc,
    table
  );
  newMetadataMetricsTable = toggleSchemaChange(newMetadataMetricsTable, enabled);
  return newMetadataMetricsTable;
}

export function isAllDeepMetricsEnabled(table, dataSource) {
  return [...tableAutoMetricTypes]
    .filter(
      (metricType) =>
        dataSourceSupportsMetricType(dataSource, metricType) &&
        tableSupportsMetricType(table, metricType)
    )
    .every((metricType) => isTableMetricTypeEnabled(table, metricType));
}

export function toggleAllDeepMetrics(table, dataSource, enabled) {
  return [...tableAutoMetricTypes].reduce(
    (acc, metricType) =>
      dataSourceSupportsMetricType(dataSource, metricType) &&
      tableSupportsMetricType(table, metricType)
        ? toggleTableMetricType(acc, metricType, enabled)
        : acc,
    table
  );
}

export function isSomeMetadataMetricEnabled(table) {
  return (
    isSchemaChangeEnabled(table) ||
    [...metadataMetricTypes].some((metricType) =>
      isTableMetricTypeEnabled(table, metricType)
    )
  );
}

// We also get this information on the table.status.allMetadataMetricsEnabled field.
// We do not, however, always have access to an up-to-date status field, as when we
// POST changes to a table's profilerConfig. In these cases we'll need to calculate
// this ourselves.
export function isAllMetadataMetricsEnabled(dataSource, table) {
  return (
    isSchemaChangeEnabled(table) &&
    [...metadataMetricTypes]
      .filter(
        (metricType) =>
          dataSourceSupportsMetricType(dataSource, metricType) &&
          tableSupportsMetricType(table, metricType)
      )
      .every((metricType) => isTableMetricTypeEnabled(table, metricType))
  );
}

export function tableSupportsDataDelay(table) {
  return (
    !table?.isUserDefined &&
    table?.profilerConfig?.queryScope &&
    table?.profilerConfig.queryScope !== QueryScope.FULL_TABLE
  );
}

export function tableSupportsMetricType(table, metricType) {
  if (
    metricType === MetricCategory.COLUMN_ACTIVITY ||
    metricType === MetricCategory.EVENT
  ) {
    return !table?.isUserDefined;
  }

  if (metricType === MetricCategory.DATA_DELAY) {
    return tableSupportsDataDelay(table);
  }

  if (metadataMetricTypes.has(metricType)) {
    return table?.status?.supportedMetadataMetrics?.[metricType] ?? false;
  }

  return true;
}

export function tableSupportsDataProfiling(table) {
  return !table?.isUserDefined || table?.profilerConfig?.enabled;
}

export function isVirtualTable(table) {
  return table.type === VirtualTableType.USER_DEFINED_VIEW;
}

export function isRelatedToVirtualTable(metric) {
  let sourceTable, targetTable;
  if (get(metric, "config.table")) {
    sourceTable = get(metric, "config.table");
  } else {
    sourceTable = get(metric, "config.sourceTable.table", {});
    targetTable = get(metric, "config.targetTable.table", {});
  }
  if (targetTable) {
    return isVirtualTable(sourceTable) || isVirtualTable(targetTable);
  }
  return isVirtualTable(sourceTable);
}
