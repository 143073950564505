import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { LabeledSelect } from "../labeled-control/labeled-control";
import ButtonText from "../button/button-text";
import Divider from "../../atom/divider";
import "./app-workspace-select.scss";

function AppWorkspaceSelect(props) {
  const {
    label = "",
    value,
    disabled,
    workspaceList,
    staticLabel,
    size = "middle",
    onChange,
    onAddWorkspace,
    style = {},
    showSearch = true,
    enableAdd = true,
    enableClear = false,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const options = workspaceList.map((workspace) => {
    return {
      label: workspace.name,
      value: workspace.uuid,
    };
  });

  return (
    <LabeledSelect
      allowClear={enableClear}
      staticLabel={staticLabel}
      showSearch={showSearch}
      options={options}
      filterOption={(input, option) => {
        return (option?.label || "").toLowerCase().includes(input.toLowerCase());
      }}
      label={label}
      disabled={disabled}
      dropdownClassName="lightup-app-workspace-select-dropdown-container"
      style={style}
      dropdownMatchSelectWidth={280}
      optionLabelProp="label"
      open={isMenuOpen}
      value={value}
      onChange={(newValue) => {
        setIsMenuOpen(false);
        value !== newValue && onChange(newValue);
      }}
      onDropdownVisibleChange={(open) => setIsMenuOpen(open)}
      size={size}
      dropdownRender={(menu) => {
        return (
          <>
            <div className="app-workspace-select-workspace-total-container">
              Workspaces ({workspaceList.length})
            </div>
            {workspaceList.length > 0 && (
              <>
                {menu}
                {enableAdd && (
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                )}
              </>
            )}
            {enableAdd && (
              <ButtonText
                className="app-workspace-select-create-workspace-button"
                onClick={() => {
                  onAddWorkspace();
                  setIsMenuOpen(false);
                }}
              >
                <PlusOutlined />
                Create new workspace
              </ButtonText>
            )}
          </>
        );
      }}
    />
  );
}

export default AppWorkspaceSelect;
