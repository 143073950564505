import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { LabeledInput, LabeledSelect } from "../../labeled-control/labeled-control";
import Button from "../../button/ng-button";
import { AntdDeleteIcon } from "../../icons/delete-icon/";
import { CloseOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { getColumnTypeCategory } from "../../../utils/column";
import {
  ConformityConditionType,
  getConformityConditionTypeDisplayName,
  TableColumnTypeCategory,
  MetricWhereClauseType,
} from "../../../utils/enums";
import {
  ColumnConfigItem,
  SchemaConfigItem,
  TableConfigItem,
  FieldRow,
  FieldRowButton,
} from "./index";
import ConfigCard from "../config-card";

import Popover from "../../popover";
import ButtonIcon from "../../button/button-icon";
import WhereClauseLogic from "./where-clause-logic";
import {
  addImplicitBracketsToExpression,
  getSymbols,
} from "../../../utils/boolean-expressions";
import { equals } from "../../../utils/set";
import Select from "../../select";
import useTableColumnCache from "./use-table-column-cache";
import { withRouter } from "react-router";
import TextArea from "../../../atom/textarea";

import "./metric-conditions-config-item.scss";

const whereClauseOptions = Object.values(MetricWhereClauseType).map((value) => ({
  label: value,
  value,
}));

function getParamTypeFromCondition(conditionType, columnType) {
  if (
    [
      ConformityConditionType.IS_UNIQUE,
      ConformityConditionType.IS_INCREASING,
      ConformityConditionType.IS_DECREASING,
    ].includes(conditionType)
  ) {
    return null;
  }

  if (
    [ConformityConditionType.EQUAL, ConformityConditionType.NOT_EQUAL].includes(
      conditionType
    )
  ) {
    if (columnType === TableColumnTypeCategory.STRING) {
      return TableColumnTypeCategory.STRING;
    }

    if (columnType === TableColumnTypeCategory.TIMESTAMP) {
      return TableColumnTypeCategory.STRING;
    }

    if (columnType === TableColumnTypeCategory.NUMERIC) {
      return TableColumnTypeCategory.NUMERIC;
    }

    //Todo Support boolean/other
    console.log(`Unsupported column type for ${columnType} and ${conditionType}`);
    return TableColumnTypeCategory.STRING;
  }

  if (
    [
      ConformityConditionType.CONTAIN_STRING_CASE_SENSITIVE,
      ConformityConditionType.NOT_CONTAIN_STRING_CASE_SENSITIVE,
      ConformityConditionType.MATCH_PATTERN_CASE_SENSITIVE,
      ConformityConditionType.NOT_MATCH_PATTERN_CASE_SENSITIVE,
      ConformityConditionType.IN,
      ConformityConditionType.NOT_IN,
      ConformityConditionType.MATCH_REGEX,
      ConformityConditionType.NOT_MATCH_REGEX,
    ].includes(conditionType)
  ) {
    return TableColumnTypeCategory.STRING;
  }

  if (
    [
      ConformityConditionType.LESS_THAN,
      ConformityConditionType.LESS_EQUAL_THAN,
      ConformityConditionType.GREATER_THAN,
      ConformityConditionType.GREATER_EQUAL_THAN,
    ].includes(conditionType)
  ) {
    if (columnType === TableColumnTypeCategory.TIMESTAMP) {
      return TableColumnTypeCategory.STRING;
    }

    if (columnType === TableColumnTypeCategory.NUMERIC) {
      return TableColumnTypeCategory.NUMERIC;
    }

    console.log(`Unsupported column type for ${columnType} and ${conditionType}`);
    return TableColumnTypeCategory.NUMERIC;
  }

  if (
    [
      ConformityConditionType.LENGTH_EQUAL,
      ConformityConditionType.LENGTH_GREATER_THAN,
      ConformityConditionType.LENGTH_LESS_THAN,
      ConformityConditionType.LENGTH_GREATER_EQUAL_THAN,
      ConformityConditionType.LENGTH_LESS_EQUAL_THAN,
    ].includes(conditionType)
  ) {
    return TableColumnTypeCategory.NUMERIC;
  }

  console.log(`Unexpected conformity condition type ${conditionType}`);
  return TableColumnTypeCategory.STRING;
}

function getDefaultValueFromCondition(conditionType, columnType) {
  if (
    [
      ConformityConditionType.NULL,
      ConformityConditionType.NOT_NULL,
      ConformityConditionType.IS_UNIQUE,
      ConformityConditionType.IS_INCREASING,
      ConformityConditionType.IS_DECREASING,
    ].includes(conditionType)
  ) {
    return [];
  }

  if (
    [ConformityConditionType.IN_COLUMN, ConformityConditionType.NOT_IN_COLUMN].includes(
      conditionType
    )
  ) {
    return [{}];
  }

  if (
    getParamTypeFromCondition(conditionType, columnType) ===
    TableColumnTypeCategory.STRING
  ) {
    return [""];
  }

  return [0];
}

const metricAllTypeConditionsOptions = [
  ConformityConditionType.EQUAL,
  ConformityConditionType.NOT_EQUAL,
  ConformityConditionType.NULL,
  ConformityConditionType.NOT_NULL,
  ConformityConditionType.IN,
  ConformityConditionType.NOT_IN,
  ConformityConditionType.MATCH_REGEX,
  ConformityConditionType.NOT_MATCH_REGEX,
].map((value) => ({
  label: getConformityConditionTypeDisplayName(value),
  value,
}));

const metricIsUniqueConditionOptions = [ConformityConditionType.IS_UNIQUE].map(
  (value) => ({ label: getConformityConditionTypeDisplayName(value), value })
);

const metricInColumnConditionOptions = [
  ConformityConditionType.IN_COLUMN,
  ConformityConditionType.NOT_IN_COLUMN,
].map((value) => ({ label: getConformityConditionTypeDisplayName(value), value }));

const metricTrendConditionOption = [
  ConformityConditionType.IS_INCREASING,
  ConformityConditionType.IS_DECREASING,
].map((value) => ({ label: getConformityConditionTypeDisplayName(value), value }));

const metricNumericOrTimestampTypeConditionsOptions = [
  ConformityConditionType.LESS_THAN,
  ConformityConditionType.LESS_EQUAL_THAN,
  ConformityConditionType.GREATER_THAN,
  ConformityConditionType.GREATER_EQUAL_THAN,
].map((value) => ({
  label: getConformityConditionTypeDisplayName(value),
  value,
}));

const metricStringTypeConditionsOptions = [
  ConformityConditionType.LENGTH_EQUAL,
  ConformityConditionType.LENGTH_GREATER_THAN,
  ConformityConditionType.LENGTH_LESS_THAN,
  ConformityConditionType.LENGTH_GREATER_EQUAL_THAN,
  ConformityConditionType.LENGTH_LESS_EQUAL_THAN,
  ConformityConditionType.CONTAIN_STRING_CASE_SENSITIVE,
  ConformityConditionType.NOT_CONTAIN_STRING_CASE_SENSITIVE,
  ConformityConditionType.MATCH_PATTERN_CASE_SENSITIVE,
  ConformityConditionType.NOT_MATCH_PATTERN_CASE_SENSITIVE,
].map((value) => ({
  label: getConformityConditionTypeDisplayName(value),
  value,
}));

const conditionsOptionsSupportedByBlobStorage = [
  ConformityConditionType.LESS_THAN,
  ConformityConditionType.LESS_EQUAL_THAN,
  ConformityConditionType.GREATER_THAN,
  ConformityConditionType.GREATER_EQUAL_THAN,
  ConformityConditionType.EQUAL,
  ConformityConditionType.NOT_EQUAL,
  ConformityConditionType.IN,
  ConformityConditionType.NOT_IN,
  ConformityConditionType.NULL,
  ConformityConditionType.IS_UNIQUE,
  ConformityConditionType.LENGTH_EQUAL,
  ConformityConditionType.LENGTH_GREATER_THAN,
  ConformityConditionType.LENGTH_LESS_THAN,
  ConformityConditionType.LENGTH_GREATER_EQUAL_THAN,
  ConformityConditionType.LENGTH_LESS_EQUAL_THAN,
  ConformityConditionType.CONTAIN_STRING_CASE_SENSITIVE,
  ConformityConditionType.NOT_CONTAIN_STRING_CASE_SENSITIVE,
  ConformityConditionType.MATCH_PATTERN_CASE_SENSITIVE,
  ConformityConditionType.NOT_MATCH_PATTERN_CASE_SENSITIVE,
  ConformityConditionType.MATCH_PATTERN_CASE_INSENSITIVE,
  ConformityConditionType.NOT_MATCH_PATTERN_CASE_INSENSITIVE,
  ConformityConditionType.MATCH_REGEX,
  ConformityConditionType.NOT_MATCH_REGEX,
];

export function getMetricConditionsOptionsFromColumnType(columnType, opts = {}) {
  const {
    includeIsUnique = false,
    isUniqueOnly = false,
    includeInColumn = false,
    isBlobStorage = false,
    includeTrend = false,
  } = opts;

  let options;
  if (isUniqueOnly) {
    options = metricIsUniqueConditionOptions;
  } else {
    options = metricAllTypeConditionsOptions;
    if (includeInColumn) {
      options = [...options, ...metricInColumnConditionOptions];
    }

    if (includeIsUnique) {
      options = [...options, ...metricIsUniqueConditionOptions];
    }

    if (columnType === TableColumnTypeCategory.STRING) {
      options = [...options, ...metricStringTypeConditionsOptions];
    } else if (columnType === TableColumnTypeCategory.NUMERIC) {
      options = [...options, ...metricNumericOrTimestampTypeConditionsOptions];
      if (includeTrend) {
        options = [...options, ...metricTrendConditionOption];
      }
    } else if (columnType === TableColumnTypeCategory.TIMESTAMP) {
      options = [...options, ...metricNumericOrTimestampTypeConditionsOptions];
    }
  }

  if (!isBlobStorage) {
    return options;
  }

  return options.filter(({ value }) =>
    conditionsOptionsSupportedByBlobStorage.includes(value)
  );
}

function ConditionValueTextArea(props) {
  const { staticLabel, ...restProps } = props;

  return <TextArea rows={1} {...restProps} />;
}

function MetricConditionInputView(props) {
  const {
    conditionType,
    columnType,
    value,
    onChange,
    defaultComponent = null,
    schemaList = [],
    inColumnTableList = [],
    inColumnColumnList = [],
    onInColumnTableChanged = null,
    configData,
    labeled = true,
    className = null,
    selectClassName = null,
    disabled,
  } = props;

  if (
    [
      ConformityConditionType.NULL,
      ConformityConditionType.NOT_NULL,
      ConformityConditionType.IS_UNIQUE,
      ConformityConditionType.IS_INCREASING,
      ConformityConditionType.IS_DECREASING,
    ].includes(conditionType)
  ) {
    return defaultComponent;
  }

  if (
    [ConformityConditionType.IN_COLUMN, ConformityConditionType.NOT_IN_COLUMN].includes(
      conditionType
    )
  ) {
    const { table = {}, columnName = "" } = value[0] || {};
    let { tableName = "", tableUuid = "", schemaName = "" } = table;
    if (!tableUuid && tableName) {
      tableUuid =
        inColumnTableList.find((currentTable) => currentTable.tableName === tableName)
          ?.tableUuid || "";
    }

    let normalizedTableList;
    if (!schemaName) {
      normalizedTableList = [];
    } else {
      normalizedTableList = inColumnTableList.filter(
        (currentTable) => currentTable.schemaName === schemaName
      );
    }

    return (
      <>
        <SchemaConfigItem
          disabled={disabled}
          schemaList={schemaList}
          value={schemaName}
          onChange={(newSchemaName) => {
            onChange([
              {
                table: { schemaName: newSchemaName, tableName: "", tableUuid: "" },
                columnName: "",
              },
            ]);
          }}
          isLabeled={labeled}
          dropdownMatchSelectWidth={labeled}
          className={selectClassName}
        />
        <TableConfigItem
          disabled={disabled}
          tableList={normalizedTableList}
          value={tableUuid}
          onChange={(_newTableUuid, currentTable) => {
            onChange([
              {
                table: {
                  schemaName,
                  tableName: currentTable.tableName,
                  tableUuid: currentTable.tableUuid,
                },
                columnName: "",
              },
            ]);
            onInColumnTableChanged?.(currentTable.tableUuid);
          }}
          isLabeled={labeled}
          dropdownMatchSelectWidth={labeled}
          className={selectClassName}
        />
        <ColumnConfigItem
          disabled={disabled}
          configData={configData}
          columnList={inColumnColumnList}
          value={columnName}
          onChange={(_newColumnUuid, currentColumn) => {
            onChange([{ table, columnName: currentColumn.columnName }]);
          }}
          isLabeled={labeled}
          dropdownMatchSelectWidth={labeled}
          className={selectClassName}
        />
      </>
    );
  }

  let InputComponent;
  let defaultValue;
  let onValueChange;
  let onBlur;
  let inputProps = { staticLabel: true, label: "value", disabled };
  if (
    [ConformityConditionType.IN, ConformityConditionType.NOT_IN].includes(conditionType)
  ) {
    InputComponent = ConditionValueTextArea;
    defaultValue = value.join(",");
    onValueChange = (e) => {
      const newStrValue = e.target.value;
      if (newStrValue === defaultValue) {
        return;
      }

      const newValue = newStrValue.split(",");
      onChange(newValue);
    };

    onBlur = () => {
      if (columnType !== TableColumnTypeCategory.NUMERIC) {
        return;
      }

      onChange(
        value.map((valueItem) => {
          const parsedFloat = parseFloat(valueItem);
          if (isNaN(parsedFloat)) {
            return valueItem;
          }

          return parsedFloat;
        })
      );
    };
  } else {
    const currentColumnTypeCategory = getParamTypeFromCondition(
      conditionType,
      columnType
    );
    if (labeled) {
      InputComponent = LabeledInput;
    } else {
      InputComponent = Input;
      inputProps = {
        placeholder: "value",
      };
    }
    defaultValue = value[0];

    onValueChange = (e) => {
      let newValue = e.target.value;
      if (newValue === value[0]) {
        return;
      }
      onChange([newValue]);
    };

    onBlur = () => {
      if (currentColumnTypeCategory !== TableColumnTypeCategory.NUMERIC) {
        return;
      }

      const strValue = value[0];
      const parsedFloatNumber = parseFloat(strValue);
      if (isNaN(parsedFloatNumber)) {
        return;
      }

      onChange([parsedFloatNumber]);
    };
  }

  return (
    <InputComponent
      {...inputProps}
      value={defaultValue}
      onChange={onValueChange}
      onBlur={onBlur}
      className={className}
    />
  );
}

function MetricRegexConditionHelpTip() {
  return (
    <div className="metric-regex-condition-help-tip-container">
      View regexp help at{" "}
      <a href="https://regex101.com/" target="_blank" rel="noreferrer">
        Regex101
      </a>
    </div>
  );
}

function MetricTimestampConditionHelpTip() {
  return (
    <div className="metric-regex-condition-help-tip-container">
      YYYY-MM-DD or YYYY-MM-DD HH:mm:ss (UTC)
    </div>
  );
}

export function MetricConditionsSetting(props) {
  const {
    value = [],
    onChange,
    disabled = false,
    isAddDisabled = false,
    isMultipleValueColumns = false,
    columnType = "",
    schemaList = [],
    inColumnTableList = [],
    inColumnColumnList = [],
    onInColumnTableChanged = null,
    isBlobStorage = false,
    isFullTable = false,
    configData,
  } = props;

  if (!columnType) {
    console.log("Invalid column type for kpi condition setting");
    return null;
  }

  const isInColumnConfigured = value.some(({ type }) =>
    [ConformityConditionType.IN_COLUMN, ConformityConditionType.NOT_IN_COLUMN].includes(
      type
    )
  );

  const isTrendConfigured = value.some(({ type }) =>
    [
      ConformityConditionType.IS_INCREASING,
      ConformityConditionType.IS_DECREASING,
    ].includes(type)
  );

  const metricConditionsOptions = getMetricConditionsOptionsFromColumnType(columnType, {
    includeIsUnique: value.length <= 1,
    isUniqueOnly: isMultipleValueColumns,
    includeInColumn: !isInColumnConfigured && value.length <= 1,
    includeTrend: !isFullTable && !isTrendConfigured && value.length < 1,
    isBlobStorage,
  });

  function onAdd() {
    value.push({
      type: metricConditionsOptions[0].value,
      values: getDefaultValueFromCondition(
        metricConditionsOptions[0].value,
        columnType
      ),
    });
    onChange([...value]);
  }

  function onRemove(index) {
    value.splice(index, 1);
    onChange([...value]);
  }

  function onTypeChange(index, newType) {
    if (value[index].type === newType) {
      return;
    }

    value[index].type = newType;
    value[index].values = getDefaultValueFromCondition(newType, columnType);
    onChange([...value]);
  }

  function onValueChange(index, newValues) {
    value[index].values = newValues;
    onChange([...value]);
  }

  const isUniqueOnlyConfigured =
    value.length === 1 && value[0].type === ConformityConditionType.IS_UNIQUE;

  return (
    <ConfigCard
      title="Conditions"
      actionComponent={
        !isTrendConfigured &&
        !isUniqueOnlyConfigured &&
        !isInColumnConfigured && (
          <Button
            disabled={disabled || isAddDisabled}
            outline
            onClick={onAdd}
            testId="metric-config-add-condition"
          >
            Add
            <PlusOutlined />
          </Button>
        )
      }
    >
      {value.map(({ type, values }, index) => {
        const currentMetricConditionsOptions = getMetricConditionsOptionsFromColumnType(
          columnType,
          {
            includeIsUnique: value.length <= 1,
            isUniqueOnly: isMultipleValueColumns,
            includeInColumn:
              (!isInColumnConfigured && value.length <= 1) ||
              [
                ConformityConditionType.IN_COLUMN,
                ConformityConditionType.NOT_IN_COLUMN,
              ].includes(type),
            includeTrend:
              !isFullTable &&
              ((!isTrendConfigured && value.length <= 1) ||
                [
                  ConformityConditionType.IS_INCREASING,
                  ConformityConditionType.IS_DECREASING,
                ].includes(type)),
            isBlobStorage,
          }
        );

        const isRegexConfigured = [
          ConformityConditionType.MATCH_REGEX,
          ConformityConditionType.NOT_MATCH_REGEX,
        ].includes(type);
        return (
          <div key={index}>
            <FieldRow>
              <LabeledSelect
                staticLabel
                enableSorting={true}
                label="Condition"
                value={type}
                onChange={(newType) => onTypeChange(index, newType)}
                options={currentMetricConditionsOptions}
                disabled={disabled}
              />
              <MetricConditionInputView
                conditionType={type}
                columnType={columnType}
                value={values}
                onChange={onValueChange.bind(null, index)}
                schemaList={schemaList}
                inColumnTableList={inColumnTableList}
                inColumnColumnList={inColumnColumnList}
                onInColumnTableChanged={onInColumnTableChanged}
                configData={configData}
                disabled={disabled}
              />
              {!disabled && (
                <FieldRowButton
                  icon={<AntdDeleteIcon />}
                  onClick={onRemove.bind(null, index)}
                />
              )}
            </FieldRow>
            {isRegexConfigured && <MetricRegexConditionHelpTip />}
          </div>
        );
      })}
      {isMultipleValueColumns && isUniqueOnlyConfigured && (
        <div className="metric-conditions-config-item-multiple-value-column-description-container">
          Your metric will be placed in the Explorer tree at the table level
        </div>
      )}
    </ConfigCard>
  );
}

const WhereClauseTitle = ({ label }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const popoverContent = (
    <div className="metric-config-where-clause-popover">
      <span className="metric-config-where-clause-popover-icon">
        <QuestionCircleOutlined />
      </span>
      <span className="metric-config-where-clause-popover-title">
        WHERE clause statements use either AND, OR or custom logic depending on the
        operation selected
      </span>

      <span
        onClick={() => setPopoverOpen(false)}
        className="metric-config-where-clause-popover-close-icon"
      >
        <CloseOutlined />
      </span>
    </div>
  );

  return (
    <div className="metric-config-where-clause-title">
      {label}
      <Popover
        trigger="click"
        content={popoverContent}
        visible={popoverOpen}
        onVisibleChange={setPopoverOpen}
      >
        <ButtonIcon icon={<QuestionCircleOutlined />} />
      </Popover>
    </div>
  );
};

function _MetricWhereConditionsSetting(props) {
  const {
    disabled = false,
    value: whereClause = null,
    columnList = [],
    schemaList = [],
    tableList = [],
    onChange,
    label = "Where clause",
    configData,
    match: {
      params: { workspaceUuid = null },
    },
    dataSourceUuid = null,
    verticalInputValue = false,
  } = props;

  const { loadTableColumns, getTableColumns } = useTableColumnCache({
    workspaceUuid,
    dataSourceUuid: dataSourceUuid ?? configData?.config?.sources?.[0],
  });

  const [logicExpressionState, setLogicExpressionState] = useState({
    value: whereClause?.clauseLogic?.toUpperCase(),
    error: null,
  });

  const columnOptions = columnList.map(({ uuid, columnName, profilerConfig }) => {
    const masked = !!profilerConfig?.masked;
    return {
      label: masked ? `${columnName} (column masked)` : columnName,
      value: uuid,
      disabled: masked,
    };
  });

  const currentClauseLogic = getCurrentClauseLogic();

  const configCardTitle =
    label === "Where clause" ? <WhereClauseTitle label={label} /> : label;

  function getCurrentClauseLogic() {
    if (!whereClause) {
      return null;
    }
    return [MetricWhereClauseType.AND, MetricWhereClauseType.OR].includes(
      whereClause.clauseLogic
    )
      ? whereClause.clauseLogic
      : MetricWhereClauseType.CUSTOM;
  }

  function defaultCustomLogicClause() {
    if (!whereClause) {
      return "1";
    }
    const prev = whereClause.clauseLogic ?? "AND";
    return whereClause.whereConditions.map((_, index) => index + 1).join(` ${prev} `);
  }

  function logicExpressionValidator(expression) {
    try {
      const expressionWithImplicitBrackets =
        addImplicitBracketsToExpression(expression);

      const symbols = new Set(getSymbols(expression));
      const operands = new Set(
        whereClause?.whereConditions.map((_, index) => (index + 1).toString())
      );
      if (!equals(symbols, operands)) {
        throw new Error(`Rules must match up with filter numbers below`);
      }
      return {
        value: expressionWithImplicitBrackets,
        error: null,
      };
    } catch (error) {
      return {
        value: null,
        error: error.toString().replace("Error: ", ""),
      };
    }
  }

  function createCondition() {
    if (columnOptions.length === 0) {
      return null;
    }

    const firstValidOption = columnOptions.filter((option) => !option.disabled);

    if (firstValidOption.length === 0) {
      console.log("Fail to find any valid option for condition column");
      return null;
    }

    const newColumn = {
      type: "column",
      columnName: firstValidOption[0].label,
      columnUuid: firstValidOption[0].value,
    };

    const currentColumn = columnList.find(({ uuid }) => newColumn.columnUuid === uuid);

    const metricConditionsOptions = getMetricConditionsOptionsFromColumnType(
      getColumnTypeCategory(currentColumn)
    );

    return {
      column: newColumn,
      conditions: [
        {
          type: metricConditionsOptions[0].value,
          values: getDefaultValueFromCondition(
            metricConditionsOptions[0].value,
            getColumnTypeCategory(currentColumn)
          ),
        },
      ],
    };
  }

  function onAdd() {
    const newCondition = createCondition();

    whereClause.whereConditions.push(newCondition);

    if (currentClauseLogic === MetricWhereClauseType.CUSTOM) {
      updateClauseLogic(logicExpressionState.value);
    }
    onChange({ ...whereClause });
  }

  function updateClauseLogic(value) {
    const { value: validatedValue, error } = logicExpressionValidator(value);
    whereClause.clauseLogic = validatedValue?.toUpperCase();
    setLogicExpressionState({ value, error });
  }

  function onClauseLogicChange(value) {
    updateClauseLogic(value);
    onChange({ ...whereClause });
  }

  function onRemove(index) {
    whereClause.whereConditions.splice(index, 1);
    if (whereClause.whereConditions.length === 0) {
      setLogicExpressionState({ value: null, error: null });
      onChange(null);
    } else {
      if (currentClauseLogic === MetricWhereClauseType.CUSTOM) {
        updateClauseLogic(logicExpressionState.value);
      }
      onChange({ ...whereClause });
    }
  }

  function onColumnChange(index, newColumnUuid) {
    const currentColumnSetting = whereClause.whereConditions[index];
    if (currentColumnSetting.columnUuid === newColumnUuid) {
      return;
    }

    const currentColumn = columnList.find(({ uuid }) => newColumnUuid === uuid);

    const newColumn = {
      type: "column",
      columnName: currentColumn.columnName,
      columnUuid: newColumnUuid,
    };

    const metricConditionsOptions = getMetricConditionsOptionsFromColumnType(
      getColumnTypeCategory(currentColumn)
    );

    const conditions = [
      {
        type: metricConditionsOptions[0].value,
        values: getDefaultValueFromCondition(
          metricConditionsOptions[0].value,
          getColumnTypeCategory(currentColumn)
        ),
      },
    ];

    whereClause.whereConditions[index] = {
      column: newColumn,
      conditions,
    };

    onChange({ ...whereClause });
  }

  function onColumnConditionTypeChange(index, conditionIndex, newType) {
    const columnConfig = whereClause.whereConditions[index];
    if (columnConfig.conditions[conditionIndex].type === newType) {
      return;
    }

    const columnUuid = columnConfig.column.columnUuid;
    const columnObject = columnList.find(
      ({ uuid: currentColumnUuid }) => currentColumnUuid === columnUuid
    );
    columnConfig.conditions[conditionIndex] = {
      type: newType,
      values: getDefaultValueFromCondition(
        newType,
        getColumnTypeCategory(columnObject)
      ),
    };

    whereClause.whereConditions[index] = columnConfig;
    onChange({ ...whereClause });
  }

  function onColumnConditionValuesChange(index, conditionIndex, newValues) {
    const columnConfig = whereClause.whereConditions[index];
    columnConfig.conditions[conditionIndex] = {
      type: columnConfig.conditions[conditionIndex].type,
      values: newValues,
    };
    onChange({ ...whereClause });
  }

  function onRuleTypeChange(newType) {
    const clauseLogic =
      newType === MetricWhereClauseType.CUSTOM ? defaultCustomLogicClause() : newType;

    if (whereClause) {
      if (newType === MetricWhereClauseType.CUSTOM) {
        updateClauseLogic(clauseLogic);
      } else {
        whereClause.clauseLogic = clauseLogic;
      }
      onChange({ ...whereClause });
    } else {
      const newCondition = createCondition();
      setLogicExpressionState({ value: clauseLogic, error: null });
      onChange({
        clauseLogic,
        whereConditions: [newCondition],
      });
    }
  }

  useEffect(() => {
    // preload table columns used in where clause conditions
    const preloadTableColumns = whereClause?.whereConditions.reduce(
      (tableUuids, { conditions }) => {
        const { type, values } = conditions[0];
        if (
          [
            ConformityConditionType.IN_COLUMN,
            ConformityConditionType.NOT_IN_COLUMN,
          ].includes(type)
        ) {
          const tableUuid = values?.[0]?.table?.tableUuid;
          if (tableUuid) {
            tableUuids.add(tableUuid);
          }
        }
        return tableUuids;
      },
      new Set()
    );

    preloadTableColumns?.forEach((tableUuid) => {
      loadTableColumns(tableUuid);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigCard
      title={configCardTitle}
      actionComponent={
        <Select
          placeholder="Add Rule"
          value={currentClauseLogic}
          options={whereClauseOptions}
          onChange={onRuleTypeChange}
          disabled={disabled || columnList.length === 0}
          className="metric-where-clause__rule-select"
          aria-label="Add Rule"
        />
      }
      className="metric-where-clause"
    >
      {Boolean(whereClause) && whereClause.whereConditions.length > 0 && (
        <div className="metric-where-clause__content">
          {MetricWhereClauseType.CUSTOM === currentClauseLogic && (
            <WhereClauseLogic
              disabled={disabled}
              value={logicExpressionState.value}
              validatedValue={whereClause.clauseLogic?.toUpperCase()}
              onChange={onClauseLogicChange}
              error={logicExpressionState.error}
            />
          )}
          <div className="metric-where-clause__content__conditions">
            {whereClause.whereConditions.map(({ column, conditions = [] }, index) => {
              const currentColumnUuid = column.columnUuid;
              const currentColumnInfo = columnList.find(
                ({ uuid }) => uuid === currentColumnUuid
              );

              const columnType = currentColumnInfo
                ? getColumnTypeCategory(currentColumnInfo)
                : TableColumnTypeCategory.STRING;
              const metricConditionsOptions = getMetricConditionsOptionsFromColumnType(
                columnType,
                { includeInColumn: true }
              );
              const { type, values } = conditions[0];
              const isRegexConfigured = [
                ConformityConditionType.MATCH_REGEX,
                ConformityConditionType.NOT_MATCH_REGEX,
              ].includes(type);

              return (
                <div key={index}>
                  <div className="metric-where-clause__content__conditions__condition">
                    {MetricWhereClauseType.CUSTOM === currentClauseLogic && (
                      <span className="metric-where-clause__content__conditions__condition__index">
                        {index + 1}
                      </span>
                    )}
                    <div className="metric-where-clause__content__conditions__condition__content">
                      <Select
                        placeholer="Column"
                        enableSorting={true}
                        value={currentColumnUuid}
                        onChange={onColumnChange.bind(null, index)}
                        options={columnOptions}
                        disabled={disabled}
                        showSearch={true}
                        className="metric-where-clause__content__conditions__condition__content__select"
                        dropdownMatchSelectWidth={false}
                      />
                      <Select
                        placeholer="Condition"
                        disabled={disabled}
                        enableSorting={true}
                        value={type}
                        onChange={onColumnConditionTypeChange.bind(null, index, 0)}
                        options={metricConditionsOptions}
                        className="metric-where-clause__content__conditions__condition__content__select"
                        dropdownMatchSelectWidth={false}
                      />
                      <div
                        className="metric-where-clause__content__conditions__condition__content__input-container"
                        style={verticalInputValue ? { flexDirection: "column" } : {}}
                      >
                        <MetricConditionInputView
                          conditionType={type}
                          columnType={columnType}
                          value={values}
                          schemaList={schemaList}
                          inColumnTableList={tableList}
                          onInColumnTableChanged={loadTableColumns}
                          inColumnColumnList={getTableColumns(
                            values?.[0]?.table?.tableUuid
                          )}
                          onChange={onColumnConditionValuesChange.bind(null, index, 0)}
                          defaultComponent={
                            <div className="metric-where-conditions-field-placeholder" />
                          }
                          configData={configData}
                          labeled={false}
                          disabled={disabled}
                          className="metric-where-clause__content__conditions__condition__content__input"
                          selectClassName="metric-where-clause__content__conditions__condition__content__select"
                        />
                      </div>
                    </div>
                    {!disabled && (
                      <ButtonIcon
                        icon={<AntdDeleteIcon />}
                        onClick={onRemove.bind(null, index)}
                      />
                    )}
                  </div>
                  {isRegexConfigured && <MetricRegexConditionHelpTip />}
                  {columnType === TableColumnTypeCategory.TIMESTAMP && (
                    <MetricTimestampConditionHelpTip />
                  )}
                </div>
              );
            })}
          </div>
          <Button
            testId="metric-config-add-where-clause"
            outline
            onClick={onAdd}
            disabled={disabled}
          >
            <PlusOutlined />
          </Button>
        </div>
      )}
    </ConfigCard>
  );
}

export const MetricWhereConditionsSetting = withRouter(_MetricWhereConditionsSetting);
