export function DataBaseIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path
        d="M7 1.8125C4.05617 1.8125 1.75 3.15781 1.75 4.875V10.125C1.75 11.8422 4.05617 13.1875 7 13.1875C9.94383 13.1875 12.25 11.8422 12.25 10.125V4.875C12.25 3.15781 9.94383 1.8125 7 1.8125ZM11.375 7.5C11.375 8.02609 10.9441 8.56258 10.1932 8.97219C9.34773 9.4332 8.21352 9.6875 7 9.6875C5.78648 9.6875 4.65227 9.4332 3.8068 8.97219C3.05594 8.56258 2.625 8.02609 2.625 7.5V6.59C3.55797 7.41031 5.1532 7.9375 7 7.9375C8.8468 7.9375 10.442 7.40812 11.375 6.59V7.5ZM3.8068 3.40281C4.65227 2.9418 5.78648 2.6875 7 2.6875C8.21352 2.6875 9.34773 2.9418 10.1932 3.40281C10.9441 3.81242 11.375 4.34891 11.375 4.875C11.375 5.40109 10.9441 5.93758 10.1932 6.34719C9.34773 6.8082 8.21352 7.0625 7 7.0625C5.78648 7.0625 4.65227 6.8082 3.8068 6.34719C3.05594 5.93758 2.625 5.40109 2.625 4.875C2.625 4.34891 3.05594 3.81242 3.8068 3.40281ZM10.1932 11.5972C9.34773 12.0582 8.21352 12.3125 7 12.3125C5.78648 12.3125 4.65227 12.0582 3.8068 11.5972C3.05594 11.1876 2.625 10.6511 2.625 10.125V9.215C3.55797 10.0353 5.1532 10.5625 7 10.5625C8.8468 10.5625 10.442 10.0331 11.375 9.215V10.125C11.375 10.6511 10.9441 11.1876 10.1932 11.5972Z"
        fill="#3F4050"
      />
    </svg>
  );
}
