import React from "react";
import { default as AntIcon } from "@ant-design/icons";
import IconMap from "./iconmap";
import { classesName } from "../../utils/css";
import "./icon.scss";
export default function Icon(props) {
  const { name, size = 16, className, ...restProps } = props;
  const icon = IconMap[name];
  return (
    <AntIcon
      component={icon}
      className={classesName("lightup-icon", className)}
      style={{ fontSize: size }}
      {...restProps}
    />
  );
}

const IconName = Object.fromEntries(Object.keys(IconMap).map((key) => [key, key]));
Object.freeze(IconName);
export { IconName };
