import React, { Component } from "react";
import { Tabs } from "antd";
import { DeleteOutlined, default as Icon } from "@ant-design/icons";
import NgButton from "../button/ng-button";
import MetricConfigBasicTabPanel from "./tabs/basic/";
import MetricConfigDataAssetTabPanel from "./tabs/data-asset/";
import MetricConfigPreviewTabPanel from "./tabs/preview/";
import MetricConfigRelatedMetricsTabPanel from "./tabs/related-metrics";
import {
  DraftState,
  DraftType,
  MetricCategory,
  MetricConfigType,
  MetricMode,
  TableType,
} from "../../utils/enums";
import { formatCustomSql } from "../../utils/general";
import { equals as setEquals } from "../../utils/set";
import {
  MetricConfigStep,
  getMetricTypeFromConfigData,
  generalDefaultMetricData,
} from "./utils";
import { getInitCallback, getValidateMetricCallback } from "./metric-utils";
import { isActivityMetric, isFullTableMetric } from "../../utils/metric";
import { AppPermissions } from "../../utils/permissions";
import { hasPermission } from "../../utils/uri-path";
import { v4 as uuidv4 } from "uuid";
import { diff as jsonDiff } from "json-diff";
import PasscodeConfirmationModal from "../passcode-confirmation-modal/passcode-confirmation-modal";
import { deepcopy } from "../../utils/objects";
import { EVENT, PAGE, getMetricDetailProps, trackEvent } from "../../utils/telemetry";
import DraftMetricSendApprovalModal from "./modals/draft-metric-send-approval-modal";
import {
  MetricDraftInfoIcon,
  MetricBasicSvgIcon,
  MetricDataAssetSvgIcon,
  MetricPreviewSvgIcon,
  MetricRelatedMetricsIcon,
  MetricDraftTrashIcon,
} from "./icons";
import DraftMetricApproveModal from "./modals/draft-metric-approve-modal";
import { ApprovedIcon, RejectedIcon } from "../../views/drafts/icons";
import NgDropdownMenu from "../ng-dropdown-menu";
import MetricConfigDraftInfoTabPanel from "./tabs/draft-info";
import { MetricDraftBanner } from "./metric-draft-banner";
import Divider from "../../atom/divider";
import "./metric.scss";

function getJsonDiffKeyList(keyList) {
  return keyList.reduce((previousValue, currentKey) => {
    previousValue.push(currentKey, `${currentKey}__added`, `${currentKey}__deleted`);

    return previousValue;
  }, []);
}

function getIsConfigDataDirty(defaultData, configData) {
  const { config, metadata } = defaultData;
  const { config: newConfig, metadata: newMetadata } = configData;

  const { isLive, ...restConfig } = config;
  const { isLive: newIsLive, ...newRestConfig } = newConfig;

  return [
    JSON.stringify(restConfig) !== JSON.stringify(newRestConfig) ||
      JSON.stringify(metadata) !== JSON.stringify(newMetadata),
    isLive !== newIsLive,
  ];
}

class MetricWizard extends Component {
  constructor(props) {
    super(props);

    const defaultData = props.defaultData || generalDefaultMetricData();
    const configData = deepcopy(defaultData);
    const canCreateDraft = Boolean(props.approvalsConfig?.canCreateDraft);
    const canApproveDraft = Boolean(props.approvalsConfig?.canApproveDraft);
    const isDraftBeingUpdated = props.isEdit && defaultData.mode === MetricMode.DRAFT;
    const hasExistingDraft =
      props.isEdit && canCreateDraft && Boolean(defaultData.metadata?.hasDraft);
    const defaultNotificationChannels = props.approvalsConfig?.alertChannels ?? [];

    if (
      canCreateDraft &&
      !configData.draftMetadata &&
      props.approvalsConfig?.alertChannels
    ) {
      configData.draftMetadata = {
        notificationChannels: props.approvalsConfig?.alertChannels,
      };
    }

    this.state = {
      isOn: true,
      activeKey: props.initialStep || MetricConfigStep.BASIC,
      defaultData,
      configData,
      validationStatus: MetricWizard.getValidationStatus(
        defaultData,
        props.dataSourceList
      ),
      confirmationDialogItems: [],
      saveInProgress: false,
      saveDraftModalVisible: false,
      sendApprovalModalVisible: false,
      approveDraftModalState: null, // null (modal closed), true (approve modal), false (approve rejection)
      isDraftBeingUpdated,
      hasExistingDraft,
      canCreateDraft,
      canApproveDraft,
      defaultNotificationChannels,
      finalStepKey: isDraftBeingUpdated
        ? MetricConfigStep.DRAFT_INFO
        : MetricConfigStep.RELATED_METRICS,
      isConfigDataDirty: false,
      isLiveChanged: false,
    };

    const normalizedMetricType = getMetricTypeFromConfigData(defaultData);
    (props.isEdit || props.isClone) &&
      getInitCallback(normalizedMetricType)?.(props, defaultData);
  }

  componentWillUnmount() {
    this.props.resetKPIStatus();
  }

  get areDraftButtonsEnabled() {
    const { isConfigDataDirty, isDraftBeingUpdated } = this.state;

    return isConfigDataDirty || isDraftBeingUpdated || this.props.isClone;
  }

  get areDraftButtonsAvailable() {
    const { canCreateDraft, finalStepKey, activeKey, isLiveChanged } = this.state;
    const isFinalStep = finalStepKey === activeKey;

    return (
      canCreateDraft && isFinalStep && (this.areDraftButtonsEnabled || !isLiveChanged)
    );
  }

  get isDrafApprovalOptionsAvailable() {
    const { canApproveDraft, finalStepKey, activeKey, isDraftBeingUpdated } =
      this.state;
    const isFinalStep = finalStepKey === activeKey;

    return canApproveDraft && isFinalStep && isDraftBeingUpdated;
  }

  onTabChange = (activeKeyStr) => {
    const activeKey = parseInt(activeKeyStr);
    this.onActiveKeyChange(activeKey);
  };

  onCancel = () => {
    const currentDataSource = this.props.dataSourceList?.find(
      (dataSource) =>
        dataSource.metadata.uuid === this.state?.configData?.config?.sources?.at(0) ||
        ""
    );

    const metricProps = getMetricDetailProps(
      this.state.configData,
      currentDataSource || {}
    );

    trackEvent(EVENT.CANCEL_METRIC_CREATION, {
      ...metricProps,
      page: PAGE.METRIC_DETAIL,
    });

    this.props.onCancel();
  };

  setOpenSaveDraft = (value) => {
    this.setState({ saveDraftModalVisible: value });
  };

  setOpenSendApproval = (value) => {
    this.setState({ sendApprovalModalVisible: value });
  };

  setOpenApproveModalWithState = (isApproving) => {
    this.setState({ approveDraftModalState: isApproving });
  };

  onNext = () => {
    const { activeKey, finalStepKey } = this.state;

    const currentDataSource = this.props.dataSourceList?.find(
      (dataSource) =>
        dataSource.metadata.uuid === this.state?.configData?.config?.sources?.at(0) ||
        ""
    );

    const metricProps = getMetricDetailProps(
      this.state.configData,
      currentDataSource || {}
    );

    if (activeKey === MetricConfigStep.BASIC) {
      trackEvent(EVENT.METRIC_INFO_FINISH, {
        ...metricProps,
        page: PAGE.METRIC_DETAIL,
      });
    }

    if (activeKey === MetricConfigStep.DATA_ASSET) {
      trackEvent(EVENT.METRIC_FINISH_CONFIGURE_METRIC, {
        ...metricProps,
        page: PAGE.METRIC_DETAIL,
      });
    }

    if (activeKey === MetricConfigStep.PREVIEW) {
      trackEvent(EVENT.METRIC_FINISH_PREVIEW, {
        ...metricProps,
        page: PAGE.METRIC_DETAIL,
      });
    }

    if (this.areDraftButtonsAvailable) {
      this.setOpenSendApproval(true);
      return;
    }

    if (activeKey === finalStepKey) {
      trackEvent(EVENT.SAVE_METRIC, { ...metricProps, page: PAGE.METRIC_DETAIL });

      this.onSave();
      return;
    }

    this.onActiveKeyChange(activeKey + 1);
    // Clear focus on the "Next" button between tabs.
    document.activeElement.blur();
  };

  isAttributeChangeOnly() {
    const newMetric = this.getNewMetric();
    const oldMetric = this.state.defaultData;

    const isFullTable = isFullTableMetric(oldMetric);
    const isIncremental =
      oldMetric.config.configType === MetricConfigType.METRIC_CONFIG;
    const isNewMetricIncremental =
      newMetric.config.configType === MetricConfigType.METRIC_CONFIG;
    const isCustomSql = newMetric.config.table?.type === TableType.CUSTOM_SQL;

    let isBackfillNeeded = false;

    // Name and dimension won't affect metric recreation
    let isAttributeOnlyUpdated =
      oldMetric.metadata.name !== newMetric.metadata.name ||
      oldMetric.metadata.description !== newMetric.metadata.description ||
      !setEquals(new Set(oldMetric.metadata.tags), new Set(newMetric.metadata.tags)) ||
      oldMetric.config.failingRecordsSql !== newMetric.config.failingRecordsSql ||
      oldMetric.config.backfillDuration !== newMetric.config.backfillDuration ||
      oldMetric.config.dimension !== newMetric.config.dimension ||
      oldMetric.config.isLive !== newMetric.config.isLive ||
      oldMetric.config.collectionMode !== newMetric.config.collectionMode ||
      !setEquals(
        new Set(oldMetric.config.relatedMetrics),
        new Set(newMetric.config.relatedMetrics)
      );

    const diffObject = jsonDiff(newMetric.config, oldMetric.config) || {};
    if (diffObject) {
      const ignoreKeysList = [
        "dimension",
        "isLive",
        "failingRecordsSql",
        "relatedMetrics",
      ];
      if (!isFullTable) {
        ignoreKeysList.push("collectionMode");
      }
      getJsonDiffKeyList(ignoreKeysList).forEach((currentKey) => {
        if (diffObject.hasOwnProperty(currentKey)) {
          delete diffObject[currentKey];
        }
      });

      if (
        diffObject.hasOwnProperty("backfillDuration") &&
        newMetric.config.backfillDuration <= oldMetric.config.backfillDuration
      ) {
        delete diffObject.backfillDuration;
      }
    }

    if (isCustomSql) {
      const ignoreKeySet = new Set(
        getJsonDiffKeyList([
          "schemaName",
          "tableUuid",
          "tableName",
          "columnName",
          "columnUuid",
        ])
      );

      if (diffObject?.table) {
        for (let currentKey in diffObject.table) {
          if (ignoreKeySet.has(currentKey)) {
            isAttributeOnlyUpdated = true;
            delete diffObject.table[currentKey];
          }
        }
      }

      if (diffObject?.table && Object.keys(diffObject.table).length === 0) {
        delete diffObject.table;
      }

      if (isIncremental && isNewMetricIncremental) {
        isBackfillNeeded =
          oldMetric.config.backfillDuration < newMetric.config.backfillDuration;
      }
    }

    const isConfigUpdated = Object.keys(diffObject).length !== 0;

    const isMissingFillingValueChanging =
      newMetric.config.missingValueFilling !== oldMetric.config.missingValueFilling;

    return {
      isSame: !isAttributeOnlyUpdated && !isConfigUpdated && !isBackfillNeeded,
      isAttributeOnlyUpdated:
        isAttributeOnlyUpdated && !isConfigUpdated && !isBackfillNeeded,
      isMissingFillingValueChanging,
    };
  }

  onSave = () => {
    if (!this.props.isEdit) {
      this.runSave();
    } else {
      const { isSame, isAttributeOnlyUpdated, isMissingFillingValueChanging } =
        this.isAttributeChangeOnly();
      const numMetricMonitors = this.props.ruleList.filter(
        (monitor) => monitor.config.metrics[0] === this.state.configData.metadata.uuid
      ).length;

      const confirmationDialogItems = [];

      if (!isAttributeOnlyUpdated && !isSame && numMetricMonitors > 0) {
        confirmationDialogItems.push(
          `Changing this metric will delete ${numMetricMonitors} monitors.`
        );
      }

      if (isMissingFillingValueChanging) {
        confirmationDialogItems.push(
          "Changing the Missing Value Filling will reset the metric and initiate a backfill."
        );
      }

      if (confirmationDialogItems.length > 0) {
        this.setState({
          confirmationDialogItems,
        });
      } else {
        this.runSave(false, isSame);
      }
    }
  };

  runSave = (isForce = false, isSame = false) => {
    const newMetric = this.getNewMetric();
    if (getMetricTypeFromConfigData(newMetric) === MetricCategory.CUSTOM_SQL) {
      newMetric.config.table.sql = formatCustomSql(newMetric.config.table.sql);
    }

    this.setState({ saveInProgress: true });
    this.props.onSave(newMetric, [], { isForce, isSame }).finally(() => {
      this.setState({ saveInProgress: false });
    });
  };

  onSaveDraftMetric = (draftMetric, sendForApproval) => {
    const { isDraftBeingUpdated, isLiveChanged } = this.state;
    const newDraftMetric = this.getNewDraftMetric(draftMetric, sendForApproval);
    if (getMetricTypeFromConfigData(newDraftMetric) === MetricCategory.CUSTOM_SQL) {
      newDraftMetric.config.table.sql = formatCustomSql(
        newDraftMetric.config.table.sql
      );
    }

    if (!isDraftBeingUpdated && isLiveChanged && this.props.isEdit) {
      // Silently update the live status metric
      const updateLiveStatusMetric = deepcopy(this.state.defaultData);
      updateLiveStatusMetric.config.isLive = newDraftMetric.config.isLive;
      this.props.onSave(updateLiveStatusMetric, [], {
        isForce: true,
        isSame: false,
        silently: true,
      });
    }

    this.setState({ saveInProgress: true });

    const savePromise = isDraftBeingUpdated
      ? this.props.onSave(newDraftMetric, [], {
          isForce: true,
          isSame: false,
          isDraft: true,
        })
      : this.props.onCreateDraft(newDraftMetric, []);

    savePromise.finally(() => {
      this.setState({ saveInProgress: false });
    });
  };

  onApproveRejectDraftMetric = (draftMetricToApproveOrReject) => {
    this.setState({ saveInProgress: true });
    this.props
      .onSave(draftMetricToApproveOrReject, [], {
        isForce: true,
        isSame: false,
        isDraft: true,
      })
      .finally(() => {
        this.setState({ saveInProgress: false });
      });
  };

  onDeleteDraftMetric = () => {
    const draftMetricToDelete = this.getNewMetric();
    this.setState({ saveInProgress: true });
    this.props.onDeleteDraft(draftMetricToDelete).finally(() => {
      this.setState({ saveInProgress: false });
      this.props.onCancel();
    });
  };

  getNewMetric() {
    const currentMetric = deepcopy(this.state.configData);
    if (
      currentMetric.draftMetadata &&
      (!currentMetric.draftMetadata?.type || !currentMetric.draftMetadata?.state)
    ) {
      // This prevents sending incomplete draft metadata. This can happen because we populate
      // the default notificationChannels at the beginning, but the rest of the draftMetadata
      // fields are only populated when saving, which can result in incomplete draftMetadata.
      delete currentMetric.draftMetadata;
    }

    return currentMetric;
  }

  getNewDraftMetric(draftMetric, sendforApproval) {
    const { isEdit } = this.props;
    const { isDraftBeingUpdated } = this.state;
    const currentMetric = deepcopy(draftMetric);

    if (isDraftBeingUpdated) {
      // update existing draft metric
      sendforApproval &&
        (currentMetric.draftMetadata.state = DraftState.APPROVAL_PENDING);
      return currentMetric;
    }

    if (isEdit) {
      // create new update draft metric
      currentMetric.draftMetadata = {
        ...currentMetric.draftMetadata,
        state: sendforApproval ? DraftState.APPROVAL_PENDING : DraftState.DRAFT,
        type: DraftType.UPDATE,
        targetUuid: draftMetric.metadata.uuid,
      };
      currentMetric.metadata.uuid = null;
    } else {
      // create new new draft metric
      currentMetric.draftMetadata = {
        ...currentMetric.draftMetadata,
        state: sendforApproval ? DraftState.APPROVAL_PENDING : DraftState.DRAFT,
        type: DraftType.NEW,
      };
    }

    currentMetric.mode = MetricMode.DRAFT;

    return currentMetric;
  }

  onPreview = (previewPeriod) => {
    const currentMetric = this.getNewMetric();
    const { previewStartTs, previewEndTs } = previewPeriod;
    const lastPreviewObject = {
      metric: currentMetric,
      previewUuid: uuidv4(),
      previewStartTs,
      previewEndTs,
      abortController: new AbortController(),
    };
    this.lastPreviewObject = lastPreviewObject;
    this.props.previewKpi(lastPreviewObject);

    const currentDataSource = this.props.dataSourceList?.find(
      (dataSource) =>
        dataSource.metadata.uuid === currentMetric.config?.sources?.at(0) || ""
    );

    const metricProps = getMetricDetailProps(
      this.state.configData,
      currentDataSource || {}
    );

    trackEvent(EVENT.METRIC_PREVIEW, {
      ...metricProps,
      page: PAGE.METRIC_DETAIL,
    });
  };

  onTerminatePreview = () => {
    const currentDataSource = this.props.dataSourceList?.find(
      (dataSource) =>
        dataSource.metadata.uuid === this.state?.configData?.config?.sources?.at(0) ||
        ""
    );

    const metricProps = getMetricDetailProps(
      this.state.configData,
      currentDataSource || {}
    );

    trackEvent(EVENT.FINISH_METRIC_PREVIEW, {
      ...metricProps,
      page: PAGE.METRIC_DETAIL,
    });

    const lastPreviewObject = this.lastPreviewObject;
    if (!lastPreviewObject) {
      console.log("There is no last preview object.");
      return;
    }
    this.props.terminateKpiPreview(lastPreviewObject);
  };

  onActiveKeyChange = (activeKey) => {
    this.setState({ activeKey });
  };

  onConfigDataChange = (newConfigData) => {
    const { configData, defaultData } = this.state;
    if (!newConfigData.draftMetadata && configData.draftMetadata) {
      // populate draftMetadata if required, this is necessary because when changing
      // metric type there is a new default config that does not include draftMetadata
      newConfigData.draftMetadata = configData.draftMetadata;
    }

    const [isConfigDataDirty, isLiveChanged] = getIsConfigDataDirty(
      defaultData,
      newConfigData
    );

    this.setState({
      configData: newConfigData,
      validationStatus: MetricWizard.getValidationStatus(
        newConfigData,
        this.props.dataSourceList
      ),
      isConfigDataDirty,
      isLiveChanged,
    });
  };

  /* Slice value selection is disabled for now.
  updateSliceColumnValueList = () => {
    const currentMetric = this.getNewMetric();
    this.props.onTableColumnChanged(currentMetric);
  }; */

  getConfirmationModalContent = () => {
    const { confirmationDialogItems } = this.state;

    if (confirmationDialogItems.length === 1) {
      return confirmationDialogItems[0];
    }

    return (
      <ul className="dialog-list">
        {confirmationDialogItems.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    );
  };

  static getValidationStatus(configData, dataSourceList) {
    const normalizedMetricType = getMetricTypeFromConfigData(configData);
    const validateMetricCallback = getValidateMetricCallback(normalizedMetricType);
    const status = validateMetricCallback
      ? validateMetricCallback(configData, dataSourceList)
      : {
          [MetricConfigStep.BASIC]: false,
          [MetricConfigStep.DATA_ASSET]: false,
        };
    status[MetricConfigStep.PREVIEW] = true;
    status[MetricConfigStep.RELATED_METRICS] = true;
    return status;
  }

  render() {
    const {
      activeKey,
      configData,
      validationStatus,
      confirmationDialogItems,
      saveDraftModalVisible,
      sendApprovalModalVisible,
      approveDraftModalState,
      saveInProgress,
      isDraftBeingUpdated,
      hasExistingDraft,
      defaultNotificationChannels,
      finalStepKey,
      isConfigDataDirty,
      isLiveChanged,
    } = this.state;
    const {
      dataSourceList,
      kpiList,
      disabled = false,
      currentSrcSchemaList,
      currentSchemaList,
      currentSrcTableList,
      currentTableList,
      currentSrcColumnList,
      currentInColumnColumnList,
      currentColumnList,
      currentCustomSqlColumnList,
      tagList,
      lastPreviewResult,
      lastPreviewStatus,
      isEdit,
      currentKpiQueryList,
      getCurrentKpiQueryList,
      getKpiList,
      onDataSourceChanged,
      onSchemaChanged,
      onSrcDataSourceChanged,
      onTableChanged,
      onSrcTableChanged,
      onCustomSqlTableChanged,
      onInColumnTableChanged,
      currentColumnValueList,
      currentKpiPartitionSampleData,
      getCurrentKpiPartitionSampleData,
      currentKpiSrcPartitionSampleData,
      getCurrentKpiSrcPartitionSampleData,
      currentKpiSampleDataTableSchemaList,
      getCurrentKpiSampleDataTableSchemaList,
      currentKpiSampleDataSampleDataList,
      getCurrentKpiSampleDataSampleDataList,
      openWorkspaceTakeover,
      verifyKpiCustomSql,
      currentKpiValidateFailingRecordsSqlResult,
      validateKpiFailingRecordsSql,
      closeTakeover,
      waffle,
      workspaceUserPermissions = { isSuperuser: true, permissions: [] },
      currentTime = new Date(),
      resetKPIStatus,
      integrationList,
      metricCategoryWhiteList = null, // if the whitelist is null everything is allowed
    } = this.props;

    const enableModifyMetric = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
    ]);
    const enablePreviewMetric = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMPREVIEW,
    ]);
    const enableViewSampleData = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_TABLESAMPLESPREVIEW,
    ]);
    const isFinalStep = finalStepKey === activeKey;

    const commonTabProps = {
      isEdit,
      disabled,
      configData,
      kpiList,
      dataSourceList,
      schemaList: currentSchemaList,
      tableList: currentTableList,
      columnList: currentColumnList,
      columnValueList: currentColumnValueList,
      srcColumnList: currentSrcColumnList,
      srcPartitionSampleData: currentKpiSrcPartitionSampleData,
      srcSchemaList: currentSrcSchemaList,
      srcTableList: currentSrcTableList,
      customSqlColumnList: currentCustomSqlColumnList,
      inColumnColumnList: currentInColumnColumnList,
      tagList: tagList,
      currentKpiSampleDataSampleDataList,
      currentKpiSampleDataTableSchemaList,
      currentKpiQueryList,
      partitionSampleData: currentKpiPartitionSampleData,
      enableViewSampleData,
      currentTime,
      openWorkspaceTakeover,
      closeTakeover,
      onStepChange: this.onActiveKeyChange,
      onConfigDataChange: this.onConfigDataChange,
      getKpiList,
      resetKPIStatus,
      waffle,
    };

    const configTabs = [
      {
        label: "step 1",
        description: "Metric Info",
        key: MetricConfigStep.BASIC,
        disabled: false,
        content: (
          <MetricConfigBasicTabPanel
            step={MetricConfigStep.BASIC}
            metricCategoryWhiteList={metricCategoryWhiteList}
            {...commonTabProps}
          />
        ),
        icon: <Icon component={MetricBasicSvgIcon} name="metricBasic" />,
      },
      {
        label: "step 2",
        description: "Configure Metric",
        key: MetricConfigStep.DATA_ASSET,
        disabled: !validationStatus[MetricConfigStep.BASIC],
        content: (
          <MetricConfigDataAssetTabPanel
            step={MetricConfigStep.DATA_ASSET}
            isValidConfig={validationStatus[MetricConfigStep.DATA_ASSET]}
            getCurrentKpiSampleDataSampleDataList={
              getCurrentKpiSampleDataSampleDataList
            }
            getCurrentKpiSampleDataTableSchemaList={
              getCurrentKpiSampleDataTableSchemaList
            }
            getPartitionSampleData={getCurrentKpiPartitionSampleData}
            getSrcPartitionSampleData={getCurrentKpiSrcPartitionSampleData}
            onCustomSqlTableChanged={onCustomSqlTableChanged}
            onDataSourceChanged={onDataSourceChanged}
            onSchemaChanged={onSchemaChanged}
            onSrcDataSourceChanged={onSrcDataSourceChanged}
            onSrcTableChanged={onSrcTableChanged}
            onInColumnTableChanged={onInColumnTableChanged}
            onTableChanged={onTableChanged}
            verifyKpiCustomSql={verifyKpiCustomSql}
            validateFailingRecordsSqlResult={currentKpiValidateFailingRecordsSqlResult}
            validateKpiFailingRecordsSql={validateKpiFailingRecordsSql}
            {...commonTabProps}
          />
        ),
        icon: <Icon component={MetricDataAssetSvgIcon} name="metricDataAsset" />,
      },
      {
        label: "step 3",
        description: "Preview",
        key: MetricConfigStep.PREVIEW,
        disabled:
          !validationStatus[MetricConfigStep.BASIC] ||
          !validationStatus[MetricConfigStep.DATA_ASSET],
        content: (
          <MetricConfigPreviewTabPanel
            step={MetricConfigStep.PREVIEW}
            enablePreviewMetric={enablePreviewMetric}
            getCurrentKpiQueryList={getCurrentKpiQueryList}
            getCurrentKpiSampleDataSampleDataList={
              getCurrentKpiSampleDataSampleDataList
            }
            getCurrentKpiSampleDataTableSchemaList={
              getCurrentKpiSampleDataTableSchemaList
            }
            onPreview={this.onPreview}
            onTerminatePreview={this.onTerminatePreview}
            previewResult={lastPreviewResult}
            previewStatus={lastPreviewStatus}
            {...commonTabProps}
          />
        ),
        icon: <Icon component={MetricPreviewSvgIcon} name="metricPreview" />,
      },
      {
        label: "step 4 (optional)",
        description: "Related metrics",
        key: MetricConfigStep.RELATED_METRICS,
        disabled:
          !validationStatus[MetricConfigStep.BASIC] ||
          !validationStatus[MetricConfigStep.DATA_ASSET],
        content: (
          <MetricConfigRelatedMetricsTabPanel
            step={MetricConfigStep.RELATED_METRICS}
            {...commonTabProps}
          />
        ),
        icon: <Icon component={MetricRelatedMetricsIcon} name="relatedMetrics" />,
      },
    ];

    if (isDraftBeingUpdated) {
      configTabs.push({
        label: "step 5",
        description: "Draft info",
        key: MetricConfigStep.DRAFT_INFO,
        disabled:
          !validationStatus[MetricConfigStep.BASIC] ||
          !validationStatus[MetricConfigStep.DATA_ASSET],
        content: (
          <MetricConfigDraftInfoTabPanel
            step={MetricConfigStep.DRAFT_INFO}
            integrationList={integrationList}
            {...commonTabProps}
            defaultNotificationChannels={defaultNotificationChannels}
            disabled={false} // draft info tab should keep enabled
          />
        ),
        icon: <Icon component={MetricDraftInfoIcon} name="relatedMetrics" />,
      });
    }

    configTabs.forEach((currentConfigTab) => {
      currentConfigTab.className = currentConfigTab.key < activeKey ? "done" : "";
    });

    const getButtonText = () => {
      if (this.areDraftButtonsAvailable) {
        return "Send for Approval";
      }
      if (isFinalStep) {
        return isEdit ? "Save" : "Create";
      }
      return "Next";
    };

    const doneButtonDisabled = isFinalStep
      ? !enableModifyMetric ||
        this.state.saveInProgress ||
        isActivityMetric(configData) ||
        (!this.areDraftButtonsEnabled && !isLiveChanged)
      : !validationStatus[activeKey];

    const isSaveDraftButtonVisible =
      this.areDraftButtonsAvailable || this.isDrafApprovalOptionsAvailable;

    const approverAction = [
      {
        label: "Approve and Publish",
        icon: <ApprovedIcon width={14} height={14} />,
        onClick: () => this.setOpenApproveModalWithState(true),
      },
      {
        label: "Reject",
        icon: <RejectedIcon width={14} height={14} />,
        onClick: () => this.setOpenApproveModalWithState(false),
      },
      {
        label: "Delete Draft",
        icon: <DeleteOutlined />,
        onClick: () => {
          this.onDeleteDraftMetric();
        },
        danger: true,
      },
    ];

    const operations = (
      <div className="metric-tab-action-container">
        {isSaveDraftButtonVisible && (
          <>
            <NgButton
              outline
              size="large"
              onClick={() => this.setOpenSaveDraft(true)}
              disabled={!this.areDraftButtonsEnabled}
            >
              Save Draft
            </NgButton>
            <Divider type="vertical" style={{ height: 36, margin: "4px 12px" }} />
          </>
        )}
        <NgButton outline size="large" onClick={this.onCancel}>
          Cancel
        </NgButton>
        {this.isDrafApprovalOptionsAvailable ? (
          <NgDropdownMenu
            trigger={
              <NgButton size="large" outline dropdown>
                Approver Actions
              </NgButton>
            }
            menuItems={approverAction}
            position="bottomRight"
          />
        ) : (
          <NgButton
            primary
            size="large"
            onClick={this.onNext}
            testId="metric-config-next"
            disabled={doneButtonDisabled}
          >
            {getButtonText()}
          </NgButton>
        )}
      </div>
    );

    const isOnlyUpdatingLiveStatus = isLiveChanged && !isConfigDataDirty;

    return (
      <div className="metric-wizard-container">
        <div className="metric-wizard-form-container">
          {hasExistingDraft && (
            <MetricDraftBanner
              icon={<MetricDraftInfoIcon fill="white" width={20} height={20} />}
              message="Another draft of this metric already exists."
              onCancel={this.onCancel}
              onViewDraft={() =>
                this.props.navigateToMetric?.(configData.metadata?.draftUuid)
              }
            />
          )}
          {disabled && (
            <MetricDraftBanner
              icon={<MetricDraftTrashIcon fill="white" width={20} height={20} />}
              message="This draft has been marked for deletion, fields can not be edited."
            />
          )}
          <Tabs
            className="lightup"
            tabBarExtraContent={
              (!hasExistingDraft || isOnlyUpdatingLiveStatus) && operations
            }
            activeKey={`${activeKey}`}
            onChange={this.onTabChange}
            size={"large"}
          >
            {configTabs.map(
              ({ label, description, key, icon, content, className, disabled }) => {
                return (
                  <Tabs.TabPane
                    tab={
                      <span className={`metric-tab-header-container ${className}`}>
                        {icon}
                        <span>
                          <div className="metric-tab-header-label-container">
                            {label}
                          </div>
                          <div className="metric-tab-header-description-container">
                            {description}
                          </div>
                        </span>
                      </span>
                    }
                    key={`${key}`}
                    className={className}
                    disabled={disabled}
                  >
                    {content}
                  </Tabs.TabPane>
                );
              }
            )}
          </Tabs>
        </div>
        {isEdit && (
          <PasscodeConfirmationModal
            title="Confirm save?"
            visible={confirmationDialogItems.length > 0}
            okText="OK"
            cancelText="Cancel"
            onConfirm={() => {
              this.setState({
                confirmationDialogItems: [],
              });
              this.runSave(true);
            }}
            onCancel={() => {
              this.setState({
                confirmationDialogItems: [],
              });
            }}
          >
            {this.getConfirmationModalContent()}
          </PasscodeConfirmationModal>
        )}
        {saveDraftModalVisible && (
          <DraftMetricSendApprovalModal
            title="Save Metric Draft"
            visible={saveDraftModalVisible}
            configData={configData}
            onConfirm={(draftMetric) => this.onSaveDraftMetric(draftMetric, false)}
            onCancel={() => this.setOpenSaveDraft(false)}
            integrationList={integrationList}
            tagList={tagList}
            isInProgress={saveInProgress}
            defaultNotificationChannels={defaultNotificationChannels}
          />
        )}
        {sendApprovalModalVisible && (
          <DraftMetricSendApprovalModal
            visible={sendApprovalModalVisible}
            configData={configData}
            onConfirm={(draftMetric) => this.onSaveDraftMetric(draftMetric, true)}
            onCancel={() => this.setOpenSendApproval(false)}
            integrationList={integrationList}
            tagList={tagList}
            isInProgress={saveInProgress}
            defaultNotificationChannels={defaultNotificationChannels}
          />
        )}
        {approveDraftModalState !== null && (
          <DraftMetricApproveModal
            visible={approveDraftModalState !== null}
            configData={configData}
            onConfirm={this.onApproveRejectDraftMetric}
            onCancel={() => this.setOpenApproveModalWithState(null)}
            isInProgress={saveInProgress}
            isApproving={approveDraftModalState}
          />
        )}
      </div>
    );
  }
}

export default MetricWizard;
