import React from "react";
import Tooltip from "../tooltip/ng-tooltip";
import { NgTextTooltip } from "../text-tooltip/ng-text-tooltip";
import { classesName } from "../../utils/css";
import { WorkspaceItemKeyToIconMapper } from "./util";

import "./sidebar-menu-item-node.scss";

function SidebarMenuItemNode(props) {
  let {
    label,
    type,
    onClick,
    iconComponent: IconComponent,
    currentSelectedKey,
    isExpanded = true,
  } = props;

  if (!IconComponent) {
    IconComponent = WorkspaceItemKeyToIconMapper[type];
  }
  const isCurrentNodeSelected = type === currentSelectedKey;
  function onSidebarMenuItemNodeClick() {
    onClick?.(type);
  }

  let iconElement;
  if (isExpanded) {
    iconElement = <IconComponent />;
  } else {
    iconElement = (
      <Tooltip title={label} placement="right" trigger="hover">
        <div className="sidebar-menu-item-icon-tooltip-container">
          <IconComponent />
        </div>
      </Tooltip>
    );
  }

  const className = classesName(
    "sidebar-menu-item-node-container",
    isCurrentNodeSelected ? "selected" : "not-selected",
    isExpanded ? "expanded" : "collapsed"
  );

  return (
    <div className={className} onClick={onSidebarMenuItemNodeClick}>
      {iconElement}
      {isExpanded && (
        <span className="sidebar-menu-item-node-label-container">
          <NgTextTooltip>{label}</NgTextTooltip>
        </span>
      )}
    </div>
  );
}

export default SidebarMenuItemNode;
