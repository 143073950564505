import {
  AutoMetricsType,
  FeatureTypeConst,
  IncidentCreatorType,
  isFileSource,
  MetricConfigType,
} from "../../utils/enums";
import { indexBy } from "../../utils/iterables";
import { highlightQueryString } from "../../utils/search";
import { sliceLabel } from "./incident-config";

export const IncidentMainTabKey = Object.freeze({
  CHART: "chart",
  SUMMARY: "summary",
  SAMPLE_DATA: "sampleData",
  ACTIVITY: "activity",
});

// React key for identifying a chart in a list.
export function getKeyForChart(metricUuid, slice) {
  return `${metricUuid}${JSON.stringify(slice || {})}`;
}

// Creates a map of metricUuid -> Set(sliceLabel) for all metrics in the list (a sliceColl).
// Non-sliced metrics have their value in the map represented as an empty set.
// Slice labels can be excluded from the set by passing a predicate function
// of the form (metric, slice) => boolean that returns true for slices to include.
export function metricSliceColl(metrics, pred = (_metric, _slice) => true) {
  const coll = {};
  for (let metric of metrics) {
    if (metric.sliceList.length > 0) {
      coll[metric.metricUuid] = new Set(
        metric.sliceList
          .filter((slice) => pred(metric, slice))
          .map((slice) => sliceLabel(slice.slice))
      );
    } else if (pred(metric, null)) {
      coll[metric.metricUuid] = new Set();
    }
  }
  return coll;
}

// Is a given metric (and optionally a slice) in a sliceColl?
export function metricSliceCollHas(coll, metricUuid, slice = null) {
  return slice
    ? coll[metricUuid]?.has(sliceLabel(slice.slice))
    : coll.hasOwnProperty(metricUuid);
}

// Does a given metric, and all of its slices if sliced, exist in a sliceColl?
export function metricSliceCollHasAll(coll, metricUuid, sliceList) {
  return sliceList.length > 0
    ? sliceList.every((slice) => metricSliceCollHas(coll, metricUuid, slice))
    : metricSliceCollHas(coll, metricUuid);
}

const queryHighlightClassName = "incident-analysis-sidebar-query-highlight";

export function filterAndHighlightMetricSlices(slices, query) {
  const filteredSlices = [];
  for (let slice of slices) {
    const { match: sliceNameMatch, highlighted: sliceNameHighlighted } =
      highlightQueryString(query, sliceLabel(slice.slice), queryHighlightClassName);
    if (sliceNameMatch) {
      filteredSlices.push({
        ...slice,
        labelHighlighted: sliceNameHighlighted,
      });
    }
  }
  return filteredSlices;
}

export function filterAndHighlightMetrics(metrics, query) {
  const filteredMetrics = [];
  for (let metric of metrics) {
    const { match: metricNameMatch, highlighted: metricNameHighlighted } =
      highlightQueryString(query, metric.metricName, queryHighlightClassName);
    const filteredSlices = filterAndHighlightMetricSlices(
      metric.sliceList ?? [],
      query
    );
    if (metricNameMatch || filteredSlices.length > 0) {
      filteredMetrics.push({
        ...metric,
        nameHighlighted: metricNameHighlighted,
        sliceList: filteredSlices,
      });
    }
  }
  return filteredMetrics;
}

// Transforms data used in the UDIA sidebar into more useful structures:
// - index metrics by UUID
// - index data sources by UUID
// - index monitors by metric UUID, including only metrics in metricUuids
// Note that metrics is a full list of workspace metrics, while metricUuids is a list
// of the metric UUIDs we are looking at in the sidebar.
export function metricItemsContext({ metricUuids, dataSources, metrics, monitors }) {
  const dataSourcesByUuid = indexBy(
    dataSources,
    (dataSource) => dataSource.metadata.uuid
  );
  const metricsByUuid = indexBy(metrics, (metric) => metric.metadata.uuid);
  const monitorsByMetricUuid = {};
  for (let monitor of monitors) {
    for (let metricUuid of monitor.config.metrics) {
      if (metricUuids.has(metricUuid)) {
        if (!monitorsByMetricUuid[metricUuid]) {
          monitorsByMetricUuid[metricUuid] = [];
        }
        monitorsByMetricUuid[metricUuid].push(monitor);
      }
    }
  }
  return {
    metricsByUuid,
    dataSourcesByUuid,
    monitorsByMetricUuid,
  };
}

const FILE_NAME_SEPERATOR = "_";
export function generateFailedRecordsCSVName({ metricName, metricId, incidentId }) {
  // Filename format should be metric_name_metric_uuid_incident_id
  return [metricName, metricId, incidentId].join(FILE_NAME_SEPERATOR);
}

// Need to move this logic to BE later
export function checkIncidentValidationAvailability(
  incidentCreatorInfo,
  autoMetricType
) {
  let isValidationDisabled = false;
  let validationDisabledReason = "";
  const metricInfo = incidentCreatorInfo.kpiInfo;
  const datasourceInfo = incidentCreatorInfo.dataSourceInfo;
  if (
    [
      AutoMetricsType.TABLE_ACTIVITY,
      AutoMetricsType.COLUMN_ACTIVITY,
      AutoMetricsType.CATEGORY_ACTIVITY,
    ].includes(autoMetricType)
  ) {
    isValidationDisabled = true;
    validationDisabledReason = "Validation is not supported on events.";
  } else if (autoMetricType === AutoMetricsType.DATA_DELAY) {
    isValidationDisabled = true;
    validationDisabledReason = "Validation is not supported on data delay metric.";
  } else if (autoMetricType === AutoMetricsType.AGGREGATION_COMPARE) {
    isValidationDisabled = true;
    validationDisabledReason =
      "Validation is not supported on compare aggregate metric.";
  } else if (autoMetricType === AutoMetricsType.FULL_COMPARE) {
    isValidationDisabled = true;
    validationDisabledReason =
      "Validation is not supported on row by row compare metric.";
  } else if (autoMetricType.has && autoMetricType.has(autoMetricType)) {
    isValidationDisabled = true;
    validationDisabledReason = "Validation is not supported on metadata metric.";
  } else if (isFileSource(datasourceInfo?.config?.connection?.type)) {
    isValidationDisabled = true;
    validationDisabledReason = "Validation is not supported on file based datasources.";
  } else if (incidentCreatorInfo.type === IncidentCreatorType.FILTER) {
    const isMetricFeatureTypeIsNotValue =
      incidentCreatorInfo.filterInfo.config.symptom.featureConfig.type !==
      FeatureTypeConst.VALUE;

    if (
      metricInfo.config.configType === MetricConfigType.FULL_TABLE_METRIC_CONFIG &&
      isMetricFeatureTypeIsNotValue
    ) {
      isValidationDisabled = true;
      validationDisabledReason = "Validation is not supported in full table metrics";
    }
  }

  return { isValidationDisabled, validationDisabledReason };
}
