import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import sqlFormatter from "sql-formatter";

import NgTable, { NgTableEmptyState } from "../../../../components/table/ng-table";
import { QueryHistoryIcon } from "../../../../components/metric/icons";
import Button from "../../../../components/button/ng-button";
import { IncidentCreatorType, MetricCategory } from "../../../../utils/enums";
import { getMetricTypeFromConfigData } from "../../../../components/metric/utils";
import {
  cancelIncidentSampleDataRequest,
  getIncidentSampleData,
  terminateIncidentSampleDataQuery,
} from "../../../../actions/incident/incident-action";
import { LabeledInputNumber } from "../../../../components/labeled-control/labeled-control";
import * as csv from "../../../../utils/csv";
import { DownloadIcon, PlayIcon } from "../../icons";
import { DataTypes, detectDataTypes } from "../../../../utils/data-types";
import { getStringFromTimeStamp } from "../../../../utils/time";
import { NgTextTooltip } from "../../../../components/text-tooltip/ng-text-tooltip";
import { Spinner } from "../../../../atom/spinner";
import TextArea from "../../../../atom/textarea";
import { generateFailedRecordsCSVName } from "../../utils";

// Css imports
import "./index.scss";

function IncidentSampleDataLoading(props) {
  const { onCancelClick, terminationSupported } = props;

  return (
    <div className="incident-sample-data-loading">
      <Spinner size="large" />
      <div className="incident-sample-data-loading-title">
        Loading failing records...
      </div>
      {terminationSupported && (
        <Button primary size="large" onClick={onCancelClick}>
          Cancel
        </Button>
      )}
    </div>
  );
}

function getSorter(dataType, key) {
  if (dataType === DataTypes.NUMBER) {
    return (objA, objB) => (objA[key] ?? 0) - (objB[key] ?? 0);
  }
  if (dataType === DataTypes.DATETIME) {
    return (objA, objB) => new Date(objA[key] ?? 0) - new Date(objB[key] ?? 0);
  }
  if (dataType === DataTypes.STRING) {
    return (objA, objB) => (objA[key] ?? "").localeCompare(objB[key] ?? "");
  }
  return false;
}

const maxSampleDataRows = 100;
const minQueryWindowHeight = 150;
const defaultLimitValue = 100;

function IncidentSampleDataContent(props) {
  const {
    incidentCreatorInfo,
    sampleData: {
      countLoading,
      loading,
      data: { data = [], query = "", message = "", countInfo = null },
    },
    downloadCsvData,
    loadSampleData,
    cancelSampleData,
  } = props;

  const panelContentRef = useRef();
  const [tableScrollHeight, setTableScrollHeight] = useState(0);

  const [limit, setLimit] = useState(defaultLimitValue);
  const isSqlTextAreaEnabled =
    incidentCreatorInfo.type === IncidentCreatorType.FILTER &&
    getMetricTypeFromConfigData(incidentCreatorInfo.kpiInfo) !==
      MetricCategory.AGGREGATION_COMPARE;
  const columnDataTypes = useMemo(
    () => detectDataTypes(data ?? [], { allowNull: true }),
    [data]
  );
  const rowsWithKey = useMemo(
    () => (data || []).map((row, index) => ({ ...row, key: index })),
    [data]
  );

  useEffect(() => {
    setTableScrollHeight(
      // The 155px here is empirically determined; accounts for the gap between
      // the query textarea and the table, as well as the panel padding. We also set
      // a floor on the size of the table so that it always shows some data.
      Math.max(
        isSqlTextAreaEnabled
          ? panelContentRef.current.clientHeight - minQueryWindowHeight - 155
          : panelContentRef.current.clientHeight - 155,
        200
      )
    );
  }, [panelContentRef, isSqlTextAreaEnabled]);

  const isValidLimit = limit >= 1 && limit <= 10000;
  const formattedSql = sqlFormatter.format(
    query.replace("{limit}", isValidLimit ? limit : defaultLimitValue),
    {
      language: "sql",
      indent: "\t",
    }
  );

  const firstRow = data?.[0] ?? {};
  const tableColumns = Object.entries(firstRow).map(([label, _value]) => ({
    title: <NgTextTooltip title={label}>{label}</NgTextTooltip>,
    dataIndex: label,
    // This width might not be perfect for every column, but this needs to be set to
    // some concrete value in order for the { scroll: { x: "100%" }} setting to work
    // in the table below. Unfortunately there does not appear to be a way for
    // Antd tables to have max-content columns, and then have the table scroll
    // over their combined width.
    width: 200,
    render: (value) => {
      if (value === null) {
        return "null";
      }

      if (typeof value === "object") {
        return JSON.stringify(value);
      }

      return value;
    },
    sorter: getSorter(columnDataTypes[label], label),
  }));

  return (
    <div className="incident-sample-data-panel-content" ref={panelContentRef}>
      <div className="incident-sample-data-panel-header">
        <div className="incident-sample-data-panel-title">Failing Records</div>
        <div className="incident-sample-data-panel-action">
          {countInfo ? (
            <>
              <span className="incident-sample-data-panel-last-scan-time">
                {`Last scanned ${getStringFromTimeStamp(countInfo.timestamp)}`}
              </span>
              <span className="incident-sample-data-panel-sample-data-count">
                {`${countInfo.count} failed row${countInfo.count > 0 ? "s" : ""}`}
              </span>
              <Button
                className="incident-sample-data-update-count-btn"
                onClick={() =>
                  loadSampleData({ limit, sqlOnly: true, refreshCount: true })
                }
                outline
                size="small"
                style={{ width: 100 }}
                loading={countLoading}
                disabled={loading}
              >
                Refresh
              </Button>
            </>
          ) : (
            <Button
              className="incident-sample-data-update-count-btn"
              onClick={() =>
                loadSampleData({ limit, sqlOnly: true, refreshCount: true })
              }
              outline
              size="small"
              style={{ width: 100 }}
              loading={countLoading}
              disabled={loading}
            >
              Get count
            </Button>
          )}
        </div>
      </div>
      {isSqlTextAreaEnabled && (
        <TextArea
          className="incident-sample-data-sql-query"
          style={{ minHeight: minQueryWindowHeight }}
          value={formattedSql}
          autoSize={false}
        />
      )}
      <div className="incident-sample-data-controls">
        <div className="incident-sample-data-controls-actions">
          <LabeledInputNumber
            label="Limit"
            value={limit}
            onChange={(newValue) => setLimit(newValue)}
            disabled={loading}
            staticLabel
          />
          <span className="separator" />
          <Button
            className="incident-sample-data-btn"
            onClick={() => downloadCsvData()}
            size="large"
            disabled={(data ?? []).length === 0 || loading}
            outline
          >
            Download <DownloadIcon />
          </Button>
          {loading ? (
            <Button
              className="incident-sample-data-btn"
              size="large"
              onClick={() => cancelSampleData({ keepQuery: true })}
            >
              Cancel
            </Button>
          ) : (
            <Button
              className="incident-sample-data-btn"
              size="large"
              disabled={!isValidLimit || countLoading}
              onClick={() => loadSampleData({ limit, keepQuery: true })}
            >
              Run Query <PlayIcon />
            </Button>
          )}
        </div>
        <span className="incident-sample-data-controls-error">
          {!isValidLimit && "Limit must be between 1 and 10,000"}
        </span>
      </div>
      {Boolean(data) && (
        <NgTable
          className="incident-sample-data-table"
          columns={tableColumns}
          dataSource={rowsWithKey}
          pagination={{ defaultPageSize: maxSampleDataRows, hideOnSinglePage: true }}
          scroll={{ y: tableScrollHeight, x: "100%" }}
          loading={loading}
          emptyState={
            message ? (
              <NgTableEmptyState
                icon={<QueryHistoryIcon />}
                title={""}
                description={message}
              />
            ) : null
          }
        />
      )}
    </div>
  );
}

export function IncidentSampleDataPanel(props) {
  const {
    currentIncidentSampleData,
    incidentCreatorInfo,
    closeTakeover,
    downloadCsvData,
    terminationSupported,
    onTerminateQuery,
    loadSampleData,
    cancelSampleData,
  } = props;

  const { loading, data } = currentIncidentSampleData;

  // show loader only if data.query is undefined
  return loading && !data.query ? (
    <IncidentSampleDataLoading
      closeTakeover={closeTakeover}
      terminationSupported={terminationSupported}
      onCancelClick={() => onTerminateQuery()}
    />
  ) : (
    <IncidentSampleDataContent
      sampleData={currentIncidentSampleData}
      downloadCsvData={downloadCsvData}
      incidentCreatorInfo={incidentCreatorInfo}
      loadSampleData={loadSampleData}
      cancelSampleData={cancelSampleData}
    />
  );
}

function IncidentSampleDataPanelConnected(props) {
  const {
    workspaceUuid,
    incidentUuid,
    incidentCreatorInfo,
    currentIncidentSampleData,
    terminationSupported,
    onTerminateQuery,
    getIncidentSampleData,
    cancelIncidentSampleDataRequest,
    terminateIncidentSampleData,
    closeTakeover,
  } = props;

  const [previewUuid] = useState(uuidv4());

  function downloadCsvData() {
    const csvStr = csv.toCsvString(currentIncidentSampleData.data.data);
    const {
      kpiInfo: {
        metadata: { name, uuid },
      },
    } = incidentCreatorInfo;

    const filename = generateFailedRecordsCSVName({
      metricName: name,
      metricId: uuid,
      incidentId: incidentUuid,
    });
    csv.download(csvStr, filename);
  }

  const loadSampleData = useCallback(
    (opts) => {
      return getIncidentSampleData(workspaceUuid, incidentUuid, previewUuid, opts);
    },
    [getIncidentSampleData, workspaceUuid, incidentUuid, previewUuid]
  );

  const cancelSampleData = useCallback(
    (opts = {}) => {
      if (terminationSupported) {
        terminateIncidentSampleData(workspaceUuid, incidentUuid, previewUuid);
      }
      cancelIncidentSampleDataRequest(opts);
    },
    [
      terminationSupported,
      workspaceUuid,
      incidentUuid,
      previewUuid,
      terminateIncidentSampleData,
      cancelIncidentSampleDataRequest,
    ]
  );

  useEffect(() => {
    loadSampleData({ sqlOnly: true });
  }, [loadSampleData]);

  useEffect(() => {
    return () => {
      cancelSampleData();
    };
  }, [cancelSampleData]);

  return (
    <IncidentSampleDataPanel
      incidentCreatorInfo={incidentCreatorInfo}
      currentIncidentSampleData={currentIncidentSampleData}
      terminationSupported={terminationSupported}
      onTerminateQuery={onTerminateQuery}
      closeTakeover={closeTakeover}
      downloadCsvData={downloadCsvData}
      loadSampleData={loadSampleData}
      cancelSampleData={cancelSampleData}
    />
  );
}

const mapStateToProps = (state) => ({
  currentIncidentSampleData: state.incidentReducer.currentIncidentSampleData,
});

const mapDispatchToProps = (dispatch) => ({
  getIncidentSampleData: (workspaceUuid, incidentUuid, previewUuid, opts = {}) =>
    dispatch(getIncidentSampleData(workspaceUuid, incidentUuid, previewUuid, opts)),
  terminateIncidentSampleData: (workspaceUuid, incidentUuid, previewUuid) =>
    dispatch(
      terminateIncidentSampleDataQuery(workspaceUuid, incidentUuid, previewUuid)
    ),
  cancelIncidentSampleDataRequest: (opts = {}) =>
    dispatch(cancelIncidentSampleDataRequest(opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentSampleDataPanelConnected);
