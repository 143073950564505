import React, { useState } from "react";
import { withRouter, Redirect } from "react-router";
import LightupLogo from "../../assets/img/lightup.png";
import AppWorkspaceSelect from "./app-workspace-select";
import AppSettingMenu from "./app-setting-menu";
import UserSettingMenu from "./user-setting-menu";
import UserAPIKeyDialog from "./user-api-key-dialog";
import UserSupportDialog from "./user-support-dialog";
import { EVENT, trackEvent } from "../../utils/telemetry";
import auth0 from "../../utils/auth0";
import {
  URIPath,
  URIPathPermissions,
  hasPermission,
  getURIInstance,
} from "../../utils/uri-path";
import "./header.scss";

function Header(props) {
  const {
    userInfo,
    workspaceList,
    userAPIKeyList,
    resetCurrentAddedUserAPIKey,
    currentAddedUserAPIKey,
    addUserAPIKey,
    deleteUserAPIKey,
    updateUserAPIKeyActiveStatus,
    logout,
    history,
    updateCurrentWorkspaceUuid,
    workspaceUserPermissions,
    userPermissions,
  } = props;
  const [isApiKeyDialogOpen, setIsApiKeyDialogOpen] = useState(false);
  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);
  const { currentWorkspaceUuid, logoutSucceed } = userInfo;

  if (logoutSucceed) {
    return <Redirect push to={URIPath.LOGIN} />;
  }

  function onGoToAdmin() {
    trackEvent(EVENT.SELECT_ADMIN_TAB);
    history.push(URIPath.ADMIN);
  }

  function onGoToCreateWorkspace() {
    history.push(URIPath.CREATE_WORKSPACE);
  }

  function onGoToWorkspaceSetting() {
    history.push(
      getURIInstance(URIPath.EDIT_WORKSPACE, { workspaceUuid: currentWorkspaceUuid })
    );
  }

  function onGoToHomePage() {
    history.push("/");
  }

  async function onLogout() {
    trackEvent(EVENT.LOGOUT);
    logout();
    const { auth0ClientId, auth0DomainName } = userInfo;
    if (!auth0ClientId || !auth0DomainName) {
      return;
    }

    try {
      await auth0.init(auth0ClientId, auth0DomainName);
      auth0.logout();
    } catch (err) {
      console.log("Catch exception while logging out from auth0");
    }
  }

  function onOpenHelpPage() {
    trackEvent(EVENT.OPEN_DOCUMENTATION);
    window.open("https://docs.lightup.ai", "_blank", "noopener");
  }

  function onOpenAPIKeyDialog() {
    trackEvent(EVENT.GET_API_CREDENTIALS);
    setIsApiKeyDialogOpen(true);
  }

  function onOpenSupportDialog() {
    trackEvent(EVENT.GET_SUPPORT);
    setIsSupportDialogOpen(true);
  }

  const currentWorkspaceRole = workspaceList.find(
    ({ uuid }) => uuid === currentWorkspaceUuid
  )?.role;

  return (
    <>
      <div className="header-container">
        <div className="header-logo-container" onClick={onGoToHomePage}>
          <img src={LightupLogo} alt="fireSpot" />
        </div>
        <div className="header-space-container" />
        <div className="header-app-workspace-select-container">
          <AppWorkspaceSelect
            workspaceList={workspaceList}
            value={currentWorkspaceUuid}
            onChange={(currentWorkspaceUuid) => {
              updateCurrentWorkspaceUuid(currentWorkspaceUuid);
              history.push(
                getURIInstance(URIPath.EXPLORER, {
                  workspaceUuid: currentWorkspaceUuid,
                })
              );
            }}
            enableAdd={hasPermission(
              userPermissions,
              URIPathPermissions[URIPath.CREATE_WORKSPACE]
            )}
            onAddWorkspace={onGoToCreateWorkspace}
          />
        </div>
        <div className="header-app-setting-menu-container">
          <AppSettingMenu
            canViewAdmin={hasPermission(
              userPermissions,
              URIPathPermissions[URIPath.ADMIN]
            )}
            onGoToAdmin={onGoToAdmin}
            canViewWorkspaceSetting={hasPermission(
              workspaceUserPermissions,
              URIPathPermissions[URIPath.EDIT_WORKSPACE]
            )}
            onGoToWorkspaceSetting={onGoToWorkspaceSetting}
          />
        </div>
        <div className="header-user-menu-container">
          <UserSettingMenu
            userInfo={userInfo}
            workspaceRole={currentWorkspaceRole}
            onLogout={onLogout}
            onOpenHelpPage={onOpenHelpPage}
            onOpenAPIKeyDialog={onOpenAPIKeyDialog}
            onOpenSupportDialog={onOpenSupportDialog}
          />
        </div>
      </div>
      {isApiKeyDialogOpen && (
        <UserAPIKeyDialog
          userAPIKeyList={userAPIKeyList}
          modalIsOpen={isApiKeyDialogOpen}
          setIsOpen={(isApiKeyDialogOpen) => {
            setIsApiKeyDialogOpen(isApiKeyDialogOpen);
            resetCurrentAddedUserAPIKey();
          }}
          currentAddedUserAPIKey={currentAddedUserAPIKey}
          addUserAPIKey={addUserAPIKey}
          deleteUserAPIKey={deleteUserAPIKey}
          updateUserAPIKeyActiveStatus={updateUserAPIKeyActiveStatus}
          resetCurrentAddedUserAPIKey={resetCurrentAddedUserAPIKey}
        />
      )}
      {isSupportDialogOpen && (
        <UserSupportDialog
          modalIsOpen={isSupportDialogOpen}
          setIsOpen={setIsSupportDialogOpen}
        />
      )}
    </>
  );
}

export default withRouter(Header);
