import React, { useState, useRef } from "react";
import { withRouter } from "react-router";
import cx from "classnames";
import { classesName } from "../../utils/css";
import { dataSourceSupportsDataProfiling } from "../../utils/datasource";
import { getURIInstance, URIPath } from "../../utils/uri-path";
import {
  ChecklistDoneIcon,
  ChecklistColumnStepIcon,
  ChecklistMonitorStepIcon,
} from "./icons";
import NgButton from "../../components/button/ng-button";
import Icon, { IconName } from "../../elements/Icon";
import "./profiler-checklist-view.scss";

function StepSeparatorLine(props) {
  return (
    <div
      className={classesName(
        "checklist-item-separator-line-container",
        props.isNextPending && "pending-seperator"
      )}
    />
  );
}

function isTableMetricsEnabled(table) {
  const { metrics = [], profilerConfig } = table;

  return (
    metrics.length > 0 ||
    profilerConfig.dataDelay.enabled ||
    profilerConfig.volume.enabled ||
    profilerConfig.metadataMetrics.byteCount ||
    profilerConfig.metadataMetrics.rowCount ||
    profilerConfig.metadataMetrics.updateDelay ||
    profilerConfig.metadataMetrics.schemaChange.enabled
  );
}

function isColumnMetricEnabled(column) {
  const { metrics = [], profilerConfig } = column;
  return (
    metrics.length > 0 ||
    profilerConfig.missingValue.enabled ||
    profilerConfig.numericalDistribution.enabled ||
    profilerConfig.categoricalDistribution.enabled ||
    profilerConfig.categoryListChange.enabled
  );
}

function ProfilerChecklistView(props) {
  const {
    history,
    localStorageKey = "@profiler-checklist-datasource",
    config = {},
    monitorSummary,
    onMonitorAll,
    className,
  } = props;
  const [isExpand, setIsExpand] = useState(
    () => (localStorage.getItem(localStorageKey) || "true") === "true"
  );
  const checklistRef = useRef();
  function onToggleExpandClick(isExpanded) {
    setIsExpand(isExpanded);
    localStorage.setItem(localStorageKey, isExpanded);
    if (isExpanded) {
      setTimeout(() => {
        checklistRef?.current?.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }

  const { workspaceUuid, dataSource, schema, table = null } = config;
  const { schemas } = dataSource;
  // Step 1;
  let isSchemaActivated = false;
  let isSchemaClickable = schemas.length > 0;
  let activateSchemaUrl = "";

  // Step 2;
  let isTableProfileActivated = false;
  let isTableProfileClickable = false;
  let currentSelectedSchema = null;
  let currentSelectedTable = null;
  let activateTableProfileUrl = "";

  // Step 3;
  let isTableMetricActivated = false;
  let isTableMetricClickable = false;
  let activateTableMetricUrl = "";

  // Step 4;
  let isColumnMetricActivated = false;
  let isColumnMetricClickable = false;
  let activateColumnMetricUrl = "";

  // Step 5;
  let isAddMonitorClickable = monitorSummary.metricCount > 0;
  let isAddMonitorDone = monitorSummary.monitorCount > 0;

  for (let currentSchema of schemas) {
    if (currentSchema.profilerConfig.enabled) {
      if (schema && currentSchema.uuid !== schema.uuid) {
        continue;
      }

      isSchemaActivated = true;
      // Schema.tables only contains enabled table.
      isTableProfileClickable = true;
      if (!currentSelectedSchema) {
        currentSelectedSchema = currentSchema;
      }

      for (let currentTable of currentSchema.tables) {
        if (table && currentTable.uuid !== table.uuid) {
          continue;
        }

        isTableMetricClickable = true;

        if (!currentSelectedTable) {
          currentSelectedTable = currentTable;
          currentSelectedSchema = currentSchema;
        }

        if (currentSelectedTable && table && currentTable.uuid === table.uuid) {
          currentSelectedTable = currentTable;
          currentSelectedSchema = currentSchema;
        }

        if (currentTable.profilerConfig.dataProfiler.enabled) {
          isTableProfileActivated = true;
        }

        if (isTableMetricsEnabled(currentTable)) {
          isTableMetricActivated = true;
          isColumnMetricClickable = true;
        }

        for (let currentColumn of currentTable.columns) {
          isColumnMetricClickable = true;
          if (isColumnMetricEnabled(currentColumn)) {
            isColumnMetricActivated = true;
          }
        }
      }
    }
  }

  if (isSchemaClickable) {
    activateSchemaUrl = `${getURIInstance(URIPath.EXPLORER, {
      workspaceUuid,
    })}?dataSourceUuid=${dataSource.metadata.uuid}&tabKey=manageSchemas`;
  }

  if (isTableProfileClickable) {
    activateTableProfileUrl = `${getURIInstance(URIPath.EXPLORER, {
      workspaceUuid,
    })}?dataSourceUuid=${dataSource.metadata.uuid}&schemaUuid=${
      currentSelectedSchema.uuid
    }&tabKey=configureDataProfiles`;
  }

  if (isTableMetricClickable) {
    activateTableMetricUrl = `${getURIInstance(URIPath.EXPLORER, {
      workspaceUuid,
    })}?dataSourceUuid=${dataSource.metadata.uuid}&schemaUuid=${
      currentSelectedSchema.uuid
    }&tabKey=configureAutoMetrics`;
  }

  if (isColumnMetricClickable) {
    activateColumnMetricUrl = `${getURIInstance(URIPath.EXPLORER, {
      workspaceUuid,
    })}?dataSourceUuid=${dataSource.metadata.uuid}&schemaUuid=${
      currentSelectedSchema.uuid
    }&tableUuid=${currentSelectedTable.uuid}&tabKey=manageColumns`;
  }

  const checkListItems = [
    {
      title: "Activate Schema",
      icon: <Icon name={IconName.Swap} size={20} />,
      isDone: isSchemaActivated,
      isClickable: isSchemaClickable,
      url: activateSchemaUrl,
    },
    {
      title: "Set up Data Profiling",
      icon: <Icon name={IconName.Article} size={20} />,
      isDone: isTableProfileActivated,
      isClickable:
        dataSourceSupportsDataProfiling(dataSource) && isTableProfileClickable,
      url: activateTableProfileUrl,
    },
    {
      title: "Enable Table Metrics",
      icon: <Icon name={IconName.GridFour} size={20} />,
      isDone: isTableMetricActivated,
      isClickable: isTableMetricClickable,
      url: activateTableMetricUrl,
    },
    {
      title: "Enable Column Metrics",
      icon: <ChecklistColumnStepIcon width={18} height={18} />,
      isDone: isColumnMetricActivated,
      isClickable: isColumnMetricClickable,
      url: activateColumnMetricUrl,
    },
    {
      title: "Enable Monitors",
      icon: <ChecklistMonitorStepIcon width={18} height={18} />,
      isDone: isAddMonitorDone,
      isClickable: isAddMonitorClickable,
      onClick: onMonitorAll,
    },
  ];
  return (
    <>
      <div
        className={cx("profiler-checklist-view-container", {
          "profile-checklist-collapsed": !isExpand,
          className,
        })}
      >
        {isExpand && (
          <div>
            <div
              className="profiler-checklist-view-header-container"
              ref={checklistRef}
            >
              <div className="profiler-checklist-view-header-title-container">
                Onboarding checklist
              </div>
            </div>
            <div className="profiler-checklist-view-content-container">
              {checkListItems.map(
                ({ title, icon, isClickable, url, isDone, onClick }, index) => {
                  let titleContent;
                  if (isClickable) {
                    titleContent = (
                      <span
                        onClick={() => {
                          if (onClick) {
                            onClick();
                          } else if (url) {
                            history.push(url);
                          }
                        }}
                      >
                        {title}
                      </span>
                    );
                  } else {
                    titleContent = title;
                  }

                  const content = (
                    <div
                      key={title}
                      className={classesName(
                        "profiler-checklist-item-container",
                        isDone && "done",
                        isClickable && "clickable"
                      )}
                    >
                      <div className="profiler-checklist-item-icon-container">
                        {icon}
                      </div>
                      <div className="profiler-checklist-item-title-container">
                        {titleContent}
                      </div>
                      {isDone && (
                        <div className="profiler-checklist-item-done-icon-container">
                          <ChecklistDoneIcon />
                        </div>
                      )}
                    </div>
                  );
                  let isNextPending;
                  if (index === checkListItems.length - 1) {
                    return content;
                  } else {
                    isNextPending = !checkListItems[index + 1].isDone;
                  }
                  return (
                    <React.Fragment key={title}>
                      {content}
                      <StepSeparatorLine isNextPending={isNextPending} />
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>

      <NgButton
        floating
        icon={<Icon name="ListChecks" size={18} />}
        className="profiler-checklist-button"
        shape="circle"
        onClick={() => onToggleExpandClick(!isExpand)}
      />
    </>
  );
}

export default withRouter(ProfilerChecklistView);
