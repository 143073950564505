import React, { useState, useEffect } from "react";
import NgButton from "../../../../button/ng-button";
import MetricFailingRecordsQueryConfigItem from "../../../fields/metric-failing-records-query-config-item";
import {
  AggregationWindowConfigItem,
  BackfillDurationConfigItem,
  BorderedFields,
  CustomScheduleCollectionConfigItem,
  CustomSeasonalityConfigItem,
  CustomSqlConfigItem,
  DataAssetCards,
  DataCollectionScheduleConfigItem,
  DataCollectionWindowConfigItem,
  FieldColumn,
  FieldRow,
  FieldSection,
  MissingValueFillingConfigItem,
  PartitionsConfigItem,
  QueryScopeConfigItem,
  QueryTimezoneConfigItem,
  SeasonalityConfigItem,
  SliceByColumnsConfigItem,
  SynchronizationDelayConfigItem,
  TimestampColumnConfigItem,
  TimestampTimezoneConfigItem,
  ValueColumnsConfigItem,
} from "../../../fields/";
import VirtualTimestampColumnForm from "../../../../../views/profiler/takeover/config/table/virtual-timestamp-column-form";
import { CheckOneIcon, DataArrivalIcon } from "../../../../icons/success";
import {
  AggregationWindowType,
  CollectionModeType,
  SupportedFeature,
  MetricConfigType,
  MetricDataAssetType,
  QueryScope,
  CollectionWindow,
} from "../../../../../utils/enums";
import {
  isFeatureEnabled,
  isTriggeredCollectionMode,
} from "../../../../../utils/general";
import {
  aggregationWindowOptions,
  betaFullTableMetricAggregationWindowOptions,
  fullTableMetricAggregationWindowOptions,
  getSeasonalityList,
  removeSecondAndMinuteOptions,
  streamingMetricAggregationWindowOptions,
} from "../../../utils";
import {
  isDataCollectionWindowRequired,
  removeCollectionAttributesFromConfig,
} from "../../../metric-utils";
import { metricQueryScopeOptions } from "../../../../../utils/options";
import AddTags from "../../../fields/add-tags";
import DataAssetSelect from "../../../fields/data-asset-select";
import { isPartitionConfigEnabled } from "../../../../../utils/datasource";
import { getDefaultBackfillDuration } from "../../../../../utils/metric";
import ConfigCard from "../../../config-card";
import { ConfigureMetricTitle } from "../../../fields/configure-metric";

import "./index.scss";

// non second and minute options
const nonSMAggregationIntervalOptions = removeSecondAndMinuteOptions(
  aggregationWindowOptions
);
const nonSMAggregationIntervalStreamingOptions = removeSecondAndMinuteOptions(
  streamingMetricAggregationWindowOptions
);

function CustomSqlMissingValueFillRow(props) {
  const { configData, onConfigDataChange, disabled } = props;
  return (
    <FieldRow>
      <div>
        <MissingValueFillingConfigItem
          value={configData.config.missingValueFilling}
          onChange={(missingValueFilling) => {
            onConfigDataChange({
              ...configData,
              config: {
                ...configData.config,
                missingValueFilling,
              },
            });
          }}
          disabled={disabled}
        />
      </div>
      <div />
    </FieldRow>
  );
}

function CustomSqlMetricConfigItem(props) {
  const {
    configData,
    onConfigDataChange,
    dataSourceList,
    columnList,
    onLoadColumnList,
    isColumnListLoaded,
    isColumnListLoading,
    partitionSampleData,
    getPartitionSampleData,
    waffle,
    disabled = false,
    openWorkspaceTakeover,
    closeTakeover,
  } = props;

  const [isVirtualTimestampFormVisible, setIsVirtualTimestampFormVisible] =
    useState(false);

  const isExistingMetric = !!configData.metadata?.uuid;
  const isStreaming = configData.config.isStreaming;
  const dataSourceInfo = dataSourceList.find(
    (dataSource) => configData.config.sources[0] === dataSource.metadata.uuid
  );
  const isPartitionRequired =
    dataSourceInfo && isPartitionConfigEnabled(dataSourceInfo?.config.connection.type);

  const { timestampColumn, timestampColumnFunctions } = configData.config;

  useEffect(() => setIsVirtualTimestampFormVisible(false), [timestampColumn]);

  const isSecondAndMinuteEnabled = !isFeatureEnabled(
    waffle,
    SupportedFeature.ENABLE_SECOND_AND_MINUTE_AGGREGATION
  );
  const aggregationIntervalOptions = isSecondAndMinuteEnabled
    ? aggregationWindowOptions
    : nonSMAggregationIntervalOptions;
  const aggregationIntervalStreamingOptions = isSecondAndMinuteEnabled
    ? streamingMetricAggregationWindowOptions
    : nonSMAggregationIntervalStreamingOptions;

  return (
    <>
      <FieldSection>
        <FieldColumn>
          <FieldRow>
            <AggregationWindowConfigItem
              options={
                isStreaming
                  ? aggregationIntervalStreamingOptions
                  : aggregationIntervalOptions
              }
              value={configData.config?.table?.aggregationWindow}
              onChange={(newAggregationWindow) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    table: {
                      ...configData.config.table,
                      aggregationWindow: newAggregationWindow,
                    },
                    // Don't adjust backfill duration on existing metrics. We expect the backend
                    // to show an error if the existing backfill duration winds up being invalid.
                    backfillDuration: isExistingMetric
                      ? configData.config.backfillDuration
                      : getDefaultBackfillDuration(
                          newAggregationWindow,
                          dataSourceInfo
                        ),
                  },
                });
              }}
              disabled={disabled}
            />
            <BackfillDurationConfigItem
              // Recreate this input whenever the aggregation window changes, so that the underlying
              // duration input will reset its time unit to the highest granularity.
              key={configData.config?.table?.aggregationWindow}
              value={configData.config?.backfillDuration}
              onChange={(newBackfillDuration) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    backfillDuration: newBackfillDuration,
                  },
                });
              }}
              disabled={disabled}
            />
            <QueryTimezoneConfigItem
              value={configData.config.queryTimezone}
              onChange={(newQueryTimezone) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    queryTimezone: newQueryTimezone,
                  },
                });
              }}
              disabled={disabled}
            />
          </FieldRow>
          <FieldRow>
            <ValueColumnsConfigItem
              columnList={columnList}
              loadOptionConfig={{
                enableLoadOption: true,
                isOptionLoading: isColumnListLoading,
                isOptionLoaded: isColumnListLoaded,
                onClickLoad: onLoadColumnList,
              }}
              value={configData.config?.valueColumns}
              onChange={(newValueColumns) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    valueColumns: newValueColumns,
                  },
                });
              }}
              disabled={disabled}
            />
            <TimestampColumnConfigItem
              columnList={columnList}
              loadOptionConfig={{
                enableLoadOption: true,
                isOptionLoading: isColumnListLoading,
                isOptionLoaded: isColumnListLoaded,
                onClickLoad: onLoadColumnList,
              }}
              showAllColumns={true}
              supportVirtualTimestamp={false}
              dataSource={dataSourceInfo}
              partitions={configData.config.partitions}
              value={timestampColumn}
              onChange={(newTimestampColumn) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    timestampColumn: newTimestampColumn,
                    timestampColumnFunctions: null,
                  },
                });
              }}
              onAddVirtualTimestamp={() => {
                setIsVirtualTimestampFormVisible(true);
              }}
              onEditVirtualTimestamp={() => {
                setIsVirtualTimestampFormVisible(true);
              }}
              disabled={disabled}
            />
            <TimestampTimezoneConfigItem
              value={configData.config.dataTimezone}
              onChange={(newDataTimezone) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    dataTimezone: newDataTimezone,
                  },
                });
              }}
              disabled={disabled}
            />
          </FieldRow>
          {isVirtualTimestampFormVisible && (
            <FieldRow>
              <div className="configure-metric-virtual-timezone-container configure-metric-full-row">
                <VirtualTimestampColumnForm
                  isAdd={!timestampColumnFunctions}
                  value={{ timestampColumn, timestampColumnFunctions }}
                  dataSource={dataSourceInfo}
                  columnList={columnList}
                  onDelete={() => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        timestampColumn: null,
                        timestampColumnFunctions: null,
                      },
                    });
                    setIsVirtualTimestampFormVisible(false);
                  }}
                  onCancel={() => setIsVirtualTimestampFormVisible(false)}
                  onSave={(newTimestampColumnInfo) => {
                    onConfigDataChange({
                      ...configData,
                      config: {
                        ...configData.config,
                        timestampColumn: newTimestampColumnInfo.timestampColumn,
                        timestampColumnFunctions:
                          newTimestampColumnInfo.timestampColumnFunctions,
                      },
                    });
                    setIsVirtualTimestampFormVisible(false);
                  }}
                />
              </div>
            </FieldRow>
          )}
          <FieldRow>
            <CustomSqlSeasonalityAndSliceConfig
              configData={configData}
              onChange={onConfigDataChange}
              columnList={columnList}
              isColumnListLoading={isColumnListLoading}
              isColumnListLoaded={isColumnListLoaded}
              onLoadColumnList={onLoadColumnList}
              disabled={disabled}
              openWorkspaceTakeover={openWorkspaceTakeover}
              closeTakeover={closeTakeover}
            />
          </FieldRow>
          <BorderedFields>
            <SynchronizationDelayConfigItem
              value={configData.config.synchronizationDelay}
              onChange={(newSynchronizationDelay) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    synchronizationDelay: newSynchronizationDelay,
                  },
                });
              }}
              disabled={disabled}
            />
          </BorderedFields>
          <CustomSqlMissingValueFillRow
            configData={configData}
            onConfigDataChange={onConfigDataChange}
            disabled={disabled}
          />
        </FieldColumn>
      </FieldSection>
      {isPartitionRequired && (
        <FieldSection>
          <PartitionsConfigItem
            disabled={disabled}
            hideColumnName={true}
            value={configData.config.partitions || []}
            onChange={(partitions) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  partitions,
                },
              });
            }}
            partitionSampleData={partitionSampleData}
            getPartitionSampleData={getPartitionSampleData}
            columnList={columnList}
            sources={configData.config.sources}
            table={configData.config.table}
            partitionTimezone={configData.config.partitionTimezone}
            onPartitionTimezoneChange={(partitionTimezone) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  partitionTimezone,
                },
              });
            }}
            partitionOffsets={configData.config.partitionOffsets}
            onPartitionOffsetsChange={(partitionOffsets) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  partitionOffsets,
                },
              });
            }}
            isTableOptional={true}
          />
        </FieldSection>
      )}
    </>
  );
}

function CustomSqlSeasonalityAndSliceConfig(props) {
  const {
    configData,
    onChange,
    columnList,
    onLoadColumnList,
    isColumnListLoaded,
    isColumnListLoading,
    disabled,
    openWorkspaceTakeover,
    closeTakeover,
  } = props;

  const seasonalityList = getSeasonalityList(configData.config.aggregation);

  return (
    <>
      <div className="custom-seasonality-and-slice-container">
        <SeasonalityConfigItem
          label="Seasonality"
          disabled={disabled}
          value={configData.config.seasonality}
          status={configData.status}
          seasonalityList={seasonalityList}
          onChange={(newSeasonality) => {
            onChange({
              ...configData,
              config: {
                ...configData.config,
                seasonality: newSeasonality,
              },
            });
          }}
        />
        <CustomSeasonalityConfigItem
          configData={configData}
          onConfigDataChange={onChange}
          disabled={disabled}
          openWorkspaceTakeover={openWorkspaceTakeover}
          closeTakeover={closeTakeover}
        />
      </div>
      <SliceByColumnsConfigItem
        columnList={columnList}
        disabled={disabled}
        loadOptionConfig={{
          enableLoadOption: true,
          isOptionLoading: isColumnListLoading,
          isOptionLoaded: isColumnListLoaded,
          onClickLoad: onLoadColumnList,
        }}
        mode="multiple"
        value={configData.config?.sliceByColumns}
        onChange={(sliceByColumns) => {
          onChange({
            ...configData,
            config: {
              ...configData.config,
              sliceByColumns,
            },
          });
        }}
      />
    </>
  );
}

function CustomSqlFullTableMetricConfig(props) {
  const {
    configData,
    onConfigDataChange,
    columnList,
    onLoadColumnList,
    isColumnListLoaded,
    isColumnListLoading,
    waffle,
    disabled,
    openWorkspaceTakeover,
    closeTakeover,
  } = props;

  return (
    <>
      <FieldSection>
        <FieldColumn>
          {!isTriggeredCollectionMode(configData.config.collectionMode) && (
            <FieldRow>
              <AggregationWindowConfigItem
                disabled={disabled}
                label="Polling interval"
                options={
                  isFeatureEnabled(waffle, SupportedFeature.FULL_TABLE_METRIC_HOURLY)
                    ? betaFullTableMetricAggregationWindowOptions
                    : fullTableMetricAggregationWindowOptions
                }
                value={configData.config?.pollingWindow}
                onChange={(newPollingWindow) => {
                  onConfigDataChange({
                    ...configData,
                    config: {
                      ...configData.config,
                      pollingWindow: newPollingWindow,
                    },
                  });
                }}
              />
              <QueryTimezoneConfigItem
                disabled={disabled}
                label="Polling timezone"
                value={configData.config.pollingTimezone}
                onChange={(newPollingTimezone) => {
                  onConfigDataChange({
                    ...configData,
                    config: {
                      ...configData.config,
                      pollingTimezone: newPollingTimezone,
                    },
                  });
                }}
              />
            </FieldRow>
          )}
          <FieldRow>
            <ValueColumnsConfigItem
              disabled={disabled}
              columnList={columnList}
              loadOptionConfig={{
                enableLoadOption: true,
                isOptionLoading: isColumnListLoading,
                isOptionLoaded: isColumnListLoaded,
                onClickLoad: onLoadColumnList,
              }}
              value={configData.config?.valueColumns}
              onChange={(newValueColumns) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    valueColumns: newValueColumns,
                  },
                });
              }}
            />
            <CustomSqlSeasonalityAndSliceConfig
              disabled={disabled}
              configData={configData}
              onChange={onConfigDataChange}
              columnList={columnList}
              isColumnListLoading={isColumnListLoading}
              isColumnListLoaded={isColumnListLoaded}
              onLoadColumnList={onLoadColumnList}
              openWorkspaceTakeover={openWorkspaceTakeover}
              closeTakeover={closeTakeover}
            />
          </FieldRow>
          {!isTriggeredCollectionMode(configData.config.collectionMode) && (
            <BorderedFields>
              <SynchronizationDelayConfigItem
                disabled={disabled}
                label="Polling delay"
                value={configData.config.pollingDelay || 0}
                onChange={(newPollingDelay) => {
                  onConfigDataChange({
                    ...configData,
                    config: {
                      ...configData.config,
                      pollingDelay: newPollingDelay,
                    },
                  });
                }}
              />
            </BorderedFields>
          )}
          <CustomSqlMissingValueFillRow
            disabled={disabled}
            configData={configData}
            onConfigDataChange={onConfigDataChange}
          />
        </FieldColumn>
      </FieldSection>
    </>
  );
}

function CustomSqlMetricDataAssetConfig(props) {
  const {
    configData,
    isValidConfig,
    onConfigDataChange,
    dataSourceList,
    schemaList,
    tableList,
    onDataSourceChanged,
    onSchemaChanged,
    columnList: {
      data: columnList,
      loading: validationInProgress,
      isLoaded: isColumnListLoaded,
    },
    onCustomSqlTableChanged,
    customSqlColumnList,
    partitionSampleData,
    getPartitionSampleData,
    tagList,
    waffle,
    verifyKpiCustomSql,
    validateKpiFailingRecordsSql,
    validateFailingRecordsSqlResult = { loading: false },
    resetKPIStatus,
    disabled,
    openWorkspaceTakeover,
    closeTakeover,
  } = props;

  const [validationStarted, setValidationStarted] = useState(false);
  const showValidationSuccess =
    validationStarted && !validationInProgress && columnList.length > 0;

  const isStreaming = configData.config.isStreaming;
  const isValidateSqlEnabled =
    typeof configData.config?.table?.sql === "string" &&
    configData.config?.table?.sql !== "" &&
    configData.config?.sources?.length > 0;

  let validateSqlButtonText = "Validate Query";
  let validateSqlButtonIcon = <DataArrivalIcon />;
  if (validationInProgress) {
    validateSqlButtonText = "Validating";
    validateSqlButtonIcon = null;
  } else if (showValidationSuccess) {
    validateSqlButtonText = "Successful Validation";
    validateSqlButtonIcon = <CheckOneIcon />;
  }

  const isMetricConfigType =
    configData.config.configType === MetricConfigType.METRIC_CONFIG;

  const workspaceUuid = dataSourceList?.[0]?.metadata?.workspaceId;
  const configureMetricTitle = (
    <ConfigureMetricTitle
      configData={configData}
      isValidConfig={isValidConfig}
      workspaceUuid={workspaceUuid}
    />
  );

  function onVerifyKpiCustomSqlClicked() {
    verifyKpiCustomSql(configData);
    setValidationStarted(true);
  }

  return (
    <DataAssetCards>
      <DataAssetSelect
        dataAssetType={MetricDataAssetType.COLUMN}
        configData={configData}
        dataSourceList={dataSourceList}
        tableList={tableList}
        schemaList={schemaList}
        columnList={customSqlColumnList}
        isStreaming={isStreaming}
        allFieldsRequired={false}
        disabled={disabled}
        onDataSourceChange={(newDataSourceUuid) => {
          setValidationStarted(false);
          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              sources: [newDataSourceUuid],
              table: {
                ...configData.config.table,
                schemaName: null,
                tableUuid: null,
                tableName: null,
                columnUuid: null,
                columnName: null,
              },
              valueColumns: [],
              sliceByColumns: [],
              timestampColumn: null,
              partitions: [],
              failingRecordsSql: "",
            },
          });
          onDataSourceChanged(newDataSourceUuid);
          resetKPIStatus({
            resetAll: false,
            resetCustomSqlColumnList: true,
            resetColumnList: true,
            resetValidateFailingQuery: true,
          });
        }}
        onSchemaChange={(newSchemaName) => {
          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              table: {
                ...configData.config.table,
                schemaName: newSchemaName,
                tableUuid: null,
                tableName: null,
                columnUuid: null,
                columnName: null,
              },
              sliceValueSelections: [],
              partitions: [],
            },
          });

          onSchemaChanged(configData.config.sources[0], newSchemaName);
        }}
        onTableChange={(_newTableUuid, currentTable) => {
          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              table: {
                ...configData.config.table,
                tableUuid: currentTable?.tableUuid,
                tableName: currentTable?.tableName,
                columnUuid: null,
                columnName: null,
              },
              collectionMode: currentTable.profilerConfig.collectionMode,
              synchronizationDelay: currentTable.profilerConfig.syncDelay,
              configType:
                currentTable.profilerConfig.queryScope === QueryScope.TIME_RANGE
                  ? MetricConfigType.METRIC_CONFIG
                  : MetricConfigType.FULL_TABLE_METRIC_CONFIG,
              sliceValueSelections: [],
              pollingDelay:
                currentTable.profilerConfig.queryScope !== QueryScope.TIME_RANGE
                  ? configData?.config?.pollingDelay ?? 0
                  : undefined,
            },
          });

          onCustomSqlTableChanged(
            configData.config.sources[0],
            currentTable?.tableUuid
          );
        }}
        onColumnChange={(_newColumnUuid, newColumn) => {
          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              table: {
                ...configData.config.table,
                columnName: newColumn?.columnName,
                columnUuid: newColumn?.uuid,
              },
            },
          });
        }}
        onConfigDataChange={onConfigDataChange}
        columnGetter={(configData) => {
          return configData.config.table.columnUuid;
        }}
      />
      <ConfigCard title={configureMetricTitle} testId="metric-wizard-configure-metric">
        <FieldColumn>
          <CustomSqlConfigItem
            validating={validationInProgress}
            value={configData.config?.table?.sql}
            disabled={disabled}
            onChange={(newSql) => {
              if (newSql !== configData.config.table.sql) {
                setValidationStarted(false);
              }
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  table: {
                    ...configData.config.table,
                    sql: newSql,
                  },
                  valueColumns: [],
                  sliceByColumns: [],
                  timestampColumn: null,
                  partitions: [],
                },
              });

              resetKPIStatus({
                resetAll: false,
                resetColumnList: true,
              });
            }}
          />
          <FieldRow>
            <QueryScopeConfigItem
              value={configData.config.configType}
              options={metricQueryScopeOptions}
              disabled={disabled}
              onChange={(newConfigType) => {
                let newConfig = removeCollectionAttributesFromConfig(
                  configData.config,
                  newConfigType === MetricConfigType.METRIC_CONFIG
                );
                if (newConfigType === MetricConfigType.METRIC_CONFIG) {
                  newConfig = {
                    ...newConfig,
                    configType: newConfigType,
                    timestampColumn: "",
                    timestampColumnFunctions: null,
                    queryTimezone: "UTC",
                    dataTimezone: "UTC",
                    partitionTimezone: "UTC",
                    pollingInterval: 300,
                    partitions: [],
                    collectionWindow: CollectionWindow.COMPLETE,
                  };
                } else {
                  newConfig = {
                    ...newConfig,
                    collectionMode:
                      configData.config.collectionMode?.type ===
                      CollectionModeType.CUSTOM_SCHEDULED
                        ? { type: CollectionModeType.SCHEDULED }
                        : configData.config.collectionMode,
                    configType: newConfigType,
                    pollingWindow: AggregationWindowType.DAY,
                    pollingTimezone: "UTC",
                    pollingDelay: 0,
                  };
                }

                onConfigDataChange({
                  ...configData,
                  config: newConfig,
                });
              }}
            />
            <DataCollectionScheduleConfigItem
              value={configData.config.collectionMode}
              disabled={disabled}
              onChange={(newCollectionMode) => {
                onConfigDataChange({
                  ...configData,
                  config: {
                    ...configData.config,
                    collectionMode: newCollectionMode,
                  },
                });
              }}
            />
            {isDataCollectionWindowRequired(configData) && (
              <DataCollectionWindowConfigItem
                disabled={disabled}
                value={configData.config.collectionWindow}
                onChange={(newCollectionWindow) => {
                  onConfigDataChange({
                    ...configData,
                    config: {
                      ...configData.config,
                      collectionWindow: newCollectionWindow,
                    },
                  });
                }}
              />
            )}
            <NgButton
              primary
              success={showValidationSuccess}
              block
              size="large"
              disabled={!isValidateSqlEnabled || validationInProgress}
              onClick={onVerifyKpiCustomSqlClicked}
            >
              <div className="metric-config-custom-sql-validate-query-button">
                <span>{validateSqlButtonText}</span>
                {validateSqlButtonIcon}
              </div>
            </NgButton>
          </FieldRow>
          <CustomScheduleCollectionConfigItem
            workspaceUuid={workspaceUuid}
            disabled={disabled}
            value={configData.config.collectionMode}
            onChange={(newCollectionMode) => {
              onConfigDataChange({
                ...configData,
                config: {
                  ...configData.config,
                  collectionMode: newCollectionMode,
                },
              });
            }}
          />
        </FieldColumn>
      </ConfigCard>
      {isMetricConfigType ? (
        <CustomSqlMetricConfigItem
          configData={configData}
          onConfigDataChange={onConfigDataChange}
          dataSourceList={dataSourceList}
          columnList={columnList}
          isColumnListLoading={validationInProgress}
          onLoadColumnList={onVerifyKpiCustomSqlClicked}
          isColumnListLoaded={isColumnListLoaded}
          partitionSampleData={partitionSampleData}
          getPartitionSampleData={getPartitionSampleData}
          waffle={waffle}
          disabled={disabled}
          openWorkspaceTakeover={openWorkspaceTakeover}
          closeTakeover={closeTakeover}
        />
      ) : (
        <CustomSqlFullTableMetricConfig
          configData={configData}
          onConfigDataChange={onConfigDataChange}
          columnList={columnList}
          isColumnListLoading={validationInProgress}
          onLoadColumnList={onVerifyKpiCustomSqlClicked}
          isColumnListLoaded={isColumnListLoaded}
          waffle={waffle}
          disabled={disabled}
          openWorkspaceTakeover={openWorkspaceTakeover}
          closeTakeover={closeTakeover}
        />
      )}
      <MetricFailingRecordsQueryConfigItem
        configData={configData}
        value={configData.config.failingRecordsSql}
        validateKpiFailingRecordsSql={validateKpiFailingRecordsSql}
        validateFailingRecordsSqlResult={validateFailingRecordsSqlResult}
        onChange={(newFailingRecordsSql) => {
          onConfigDataChange({
            ...configData,
            config: {
              ...configData.config,
              failingRecordsSql: newFailingRecordsSql,
            },
          });
        }}
        disabled={disabled}
      />
      <AddTags
        configData={configData}
        tagList={tagList}
        onConfigDataChange={onConfigDataChange}
        disabled={disabled}
      />
    </DataAssetCards>
  );
}

CustomSqlMetricDataAssetConfig.assetMeta = {
  title: "Select data asset",
  createDescription:
    "The datasource selected will be queried using the SQL specified below.\n" +
    "Your metric will be placed in the Explorer based on the datasource, schema, table, and column selected.",
  editDescription:
    "The datasource selected will be queried using the SQL specified below.\n" +
    "Your metric will be placed in the Explorer based on the datasource, schema, table, and column selected.",
};

export default CustomSqlMetricDataAssetConfig;
