import React, { useState } from "react";
import Sidebar from "../../components/sidebar/";
import PrivateRoute from "../../components/private-route/private-route";
import Workspace from "../workspace";
import AddDataSourceWizard from "../add-datasource";
import Admin from "../admin";
import EditDataSourceWizard from "../edit-datasource";
import AddKPIWizard from "../add-kpi";
import EditKpiWizard from "../edit-kpi";
import AddRuleWizard from "../add-rule";
import EditRuleWizard from "../edit-rule";
import AddWorkspace from "../add-workspace";
import EditWorkspace from "../edit-workspace";
import TakeoverView from "../takeover-view";
import { URIPath, URIPathPermissions } from "../../utils/uri-path";

import "./sidebar-app.scss";

function SidebarApp(props) {
  const { sidebarCollapsed, ...restProps } = props;
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(true);

  return (
    <div className="lightup-sidebar-app-container">
      <Sidebar
        isSideBarExpanded={isSideBarExpanded}
        setIsSideBarExpanded={setIsSideBarExpanded}
        className="lightup-app-left-panel-container"
        workspaceUserPermissions={props.workspaceUserPermissions}
        userPermissions={props.userPermissions}
      />
      <div className="lightup-app-right-panel-container">
        <RightPanelRoutes {...restProps} />
      </div>
      <TakeoverView isSideBarExpanded={isSideBarExpanded} />
    </div>
  );
}

function RightPanelRoutes() {
  return (
    <>
      <PrivateRoute
        path={[
          URIPath.SETTINGS,
          URIPath.MONITOR_LIST,
          URIPath.INCIDENT_LIST,
          URIPath.ALERT_LIST,
          URIPath.ALERT_ITEM,
          URIPath.DASHBOARD,
          URIPath.EXPLORER,
          URIPath.DOWNLOADS,
          URIPath.NG_METRIC_LIST,
          URIPath.NG_MONITOR_LIST,
          URIPath.NG_INCIDENT_LIST,
          URIPath.NG_DRAFT_LIST,
          URIPath.INCIDENT,
        ]}
        component={Workspace}
      />
      <PrivateRoute
        exact
        path={URIPath.ADMIN}
        component={Admin}
        requiredPermissions={URIPathPermissions[URIPath.ADMIN]}
        requiredWorkspacePermissions={[]}
      />
      <PrivateRoute
        exact
        path={URIPath.ADD_DATA_SOURCE}
        component={AddDataSourceWizard}
        requiredWorkspacePermissions={URIPathPermissions[URIPath.ADD_DATA_SOURCE]}
      />
      <PrivateRoute
        exact
        path={URIPath.ADD_METRIC}
        component={AddKPIWizard}
        requiredWorkspacePermissions={URIPathPermissions[URIPath.ADD_METRIC]}
      />
      <PrivateRoute
        exact
        path={URIPath.ADD_MONITOR}
        component={AddRuleWizard}
        requiredWorkspacePermissions={URIPathPermissions[URIPath.ADD_MONITOR]}
      />
      <PrivateRoute
        path={URIPath.EDIT_DATA_SOURCE}
        component={EditDataSourceWizard}
        requiredWorkspacePermissions={URIPathPermissions[URIPath.EDIT_DATA_SOURCE]}
      />
      <PrivateRoute
        path={URIPath.EDIT_METRIC}
        component={EditKpiWizard}
        requiredWorkspacePermissions={URIPathPermissions[URIPath.EDIT_METRIC]}
      />
      <PrivateRoute
        path={URIPath.EDIT_MONITOR}
        component={EditRuleWizard}
        requiredWorkspacePermissions={URIPathPermissions[URIPath.EDIT_MONITOR]}
      />
      <PrivateRoute
        path={URIPath.EDIT_WORKSPACE}
        component={EditWorkspace}
        requiredWorkspacePermissions={URIPathPermissions[URIPath.EDIT_WORKSPACE]}
      />
      <PrivateRoute
        path={URIPath.CREATE_WORKSPACE}
        requiredPermissions={URIPathPermissions[URIPath.CREATE_WORKSPACE]}
        component={AddWorkspace}
      />
    </>
  );
}

export default SidebarApp;
